import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import InfosMinhaConta from './InfosMinhaConta';
import AbaContainer from './AbaContainer';
import Devolucao from './Devolucao';
import Resposta from './Resposta';

export const AbaDevolucao = ({ statusDevolucao }) => {
  if (statusDevolucao === 'Requisitado') {
    return (
      <Resposta
        icone='boleto'
        titulo='Sua solicitação de boleto foi enviada.'
        subtitulo='Você receberá seu boleto por e-mail o mais rápido possível.'
      />
    );
  }

  if (statusDevolucao === 'Pendente') {
    return (
      <Resposta
        icone='transferencia'
        titulo='Por favor aguarde o processamento.'
        subtitulo='Após conferência sua TED será processada e você poderá conferir em “minha conta”.'
      />
    );
  }

  return (
    <AbaContainer>
      <Grid container>
        <Grid item xs={false} md={1} lg={2} />
        <Grid item xs={12} md={3} lg={3}>
          <InfosMinhaConta />
        </Grid>
        <Grid item xs={12} md={7} lg={5}>
          <Devolucao />
        </Grid>
      </Grid>
    </AbaContainer>
  );
};

AbaDevolucao.defaultProps = {
  statusDevolucao: false,
};

AbaDevolucao.propTypes = {
  statusDevolucao: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

const mapStateToProps = (state) => ({
  statusDevolucao: state.minhaConta.statusDevolucao,
});

export default connect(mapStateToProps)(AbaDevolucao);
