import React from 'react';
import { TableBody, TableRow, TableCell } from '@material-ui/core';
import styled from 'styled-components';
import Icone from '../Icone';
import { CINZA_700 } from '../../utils/color';

const StyledTableCell = styled(TableCell)`
  padding: 3.5rem 1rem;
  text-align: center;

  .nenhuma-movimentacao {
    padding-top: 1.5rem;
    padding-left: 0;
    color: ${CINZA_700};
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    padding: 2rem 3rem;

    .nenhuma-movimentacao {
      padding-top: .5rem;
      font-size: .8rem;
    }
  }
`;

const ZeroState = () => (
  <TableBody>
    <TableRow>
      <StyledTableCell colSpan={5} rowSpan={10}>
        <Icone name='simulacao' />
        <p className='nenhuma-movimentacao'>
          Sua conta ainda não possui movimentações.
          <br />
          <strong>Faça o primeiro saque </strong>
          e comece a utilizar seu Crédito Rotativo.
        </p>
      </StyledTableCell>
    </TableRow>
  </TableBody>
);

export default ZeroState;
