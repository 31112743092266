import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { uniqueId } from 'lodash';
import { CINZA_200, AZUL_300 } from '../../utils/color';
import FormatadorDeMoeda from '../FormatadorDeMoeda';
import BotaoDeIcone from '../BotaoDeIcone';
import IndicadorMovimentacao from '../../pages/MinhaConta/Conta/Movimentacoes/IndicadorMovimentacao';
import EtapaUm from '../../pages/Simulacao/Conteudo/Introducao/EtapaUm';

const StyledTableCell = withStyles({
  'root': {
    borderBottom: 'none',
  },
  'body': {
    padding: 10,
    paddingLeft: 24,
    fontSize: 16,
    '&:last-child': {
      padding: 10,
      paddingLeft: 0,
    },
  },
  '@media (max-width: 600px)': {
    body: {
      'fontSize': 12,
      'padding': '8px 0',
      'paddingLeft': 10,
      '&:last-child': {
        padding: 8,
        paddingLeft: 2,
      },
    },
  },
  '@media (max-width: 375px)': {
    root: {
      'paddingLeft': 8,
    },
  },
  '@media (max-width: 320px)': {
    root: {
      'fontSize': 10,
      'paddingLeft': 6,
    },
  },
})(TableCell);

const StyledInsideTableCell = withStyles({
  'root': {
    padding: '8px 0',
    borderBottom: 'none',
  },
  'body': {
    fontSize: 14,
    lineHeight: 1,
    paddingLeft: 40,
  },
  '@media (max-width: 600px)': {
    'root': {
      padding: '6px 0',
    },
    'body': {
      'fontSize': 10,
      'paddingLeft': 0,
    },
  },
})(TableCell);

const useStyles = makeStyles({
  linhaCinza: {
    backgroundColor: `${CINZA_200}`,
  },
  botao: {
    padding: 0,
    color: `${AZUL_300}`,
  },
});

export const Itens = ({ movimentacoes, exibirColunaBotao }) => {
  const classes = useStyles();
  const [open, setOpen] = useState({});

  return (
    <TableBody>
      {movimentacoes.map((movimentacao, index) => (
        <Fragment key={uniqueId('movimentacao-')}>
          <TableRow
            className={index % 2 === 0 ? classes.linhaCinza : null}
            onClick={() => setOpen({ ...open, [index]: !open[index] })}
          >
            <StyledTableCell>
              {movimentacao.data}
            </StyledTableCell>
            <StyledTableCell>
              <IndicadorMovimentacao tipo={movimentacao.tipo} />
              <span>{movimentacao.descricao}</span>
            </StyledTableCell>
            <StyledTableCell align='right'>
              <FormatadorDeMoeda valor={movimentacao.valor} />
            </StyledTableCell>
            {index === 0 ? (
              <EtapaUm>
                <StyledTableCell align='right'>
                  <FormatadorDeMoeda valor={movimentacao.saldo} />
                </StyledTableCell>
              </EtapaUm>
            ): (
              <StyledTableCell align='right'>
                <FormatadorDeMoeda valor={movimentacao.saldo} />
              </StyledTableCell>
              )}
            {exibirColunaBotao && (
              <StyledTableCell align='right'>
                  {movimentacao.descricao_interna?.length > 0 ? (
                  <BotaoDeIcone
                    className={classes.botao}
                    icone={open[index] ? 'arrowUp' : 'arrowDown'}
                  /> ) : null
                  }
              </StyledTableCell>
            )}
          </TableRow>
          {open[index] && movimentacao.descricao_interna?.map((item) => (
            <TableRow
              key={uniqueId('movimentacao-item-')}
              className={index % 2 === 0 ? classes.linhaCinza : null}
            >
              <StyledInsideTableCell />
              <StyledInsideTableCell>
                {item.descricao}
              </StyledInsideTableCell>
              <StyledInsideTableCell align='right'>
                <FormatadorDeMoeda valor={item.valor} />
              </StyledInsideTableCell>
              <StyledInsideTableCell align='right'>
                <FormatadorDeMoeda valor={item.saldo} />
              </StyledInsideTableCell>
              <StyledInsideTableCell align='right' />
            </TableRow>
          ))}
        </Fragment>
      ))}
    </TableBody>
  );
};

Itens.defaultProps = {
  movimentacoes: [],
};

Itens.propTypes = {
  movimentacoes: PropTypes.arrayOf(PropTypes.object),
  exibirColunaBotao: PropTypes.bool.isRequired,
};

export default Itens;
