import { format, parse } from 'date-fns';

export const getDataAtual = () => new Date().toLocaleDateString('pt-BR');

export const formatarData = (data, formatoAtual, novoFormato) => {
  const dataAsDate = parse(data, formatoAtual, new Date());
  const dataFormatada = format(dataAsDate, novoFormato);

  return dataFormatada;
};

export const formatarDataBRparaUSA = (data) =>
  formatarData(data, 'dd/mm/yyyy', 'yyyy-mm-dd');
