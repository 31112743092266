import { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const useMobileStepper = (numOfSteps) => {
  const [activeStep, setActiveStep] = useState(1);

  const onSwipedLeft = () => {
    if (activeStep === numOfSteps) {
      return null;
    }

    return setActiveStep(activeStep + 1);
  };

  const onSwipedRight = () => {
    if (activeStep === 1) {
      return null;
    }

    return setActiveStep(activeStep - 1);
  };

  const swipeableRef = useSwipeable({ onSwipedLeft, onSwipedRight }).ref;

  return { swipeableRef, activeStep, setActiveStep };
};

export default useMobileStepper;
