import React from 'react';
import styled from 'styled-components';
import { AZUL_400, AZUL_300, LARANJA_200, ROXO, VERMELHO } from '../../../../utils/color';

const StyledIndicadorMovimentacao = styled.div`
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: .5rem;
  background-color: ${AZUL_400};
  border-radius: 50%;

  &.indicador-movimento-saque {
    background-color: ${AZUL_300};
  }

  &.indicador-movimento-cobrancajuro {
    background-color: ${ROXO};
  }

  &.indicador-movimento-pagamento {
    background-color: ${LARANJA_200};
  }

  &.indicador-movimento-pgcobrancajuro {
    background-color: ${VERMELHO};
  }
`;

const IndicadorMovimentacao = ({ tipo }) => (
  <StyledIndicadorMovimentacao className={`indicador-movimento-${tipo.toLowerCase()}`} />
);

export default IndicadorMovimentacao;
