import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as AuthActions from '../store/auth/sagas';
import { selectNomeEmpresa } from '../store/auth/selectors';
import { CINZA_700, VERMELHO } from '../utils/color';
import Icone from './Icone';
import { analyticsEvents } from '../utils/analytics';

const StyledItemMenuAutenticado = styled.li`
  padding: 2em 0;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
  }

  a {
    color: ${VERMELHO};
    text-transform: none;
    text-align: right;
  }

  img {
    display: none;
    margin-left: 1em;
  }

  .auth-area {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-transform: none;
    font-weight: normal;

    > .MuiButton-root:hover {
      background-color: transparent;
    }

    > span {
      display: inline-flex;
      max-width: 190px;

      > b {
        margin-left: 4px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    button {
      padding: 0;
      min-width: auto;
      font-size: .75rem;

      > span {
        position: relative;
        text-transform: none;
        transition: all .2s ease-in-out;

        &:before,
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          width: 0;
          height: 1px;
          margin: 1px 0 0;
          transition: all .2s ease-in-out;
          transition-duration: .15s;
          opacity: 0;
          background-color: rgba(255,255,255,.75);
          ${(props) => props.color === 'black' && `
            background-color: rgba(0,0,0,.75);
          `}
        }

        &:before {
          left: 50%;
        }

        &:after {
          right: 50%;
        }

        &:hover {
          &:before,
          &:after {
            width: 50%;
            opacity: 1;
          }
        }
      }
    }
  }

  @media (min-width: 1000px) {
    a,
    span {
      color: #fff;
      ${(props) => props.color === 'black' && `
        color: ${CINZA_700};
      `}
    }

    .auth-area {
      padding-left: 2.5rem;
    }
  }

  @media (min-width: 1220px) {
    img {
      display: inline-block;
    }
  }
`;

const ItemMenuAutenticado = ({ signOut, isSignedIn, nomeEmpresa, color }) => {
  if (isSignedIn) {
    return (
      <StyledItemMenuAutenticado color={color}>
        <div className='auth-area'>
          <span>
            Olá, <b id='nome-empresa'>{nomeEmpresa}</b>
          </span>
          <Button onClick={signOut}>Sair</Button>
        </div>
      </StyledItemMenuAutenticado>
    );
  }

  return (
    <StyledItemMenuAutenticado color={color}>
      <div>
        <Link
          to='/cadastro'
          onClick={() => { analyticsEvents('click_cadastro') }}>
          Cadastre-se ou
        </Link>
        <Link to='/login'>faça seu login</Link>
      </div>
      <Icone name='user' alt='Faça seu login' />
    </StyledItemMenuAutenticado>
  );
};

StyledItemMenuAutenticado.propTypes = {
  color: PropTypes.string,
};

StyledItemMenuAutenticado.defaultProps = {
  color: CINZA_700,
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  nomeEmpresa: selectNomeEmpresa(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemMenuAutenticado);
