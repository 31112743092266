import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Menu from '../../../components/Menu';
import logoBGCinza from '../../../assets/home_bg_cinza.png';
import logoBGCinzaMobile from '../../../assets/home_bg_cinza_mobile.png';
import RoundButton from '../../../components/RoundButton';
import { AZUL_300, CINZA_100, CINZA_RA, CINZA_700, CINZA_RA_20 } from '../../../utils/color';
import YoutubeEmbed from '../../../components/YoutubeEmbed';
import { queries } from '../../../utils/breakpoints';

const ContainerBGLogo = styled.div`
  background-image: url(${logoBGCinza});
  background-size: 52%;
  background-repeat: no-repeat;
  background-position: 111% 0%;
  padding-top: 16vw;
  margin-top: -9vw;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;

  @media ${queries.mobileDown} {
    padding-top: 13vw;
    margin-top: -13vw;
    background-image: url(${logoBGCinzaMobile});
    background-size: 60% 72%;
    background-position: 115% 94%;
  }

  @media ${queries.mobileUp} and ${queries.tabletDown} {
    background-image: url(${logoBGCinzaMobile});
    background-size: 70% 90%;
    background-position: 110% 120%;
    padding-top: 17vw;
    margin-top: -13vw;
  }

  @media ${queries.desktopUp} {
    padding-top: 11vw;
    padding-bottom: 8vw;
    margin-top: -8vw;
  }
`;

const ContainerVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  background-color: ${CINZA_100};
  padding: 0.7rem;
  height: 55vh;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border 1px solid ${CINZA_700};

  @media ${queries.mobileDown} {
    margin-top: 1rem;
    width: 100%;
    height: 32vh;
    padding: 5px;
    border: none;
    box-shadow: none;
  }

  @media ${queries.mobileUp} and ${queries.tabletDown} {
    margin-top: 1rem;
    width: 100%;
    padding: 0;
    height: 40vh;
  }
`;

const ContainerPrimeiraSecao = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  @media ${queries.tabletDown} {
    flex-direction: column;
  }
`;

const SecaoAtendimento = styled.div`
  background-color: transparent;
  margin-top: 9rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${queries.tabletDown} {
    margin-top: 2rem;
    padding: 0.5rem;
  }

  p {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 49px;
    text-align: center;

    @media ${queries.tabletDown} {
      font-size: 1.25rem;
      line-height: 1.8rem;
    }

    &.gray-fg {
      color: ${CINZA_RA_20};
    }
    &.small {
      font-size: 1.7rem;

      @media ${queries.tabletDown} {
        font-size: 1rem;
        line-height: 1.3rem;
        margin-top: 1.5rem;
      }
    }
  }
`;

const StyledCrieSuaConta = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  color: ${AZUL_300};
  font-weight: bold;
  a {
    align-self: center;
  }
  .credito-facil {
    font-size: 2.7vw;
    text-align: center;
  }
  .para-usar {
    font-size: 2vw;
    text-align: left;
    padding-left: 0vw;
  }
  .e-devolver {
    font-size: 2vw;
    text-align: left;
    padding-left: 6vw;
    margin-bottom: 1.5vw;
  }
  .forma-diferente {
    color: ${CINZA_RA};
    font-size: 1.7rem;
    font-weight: 500;
    margin-bottom: 0.5vw;
    line-height: 1.5;
    align-self: center;
  }

  @media ${queries.desktopUp} {
    padding: 2rem 2vw 3rem 8rem;

    .credito-facil {
      font-size: 2.2vw;
      text-align: center;
    }
    .para-usar {
      text-align: left;
      padding-left: 3.5vw;
    }
    .e-devolver {
      text-align: left;
      padding-left: 6vw;
      margin-bottom: 1.5vw;
    }
    .forma-diferente {
      font-size: 1.4vw;
      font-weight: 400;
      margin-bottom: 0.5vw;
      padding-left: 0;
      line-height: 1.5;
      color: ${CINZA_RA};
    }
  }

  @media ${queries.mobileUp} and ${queries.tabletDown}  {
    padding: 6rem 0vw 3rem 0rem;
    span {
      line-height: 2.7rem;
      font-size: 2rem;
    }
    .credito-facil {
      font-size: 3rem;
      text-align: center;
    }
    .para-usar {
      text-align: left;
      padding-left: 1vw;
      font-size: 1.85rem;
    }
    .e-devolver {
      text-align: left;
      padding-left: 4vw;
    }
    button > span {
      font-size: 1.5rem;
      font-weight: 500;
      padding: 0 1vw .2vw 1vw;
    }
    button {
      border-radius: 50px;
      padding: 1vw;
      width: 40vw;
      margin-top: 1rem;
    }
    .forma-diferente {
        line-height: 2rem;
    }
  }

  @media ${queries.mobileDown} {
    padding: 6.5rem .5rem 2vw .5rem;
    font-size: 4.7vw;
      span {
        line-height: 1.8rem;
        text-align: right;
      }
      .credito-facil {
        font-size: 1.8rem;
        line-height: 40px;
      }
      .e-devolver, .para-usar {
        font-size: 1.15rem;
        margin-bottom: 0;
      }
      .forma-diferente {
        margin: 2rem 0;
        font-size: 1rem;
        text-align: left;
      }
  }
`;

const BannerContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: visible;
`;

const BannerPrincipal = ({ isSignedIn }) => {
  const CallToActionButton = () => {
    const path = isSignedIn ? '/minhaconta' : '/cadastro';
    const buttonLabel = isSignedIn ? 'Acesse sua conta' : 'Crie sua conta';

    return (
      <Link to={path}>
        <RoundButton value={buttonLabel} color='primary' bold={false} />
      </Link>
    );
  };

  return (
    <BannerContainer>
      <Menu />
      <ContainerBGLogo>
        <ContainerPrimeiraSecao>
          <StyledCrieSuaConta>
            <span className='credito-facil'>CRÉDITO FÁCIL</span>
            <span className='para-usar'>para usar como quiser</span>
            <span className='e-devolver'>e devolver como puder.</span>
            <span className='forma-diferente'>Uma forma de crédito
            <strong> <br /> DIFERENTE</strong> para sua empresa.</span>
            <CallToActionButton />
          </StyledCrieSuaConta>
          <ContainerVideo>
            <YoutubeEmbed embedId='tno735O-cPQ' />
          </ContainerVideo>
        </ContainerPrimeiraSecao>
        <SecaoAtendimento>
          <p>Aqui te oferecemos <strong>saques ilimitados</strong> para dar uma ajuda </p>
          <p>no caixa da sua empresa </p>
        </SecaoAtendimento>
      </ContainerBGLogo>
    </BannerContainer>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
});

export default connect(
  mapStateToProps,
)(BannerPrincipal);
