import React from 'react';
import styled from 'styled-components';

const StyledContentWrap = styled.div`
  padding-bottom: 90px;
  overflow: hidden;

  @media (max-width: 600px) {
    padding-bottom: 105px;
  }
`;

const ContentWrap = ({ children }) => (
  <StyledContentWrap>
    {children}
  </StyledContentWrap>
);

export default ContentWrap;
