import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, TextField, Link, Box } from '@material-ui/core';
import styled from 'styled-components';
import RoundButton from './RoundButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import InputMask from 'react-input-mask';

const StyledContainer = styled(Container)`
  text-align: center;
  display: flex;
  flex-direction: column;

  .MuiAlert-root {
    margin-top: 1rem;
    padding: 4px 10px;
    font-size: 12px;
    text-align: left;
  }

  .MuiAlertTitle-root {
    font-size: 12px;
  }

  .MuiTypography-gutterBottom {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 6px;
    text-align: left;
  }

  .link-confirmar {
    font-weight: bold;
    margin-top: 16px;
  }
`

const StyledBotao = styled(RoundButton)`
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
`

const StyledTextField = styled(TextField)`
  .MuiInputLabel-shrink {
    font-size: 1rem;
    font-weight: normal;
  }
`

const ConfirmarCelular = ({ celular, onChangeCelular }) => (
  <StyledContainer>
    <p>
      Confirme o número do celular utilizado na sua conta do Crédito Rotativo.
    </p>
    <InputMask
      id='celular'
      mask='(99)999999999'
      value={celular}
      disabled={false}
      maskChar=' '
      onChange={onChangeCelular}
    >
      {() => (
        <StyledTextField
          label='Celular'
          variant='outlined'
        />
      )}
    </InputMask>
  </StyledContainer>
)

export const ConfirmarComToken = ({
  children,
  corDoBotao,
  tipoDeConfirmacao,
  tokenInvalido,
  disabled,
  loadingBotao,
  onNextButtonClick,
  onConfirmButtonClick,
  confirmarCelular,
  celularValido,
  setExibirConfirmacaoDoCelular,
  exibirConfirmacaoDoCelular
}) => {
  const [aguardandoToken, setAguardandoToken] = useState(false);
  const [token, setToken ] = useState('');
  const [celular, setCelular] = useState('')
  const aguardarToken = () => setAguardandoToken(true);

  const handleExibirConfirmacaoDeCelular = () => {
    setExibirConfirmacaoDoCelular(true)
  }

  const onChangeCelular = ({ target: { value }}) => {
    setCelular(value)
  }

  const handleConfirmarCelular = () => {
    confirmarCelular(celular)
  }

  const handleConfirmarToken = () => {
    setExibirConfirmacaoDoCelular(false)
    onConfirmButtonClick(token)
  }

  if (exibirConfirmacaoDoCelular && !celularValido) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        {celularValido === false ? (
          <Alert severity='warning' style={{ margin: 16 }}>
            <AlertTitle>Atenção</AlertTitle>
            <p>O celular digitado não é o mesmo informado no cadastro.</p>
            <p>
              Para atualizar o número do celular do responsável, envie um e-mail para
              <strong> atendimento@creditorotativo.com.br</strong> com o novo número.
            </p>
            <p>É necessário que o e-mail seja enviado pelo mesmo e-mail utilizado na sua conta do Crédito Rotativo.</p>
        </Alert>
        ): null}
        <ConfirmarCelular celular={celular} onChangeCelular={onChangeCelular} />
        <StyledBotao
          value='Confimar'
          color={corDoBotao}
          disabled={disabled}
          loading={loadingBotao}
          onClick={handleConfirmarCelular}
          style={{ marginTop: '1.5rem' }}
        />
      </Box>
    )
  }

  if (aguardandoToken || celularValido) {
    return (
      <StyledContainer>
        <Alert severity='warning'>
          <AlertTitle>Atenção</AlertTitle>
          Ao sair sem confirmar esta operação será cancelada.
        </Alert>

        <p>
          {celularValido ? (
            'Enviamos novamente o código para o celular informado e também por e-mail'
          ):(
            `Dígite o código que nós enviamos a você por ${tipoDeConfirmacao}`
          )}
        </p>

        <StyledTextField
          id='token-conta-bancaria'
          label='Código'
          variant='outlined'
          disabled={loadingBotao}
          value={token}
          error={tokenInvalido}
          helperText={tokenInvalido && 'Token inválido'}
          onChange={(e) => {
            setToken(e.target.value);
          }}
        />

        <StyledBotao
          value='Confirmar'
          color={corDoBotao}
          loading={loadingBotao}
          disabled={disabled}
          style={{ marginTop: '1.5rem'}}
          onClick={handleConfirmarToken}
        />
        {tipoDeConfirmacao === 'celular' && !celularValido ? (
          <>
            <Link
              href="#"
              onClick={handleExibirConfirmacaoDeCelular}
              className="link-confirmar"
            >
              Não recebeu o código?
            </Link>
            <Link href="#" onClick={handleExibirConfirmacaoDeCelular}>
              Clique aqui para confirmar o número do celular
            </Link>
          </>
        ): null }
      </StyledContainer>
    );
  };

  return (
    <>
      {children}

      <div style={{ textAlign: 'center', paddingTop: '1rem'}}>
        <StyledBotao
          value='Avançar'
          color={corDoBotao}
          disabled={disabled}
          loading={loadingBotao}
          onClick={() => onNextButtonClick({ onSuccess: aguardarToken })}
        />
      </div>
    </>
  );
};

ConfirmarComToken.defaultProps = {
  corDoBotao: 'primary',
  tokenInvalido: false,
  disabled: false,
  loadingBotao: false,
  celularValido: null,
  exibirConfirmacaoDoCelular: false,
  confirmarCelular: () => {},
  setExibirConfirmacaoDoCelular: () => {},
};

ConfirmarComToken.propTypes = {
  children: PropTypes.node.isRequired,
  corDoBotao: PropTypes.string,
  tipoDeConfirmacao: PropTypes.string.isRequired,
  tokenInvalido: PropTypes.bool,
  disabled: PropTypes.bool,
  loadingBotao: PropTypes.bool,
  onNextButtonClick: PropTypes.func.isRequired,
  onConfirmButtonClick: PropTypes.func.isRequired,
  celularValido: PropTypes.bool,
  exibirConfirmacaoDoCelular: PropTypes.bool,
  confirmarCelular: PropTypes.func,
  setExibirConfirmacaoDoCelular: PropTypes.func,
};

export default ConfirmarComToken;
