import { Grid } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import BotaoAdicionar from '../../../../pages/MinhaConta/ContasBancarias/BotaoAdicionar';
import ContaBancaria from '../../../../pages/MinhaConta/ContasBancarias/ContaBancaria';
import { RootState } from '../../../../store';
import { setContaBancariaId as setContaBancariaIdAction } from '../../../../store/pedidoAntecipacao/actions';
import EmptyStateContas from './EmptyStateContas';
import { StyledContasBancariasRecebaAntes } from './ContasBancariasRecebaAntes.style';
import { StatusPedidoAntecipacao } from '../../../../store/pedidoAntecipacao/types';

interface ContasBancariasRecebaAntesProps
  extends ConnectedProps<typeof connector> {}

export const ContasBancariasRecebaAntes = ({
  contaBancariaId,
  setContaBancariaId,
  contasBancarias,
  statusPedido,
}: ContasBancariasRecebaAntesProps) => {
  const handleChange = (value) => {
    setContaBancariaId(parseInt(value, 10) || null);
  };

  if (contasBancarias.length > 0 && !contaBancariaId) {
    setContaBancariaId(contasBancarias[0].id);
  }

  if (statusPedido !== StatusPedidoAntecipacao.emFinalizacao) {
    return null;
  }

  return (
    <StyledContasBancariasRecebaAntes>
      <h3 className='contas-bancarias-titulo'>
        Conta bancária para transferência
      </h3>

      {contasBancarias.length >= 0 ? (
        <Grid container alignItems='center'>
          <Grid item xs={12} sm={7} className='container-contas'>
            <div className='contas-bancarias-lista'>
              {contasBancarias.map((contaBancaria) => (
                <ContaBancaria
                  key={contaBancaria.id}
                  radioGroup
                  selectedRadioGroupValue={contaBancariaId}
                  onRadioGroupChange={handleChange}
                  contaBancaria={contaBancaria}
                />
              ))}
            </div>
            <span className='aviso-credito-disponibilizado'>
              O crédito será disponibilizado na conta cadastrada
            </span>
          </Grid>
          <Grid item xs={12} sm={5} container justify='center'>
            <BotaoAdicionar cor='secondary' texto='CADASTRAR NOVA CONTA' />
          </Grid>
        </Grid>
      ) : (
        <div>
          <EmptyStateContas />
          <span className='aviso-credito-disponibilizado'>
            O crédito será disponibilizado na conta cadastrada
          </span>
        </div>
      )}
    </StyledContasBancariasRecebaAntes>
  );
};

const mapStateToProps = (state: RootState) => ({
  contaBancariaId: state.pedidoAntecipacao.contaBancariaId,
  contasBancarias: state.contaBancaria.contasBancarias,
  bancos: state.contaBancaria.bancos,
  statusPedido: state.pedidoAntecipacao.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setContaBancariaId: setContaBancariaIdAction,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ContasBancariasRecebaAntes);
