import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AZUL_300, BRANCO, CINZA_200 } from '../../../utils/color';
import FormatadorDeMoedas from '../../../components/FormatadorDeMoeda';

const StyledValores = styled.table`
  margin-top: 1.5rem;
  font-size: 16px;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  background-color: ${CINZA_200};

  .valores-titulo {
    padding: .8rem;
    font-size: 12px;
    background-color: ${BRANCO};
  }

  .valores-coluna-descricao {
    padding: 1rem;
  }

  .valores-coluna-valor {
    text-align: right;
    padding: 1rem;
  }

  .valor-creditar {
    color: ${AZUL_300};
  }

  .linha-branca {
    background-color: ${BRANCO}
  }
`;

export const Valores = ({
  valorJuros,
  valorFinal,
}) => (
    <StyledValores>
      <thead>
        <tr>
          <th colSpan={2} className='valores-titulo'>
          Valores a serem debitados:
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='valores-coluna-descricao'>Juros até esta data</td>
          <td className='valores-coluna-valor'>
            R$
            {' '}
            <FormatadorDeMoedas valor={valorJuros} />
          </td>
        </tr>
        <tr className='linha-branca'>
          <td className='valores-coluna-descricao'>Valor a creditar</td>
          <td className='valores-coluna-valor valor-creditar'>
            R$
            {' '}
            <FormatadorDeMoedas valor={valorFinal} />
          </td>
        </tr>
      </tbody>
    </StyledValores>
);

Valores.defaultProps = {
  valorJuros: 0,
  valorFinal: 0,
};

Valores.propTypes = {
  valorJuros: PropTypes.number,
  valorFinal: PropTypes.number,
};

const mapStateToProps = (state) => ({
  valorJuros: state.saque.valorJuros,
  valorFinal: state.saque.valorFinal,
});

export default connect(mapStateToProps)(Valores);
