import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Table, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { AZUL_400, BRANCO, CINZA_400 } from '../../utils/color';
import Itens from './Itens';
import ZeroState from './ZeroState';

const StyledTableCell = withStyles({
  'root': {
    borderBottom: 'none',
  },
  'head': {
    padding: '6px 24px',
    color: `${BRANCO}`,
    fontWeight: 'bold',
  },
  '@media (max-width: 600px)': {
    head: {
      'padding': '6px 10px',
      'fontSize': 12,
    },
  },
  '@media (max-width: 375px)': {
    head: {
      'fontSize': 10,
    },
  },
})(TableCell);

const useStyles = makeStyles({
  'container': {
    borderBottom: `2px solid ${CINZA_400}`,
  },
  'paper': {
    width: '100%',
    maxHeight: 460,
  },
  'table': {
    height: 'inherit',
  },
  'tableHead': {
    backgroundColor: `${AZUL_400}`,
  },
  'colunaBotao': {
    width: 20,
  },
  '@media (max-width: 600px)': {
    paper: {
      maxHeight: 390,
    },
  },
});

export const TabelaMovimentacoes = ({ movimentacoes, classes = {} }) => {
  const classesBase = useStyles();
  const tableHeadClassName = clsx(classesBase.tableHead, classes.tableHead);
  const containerClassName = clsx(classesBase.container, classes.container);
  const exibirColunaBotao = movimentacoes.some((movimentacao) => movimentacao.descricao_interna?.length > 0);

  return (
    <TableContainer className={containerClassName}>
      <Paper elevation={0} className={classesBase.paper}>
        <Table className={classesBase.table}>
          <TableHead className={tableHeadClassName}>
            <TableRow>
              <StyledTableCell>DATA</StyledTableCell>
              <StyledTableCell align='left'>DESCRIÇÃO</StyledTableCell>
              <StyledTableCell align='right'>R$</StyledTableCell>
              <StyledTableCell align='right'>SALDO</StyledTableCell>
              {exibirColunaBotao && <StyledTableCell align='right' className={classesBase.colunaBotao} />}
            </TableRow>
          </TableHead>
          {movimentacoes.length === 0 ? <ZeroState /> : <Itens movimentacoes={movimentacoes} exibirColunaBotao={exibirColunaBotao} />}
        </Table>
      </Paper>
    </TableContainer>
  );
};

TabelaMovimentacoes.defaultProps = {
  movimentacoes: [],
  classes: {},
};

TabelaMovimentacoes.propTypes = {
  movimentacoes: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
};

export default TabelaMovimentacoes;
