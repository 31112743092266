const formataDocumento = (documento: string): string => {
  //remove tudo que não for número
  documento = documento.replace(/\D/g, '');
  //cpf  000.000.000-00
  if (documento.length === 11) {
    const regexCpf = /^(\d{3})(\d{3})(\d{3})(\d{2})$/;
    return documento.replace(regexCpf, '$1.$2.$3-$4');
  }

  //cnpj 00.000.000/0000-00
  if (documento.length === 14) {
    const regexCnpj = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/;
    return documento.replace(regexCnpj, '$1.$2.$3/$4-$5');
  }
  return documento;
};

export default formataDocumento;
