import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store';
import { format } from 'date-fns';

import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import { CINZA_600 } from '../../../utils/color';

const StyledValidade = styled.p`
  width: 100%;
  text-align: center;
  font-size: 0.9vw;
  color: ${CINZA_600};

  @media ${queries.desktopUp} {
    font-size: 14px;
  }

  @media ${queries.mobileDown} {
    font-size: 3vw;
  }
`;

const ValidadeProposta = ({ validade }) => {
  if (!validade) {
    return <></>;
  }

  const dataAsDate = new Date(validade.split('T')[0]);

  const data = format(dataAsDate, 'dd/MM/yyyy');

  return <StyledValidade>Proposta válida até {data}</StyledValidade>;
};

const mapStateToProps = (state: RootState) => ({
  validade: state.pedidoAntecipacao.dataValidade,
});

export default connect(mapStateToProps)(ValidadeProposta);
