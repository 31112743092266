import React from 'react';
import { uniqueId } from 'lodash';
import Item from './Item';

const ExpansionPanel = ({ items }) => (
  <div>
    {items.map((item) => (
      <Item key={uniqueId('expansion-panel-item-')} title={item.title} description={item.description} />
    ))}
  </div>
);

export default ExpansionPanel;
