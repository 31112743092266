import * as React from 'react';

const IconeRecebaAntesMenu = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 26.26 26.14'
    xmlSpace='preserve'
    {...props}
  >
    <path
      fill='currentColor'
      d='M13.13 0C5.92 0 .07 5.85.07 13.07s5.85 13.06 13.06 13.06c7.22 0 13.07-5.85 13.07-13.06S20.35 0 13.13 0zm.07 17.51c-2.68 0-4.85-2.17-4.85-4.85s2.17-4.85 4.85-4.85 4.85 2.17 4.85 4.85-2.17 4.85-4.85 4.85z'
    />
  </svg>
);

export default IconeRecebaAntesMenu;
