import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import CampoValorSaque from './CampoValorSaque';
import RoundButton from '../../components/RoundButton';
import { AZUL_400 } from '../../utils/color';
import { simularSaque } from '../../store/simulacao/actions';
import { analyticsEvents } from '../../utils/analytics';
import { fetchConfiguracoesIfNeeded as fetchConfiguracoesIfNeededAction } from '../../store/configuracoes/actions'

const StyledZeroState = styled(Container)`
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 2rem;
  align-items: center;

  h1 {
    margin-bottom: 0;
    color: ${AZUL_400};
    font-size: 1.5rem;
    text-align: center;
  }

  .simulacao-input-container {
    padding-bottom: 1.2rem;
  }

  .MuiFormHelperText-root {
    font-size: 1rem;
  }

  .botao-simular {
    padding: 10px 35px;
    font-size: 1.1rem;
  }

  @media (max-width: 600px) {
    padding-top: 1rem;

    h1 {
      padding-bottom: 1.5rem;
      font-size: 1.5rem;
    }

    .botao-simular {
      padding: 10px 80px;
      text-transform: uppercase;
    }

    .MuiFormHelperText-root {
      font-size: .9rem;
    }
  }

  @media (max-width: 375px) {
    h1 {
      font-size: 1.2rem;
    }

    .MuiFormHelperText-root {
      font-size: .7rem;
    }
  }
`
export const ZeroState = ({
  onClick,
  limiteDeCreditoPadraoFormatado,
  fetchConfiguracoesIfNeeded
}) => {
  const dataSaque = new Date().toLocaleDateString('pt-BR');

  useEffect(() => {
    fetchConfiguracoesIfNeeded()
  }, [fetchConfiguracoesIfNeeded])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onClick({ dataSaque });
    }
  };

  const handleOnClick = () => {
    onClick({ dataSaque })
    analyticsEvents('click_simular')
  }

  return (
    <StyledZeroState maxWidth='md'>
      <div className='simulacao-input-container'>
        <h1>Quanto deseja sacar?</h1>
        <CampoValorSaque
          helperText={`Digite um valor entre R$ 100,00 e R$${limiteDeCreditoPadraoFormatado}`}
          placeholder='R$ 00,00'
          onKeyPress={handleKeyPress}
        />
      </div>
      <RoundButton
        value='Simular'
        color='primaryDark'
        size='small'
        bold
        onClick={handleOnClick}
        className='botao-simular'
      />
    </StyledZeroState>
  );
};

ZeroState.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  limiteDeCreditoPadraoFormatado: state.configuracoes.limiteDeCreditoPadraoFormatado,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onClick: simularSaque,
  fetchConfiguracoesIfNeeded: fetchConfiguracoesIfNeededAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ZeroState);
