import styled from 'styled-components';
import { CINZA_600 } from '../../../utils/color';
import { queries } from '../../../utils/breakpoints';

export const StyledConteudoFiltro = styled.div`
  padding: 0.6vw 0.8vw;
  display: flex;
  flex-direction: column;

  color: ${CINZA_600};
  .container-filtro {
    max-width: 19.8vw;
  }
  span {
    display: flex;
    align-items: center;
    gap: 0.3vw;
    font-size: 0.7vw;
    vertical-align: middle;
    svg {
      font-size: 0.9vw;
    }
  }

  strong {
    font-size: 0.8vw;
  }

  .opcoes {
    display: flex;
    gap: 0.6vw;
    padding: 0 0.2vw;
    flex-wrap: wrap;
    margin-top: 0.6vw;
    margin-bottom: 1vw;
  }

  button {
    font-size: 0.8vw;
    line-height: 1.35;
    margin-top: 0;
  }
  button:hover {
    font-weight: bold;
  }

  @media ${queries.desktopUp} {
    padding: 8px 12px;

    .container-filtro {
      max-width: 254px;
    }

    span {
      gap: 4px;
      font-size: 10px;
      svg {
        font-size: 12px;
      }
    }

    strong {
      font-size: 12px;
    }

    .opcoes {
      gap: 8px;
      padding: 0 4px;
      margin-top: 8px;
      margin-bottom: 14px;
    }

    button {
      font-size: 12px;
    }
  }

  @media ${queries.mobileDown} {
    padding: 5vw;
    min-height: 64.4vh;

    .container-filtro {
      max-width: 100%;
    }

    span {
      gap: 1vw;
      font-size: 4vw;
      svg {
        font-size: 5vw;
      }
    }

    strong {
      font-size: 4.2vw;
      margin-top: 3.7vw;
      display: block;
    }

    .opcoes {
      gap: 3.2vw;
      padding: 0 0;
      margin-top: 3vw;
      margin-bottom: 14vw;
    }

    button {
      line-height: 2.05;
    }
  }
`;
