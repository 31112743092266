import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import {
  setContaBancariaId as setContaBancariaIdAction,
} from "../../../store/saque/actions";
import { CINZA_600 } from "../../../utils/color";
import ContasBancarias from "../ContasBancarias";

const StyledContasBancariasRadioGroup = styled.div`
  padding: 0 .8rem;
  padding-bottom: 1rem;
  text-align: left;

  .contas-bancarias-titulo {
    font-size: 14px;
  }

  .contas-bancarias-lista {
    color: ${CINZA_600};

    .conta {
      margin-bottom: 1rem;
    }

    .radio svg {
      font-size: .8rem;
    }

    .remover-conta-bancaria {
      padding-top: 0;
    }

  }
`;

export const ContasBancariasRadioGroup = ({
  contaBancariaId,
  setContaBancariaId,
  hideButton,
}) => {
  const handleChange = (value) => {
    setContaBancariaId(parseInt(value, 10) || null);
  };

  return (
    <StyledContasBancariasRadioGroup>
      <ContasBancarias
        titulo='Transferir para:'
        radioGroup
        selectedRadioGroupValue={contaBancariaId}
        onRadioGroupChange={handleChange}
        hideButton={hideButton}
      />
    </StyledContasBancariasRadioGroup>
  );
};

ContasBancariasRadioGroup.defaultProps = {
  contaBancariaId: '',
  setContaBancariaId: () => { },
};

ContasBancariasRadioGroup.propTypes = {
  contaBancariaId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  setContaBancariaId: PropTypes.func,
};

const mapStateToProps = (state) => ({
  contaBancariaId: state.saque.contaBancariaId,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setContaBancariaId: setContaBancariaIdAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContasBancariasRadioGroup);
