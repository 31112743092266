import { Box } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { RootState } from '../../../../store';
import { fecharModaleForm } from '../../../../store/cadastroSacado/actions';
import { AZUL_300, CINZA_600 } from '../../../../utils/color';
import IconeCadastroMenu from '../../../IconesSvg/IconeCadastroMenu';
import Modal from '../../../Modal';
import RoundButton from '../../../RoundButton';

const StyledConteudo = styled(Box)`
  svg {
    width: 70px;
    color: ${AZUL_300};
  }
  p {
    font-size: 1.1rem;
    font-weight: bold;
    color: ${CINZA_600};
  }
`;

interface ModalCadastroSacadoProps {
  openModal: boolean;
  onClickClose: () => void;
}

const ModalCadastroSacado = ({
  openModal,
  onClickClose,
}: ModalCadastroSacadoProps) => {
  const ConteudoModal = () => {
    return (
      <StyledConteudo>
        <IconeCadastroMenu />
        <p className='conteudo-modal'>Cliente cadastrado com sucesso.</p>
        <RoundButton
          value='OK'
          color='primary'
          size='small'
          onClick={onClickClose}
          data-cy='botao-ok-modal-cadastro-sacado'
        />
      </StyledConteudo>
    );
  };

  return (
    <>
      <Modal
        fullWidth={false}
        maxWidth='sm'
        open={openModal}
        conteudo={<ConteudoModal />}
        onClose={onClickClose}
        exibirBotao={false}
        icone=' '
        style={{
          padding: '0rem 2rem 1rem',
        }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  openModal: state.cadastroSacado.modalAberto,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClickClose: fecharModaleForm,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCadastroSacado);
