import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  solicitarTokenContaBancaria as solicitarTokenContaBancariaAction,
  enviarContaBancaria as enviarContaBancariaAction,
  fecharModalAdicionar,
  dispensarNotificacaoDeSucesso,
  confirmarCelular as confirmarCelularAction,
} from '../../../../store/contaBancaria/actions';
import Notification from '../../../../components/Notification';
import Modal from '../../../../components/Modal';
import FormContaBancaria from './FormContaBancaria';
import ConfirmarComToken from '../../../../components/ConfirmarComToken'

export const ModalAdicionarContaBancaria = ({
  open,
  enviadoComSucesso,
  onClose,
  onSuccessMessageClose,
  contasBancarias,
  enviandoConta,
  solicitarTokenContaBancaria,
  enviarContaBancaria,
  errors,
  confirmarCelular,
  celularValido,
}) => {
  const conteudoModal = (
    <p style={{ padding: '0 1rem', margin: 0 }}>Você atingiu o limite de contas cadastradas</p>
  );

  const [exibirConfirmacaoDoCelular, setExibirConfirmacaoDoCelular] = useState(false);

  const handleOnClose = () => {
    onClose()
    setExibirConfirmacaoDoCelular(false)
  }

  if (contasBancarias.length >= 3) {
    return (
      <Modal
        open={open}
        fullWidth
        maxWidth='xs'
        titulo='Adicionando conta bancária'
        conteudo={conteudoModal}
        botaoColor='primaryDark'
        onConfirm={onClose}
        onClose={onClose}
      />
    );
  };

  const formulario = (
    <ConfirmarComToken
      corDoBotao='primaryDark'
      tipoDeConfirmacao='celular'
      loadingBotao={enviandoConta}
      onNextButtonClick={solicitarTokenContaBancaria}
      onConfirmButtonClick={enviarContaBancaria}
      tokenInvalido={Boolean(errors?.token)}
      confirmarCelular={confirmarCelular}
      celularValido={celularValido}
      exibirConfirmacaoDoCelular={exibirConfirmacaoDoCelular}
      setExibirConfirmacaoDoCelular={setExibirConfirmacaoDoCelular}
    >
      <FormContaBancaria />
    </ConfirmarComToken>
  );

  return (
    <>
      <Notification
        open={enviadoComSucesso}
        onClose={onSuccessMessageClose}
        severity='success'
        message='Conta Bancária adicionada com sucesso.'
      />

      <Modal
        open={open}
        fullWidth
        maxWidth='xs'
        titulo='Adicionando conta bancária'
        conteudo={formulario}
        botaoColor='primaryDark'
        botaoLabel='Adicionar'
        exibirBotao={false}
        onClose={handleOnClose}
        botaoLoading={enviandoConta}
      />
    </>
  );
};

ModalAdicionarContaBancaria.defaultProps = {
  contasBancarias: [],
  errors: {},
  confirmarCelular: () => {},
  celularValido: null,
};

ModalAdicionarContaBancaria.propTypes = {
  open: PropTypes.bool.isRequired,
  enviadoComSucesso: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccessMessageClose: PropTypes.func.isRequired,
  contasBancarias: PropTypes.arrayOf(PropTypes.object),
  enviandoConta: PropTypes.bool,
  solicitarTokenContaBancaria: PropTypes.func.isRequired,
  enviarContaBancaria: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    agencia: PropTypes.arrayOf(PropTypes.string),
    bancoId: PropTypes.arrayOf(PropTypes.string),
    conta: PropTypes.arrayOf(PropTypes.string),
  }),
  confirmarCelular: PropTypes.func,
  celularValido: PropTypes.bool
};

const mapStateToProps = (state) => ({
  open: state.contaBancaria.modalAdicionarAberta,
  enviadoComSucesso: state.contaBancaria.enviadoComSucesso,
  contasBancarias: state.contaBancaria.contasBancarias,
  enviandoConta: state.contaBancaria.enviandoConta,
  errors: state.contaBancaria.errors,
  celularValido: state.contaBancaria.celularValido
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  solicitarTokenContaBancaria: solicitarTokenContaBancariaAction,
  enviarContaBancaria:enviarContaBancariaAction,
  onClose: fecharModalAdicionar,
  onSuccessMessageClose: dispensarNotificacaoDeSucesso,
  confirmarCelular: confirmarCelularAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalAdicionarContaBancaria);
