import * as React from 'react';

const IconeSaqueMenu = (props) => (
  <svg
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25.16 21.94'
    {...props}
  >
    <g
      style={{
        fill: 'currentColor',
      }}
    >
      <path d='M23.36 13.29c0 .37-.1.17-.16-.07-.06.07-.08-.09-.15 0v2.88c-.2.45-3.88 1.09-10.29 1.11-6.78 0-10.25-.66-10.29-1v-3a1.07 1.07 0 0 1-.06-.3h-.19v3.14a.32.32 0 0 0-.06.18c0 1.08 6.63 1.36 10.35 1.36h.24c4.8 0 9.8-.38 10.49-1.15l.1-.06Z' />
      <path d='M25 12.64a28 28 0 0 1-5.84 1c-1.65.16-3.79.11-6.37.12-5.87 0-9.55-.51-10.11-.92v-.06a2.58 2.58 0 0 1 .85-.3v-.61c-1.25.28-1.47.59-1.46.88v.16c0 .41.4.83 3.32 1.16a65.66 65.66 0 0 0 7.12.34h.3a64.72 64.72 0 0 0 7.37-.41c2.29-.28 4.91-.87 5.12-1.2-.8.06.43-.38-.3-.16Z' />
      <path d='M25.28 9.13h-.09a.88.88 0 0 1-.21.39v3.07c-.21.47-4 1.14-10.71 1.17a72.54 72.54 0 0 1-7.66-.35c-3-.34-3.06-.75-3.06-.75V9.64a1.93 1.93 0 0 1-.47-.29l.18 3.12a.33.33 0 0 0-.06.19c0 .18 0 .67 3.32 1.05a70.08 70.08 0 0 0 7.38.35h.32c5 0 10.23-.45 10.93-1.26h.09Z' />
      <path d='M25.43 9c0-.4-.39-.82-3.14-1.16a1 1 0 0 1-.41.57A8.4 8.4 0 0 1 24.8 9v.12c-.56.42-4.45 1-10.53 1s-10-.55-10.54-1v-.07C3.84 9 4 9 4.36 8.91c-.36-.05-.79-.11-1.17-.13-.13.13-.09.12-.09.25a.22.22 0 0 0 0 .08.22.22 0 0 0 0 .08c0 .42.42.85 3.45 1.2a68 68 0 0 0 7.4.35h.32a69.68 69.68 0 0 0 7.72-.4c3-.37 3.45-.81 3.44-1.23Z' />
      <path d='M22.24 5.13V8c-.2.46-4 1.12-10.53 1.14a68.19 68.19 0 0 1-7.55-.34c-2.92-.34-3-.74-3-.74s0-2.95-.05-3a2.65 2.65 0 0 1-.4-.42s.16 3.41.16 3.47 0 .66 3.27 1a68.19 68.19 0 0 0 7.26.34h.32c4.91 0 10.06-.44 10.75-1.23h.09l.13-3.54a2.6 2.6 0 0 1-.45.45Z' />
      <path d='M11.39 6.23a65.65 65.65 0 0 1-7.28-.35C1.12 5.54.71 5.11.7 4.7v-.16c0-.41.41-.84 3.4-1.21a67.57 67.57 0 0 1 7.59-.4 71.32 71.32 0 0 1 7.6.35c3 .34 3.4.77 3.4 1.18V4.61c0 .42-.41.85-3.4 1.21a67.57 67.57 0 0 1-7.59.4Zm-10-1.56c.56.42 4.4 1 10.36 1s9.8-.61 10.36-1v-.06c-.56-.42-4.39-1-10.36-1s-9.8.6-10.36 1v.05Zm20.76 0Z' />
    </g>
  </svg>
);

export default IconeSaqueMenu;
