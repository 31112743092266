import format from 'date-fns/format';
import { RootState } from '..';
import { iniciaNovaAntecipacao } from '../antecipacao/actions';
import { avancarEtapa } from '../recebaAntes/actions';
import { selectHaPedidoEmAndamento } from '../recebaAntes/selectors';
import {
  getPedidoAndamento,
  patchCancelaPedido,
  patchFinalizaPedido,
  postPedidosAntecipacoes,
} from './api';
import {
  PedidoAntecipacaoState,
  PedidoAntecipacaoActionTypes as types,
  StatusPedidoAntecipacao,
  CriaPedidoAntecipacaoDto,
} from './types';

const setCarregando = (carregando) => ({
  type: types.SET_CARREGANDO,
  carregando,
});

const fetchPedidoSucess = (pedido: PedidoAntecipacaoState) => ({
  type: types.FETCH_PEDIDO_SUCESS,
  pedido,
});

const fetchPedidoEmAndamento = () => async (dispatch, getState) => {
  try {
    if (!selectHaPedidoEmAndamento(getState())) {
      dispatch(setCarregando(false));
      return;
    }
    const response = await getPedidoAndamento();
    if (response.data.success) {
      const info = response.data.data.data;
      const antecipacoes = info.antecipacoes.map((antecipacao) => {
        const parcelas = antecipacao.parcelas.map((parcela) => {
          const arrayData = parcela.data_vencimento.split('/');
          return {
            id: parcela.id,
            valor: parcela.valor,
            vencimento: new Date(
              +arrayData[2],
              +arrayData[1] - 1,
              +arrayData[0]
            ),
            erro: false,
          };
        });

        return {
          sacado: {
            id: 0,
            nomeRazaoSocial: antecipacao.sacado,
            documentoSacado: antecipacao.documento_sacado,
          },
          valorNotaFiscal: antecipacao.valor,
          nomeArquivoNotaFiscal: '',
          carregandoArquivo: false,
          parcelas,
          somatoriaValorDigitado: antecipacao.valor,
          status: antecipacao.status,
          motivoRecusa: antecipacao.motivo_recusa,
        };
      });

      const pedido: PedidoAntecipacaoState = {
        carregando: false,
        id: info.id,
        dataValidade: info.data_validade,
        valorAprovado: info.valor_aprovado,
        jurosOperacao: info.juros_operacao || 0,
        iof: info.iof || 0,
        jurosAcumulados: info.juros_acumulados || 0,
        valorBoletosPendentes: info.valor_boletos_pendentes || 0,
        saldo: info.saldo,
        antecipacoes,
        status: info.status || StatusPedidoAntecipacao.aguardandoAssinatura,
        valorDevolucao:
          info.valor_minimo_devolucao || info.valor_sugerido_devolucao || 0,
        valorMinimoDevolucao: info.valor_minimo_devolucao || 0,
        valorSugeridoDevolucao: info.valor_sugerido_devolucao || 0,
        usaCreditoRotativo: info.usa_credito_rotativo || false,
        limiteDisponivel: info.limite_disponivel || 0,
        limiteTotal: info.limite_total || 0,
        valorPendente: info.valor_pendente || 0,
      };
      dispatch(fetchPedidoSucess(pedido));
    }
  } catch (error) {
    console.error('Erro ao carregar pedido em andamento', error);
  }
};

const atualizarPedido = (antecipacoesAtualizadas) => ({
  type: types.ALTERAR_ANTECIPACOES,
  antecipacoes: antecipacoesAtualizadas,
});

const excluirAntecipacao = (index) => (dispatch, getState) => {
  const state: RootState = getState();
  const novaLista = [...state.pedidoAntecipacao.antecipacoes];
  novaLista.splice(index, 1);
  dispatch(atualizarPedido(novaLista));
};

const salvarAntecipacao = () => (dispatch, getState) => {
  const state: RootState = getState();
  const novaLista = [
    ...state.pedidoAntecipacao.antecipacoes,
    state.antecipacao,
  ];
  dispatch(atualizarPedido(novaLista));
  dispatch(avancarEtapa());
};

const alterarStatus = (status) => ({
  type: types.ALTERAR_STATUS,
  status,
});

const limpaPedido = () => ({
  type: types.LIMPAR_PEDIDO,
});

const setModalFinalizacaoAberto = (aberto) => ({
  type: types.SET_MODAL_FINALIZACAO,
  aberto,
});

const cancelaPedido = () => (dispatch) => {
  patchCancelaPedido().then(() => {
    dispatch(alterarStatus(StatusPedidoAntecipacao.naoEnviado));
    dispatch(limpaPedido());
    dispatch(iniciaNovaAntecipacao());
  });
};

const finalizarPedidoFailure = (mensagem) => ({
  type: types.FINALIZAR_PEDIDO_FAILURE,
  mensagemErro: mensagem,
});

const finalizaPedido = () => async (dispatch, getState) => {
  try {
    dispatch(setCarregando(true));
    const { pedidoAntecipacao }: RootState = getState();
    const pedido: CriaPedidoAntecipacaoDto = {
      antecipacoes: pedidoAntecipacao.antecipacoes.map((antecipacao) => ({
        valor: antecipacao.valorNotaFiscal,
        sacado_id: antecipacao.sacado.id,
        arquivo_id: antecipacao.idArquivoNotaFiscal,
        parcelas: antecipacao.parcelas.map((parcela) => ({
          valor: parcela.valor,
          data_vencimento: format(parcela.vencimento, 'dd/MM/yyyy'),
        })),
      })),
    };
    const response = await postPedidosAntecipacoes(pedido);
    if (response.data.success) {
      dispatch(setCarregando(false));
      dispatch(alterarStatus(StatusPedidoAntecipacao.pendente));
      dispatch(setModalFinalizacaoAberto(true));
    }
  } catch (error) {
    dispatch(setCarregando(false));
    console.error('Erro ao finalizar pedido', error?.response);
    const erro = error?.response?.data?.errors?.antecipacoes?.[0];
    let stringErro = Array.isArray(erro) ? erro.join('. ') : erro;
    dispatch(finalizarPedidoFailure(stringErro || 'Erro ao finalizar pedido'));
  }
};

const dispersarMensagemErro = () => ({
  type: types.DISPERSAR_MENSAGEM_ERRO,
});

const setValorDevolvido = (valorDevolucao: number) => ({
  type: types.SET_VALOR_DEVOLUCAO,
  valorDevolucao,
});

const setErroValorDevolvido = (erro: boolean) => ({
  type: types.SET_ERRO_VALOR_DEVOLUCAO,
  erro,
});

const setContaBancariaId = (contaBancariaId) => ({
  type: types.SET_CONTA_ID,
  contaBancariaId,
});

const concluirPedidoFailure = () => ({
  type: types.CONCLUIR_PEDIDO_FAILURE,
  mensagemErro: 'Erro ao concluir pedido',
});

const concluiPedido = () => async (dispatch, getState) => {
  const { pedidoAntecipacao }: RootState = getState();
  try {
    dispatch(setCarregando(true));
    await patchFinalizaPedido({
      valor_devolucao: pedidoAntecipacao.valorDevolucao,
      conta_bancaria_id: pedidoAntecipacao.contaBancariaId,
    });
    dispatch(alterarStatus(StatusPedidoAntecipacao.aguardandoAssinatura));
    dispatch(setCarregando(false));
  } catch (error) {
    console.error('Erro ao concluir pedido', error);
    dispatch(concluirPedidoFailure());
    dispatch(setCarregando(false));
  }
};

export {
  fetchPedidoEmAndamento,
  excluirAntecipacao,
  salvarAntecipacao,
  alterarStatus,
  cancelaPedido,
  finalizaPedido,
  setValorDevolvido,
  setContaBancariaId,
  setModalFinalizacaoAberto,
  concluiPedido,
  setErroValorDevolvido,
  dispersarMensagemErro,
};
