import styled from 'styled-components';

import { queries } from '../../../../utils/breakpoints';
import {
  AZUL_300,
  CINZA_200,
  CINZA_400,
  CINZA_500,
  VERMELHO,
} from '../../../../utils/color';

export const ValoresBoletoStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .titulo {
    color: ${AZUL_300};
    font-size: 1.15vw;
  }

  .boleto-container {
    display: flex;
    flex-direction: column;
    border: 1px solid ${CINZA_400};
    width: 27.7vw;
    border-radius: 5px;
    padding: 1vw 2.2vw 0vw;
  }

  .total-antecipacao {
    margin-top: 0.8vw;
    margin-bottom: 0.9vw;
    padding: 0.8vw 3vw 0.9vw 2.9vw;
    border-radius: 4px;
    background-color: ${CINZA_200};
    font-size: 0.95vw;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    width: 27.7vw;
  }

  .valor-fixo {
    color: ${AZUL_300};
  }

  .somatoria {
    font-size: 0.95vw;
    width: 27.7vw;
    padding: 1.3vw 3vw;
    display: flex;
    justify-content: flex-end;
    gap: 3.7vw;
  }

  .valor-variavel {
    color: ${AZUL_300};
  }

  .valor-variavel-errado {
    color: ${CINZA_500};
  }

  .texto-soma-errada {
    font-size: 0.95vw;
    padding: 0.5rem;
    color: ${VERMELHO};
  }

  @media ${queries.mobileDown} {
    .titulo {
      font-size: 4.2vw;
    }

    .boleto-container {
      width: 83.8vw;
      padding: 4vw 3.6vw 1vw 6.5vw;
    }

    .total-antecipacao {
      margin-bottom: 3.3vw;
      padding: 2.9vw 6vw 2.4vw 6.7vw;
      font-size: 3.3vw;
      width: 83.8vw;
    }

    .somatoria {
      width: 83.8vw;
      font-size: 3.4vw;
      padding: 2.5vw 5.6vw;
      gap: 10vw;
    }

    .texto-soma-errada {
      font-size: 3.1vw;
    }
  }

  @media ${queries.desktopUp} {
    .titulo {
      font-size: 16px;
    }

    .boleto-container {
      width: 400px;
      padding: 12px 24px 0px;
    }

    .total-antecipacao {
      margin-top: 12px;
      margin-bottom: 14px;
      padding: 12px 40px 14px 38px;
      font-size: 14px;
      width: 400px;
    }

    .somatoria {
      width: 400px;
      font-size: 14px;
      padding: 16px 35px;
      gap: 60px;
    }

    .texto-soma-errada {
      font-size: 12px;
    }
  }
`;
