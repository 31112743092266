import React from 'react';
import styled from 'styled-components';

import AnexarNota from './AnexarNota';
import ValorNota from './ValorNota';

const StyledNotaFiscal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotaFiscal = () => {
  return (
    <StyledNotaFiscal>
      <ValorNota />
      <AnexarNota />
    </StyledNotaFiscal>
  );
};

export default NotaFiscal;
