import React, { useEffect }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { simularSaque } from '../../../../store/simulacao/actions'
import Modal from '../../../../components/Modal'
import Conteudo from './Conteudo';

export const ModalSaque = ({
  onConfirm,
  enviadoComSucesso,
  open,
  onClose,
}) => {
  useEffect(() => {
    if(enviadoComSucesso) {
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ enviadoComSucesso ])

  return (
    <Modal
      fullWidth
      maxWidth='xs'
      open={open}
      conteudo={<Conteudo />}
      botaoLabel='Adicionar Saque'
      botaoColor='primaryDarkGradient'
      onClose={onClose}
      style={{ padding: '0 0 1rem 0' }}
      onConfirm={onConfirm}
    />
  );
};

ModalSaque.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  enviadoComSucesso: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  enviadoComSucesso: state.simulacao.enviadoComSucesso,
});

const onConfirm = () => (dispatch, getState) => {
  const { dataSaque } = getState().simulacao;
  dispatch(simularSaque({ dataSaque }));
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onConfirm,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalSaque);
