import { Chip, Divider, Drawer, Hidden, Popover } from '@material-ui/core';
import IconeCalendario from '@material-ui/icons/CalendarToday';
import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../store';
import {
  setFiltroModalOpen,
  setFiltroPeriodo,
  setFiltroPeriodoErro,
  setFiltroStatus,
} from '../../../store/recebaAntes/actions';
import { formatarData } from '../../../utils/data';
import RoundButton from '../../RoundButton';
import ConteudoFiltro from './ConteudoFiltro';
import { StyledFiltroBoletos } from './FiltroBoletos.style';

interface Props extends ConnectedProps<typeof connector> {}

const FiltroBoletos = ({
  isFiltroModalOpen,
  setModalAberto,
  filtroPeriodo,
  filtroStatus,
  setStatus,
  setPeriodo,
  setErroPeriodo,
}: Props) => {
  const [ancoraFiltro, setAncoraFiltro] = useState(null);
  const tipo = ancoraFiltro ? ancoraFiltro.getAttribute('id') : '';

  const dataInicio = filtroPeriodo
    ? formatarData(filtroPeriodo?.dataInicial, 'yyyy-MM-dd', 'dd/MM/yyyy')
    : '';

  const dataFim = filtroPeriodo
    ? formatarData(filtroPeriodo?.dataFinal, 'yyyy-MM-dd', 'dd/MM/yyyy')
    : '';

  const handleClick = (event) => {
    setAncoraFiltro(event.currentTarget.parentElement);
    setModalAberto(true);
  };

  const handleClose = () => {
    setAncoraFiltro(null);
    setModalAberto(false);
    setErroPeriodo('');
  };

  const handleLimpaFiltroStatus = () => {
    setStatus(null);
  };

  const handleLimpaFiltroPeriodo = () => {
    setPeriodo(null);
  };

  return (
    <StyledFiltroBoletos>
      <Divider />
      <div className='filtro'>
        <span className='filtre'>Filtrar por</span>
        <div className='filtro-periodo' id='data'>
          {!filtroPeriodo ? (
            <RoundButton
              color='secondaryCinza'
              value='Data'
              size='extra-small'
              onClick={handleClick}
            />
          ) : (
            <Chip
              className='chip'
              icon={<IconeCalendario className='calendario' />}
              label={`${dataInicio} - ${dataFim}`}
              onClick={handleClick}
              onDelete={handleLimpaFiltroPeriodo}
            />
          )}
        </div>
        <div className='filtro-status' id='status'>
          {!filtroStatus ? (
            <RoundButton
              color='secondaryCinza'
              value='Status'
              size='extra-small'
              onClick={handleClick}
            />
          ) : (
            <Chip
              className='chip'
              icon={<IconeCalendario className='calendario' />}
              label={filtroStatus}
              onClick={handleClick}
              onDelete={handleLimpaFiltroStatus}
            />
          )}
        </div>
      </div>
      <Hidden xsDown>
        <Popover
          open={isFiltroModalOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorEl={ancoraFiltro}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <ConteudoFiltro tipo={tipo} />
        </Popover>
      </Hidden>
      <Hidden smUp>
        <Drawer
          anchor='bottom'
          open={isFiltroModalOpen}
          onClose={handleClose}
          PaperProps={{
            style: {
              borderRadius: '16px 16px 0 0',
            },
          }}
        >
          <ConteudoFiltro tipo={tipo} />
        </Drawer>
      </Hidden>
    </StyledFiltroBoletos>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFiltroModalOpen: state.recebaAntes.isFiltroModalOpen,
  filtroPeriodo: state.recebaAntes.filtroPeriodo,
  filtroStatus: state.recebaAntes.filtroStatus,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setModalAberto: setFiltroModalOpen,
      setStatus: setFiltroStatus,
      setPeriodo: setFiltroPeriodo,
      setErroPeriodo: setFiltroPeriodoErro,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FiltroBoletos);
