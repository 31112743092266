import React from 'react';
import styled from 'styled-components';
import { Container, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { AZUL_400, AZUL_300 } from '../../../utils/color';
import TabelaMovimentacoes from '../../../components/TabelaMovimentacoes'

const StyledMovimentacoes = styled(Container)`
  padding: 0;

  .simulacao-movimentacoes-titulo {
    margin-top: 0;
    margin-bottom: .3rem;
    padding-left: 1.3rem;
  }

  @media (max-width: 600px) {
    padding-left: 0;
    text-align: center;
  }
`;

const useStyles = makeStyles({
  'container': {
    borderBottom: 'none',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  'tableHead': {
    backgroundImage: `linear-gradient(141deg, ${AZUL_400}, ${AZUL_300} 62%)`,
  },
  '@media (max-width: 960px)': {
    container: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    'tableHead': {
      backgroundImage: 'none',
    },
  },
});

export const Movimentacoes = ({ movimentacoes }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={11} lg={8}>
      <StyledMovimentacoes>
        <h4 className='simulacao-movimentacoes-titulo'>Veja como fica sua conta:</h4>
        <TabelaMovimentacoes movimentacoes={movimentacoes} classes={classes} />
      </StyledMovimentacoes>
    </Grid>
  );
};

Movimentacoes.defaultProps = {
  movimentacoes: [],
};

Movimentacoes.propTypes = {
  movimentacoes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  movimentacoes: state.simulacao.movimentacoes,
});

export default connect(mapStateToProps)(Movimentacoes);
