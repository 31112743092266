import { Antecipacao } from '../antecipacao/types';

export enum PedidoAntecipacaoActionTypes {
  FETCH_PEDIDO_SUCESS = 'pedidoAntecipacao/FETCH_PEDIDO_SUCESS',
  ALTERAR_ANTECIPACOES = 'pedidoAntecipacao/ALTERAR_ANTECIPACOES',
  ALTERAR_STATUS = 'pedidoAntecipacao/ALTERAR_STATUS',
  LIMPAR_PEDIDO = 'pedidoAntecipacao/LIMPAR_PEDIDO',
  SET_CARREGANDO = 'pedidoAntecipacao/SET_CARREGANDO',
  SET_VALOR_DEVOLUCAO = 'pedidoAntecipacao/SET_VALOR_DEVOLUCAO',
  SET_CONTA_ID = 'pedidoAntecipacao/SET_CONTA_ID',
  SET_MODAL_FINALIZACAO = 'pedidoAntecipacao/SET_MODAL_FINALIZACAO',
  FINALIZAR_PEDIDO_FAILURE = 'pedidoAntecipacao/FINALIZAR_PEDIDO_FAILURE',
  CONCLUIR_PEDIDO_FAILURE = 'pedidoAntecipacao/CONCLUIR_PEDIDO_FAILURE',
  SET_ERRO_VALOR_DEVOLUCAO = 'pedidoAntecipacao/SET_ERRO_VALOR_DEVOLUCAO',
  SET_MENSAGEM_ERRO = 'pedidoAntecipacao/SET_MENSAGEM_ERRO',
  DISPERSAR_MENSAGEM_ERRO = 'pedidoAntecipacao/DISPERSAR_MENSAGEM_ERRO',
}

export type PedidoAntecipacao = {
  antecipacoes: Antecipacao[];
  status: StatusPedidoAntecipacao;
  valorDevolucao: number;
  carregando: boolean;
  contaBancariaId?: number;
  modalFinalizacaoAberto?: boolean;
  erroValorDevolucao?: string;
  mensagemErro?: string;
};

type PedidoAntecipacaoApi = {
  id: number;
  dataValidade: string;
  valorAprovado: number;
  jurosOperacao?: number;
  iof?: number;
  jurosAcumulados?: number;
  valorBoletosPendentes?: number;
  saldo: number;
  valorMinimoDevolucao: number;
  valorSugeridoDevolucao: number;
  usaCreditoRotativo: boolean;
  limiteDisponivel: number;
  limiteTotal: number;
  valorPendente: number;
};

export enum StatusPedidoAntecipacao {
  naoEnviado = 'Nao enviado',
  pendente = 'Pendente',
  aguardandoCliente = 'Aguardando cliente',
  aguardandoAssinatura = 'Aguardando assinatura do contrato',
  emFinalizacao = 'Em finalizacao',
}

export type PedidoAntecipacaoState = Partial<PedidoAntecipacaoApi> &
  PedidoAntecipacao;

export type CriaPedidoAntecipacaoDto = {
  antecipacoes: {
    sacado_id: number;
    valor: number;
    arquivo_id: number;
    parcelas: {
      valor: number;
      data_vencimento: string;
    }[];
  }[];
};
