import * as React from 'react';

const IconeDevolucaoMenu = (props) => (
  <svg
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 23.16 18.94'
    {...props}
  >
    <g
      style={{
        fill: 'currentColor',
      }}
    >
      <path d='M7.26 5.38h1.83v8.27H7.26zM9.6 5.38h.68v8.27H9.6zM10.96 5.38h.68v8.27h-.68zM12.48 5.38h1.53v8.27h-1.53zM14.66 5.38h.68v8.27h-.68zM16.02 5.38h.68v8.27h-.68zM17.54 5.38h1.53v8.27h-1.53zM23.28 5.71h-1.06V3.48a.13.13 0 0 0-.13-.13h-1.7V2.29h1.7a1.19 1.19 0 0 1 1.19 1.19ZM4.1 5.71h-1V3.48a1.18 1.18 0 0 1 1.13-1.19h1.71v1.06H4.23a.13.13 0 0 0-.13.13ZM5.94 17H4.23a1.18 1.18 0 0 1-1.18-1.18v-2.23h1v2.23a.12.12 0 0 0 .13.12h1.76ZM22.09 17h-1.7v-1.06h1.7a.12.12 0 0 0 .13-.12v-2.23h1.06v2.23A1.19 1.19 0 0 1 22.09 17Z' />
    </g>
  </svg>
);

export default IconeDevolucaoMenu;
