import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import CardInfos from '../../../components/CardInfos';
import FormatadorDeMoedas from '../../../components/FormatadorDeMoeda';

const StyledInfosMinhaConta = styled(Grid)`
  padding-top: 4rem;

  @media (max-width: 960px) {
    padding: .5rem .8rem 0 .8rem;
  }
`;

export const InfosMinhaConta = ({
  limiteDisponivel,
  limiteTotal,
  valorPagamento,
  dataPagamento,
  emAtraso,
}) => {
  const dicaProximoPagamento = `
    Este é o valor a ser pago, referente aos
    juros sobre os saques realizados, caso não
    haja mais nenhuma movimentação em
    sua conta até a data do vencimento.
    Boleto mínimo R$ 10,00.
`;
  return (
    <StyledInfosMinhaConta container spacing={1}>
      <Grid item xs={6} md={10} lg={9}>
        <CardInfos
          titulo='Limite disponível'
          valor={limiteDisponivel}
          tituloRodape='Limite total'
          textoRodape={<FormatadorDeMoedas valor={limiteTotal} comSimbolo />}
        />
      </Grid>

      <Grid item xs={6} md={10} lg={9}>
        <CardInfos
          titulo='Próximo Pagamento'
          valor={valorPagamento}
          tituloRodape='Data'
          textoRodape={dataPagamento}
          dica={dicaProximoPagamento}
          pagamentoPendente={emAtraso}
          valorMinimo={10}
        />
      </Grid>
    </StyledInfosMinhaConta>
  );
};

const mapStateToProps = (state) => ({
  limiteDisponivel: state.minhaConta.limiteDisponivel,
  limiteTotal: state.minhaConta.limiteTotal,
  valorPagamento: state.minhaConta.valorPagamento,
  dataPagamento: state.minhaConta.dataPagamento,
  emAtraso: state.minhaConta.emAtraso,
});

export default connect(mapStateToProps)(InfosMinhaConta);
