import { Grid } from '@material-ui/core';
import React from 'react';
import BotaoAdicionar from '../../../../pages/MinhaConta/ContasBancarias/BotaoAdicionar';
import styled from 'styled-components';
import { CINZA_400, CINZA_600 } from '../../../../utils/color';
import { queries } from '../../../../utils/breakpoints';

const StyledEmptyStateContas = styled(Grid)`
  border: 1px solid ${CINZA_400};
  border-radius: 4px;
  padding: 0.5vw 0;

  p {
    color: ${CINZA_600};
    font-size: 0.9vw;
  }

  @media ${queries.desktopUp} {
    padding: 6px 0;
    p {
      font-size: 12px;
    }
  }

  @media ${queries.mobileDown} {
    p {
      font-size: 3.35vw;
      padding: 0 4vw 0 2vw;
      margin-top: 2.6vw;
      margin-bottom: 6.5vw;
      line-height: 1.2;
    }
  }
`;

const EmptyStateContas = () => {
  return (
    <StyledEmptyStateContas
      container
      alignItems='center'
      justify='space-around'
    >
      <Grid item xs={12} sm={6}>
        <p>
          Você ainda não possui nenhuma conta bancária cadastrada. Cadastre para
          continuar a operação.
        </p>
      </Grid>
      <Grid item xs={12} sm={5}>
        <BotaoAdicionar cor='secondary' texto='CADASTRAR NOVA CONTA' />
      </Grid>
    </StyledEmptyStateContas>
  );
};

export default EmptyStateContas;
