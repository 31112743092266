import { signUpUserEmpresa, postValidarCodigoPromocional } from "./api";

export const CadastroTypes = {
  SIGNUP_REQUEST: "cadastro/SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "cadastro/SIGNUP_SUCCESS",
  SIGNUP_ERROR: "cadastro/SIGNUP_ERROR",
  VALIDAR_CODIGO_PROMOCIONAL_REQUEST: "cadastro/VALIDAR_CODIGO_PROMOCIONAL_REQUEST",
  VALIDAR_CODIGO_PROMOCIONAL_SUCCESS: "cadastro/VALIDAR_CODIGO_PROMOCIONAL_SUCCESS",
  VALIDAR_CODIGO_PROMOCIONAL_ERROR: "cadastro/VALIDAR_CODIGO_PROMOCIONAL_ERROR",
  CLEAR: "cadastro/CLEAR",
};

const INITIAL_STATE = {
  usuario: null,
  empresa: null,
  error: null,
  isLoading: false,
};

export default function cadastro(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CadastroTypes.SIGNUP_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case CadastroTypes.SIGNUP_SUCCESS:
      const { usuario, empresa } = action;
      return {
        ...state,
        error: null,
        isLoading: false,
        usuario,
        empresa,
      };
    case CadastroTypes.SIGNUP_ERROR:
      const { error } = action;
      return {
        ...INITIAL_STATE,
        isLoading: false,
        error,
      }
      case CadastroTypes.VALIDAR_CODIGO_PROMOCIONAL_REQUEST:
        return {
          ...state,
          error: null,
        };
      case CadastroTypes.VALIDAR_CODIGO_PROMOCIONAL_SUCCESS:
        return {
          ...state,
          error: null,
        };
      case CadastroTypes.VALIDAR_CODIGO_PROMOCIONAL_ERROR:
        return {
          ...INITIAL_STATE,
          error: action.error
        }
    case CadastroTypes.CLEAR:
      return {
        ...INITIAL_STATE,
        isLoading: false,
        error: false,
      }
    default:
      return state;
  }
}

function signUpRequest() {
  return {
    type: CadastroTypes.SIGNUP_REQUEST,
  }
};

function signUpSuccess({ data: { usuario, empresa } }) {
  return {
    type: CadastroTypes.SIGNUP_SUCCESS,
    usuario,
    empresa,
  }
};

function signUpFailure(error) {
  const {
    response: {
      data: { message }
    }
  } = error;
  return {
    type: CadastroTypes.SIGNUP_ERROR,
    error: message,
  }
};

export function signUp({ usuario, empresa }) {
  return function(dispatch) {
    dispatch(signUpRequest())
    return signUpUserEmpresa({ usuario, empresa }).then(
      ({ data }) => dispatch(signUpSuccess(data)),
      (error) => dispatch(signUpFailure(error)),
    );
  };
}

export const validarCodigoPromocionalRequest = () => ({
  type: CadastroTypes.VALIDAR_CODIGO_PROMOCIONAL_REQUEST,
});

export const validarCodigoPromocionalSuccess = () => ({
  type: CadastroTypes.VALIDAR_CODIGO_PROMOCIONAL_SUCCESS,
});

export const validarCodigoPromocionalFailure = (error) => ({
  type: CadastroTypes.VALIDAR_CODIGO_PROMOCIONAL_ERROR,
  error,
});

export const validarCodigoPromocional = (codigoPromocional) => (
  async (dispatch) => {
    dispatch(validarCodigoPromocionalRequest());

    try {
      const res = await postValidarCodigoPromocional(codigoPromocional);
      dispatch(validarCodigoPromocionalSuccess());
      return res?.data?.data?.id;
    }
    catch (error) {
      const errorMessage = error?.response?.data?.message;
      dispatch(validarCodigoPromocionalFailure(errorMessage));
    }
  }
);

export function clearCadastro() {
  return {
    type: CadastroTypes.CLEAR,
  }
};
