import * as React from "react"

const IconeSaque = (props) => (
  <svg
    id="Camada_1"
    data-name="Camada 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 85.06 125.08"
    width="1em"
    height="1em"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#007fc4}"}</style>
    </defs>
    <path
      className="cls-1"
      d="M47.56 40.91 61.69.75C61.83.38 61.49 0 61 0L16.46.06c-.47 0-.8.37-.68.74l13.69 40.13c.13.38-.21.75-.67.75H17.07c-.59 0-.91.58-.53 1L38 64.22a.79.79 0 0 0 1.07 0L60.39 42.6c.37-.38 0-1-.54-1H48.23c-.46.06-.8-.32-.67-.69Z"
    />
    <ellipse className="cls-1" cx={37.82} cy={79.3} rx={37.45} ry={3.84} />
    <path
      className="cls-1"
      d="M0 81.6v10.77s5.33 3.55 37.75 3.55 38-4 38-4V81.6s-5.89 3.55-38 3.55S0 81.6 0 81.6Z"
    />
    <path
      className="cls-1"
      d="M84.61 95.76c0 2.12-16.76 3.84-37.45 3.84C27 99.6 10.5 98 9.74 95.91a233.39 233.39 0 0 0 28 1.32c27.57 0 36-2.88 38.11-3.94 5.47.71 8.76 1.53 8.76 2.47Z"
    />
    <path
      className="cls-1"
      d="M9.35 98.06v10.76s5.33 3.56 37.75 3.56 38-4 38-4V98.06s-5.9 3.54-38 3.54-37.75-3.54-37.75-3.54Z"
    />
    <path
      className="cls-1"
      d="M10.71 110.76c-4.67-.64-7.44-1.44-7.44-2.3 0-.68 1.73-1.32 4.77-1.88v2.94l.58.38a8.19 8.19 0 0 0 2.09.86ZM2.91 110.76v10.76s5.33 3.56 37.75 3.56 38-4 38-4v-10.32s-5.9 3.54-38 3.54-37.75-3.54-37.75-3.54Z"
    />
  </svg>
)

export default IconeSaque
