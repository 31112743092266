import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Snackbar, Button } from '@material-ui/core';
import * as serviceWorker from '../serviceWorker';

const ServiceWorkerWrapper = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const currentPath = useLocation().pathname;

  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  const onServiceWorkerUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  useEffect(() => {
    if (!isLocalhost) {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
      navigator.serviceWorker.getRegistrations()
      .then((regs) => regs.forEach((reg) => { reg.update() }));
    }
}, [currentPath, isLocalhost]);

  const reloadPage = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    }
    setShowReload(false);
    window.location.reload();
  };

  return (
    <Snackbar
      open={showReload}
      message="Uma nova versão do aplicativo está disponível."
      onClick={reloadPage}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <Button
          color="primary"
          size="small"
          onClick={reloadPage}
        >
          Atualizar
        </Button>
      }
    />
  );
}

export default ServiceWorkerWrapper;
