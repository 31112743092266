import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { CINZA_200, AZUL_300 } from '../../../utils/color';

const StyledMeusDados = styled(Container)`
  padding: 1rem;

  .meus-dados-titulo {
    margin-bottom: 0;
    padding-left: .5rem;
    padding-bottom: .3rem;
    color: ${AZUL_300};
  }

  .meus-dados {
    padding: .2rem 1rem;
    background-color: ${CINZA_200};
  }

  .meus-dados .label {
    margin-bottom: 0;
    font-weight: bold;
  }

  .meus-dados .dados {
    margin-top: 0;
  }

  @media (max-width: 375px) {
    padding: 0;

    .meus-dados {
      width: 100%;
      font-size: 10px;
    }
  }

  @media (max-width: 600px) {
    padding: .5rem;
  }
`;

export const MeusDados = ({
  nomeEmpresa,
  cnpj,
  tipoEmpresa,
  nomeSocio,
  cpf,
  rg,
  diaVencimentoJuros,
  email,
}) => (
  <StyledMeusDados maxWidth='md'>
    <h3 className='meus-dados-titulo'>Meus dados</h3>
    <div className='meus-dados'>
      <p className='label'>Razão social</p>
      <p className='dados'>{nomeEmpresa}</p>

      <p className='label'>CPNJ</p>
      <p className='dados'>{cnpj}</p>

      <p className='label'>Tipo de empresa</p>
      <p className='dados'>{tipoEmpresa}</p>

      <p className='label'>Nome completo</p>
      <p className='dados'>{nomeSocio}</p>

      <p className='label'>CPF</p>
      <p className='dados'>{cpf}</p>

      <p className='label'>RG</p>
      <p className='dados'>{rg}</p>

      <p className='label'>Dia de vencimento dos juros:</p>
      <span className='dados'>{diaVencimentoJuros}</span>

      <p className='label'>Email cadastrado</p>
      <p className='dados'>{email}</p>
    </div>
  </StyledMeusDados>
);

MeusDados.defaultProps = {
  nomeEmpresa: '',
  cnpj: '',
  tipoEmpresa: '',
  nomeSocio: '',
  cpf: '',
  rg: '',
  diaVencimentoJuros: null,
  email: '',
};

MeusDados.propTypes = {
  nomeEmpresa: PropTypes.string,
  cnpj: PropTypes.string,
  tipoEmpresa: PropTypes.string,
  nomeSocio: PropTypes.string,
  cpf: PropTypes.string,
  rg: PropTypes.string,
  diaVencimentoJuros: PropTypes.number,
  email: PropTypes.string,
};

const mapStateToProps = (state) => ({
  nomeEmpresa: state.minhaConta.nomeEmpresa,
  cnpj: state.minhaConta.cnpj,
  tipoEmpresa: state.minhaConta.tipoEmpresa,
  nomeSocio: state.minhaConta.nomeSocio,
  cpf: state.minhaConta.cpf,
  rg: state.minhaConta.rg,
  diaVencimentoJuros: state.minhaConta.diaVencimentoJuros,
  email: state.minhaConta.email,
});

export default connect(mapStateToProps)(MeusDados);
