import { Button } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import format from 'date-fns/format';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../../store';

import { carregaDadosEdicao } from '../../../../store/antecipacao/actions';
import {
  Antecipacao as AntecipacaoType,
  StatusAntecipacao,
} from '../../../../store/antecipacao/types';
import { StatusPedidoAntecipacao } from '../../../../store/pedidoAntecipacao/types';
import formataDocumento from '../../../../utils/formataDocumento';
import FormatadorDeMoeda from '../../../FormatadorDeMoeda';
import IconeLixeira from '../../../IconesSvg/IconeLixeira';
import AntecipacaoStyled from './Antecipacao.style';
import ModalExcluirAntecipacao from './ModalExcluirAntecipacao';

interface AntecipacaoProps {
  antecipacao: AntecipacaoType;
  index: number;
  carregaDadosEdicao: (antecipacao: AntecipacaoType, index: number) => void;
  statusPedido: StatusPedidoAntecipacao;
}

const Antecipacao = ({
  antecipacao,
  index,
  carregaDadosEdicao,
  statusPedido,
}: AntecipacaoProps) => {
  const naoEnviado = statusPedido === StatusPedidoAntecipacao.naoEnviado;
  const reprovado =
    !naoEnviado && antecipacao.status === StatusAntecipacao.reprovado;
  const [modalAberto, setModalAberto] = useState(false);

  const onClickExcluir = () => {
    setModalAberto(true);
  };

  const onClickEditar = () => {
    carregaDadosEdicao(antecipacao, index);
  };

  return (
    <AntecipacaoStyled>
      <div className={reprovado ? 'header header-reprovado' : 'header'}>
        <span className='titulo'>
          Antecipação {`${index + 1}`.padStart(2, '0')}
        </span>
        {reprovado && <span className='status'>Reprovada</span>}
        {naoEnviado && (
          <div className='botoes'>
            <Button
              className='botao'
              color='primary'
              startIcon={<Create />}
              onClick={onClickEditar}
              data-cy='botao-editar-antecipacao'
            >
              Editar
            </Button>
            <Button
              className='botao'
              color='primary'
              startIcon={<IconeLixeira />}
              onClick={onClickExcluir}
              data-cy='botao-excluir-antecipacao'
            >
              Excluir
            </Button>
          </div>
        )}
      </div>
      <div
        className={
          reprovado ? 'identificacao identificacao-reprovado' : 'identificacao'
        }
      >
        <span className='nome'>{antecipacao.sacado.nomeRazaoSocial}</span>
        <span className='documento'>
          {formataDocumento(antecipacao.sacado.documentoSacado)}
        </span>
        <span className='valor'>
          <FormatadorDeMoeda valor={antecipacao.valorNotaFiscal} comSimbolo />
        </span>
      </div>
      {reprovado && (
        <div className='motivo-recusa'>
          <strong>Motivo da recusa: </strong>
          <span>{antecipacao.motivoRecusa}</span>
        </div>
      )}
      <div className='lista-container'>
        <div className='lista-boletos'>
          {antecipacao.parcelas.map((parcela, indexParcela) => (
            <div
              key={indexParcela}
              className={reprovado ? 'boleto boleto-reprovado' : 'boleto'}
            >
              <span>{indexParcela + 1}º Boleto</span>
              <span className='data'>
                {format(parcela.vencimento, 'dd/MM/yyyy')}
              </span>
              <span className='valor-parcela'>
                <FormatadorDeMoeda valor={parcela.valor} comSimbolo />
              </span>
            </div>
          ))}
        </div>
      </div>
      <ModalExcluirAntecipacao
        modalAberto={modalAberto}
        toggleModal={() => setModalAberto(false)}
        index={index}
      />
    </AntecipacaoStyled>
  );
};

const mapStateToProps = (state: RootState) => ({
  statusPedido: state.pedidoAntecipacao.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      carregaDadosEdicao,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Antecipacao);
