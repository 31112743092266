import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import BotaoDeIcone from '../../../components/BotaoDeIcone';
import Modal from '../../../components/Modal';
import Notification from '../../../components/Notification';
import {
  dispensarNotificacaoDeSucesso,
  removerContaBancaria as removerContaBancariaAction,
} from '../../../store/contaBancaria/actions';
import { AZUL_300 } from '../../../utils/color';

const StyledBotaoRemover = styled.div`
  align-self: flex-start;
  .remover-conta-bancaria {
    color: ${AZUL_300};
  }
`;

const ConteudoModal = (
  <p style={{ padding: '0 1rem', margin: 0 }}>
    Você tem certeza que deseja remover essa conta bancária?
  </p>
);

interface BotaoRemoverProps {
  contaBancariaId: number;
  removerContaBancaria: () => void;
  deletadoComSucesso: boolean;
  onSuccessMessageClose: () => void;
}

export const BotaoRemover = ({
  removerContaBancaria,
  onSuccessMessageClose,
  deletadoComSucesso,
}: BotaoRemoverProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <StyledBotaoRemover>
      <Notification
        open={deletadoComSucesso}
        onClose={onSuccessMessageClose}
        severity='success'
        message='Conta Bancária removida com sucesso.'
      />
      <BotaoDeIcone
        icone='close'
        className='remover-conta-bancaria'
        onClick={handleClickOpenModal}
      />
      <Modal
        fullWidth
        maxWidth='xs'
        open={openModal}
        titulo='Removendo conta bancária'
        conteudo={ConteudoModal}
        botaoLabel='Confirmar'
        botaoColor='primaryDark'
        onClose={onCloseModal}
        onConfirm={removerContaBancaria}
      />
    </StyledBotaoRemover>
  );
};

const mapStateToProps = (state) => ({
  deletadoComSucesso: state.contaBancaria.deletadoComSucesso,
});

const mapDispatchToProps = (dispatch, ownProps) =>
  bindActionCreators(
    {
      removerContaBancaria: () =>
        removerContaBancariaAction(ownProps.contaBancariaId),
      onSuccessMessageClose: dispensarNotificacaoDeSucesso,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BotaoRemover);
