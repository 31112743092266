import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import { CINZA_600 } from '../../../../utils/color';

export const StyledConteudo = styled(Box)`
  padding: 2vw 8vw;

  svg {
    font-size: 6vw;
  }
  p {
    font-size: 1.4vw;
    font-weight: bold;
    color: ${CINZA_600};
  }
  .botoes {
    display: flex;
    justify-content: center;
    gap: 3vw;

    .MuiButton-label {
      text-transform: uppercase;
      font-size: 1vw;
      font-weight: bold;
    }
  }

  @media ${queries.desktopUp} {
    padding: 26px 90px;
    svg {
      font-size: 80px;
    }
    p {
      font-size: 1.1rem;
    }
    .botoes {
      gap: 40px;

      .MuiButton-label {
        font-size: 1rem;
      }
    }
  }

  @media ${queries.mobileDown} {
    padding: 4vw 10vw;
    svg {
      font-size: 12vw;
    }
    p {
      font-size: 3vw;
    }
    .botoes {
      gap: 4vw;

      .MuiButton-label {
        font-size: 2.6vw;
      }
    }
  }
`;
