import { Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { toCurrency } from '../../../utils/number';
import Texto from '../../Texto';
import CirculoLimite from './CirculoLimite';
import LinkAumentoLimite from './LinkAumentoLimite';
import { RootState } from '../../../store';
import { queries } from '../../../utils/breakpoints';

const StyledPaper = styled(Paper)`
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  background-color: rgba(218, 225, 229, 0.3); // CINZA_200 a 30%
  padding: 1.3vw;
  display: flex;
  flex-direction: column;

  .valor-limite-total {
    font-size: 1vw;
    display: block;
  }
  .container-circulo {
    padding: 7% 18.5% 0%;
  }

  .container-textos-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }

  @media ${queries.desktopUp} {
    padding: 18px;
    .valor-limite-total {
      font-size: 12px;
    }
  }

  @media ${queries.mobileDown} {
    .container-circulo {
      padding: 2% 28%;
    }

    .container-textos-bottom {
      span {
        font-size: 3.5vw;
        display: inline;
      }

      .texto-limite-total {
        font-weight: bold;
      }
    }
  }
`;

interface CardLimiteProps {
  limiteTotal: number;
}

const CardLimite = ({ limiteTotal }: CardLimiteProps) => {
  return (
    <StyledPaper>
      <div className='container-circulo'>
        <CirculoLimite />
      </div>
      <div className='container-textos-bottom'>
        <span>
          <Texto className='texto-limite-total'>Limite total</Texto>
          <Texto className='valor-limite-total'>{` R$ ${toCurrency(
            limiteTotal
          )}`}</Texto>
        </span>
        <LinkAumentoLimite />
      </div>
    </StyledPaper>
  );
};

const mapStateToProps = (state: RootState) => ({
  limiteTotal: state.recebaAntes.limiteTotal,
});

export default connect(mapStateToProps)(CardLimite);
