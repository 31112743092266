import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HelpIcon from '@material-ui/icons/Help';
import Dica from '../../../../components/Dica';
import FormatadorDeMoeda from '../../../../components/FormatadorDeMoeda';
import { CINZA_700, AZUL_400, CINZA_200 } from '../../../../utils/color';

const StyledCardValores = styled.div`
  margin-bottom: 1rem;
  border: 1px solid ${CINZA_700};
  border-radius: 5px;

  .simulacao-valores {
    padding: .5rem 1rem;
  }

  .simulacao-valores svg {
    position: absolute;
    font-size: .9rem;
    margin-left: .2rem;
  }

  .simulacao-valores h5 {
    margin: 0;
    color: ${AZUL_400};
    text-transform: uppercase;
  }

  .simulacao-valores p {
    margin: 0;
    font-size: 1rem;
  }

  @media (max-width: 960px) {
    border: none;
    background-color: ${CINZA_200};

    .simulacao-valores {
      padding: 1rem  .5rem;
    }

    .simulacao-valores {
      font-size: 1rem;
    }
  }

  @media (max-width: 375px) {
    .simulacao-valores h5 {
      font-size: .7rem;
    }
  }

  @media (max-width: 320px) {
    .simulacao-valores h5 {
      font-size: .6rem;
    }
  }
`
const CardValores = forwardRef(({ titulo, descricao, dica }, ref) => (
  <StyledCardValores ref={ref}>
    <div className='simulacao-valores'>
      <h5>
        {titulo}
        <Dica dica={dica} placement='top'><HelpIcon /></Dica>
      </h5>
      <p>
        <FormatadorDeMoeda valor={descricao} comSimbolo />
      </p>
    </div>
  </StyledCardValores>
));

CardValores.propTypes = {
  titulo: PropTypes.string.isRequired,
  descricao: PropTypes.node.isRequired,
  dica: PropTypes.node.isRequired,
};

export default CardValores;
