import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import CardValores from './CardValores';
import EtapaTres from '../Introducao/EtapaTres';
import EtapaQuatro from '../Introducao/EtapaQuatro';

const dicaLimite = (
  <p style={{ fontSize: 12}}>
    <strong>Este é o valor que você tem disponível para saque</strong>
  </p>
);

const dicaPagamento = (
  <>
    <p style={{ fontSize: 12}}>
      <strong>
        Este é o valor a ser pago, referente aos juros sobre os saques realizados,
        caso não haja mais nenhuma movimentação em sua conta até a data do vencimento.
      </strong>
    </p>
    <p style={{ fontSize: 12}}>
      Aqui seu vencimento será
      <strong> dia 20 </strong>
      mas fique tranquilo que é você que escolhe a data ao se cadastrar.
    </p>
  </>
);

export const Valores = ({
  limiteDisponivel,
  proximoPagamento,
}) => (
  <Grid
    container
    spacing={1}
    direction='row'
    style={{ padding: '1rem .5rem 0' }}
  >
    <Grid item xs={6} md={4} xl={3}>
      <EtapaTres>
        <CardValores
          titulo='Limite disponível'
          descricao={limiteDisponivel}
          dica={dicaLimite}
        />
      </EtapaTres>
    </Grid>
    <Grid item xs={6} md={4} xl={3}>
      <EtapaQuatro>
        <CardValores
          titulo='Próximo pagamento'
          descricao={proximoPagamento}
          dica={dicaPagamento}
        />
      </EtapaQuatro>
    </Grid>
  </Grid>
);

Valores.propTypes = {
  limiteDisponivel: PropTypes.number.isRequired,
  proximoPagamento: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  limiteDisponivel: state.simulacao.limiteDisponivel,
  proximoPagamento: state.simulacao.proximoPagamento,
});

export default connect(mapStateToProps)(Valores);
