import * as React from 'react';

const IconeLixeira = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 88.4 107.8'
    style={{
      enableBackground: 'new 0 0 88.4 107.8',
    }}
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
  >
    <style>{'.st1{fill:#fafafc}.st50{fill:#0f79bd}'}</style>
    <g id='Camada_1'>
      <path
        className='st50'
        d='M67.1 107.8H21.3c-5.4 0-9.9-4.2-10.2-9.6L7 30.9h74.4l-4 67.2c-.4 5.5-4.8 9.7-10.3 9.7z'
      />
      <path
        className='st1'
        d='M44.2 100.9c-2 0-3.6-1.6-3.6-3.6v-53c0-2 1.6-3.6 3.6-3.6s3.6 1.6 3.6 3.6v53c.1 2-1.6 3.6-3.6 3.6zM27.3 100.9c-1.9 0-3.5-1.5-3.6-3.4l-3.1-53c-.1-2 1.4-3.7 3.4-3.9 2-.1 3.7 1.4 3.9 3.4L31 97c.1 2-1.4 3.7-3.4 3.9h-.3zM61.1 100.9h-.2c-2-.1-3.5-1.8-3.4-3.9l3.1-53c.1-2 1.8-3.6 3.9-3.4 2 .1 3.5 1.8 3.4 3.9l-3.1 53c-.1 1.9-1.7 3.4-3.7 3.4z'
      />
      <path
        className='st50'
        d='M81.4 27.2H7c-3.9 0-7-3.1-7-7s3.1-7 7-7h74.4c3.9 0 7 3.1 7 7 0 3.8-3.1 7-7 7z'
      />
      <path
        className='st50'
        d='M66.4 23.8H22.1v-5.2C22.1 8.3 30.4 0 40.6 0h7.1C58 0 66.4 8.3 66.4 18.6v5.2zm-36.9-7.3h29.3c-1-5.2-5.6-9.2-11.1-9.2h-7.1c-5.5 0-10.1 4-11.1 9.2z'
      />
    </g>
  </svg>
);

export default IconeLixeira;
