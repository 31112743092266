import DateFnsUtils from '@date-io/date-fns';
import { Button, Divider, Grid, useMediaQuery } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { addDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../store';

import {
  setFiltroPeriodo,
  setFiltroPeriodoErro,
} from '../../../store/recebaAntes/actions';
import { queries } from '../../../utils/breakpoints';
import RoundButton from '../../RoundButton';
import { StyledFiltroData } from './FiltroData.style';

interface Props extends ConnectedProps<typeof connector> {}

const FiltroData = ({ setFiltro, erro, setErro }: Props) => {
  const [dataInicio, setDataInicio] = React.useState<Date>(null);
  const [dataFim, setDataFim] = React.useState<Date>(null);
  const isMobile = useMediaQuery(queries.mobileDown);
  const desabilitaAplicar = !dataInicio || !dataFim;

  const opcoes = [
    { label: 'Hoje', id: '1' },
    { label: '7 dias', id: '7' },
    { label: '15 dias', id: '15' },
    { label: '30 dias', id: '30' },
    { label: '60 dias', id: '60' },
    { label: '90 dias', id: '90' },
  ];

  const handleClickOpcao = ({ currentTarget }) => {
    const hoje = new Date();
    const final = addDays(hoje, Number(currentTarget.getAttribute('id')));
    const dataInicial = format(hoje, 'yyyy-MM-dd');
    const dataFinal = format(final, 'yyyy-MM-dd');
    setFiltro({ dataInicial, dataFinal });
  };

  const handleInformaDatas = () => {
    const dataInicial = format(dataInicio, 'yyyy-MM-dd');
    const dataFinal = format(dataFim, 'yyyy-MM-dd');
    setFiltro({ dataInicial, dataFinal });
  };

  const handleLimpaDatas = () => {
    setDataInicio(null);
    setDataFim(null);
    setErro('');
  };

  return (
    <StyledFiltroData className='container-filtro'>
      <strong>Período</strong>
      <Divider />
      <div className='opcoes'>
        {opcoes.map((opcao) => (
          <RoundButton
            onClick={handleClickOpcao}
            className='opcao-periodo'
            color='secondaryCinza'
            size='extra-small'
            value={opcao.label}
            id={opcao.id}
            key={opcao.id}
          />
        ))}
      </div>
      <strong>ou informe uma data</strong>
      <Divider />
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Grid container alignItems='center' className='informa-data'>
          <Grid item xs={3}>
            <span>Data inicial</span>
          </Grid>
          <Grid item xs={9}>
            <DatePicker
              disableToolbar
              autoOk
              inputVariant='filled'
              variant='inline'
              format='dd/MM/yyyy'
              placeholder='dd/mm/aaaa'
              id='data-inicial'
              value={dataInicio}
              onChange={setDataInicio}
            />
          </Grid>
          <Grid item xs={3}>
            <span>Data final</span>
          </Grid>
          <Grid item xs={9}>
            <DatePicker
              disableToolbar
              placeholder='dd/mm/aaaa'
              autoOk
              inputVariant='filled'
              variant='inline'
              format='dd/MM/yyyy'
              id='data-final'
              value={dataFim}
              onChange={setDataFim}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      {erro && <span className='erro'>{erro}</span>}
      <div className='botoes'>
        <Button variant='text' onClick={handleLimpaDatas}>
          Limpar
        </Button>
        <Button
          variant={isMobile ? 'outlined' : 'text'}
          color='primary'
          disabled={desabilitaAplicar}
          onClick={handleInformaDatas}
          data-cy='botao-aplicar-filtro'
        >
          Aplicar
        </Button>
      </div>
    </StyledFiltroData>
  );
};

const mapStateToProps = (state: RootState) => ({
  erro: state.recebaAntes.filtroPeriodoErro,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFiltro: setFiltroPeriodo,
      setErro: setFiltroPeriodoErro,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FiltroData);
