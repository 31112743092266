import formataDocumento from '../../utils/formataDocumento';
import { setSacado } from '../antecipacao/actions';
import { esconderBtnContinuar } from '../recebaAntes/actions';
import {
  getCidadesPorEstado,
  getEnderecoPorCep,
  getEstados,
  postCadastroSacado,
} from './api';
import {
  CadastroSacadoActionTypes as types,
  Cidade,
  DadosSacado,
  DadosSacadoApi,
  Estado,
  RetornoApiViaCep,
} from './types';

const mostrarFormCadastro = (textoBusca) => async (dispatch) => {
  dispatch(fetchEstados());
  const dadoSacado = textoBusca.match(/^\d/)
    ? { documento: formataDocumento(textoBusca) }
    : { nomeRazaoSocial: textoBusca };
  dispatch(setDadosSacado(dadoSacado));
  dispatch(mostrarForm());
  dispatch(esconderBtnContinuar());
};

const mostrarForm = () => ({
  type: types.MOSTRA_FORM_CADASTRO,
});

const fetchEstadosSuccess = (estados: Estado[]) => ({
  type: types.FETCH_ESTADOS_SUCCESS,
  estados,
});

const fetchEstados = () => async (dispatch) => {
  try {
    const { data } = await getEstados();
    dispatch(fetchEstadosSuccess(data.data.estados || []));
  } catch (error) {
    console.error('erro no fetchEstados', error);
  }
};

const fetchCidadesSuccess = (cidades: Cidade[]) => ({
  type: types.FETCH_CIDADES_SUCCESS,
  cidades,
});

const fetchCidades = (estadoId) => async (dispatch) => {
  try {
    const { data } = await getCidadesPorEstado(estadoId);
    dispatch(fetchCidadesSuccess(data.data.cidades || []));
  } catch (error) {
    console.error('erro no fetchCidades', error);
  }
};

const cadastraSacadoRequest = () => ({
  type: types.CADASTRO_SACADO_REQUEST,
});

const cadastraSacadoSuccess = () => ({
  type: types.CADASTRO_SACADO_SUCCESS,
});

const cadastraSacadoFailure = (erros) => ({
  type: types.CADASTRO_SACADO_ERROR,
  erros,
});

const cadastraSacado = () => async (dispatch, getState) => {
  dispatch(cadastraSacadoRequest());
  try {
    const dadosSacado = getState().cadastroSacado.dadosSacado;
    const endereco = [dadosSacado.rua, dadosSacado.numero]
      .filter(Boolean)
      .join(', ');

    const dadosEnviar: DadosSacadoApi = {
      cpf_cnpj: dadosSacado.documento,
      nome_razao_social: dadosSacado.nomeRazaoSocial,
      email: dadosSacado.email,
      telefone: dadosSacado.telefone,
      cep: dadosSacado.cep,
      cidade_id: dadosSacado.cidade?.id.toString(),
      endereco,
      cobrar_multa: dadosSacado.cobrarMulta,
      email_2: dadosSacado.email2,
      email_3: dadosSacado.email3,
      telefone_2: dadosSacado.telefone2,
    };

    const { data } = await postCadastroSacado(dadosEnviar);
    const retorno = data?.data;
    dispatch(cadastraSacadoSuccess());
    dispatch(
      setSacado({
        id: retorno.id,
        nomeRazaoSocial: retorno.nome_razao_social,
        documentoSacado: retorno.cpf_cnpj,
      })
    );
  } catch (error) {
    console.error('erro no cadastraSacado', error);
    const erros = error?.response?.data?.errors;

    dispatch(cadastraSacadoFailure(erros));
  }
};

const setDadosSacado = (dadosSacado: Partial<DadosSacado>) => ({
  type: types.SET_DADOS_SACADO,
  dadosSacado,
});

const inicioBuscaEndereco = () => ({
  type: types.BUSCA_ENDERECO_POR_CEP,
});

const finalBuscaEndereco = () => ({
  type: types.BUSCA_ENDERECO_POR_CEP_FINALIZADA,
});

const setCamposDesabilitados = (camposDesabilitados: {
  estado?: boolean;
  cidade?: boolean;
  rua?: boolean;
}) => ({
  type: types.SET_CAMPOS_DESABILITADOS,
  desabilitar: camposDesabilitados,
});

const buscaEnderecoPeloCep = (cep) => async (dispatch, getState) => {
  try {
    dispatch(inicioBuscaEndereco());
    const {
      data: { uf, localidade, logradouro, complemento, bairro },
    }: RetornoApiViaCep = await getEnderecoPorCep(cep);
    const estado = getState().cadastroSacado.estados.find(
      (e) => e.sigla === uf
    );
    dispatch(setDadosSacado({ estado }));
    dispatch(setCamposDesabilitados({ estado: true }));
    await dispatch(fetchCidades(estado.id));
    const cidade = getState().cadastroSacado.cidades.find(
      (c) => c.nome === localidade
    );
    const rua = [logradouro, complemento, bairro].filter(Boolean).join(', ');
    dispatch(setDadosSacado({ rua, cidade }));
    dispatch(setCamposDesabilitados({ cidade: true, rua: false }));
  } catch (error) {
    dispatch(
      setCamposDesabilitados({ estado: false, cidade: false, rua: false })
    );
    console.error('Endereço não encontrado pelo CEP', error);
  } finally {
    dispatch(finalBuscaEndereco());
  }
};

const fecharModaleForm = () => ({
  type: types.FECHAR_MODAL,
});

export {
  mostrarFormCadastro,
  fetchCidades,
  cadastraSacado,
  fecharModaleForm,
  buscaEnderecoPeloCep,
  setDadosSacado,
};
