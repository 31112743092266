import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setValorSaque } from '../../store/simulacao/actions';
import { toNumber } from '../../utils/number';
import CampoDeMoeda from '../../components/CampoDeMoeda';

const CampoValorSaque = ({
  value,
  errors,
  placeholder,
  helperText,
  limiteDeCreditoPadrao,
  limiteDeCreditoPadraoFormatado,
  onChange,
  onStopTyping,
  onKeyPress,
}) => {
  const [hasErrors, setHasErrors] = useState(Boolean(errors));

  useEffect(() => {
    if (errors?.valorSaque){
      setHasErrors(true)
    }
  }, [errors]);

  const validationMessage = `Digite um valor entre R$ 100,00 e R$${limiteDeCreditoPadraoFormatado}`;
  const valorMaximo = limiteDeCreditoPadrao;
  const valorMinimo = 100;

  const handleChange = (newValue) => {
    setHasErrors(false);

    const newValueAsNumber = toNumber(newValue);
    if (newValueAsNumber < valorMinimo || newValueAsNumber > valorMaximo) {
      setHasErrors(true)
    }

    onChange(newValue);
  };

  const errorMessage = errors?.valorSaque || validationMessage;

  return (
    <CampoDeMoeda
      value={value}
      placeholder={placeholder}
      error={hasErrors}
      helperText={hasErrors ? errorMessage : helperText}
      onChange={handleChange}
      onStopTyping={onStopTyping}
      onKeyPress={onKeyPress}
    />
  );
};

CampoValorSaque.defaultProps = {
  placeholder: '0,00',
  helperText: '',
  onStopTyping: () => {},
};

CampoValorSaque.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onStopTyping: PropTypes.func,
};

const mapStateToProps = (state) => ({
  value: state.simulacao.valorSaque,
  errors: state.simulacao.errors,
  limiteDeCreditoPadrao: state.configuracoes.limiteDeCreditoPadrao,
  limiteDeCreditoPadraoFormatado: state.configuracoes.limiteDeCreditoPadraoFormatado
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onChange: setValorSaque,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CampoValorSaque);
