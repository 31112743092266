import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { RootState } from '../../../store';
import { CINZA_500, CINZA_600 } from '../../../utils/color';
import EstadoInicial from './EstadoInicial';
import ItensTabela from './ItensTabela';

const StyledTableHeader = withStyles({
  root: {
    borderBottom: `2px solid ${CINZA_500}`,
  },
  head: {
    padding: '0',
    color: CINZA_600,
    fontWeight: 'bold',
    fontSize: '10px',
    textAlign: 'center',
  },
  '@media (max-width: 1280px)': {
    head: {
      fontSize: '0.7vw',
    },
  },
})(TableCell);

interface Props extends ConnectedProps<typeof connector> {}

const TabelaBoletos = ({ usaRecebaAntes }: Props) => {
  return (
    <Table id='tabela-boletos'>
      <TableHead>
        <TableRow>
          <StyledTableHeader>N° da operação</StyledTableHeader>
          <StyledTableHeader>Número do boleto</StyledTableHeader>
          <StyledTableHeader>Cliente</StyledTableHeader>
          <StyledTableHeader>Valor</StyledTableHeader>
          <StyledTableHeader>Vencimento</StyledTableHeader>
          <StyledTableHeader>Data Crédito</StyledTableHeader>
          <StyledTableHeader>Status</StyledTableHeader>
        </TableRow>
      </TableHead>
      {usaRecebaAntes ? <ItensTabela /> : <EstadoInicial />}
    </Table>
  );
};

const mapStateToProps = (state: RootState) => ({
  usaRecebaAntes: state.recebaAntes.usaRecebaAntes,
});

const connector = connect(mapStateToProps);

export default connector(TabelaBoletos);
