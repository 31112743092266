import React from 'react';
import { uniqueId } from 'lodash';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Item from './Item';
import IconePercentage from '../../../components/IconesSvg/IconePercentage';
import IconeCalendario from '../../../components/IconesSvg/IconeCalendario';
import IconeSaque from '../../../components/IconesSvg/IconeSaque';
import imgDetalhes from '../../../assets/home_vantagens.png';
import { queries } from '../../../utils/breakpoints';

const StyledGrid = styled(Grid)`
  @media ${queries.mobileUp} {
    flex-basis: 32%;
  }
`;

const StyledImgDetalhes = styled.div`
  background-image: url(${imgDetalhes});
  width: 57%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  min-height: 680px;

  @media ${queries.mobileUp} and ${queries.tabletDown} {
    width: 100%;
    min-height: 35vh;
  }

  @media ${queries.mobileDown} {
    width: 100%;
    min-height: 260px;
  }
`;

const StyledDetails = styled.div`
  background: transparent;

  .container-principal {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
  }

  .items-container {
    justify-content: space-between;
    flex-direction: column;
    width: 50%;
    padding-left: 4rem;
    margin-top: 5rem;

  }

  .bottom-container {
    padding: 3vw 0 1vw 0;
  }


  @media ${queries.tabletDown} {
    padding: 6% 0 0;

    .container-principal {
      width: 100%;
      flex-direction: column;
    }

    .items-container {
      width: 100%;
      padding: 1rem 0;
      margin: 0;
    }

   .bottom-container {
     padding: 0;
     padding-bottom: 2rem;
   }

    .parceria-container {
      padding: 5%;
      border-radius: 10px;

      p {
        text-align: center;
        padding-bottom: 5%;
        font-size: 3.7vw;
        width: 75%;
        margin: 0 auto;
        line-height: 1.2em;
      }

      .logo-sebrae {
        width: 27vw;
      }

      .logo-credito {
        width: 38vw;
      }
    }
  }
`;
const Details = () => {
  const items = [
    {
      icon: <IconePercentage />,
      title: 'Sem taxas',
      description:
        'Sem taxas de cadastro, sem taxas <br>de manutenção. Zero taxas !',
    },
    {
      icon: <IconeSaque />,
      title: 'Saque sempre que precisar',
      description:
        'Você pode fazer um saque sem<br> ter pago saques anteriores',
    },
    {
      icon: <IconeCalendario />,
      title: 'Pague como puder',
      description: 'Mensalmente você pode só pagar <br>os juros.',
    },
  ];

  return (
    <StyledDetails name='detalhes' id='detalhes'>
      <div className='container-principal'>
        <StyledImgDetalhes/>
          <div className='items-container'>
            {items.map((item) => (
              <StyledGrid key={uniqueId('detail-item-')} item xs={12} sm={12}>
                <Item
                  icon={item.icon}
                  title={item.title}
                  description={item.description}
                />
              </StyledGrid>
            ))}
          </div>
      </div>

    </StyledDetails>

  );
};

export default Details;
