import { RootState } from '..';
import { StatusPedidoAntecipacao } from './types';

const selectValorSolicitado = (state: RootState): number => {
  let valorSolicitado = state.pedidoAntecipacao.antecipacoes.reduce(
    (valorAnterior, valorAtual) => valorAnterior + valorAtual.valorNotaFiscal,
    0
  );
  return valorSolicitado;
};

const selectValorFooter = (state: RootState): number => {
  const { status, valorAprovado, valorDevolucao, saldo } =
    state.pedidoAntecipacao;

  if (status === StatusPedidoAntecipacao.aguardandoCliente) {
    return valorAprovado;
  }
  if (status === StatusPedidoAntecipacao.emFinalizacao) {
    return saldo - valorDevolucao;
  }
  // status não enviado ou pendente, no aguardando assinatura não tem footer
  return selectValorSolicitado(state);
};

const mostrarSecaoDevolucao = (state: RootState): boolean => {
  const { limiteTotal, limiteDisponivel, usaCreditoRotativo } =
    state.pedidoAntecipacao;
  if (!usaCreditoRotativo || limiteTotal === limiteDisponivel) {
    return false;
  }
  return true;
};

const haAntecipacoes = (state: RootState): boolean => {
  return state.pedidoAntecipacao.antecipacoes.length > 0;
};

export { selectValorFooter, mostrarSecaoDevolucao, haAntecipacoes };
