import React from 'react';
import styled from 'styled-components';
import Icone from '../../../components/Icone';
import { CINZA_700 } from '../../../utils/color';

const StyledZeroState = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem .5rem;

  img {
    width: 70px;
  }

  .nenhuma-conta {
    margin: 0;
    padding-left: 1.5rem;
    color: ${CINZA_700};
    font-size: 1rem;
  }

  @media (max-width: 375px) {
    .nenhuma-conta {
      font-size: .8rem;
    }
  }
`;

const ZeroState = () => (
  <StyledZeroState>
    <Icone name='contaBancaria' />
    <p className='nenhuma-conta'>
      Nenhuma conta
      <br />
      báncaria cadastrada.
    </p>
  </StyledZeroState>
);

export default ZeroState;
