import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Radio } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { setTipoDevolucao } from '../../../store/devolucao/actions';
import { AZUL_300 } from '../../../utils/color';

const StyledRadioDevolucao = styled.div`
  svg {
    color: ${AZUL_300};
    font-size: .8rem;
  }

  span {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const RadioDevolucao = ({ value, currentValue, onChange }) => {
  return (
    <StyledRadioDevolucao>
      <Radio value={value} onChange={onChange} checked={value === currentValue} />
    </StyledRadioDevolucao>
  );
};

RadioDevolucao.propTypes = {
  value: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  currentValue: state.devolucao.tipoDevolucao,
});

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
  onChange: () => setTipoDevolucao(ownProps.value),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RadioDevolucao);
