import { Reducer } from 'redux';
import {
  CadastroSacadoState,
  CadastroSacadoActionTypes as types,
} from './types';

const INITIAL_STATE: CadastroSacadoState = {
  formAberto: false,
  modalAberto: false,
  loadingCadastro: false,
  estados: [],
  cidades: [],
  buscandoEndereco: false,
  dadosSacado: {
    documento: '',
    nomeRazaoSocial: '',
    email: '',
    email2: '',
    email3: '',
    telefone: '',
    telefone2: '',
    endereco: '',
    cep: '',
    cobrarMulta: false,
    cidade: null,
    estado: null,
    rua: '',
    numero: '',
  },
  desabilitar: {
    estado: true,
    cidade: true,
    rua: true,
  },
};

const cadastroSacado: Reducer<CadastroSacadoState, any> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case types.MOSTRA_FORM_CADASTRO:
      return {
        ...state,
        erros: {},
        formAberto: true,
      };
    case types.FETCH_ESTADOS_SUCCESS:
      return {
        ...state,
        estados: action.estados,
      };
    case types.FETCH_CIDADES_SUCCESS:
      return {
        ...state,
        cidades: action.cidades,
      };
    case types.CADASTRO_SACADO_REQUEST:
      return {
        ...state,
        loadingCadastro: true,
      };
    case types.CADASTRO_SACADO_ERROR:
      return {
        ...state,
        loadingCadastro: false,
        erros: action.erros,
      };
    case types.CADASTRO_SACADO_SUCCESS:
      return {
        ...state,
        loadingCadastro: false,
        erros: {},
        modalAberto: true,
      };
    case types.FECHAR_MODAL:
      return {
        ...state,
        modalAberto: false,
        formAberto: false,
        dadosSacado: INITIAL_STATE.dadosSacado,
        cidades: [],
      };
    case types.SET_DADOS_SACADO:
      return {
        ...state,
        dadosSacado: {
          ...state.dadosSacado,
          ...action.dadosSacado,
        },
      };
    case types.BUSCA_ENDERECO_POR_CEP:
      return {
        ...state,
        buscandoEndereco: true,
      };
    case types.BUSCA_ENDERECO_POR_CEP_FINALIZADA:
      return {
        ...state,
        buscandoEndereco: false,
      };
    case types.SET_CAMPOS_DESABILITADOS:
      return {
        ...state,
        desabilitar: {
          ...state.desabilitar,
          ...action.desabilitar,
        },
      };
    default:
      return state;
  }
};

export default cadastroSacado;
