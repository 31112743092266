import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { AZUL_300, BRANCO, PRETO, CINZA_700 } from '../utils/color';
import LinkSublinhado from './LinkSublinhado';

const StyledPopup = styled(Box)`
  .cookie-consent {
    max-width: 500px;
    top: 50%;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    transform: translate3d(-50%, -50%, 9999990px);
    background-color: ${BRANCO};
    border-radius: 4px;
    color: ${PRETO};

  .button-container {
    align-self: flex-end;
  }
}
`
const CookieConsentPopup = () => {
  const currentPath = useLocation().pathname;

  if (currentPath === '/politica-de-privacidade') {
    return null
  };

  return (
    <StyledPopup>
      <CookieConsent
        overlay
        location='center'
        buttonText='Concordo'
        cookieName='politica-de-privacidade'
        style={{ width: '90%', left: '50%', backgroundColor: `${BRANCO}`, color: `${CINZA_700}`}}
        containerClasses='cookie-consent'
        buttonWrapperClasses='button-container'
        contentStyle={{ flex: '1 0 0', lineHeight: '1.5', marginBottom: 0 }}
        buttonStyle={{
          padding: '10px 30px',
          backgroundColor: `${AZUL_300}`,
          color: `${BRANCO}`,
          borderRadius: 30,
          fontSize: 14,
          fontWeight: 'bold'
        }}
      >
        O Crédito Rotativo usa cookies e outras tecnologias semelhantes para
        melhorar a sua experiência em nossos serviços.
        Portanto, para utilizar nossos serviços é necessário concordar com a nossa
        <LinkSublinhado to='/politica-de-privacidade'>
          {' '}
          Política de Privacidade e Segurança
        </LinkSublinhado>.
      </CookieConsent>
    </StyledPopup>
  );
};

export default CookieConsentPopup;
