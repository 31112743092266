/* eslint-disable import/prefer-default-export */
import Api from '../../Api';

export const postDevolucao = ({ valor, recibo }) => {
  const formData = new FormData();
  formData.append('valor', valor);
  formData.append('recibo', recibo);

  return (
    Api.post(
      'devolucoes',
      formData,
    )
  );
};
