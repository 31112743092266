import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { CINZA_700 } from '../../../utils/color';
import { zerarSimulacao } from '../../../store/simulacao/actions';

const StyledLink = styled(Link)`
  display: block;
  color: ${CINZA_700};
  border-bottom: 2px solid ${CINZA_700};
  font-size: .9rem;
  font-weight: bold;
  text-transform: uppercase;
  margin: 5px;
`;

export const BotaoNovaSimulacao = ({ onClick }) => (
  <StyledLink
    component='button'
    onClick={onClick}
  >
    Fazer nova simulação
  </StyledLink>
);

BotaoNovaSimulacao.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onClick: zerarSimulacao,
}, dispatch);

export default connect(null, mapDispatchToProps)(BotaoNovaSimulacao);
