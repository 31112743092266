import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchMovimentacoes } from '../../../../store/conta/actions';
import Progresso from '../../../../components/Progresso';
import TabelaMovimentacoes from '../../../../components/TabelaMovimentacoes'

export const Tabela = ({ movimentacoes, fetch, buscandoMovimentacoes }) => {
  useEffect(() => {
    fetch();
  }, [fetch]);

  if (buscandoMovimentacoes) {
    return (
      <Progresso />
    );
  }

  return (
    <TabelaMovimentacoes movimentacoes={movimentacoes} />
  );
};

Tabela.defaultProps = {
  movimentacoes: [],
};

Tabela.propTypes = {
  movimentacoes: PropTypes.arrayOf(PropTypes.object),
  fetch: PropTypes.func.isRequired,
  buscandoMovimentacoes: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  movimentacoes: state.conta.movimentacoes,
  buscandoMovimentacoes: state.conta.buscandoMovimentacoes,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetch: fetchMovimentacoes,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tabela);
