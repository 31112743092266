import styled from 'styled-components';
import {
  AZUL_300,
  AZUL_500,
  BRANCO,
  CINZA_400,
  CINZA_500,
  CINZA_700,
} from '../utils/color';
export const CINZA_ICONE_INATIVO = 'rgba(0,0,0,.4)';

export const Navbar = styled.ul`
  padding: 0.425rem 1rem;
  margin: 0;
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-size: 0.725rem;
  list-style-type: none;
  background-color: #3298cf;
  background-image: linear-gradient(to bottom, #49a3d4, #188bc9);

  .icone-receba-antes {

    @media (max-width: 486px) {
      min-width: 80px;
      min-height: 27px;
    }

    padding: 2px 0 2px 4px;
  }

  li {
    height: 100%;

    @media (max-width: 486px) {
      flex: 1 1 0;
      font-size: 0.5rem;
    }

    img,
    svg {
      margin-bottom: 0.2rem;
    }

    span {
      line-height: 1;
      text-align: center;
    }
  }

  a,
  &.menu-desativado li {
    height: 100%;
    transition: text-shadow ease 0.15s;
    color: ${AZUL_500};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    outline: none;

    @media (max-width: 600px) {
      justify-content: center;
      color: ${CINZA_700};
    }

    img,
    svg {
      display: block;
      width: 1.5rem;
      height: 1.5rem;
      margin-bottom: 2px;
    }
  }

  a:not(.active):hover {
    text-shadow: -1px -1px 0 rgba(26, 89, 124, 0.125),
      1px -1px 0 rgba(26, 89, 124, 0.125), -1px 1px 0 rgba(26, 89, 124, 0.125),
      1px 1px 0 rgba(26, 89, 124, 0.125);
  }

  a.active,
  &.menu-desativado li.active {
    color: ${BRANCO};
    font-weight: 600;
  }

  a:not(.active) svg {
    color: ${CINZA_ICONE_INATIVO};
  }

  @media (min-width: 600px) {
    box-shadow: inset 0 2px 1px ${CINZA_500};
    font-size: 1rem;

    a,
    &.menu-desativado li {
      flex-direction: row;
      img,
      svg {
        margin: 0;
        margin-right: 8px;
      }
    }
  }

  @media (max-width: 599px) {
    border-top: 1px solid ${CINZA_400};
    border-bottom: 1px solid ${CINZA_400};
    background-color: ${BRANCO};
    background-image: none;
    width: 92%;
    margin: 0 auto;
    padding: 0.425rem 0;

    svg {
      color: ${CINZA_ICONE_INATIVO};
    }

    a.active,
    li.active,
    svg {
      color: ${AZUL_300};
      font-weight: 600;
    }

    .icone-receba-antes {
      padding: 4px;
    }
  }

  @media (min-width: 1000px) {
    font-size: 1.3rem;
  }

  @media (min-width: 1400px) {
    font-size: 1.4rem;
  }
`;
