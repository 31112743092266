import React from 'react';
import { CircularProgress, Button, ButtonProps } from '@material-ui/core';
import styled from 'styled-components';
import {
  CINZA_200,
  AZUL_300,
  AZUL_400,
  BRANCO,
  VERMELHO,
  PRIMARY_LINEAR_GRADIENT,
  CINZA_400,
  CINZA_600,
} from '../utils/color';

const StyledButton = styled(Button)`
  transition: all 0.2s;
  border-radius: 30px;
  padding: 10px 50px;
  align-self: center;
  text-transform: none;
  margin-top: 10px;

  ${(props) =>
    props.color === 'default' &&
    `
    background-color: ${BRANCO};
    &:hover {
      background-color: ${BRANCO};
    }
  `}

  ${(props) =>
    props.color === 'primary' &&
    `
    background-color: ${AZUL_300};
    &:hover {
      background-color: ${AZUL_300};
    }
  `}

  ${(props) =>
    props.color === 'secondary' &&
    `
    background-color: #fff;
    border: 1px solid ${AZUL_300};
    color: ${AZUL_300};
    &:hover {
      background-color: ${CINZA_200};
    }
  `}

${(props) =>
    props.customcolor === 'secondaryCinza' &&
    `
    background-color: transparent;
    border: 1px solid ${CINZA_600};
    color: ${CINZA_600};
    &:hover {
      background-color: ${CINZA_200};
    }
  `}

  ${(props) =>
    props.customcolor === 'primaryDark' &&
    `
    background-color: ${AZUL_400};
    &:hover {
      background-color: ${AZUL_400};
    }
  `}

  ${(props) =>
    props.customcolor === 'destaque' &&
    `
    background-color: ${VERMELHO};
    &:hover {
      background-color: ${VERMELHO};
    }
  `}

  ${(props) =>
    props.customcolor === 'primaryDarkGradient' &&
    `
    background-image: linear-gradient(141deg , ${AZUL_400}, ${AZUL_300} 62%);
    &:hover {
      background-image: linear-gradient(141deg , ${AZUL_400}, ${AZUL_300} 62%);
    }
  `}

  ${(props) =>
    props.customcolor === 'primaryGradient' &&
    `
    background-image: radial-gradient(circle, #0ba1d9 29%, #088ecc 78%);
    &:hover {
      background-image: radial-gradient(circle, #0ba1d9 29%, #088ecc 78%);
    }
  `}

  ${(props) =>
    props.customcolor === 'primaryLinearGradient' &&
    `
    background-image: ${PRIMARY_LINEAR_GRADIENT};
    &:hover {
      background-image: ${PRIMARY_LINEAR_GRADIENT};
    }
  `}


  ${(props) => props.customsize === 'extra-large' && 'font-size: 24px;'}

  ${(props) => props.customsize === 'large' && 'font-size: 20px;'}

  ${(props) => props.customsize === 'small' && 'font-size: 14px;'}

  ${(props) =>
    props.customsize === 'extra-small' &&
    `
    font-size: 10px;
    padding: 4px;
  `}

  ${(props) => props.bold && 'font-weight: 600;'}
  :hover {
    opacity: 0.8;
  }

  ${(props) =>
    props.blockxs &&
    `
    @media (max-width: 540px) {
      width: 100vw;
      border-radius: 0;
    }
  `}
`;

export type RoundButtonColors =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'secondaryCinza'
  | 'primaryDark'
  | 'destaque'
  | 'primaryLinearGradient'
  | 'primaryGradient'
  | 'primaryDarkGradient';

export interface RoundButtonProps
  extends Omit<ButtonProps, 'color' | 'value' | 'size'> {
  value: string | React.ReactNode;
  loading?: boolean;
  color?: RoundButtonColors;
  size?: 'extra-large' | 'large' | 'small' | 'extra-small';
  customsize?: 'extra-large' | 'large' | 'small' | 'extra-small';
  bold?: string | boolean;
  blockXS?: boolean;
  disabled?: boolean;
  style?: any;
}

const RoundButton = ({
  value,
  loading = false,
  bold = false,
  blockXS = false,
  disabled = false,
  color = 'default',
  size = 'large',
  style,
  ...props
}: RoundButtonProps) => {
  let buttonColor = color;
  const customcolor = color;
  if (
    color === 'primaryDark' ||
    color === 'destaque' ||
    color === 'primaryLinearGradient' ||
    color === 'primaryGradient' ||
    color === 'primaryDarkGradient'
  ) {
    buttonColor = 'primary';
  }
  if (color === 'secondaryCinza') {
    buttonColor = 'secondary';
  }

  if (disabled) {
    style = {
      color: BRANCO,
      background: CINZA_400,
      ...style,
    };
  }

  return (
    <StyledButton
      variant='contained'
      color={buttonColor}
      customcolor={customcolor}
      size={size === 'extra-small' ? 'small' : size}
      disabled={disabled || loading}
      bold={bold ? bold.toString() : null}
      blockxs={blockXS ? blockXS.toString() : null}
      style={style}
      disableElevation
      customsize={size}
      {...props}
    >
      {loading ? <CircularProgress color='inherit' size={28} /> : value}
    </StyledButton>
  );
};

export default RoundButton;
