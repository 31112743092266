import { Reducer } from 'redux';
import {
  RecebaAntesState,
  RecebaAntesActionTypes as types,
  EstadosBotaoContinuar,
} from './types';

const INITIAL_STATE: RecebaAntesState = {
  limiteTotal: 0,
  limiteDisponivel: 0,
  aumentoLimitePendente: false,
  boletos: [],
  etapaAtiva: 1,
  estadoBotaoContinuar: EstadosBotaoContinuar.desabilitado,
  listaSacados: [],
  buscandoSacados: false,
  carregando: true,
  usaRecebaAntes: true,
  isFiltroModalOpen: false,
  carregandoFiltro: true,
};

const recebaAntes: Reducer<RecebaAntesState, any> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case types.FETCH_LISTAGEM_SUCCESS:
      return {
        ...state,
        ...action.listagem,
        isFiltroModalOpen: false,
        filtroPeriodoErro: '',
      };
    case types.SET_FILTRO_PERIODO_ERRO:
      return {
        ...state,
        filtroPeriodoErro: action.filtroPeriodoErro,
      };
    case types.ETAPA_CADASTRO_PROXIMA:
      return {
        ...state,
        etapaAtiva: state.etapaAtiva + 1,
      };
    case types.ETAPA_CADASTRO_ANTERIOR:
      return {
        ...state,
        etapaAtiva: state.etapaAtiva - 1,
      };
    case types.SET_ETAPA_FINAL:
      return {
        ...state,
        etapaAtiva: 5,
      };
    case types.SET_ETAPA_INICIAL:
      return {
        ...state,
        etapaAtiva: 1,
        estadoBotaoContinuar: EstadosBotaoContinuar.desabilitado,
      };
    case types.BUSCA_SACADO_ERROR:
      return {
        ...state,
        erroBuscaSacado: action.error,
        buscandoSacados: false,
      };
    case types.BUSCA_SACADO_REQUEST:
      return {
        ...state,
        buscandoSacados: true,
        erroBuscaSacado: '',
      };
    case types.BUSCA_SACADO_SUCCESS:
      return {
        ...state,
        listaSacados: action.listaSacados,
        buscandoSacados: false,
        erroBuscaSacado: '',
      };
    case types.LIMPAR_BUSCA_SACADO:
      return {
        ...state,
        listaSacados: [],
        estadoBotaoContinuar: EstadosBotaoContinuar.desabilitado,
      };

    case types.ALTERA_BTN_PROXIMA_ETAPA:
      return {
        ...state,
        estadoBotaoContinuar: action.estadoBotaoContinuar,
      };
    case types.SET_CARREGANDO:
      return {
        ...state,
        carregando: action.carregando,
      };
    case types.SET_FILTRO_MODAL_OPEN:
      return {
        ...state,
        isFiltroModalOpen: action.isFiltroModalOpen,
      };
    case types.SET_FILTRO:
      return {
        ...state,
        ...action,
      };
    case types.SET_CARREGANDO_FILTRO:
      return {
        ...state,
        carregandoFiltro: action.carregandoFiltro,
      };
    default:
      return state;
  }
};

export default recebaAntes;
