export const ROXO = '#af016f';
export const VERMELHO = '#e94541';
export const LARANJA_200 = '#f39965';
export const LARANJA_100 = '#f2b163';
export const VERDE = '#20c05c';

export const LARANJA_VERMELHO = '#E84540';

export const AZUL_PARCERIA = '#07b8ed';
export const AZUL_100 = '#93d5f6';
export const AZUL_200 = '#00b9ee';
export const AZUL_300 = '#007fc4';
export const AZUL_400 = '#004899';
export const AZUL_500 = '#123d54';
export const AZUL_RA_BG_OPACITY = 'rgba(0, 127, 196, 0.8)';

export const BRANCO = '#ffffff';
export const CINZA_100 = '#eff0f1';
export const CINZA_200 = '#dae1e5';
export const CINZA_300 = '#adb0b6';
export const CINZA_400 = '#a9b6bf';
export const CINZA_500 = '#9294a0';
export const CINZA_600 = '#5e6c7f';
export const CINZA_700 = '#313448';
export const PRETO = '#000000';

export const CINZA_RA = '#90A4AE';
export const CINZA_RA_20 = 'rgba(49, 58, 72, 0.62)';

export const CINZA_200_20 = 'rgba(218, 225, 229, 0.2)';
export const PRIMARY_LINEAR_GRADIENT = `linear-gradient(90deg, ${AZUL_300}, #47A2D4 50%)`
