export const SET_VALOR_SAQUE = 'saque/SET_VALOR_SAQUE';
export const FETCH_DADOS_SAQUE_REQUEST = 'saque/FETCH_DADOS_SAQUE_REQUEST';
export const FETCH_DADOS_SAQUE_SUCCESS = 'saque/FETCH_DADOS_SAQUE_SUCCESS';
export const FETCH_DADOS_SAQUE_ERROR = 'saque/FETCH_DADOS_SAQUE_ERROR';
export const SET_CONTABANCARIA_ID = 'saque/SET_CONTABANCARIA_ID';
export const SOLICITAR_TOKEN_SAQUE_REQUEST = 'contaBancaria/SOLICITAR_TOKEN_SAQUE_REQUEST';
export const SOLICITAR_TOKEN_SAQUE_SUCCESS = 'contaBancaria/SOLICITAR_TOKEN_SAQUE_SUCCESS';
export const SOLICITAR_TOKEN_SAQUE_ERROR = 'contaBancaria/SOLICITAR_TOKEN_SAQUE_ERROR';
export const ENVIAR_SAQUE_REQUEST = 'saque/ENVIAR_SAQUE_REQUEST';
export const ENVIAR_SAQUE_SUCCESS = 'saque/ENVIAR_SAQUE_SUCCESS';
export const ENVIAR_SAQUE_ERROR = 'saque/ENVIAR_SAQUE_ERROR';
