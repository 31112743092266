import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ContentWrap from '../../components/ContentWrap';
import MenuAutenticado from '../../components/MenuAutenticado';
import Cabecalho from './Conteudo/Cabecalho';
import Footer from '../../components/Footer';
import ZeroState from './ZeroState';
import Conteudo from './Conteudo';
import BotaoCadastro from './Conteudo/BotaoCadastro';
import { bindActionCreators } from 'redux';
import { zerarSimulacao } from '../../store/simulacao/actions';

const StyledDivIntroducao = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 1;
  opacity: 0.8;
  background-color: #000;
  background: radial-gradient(ellipse, rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  background: -webkit-radial-gradient(center,ellipse cover,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
`
export const Simulacao = ({
  possuiMovimentacoes,
  etapaIntroducao,
  enviandoSimulacao,
  zerarSimulacao,
}) => {
  useEffect(() => {
    return () => {
      zerarSimulacao();
    };
  }, [zerarSimulacao]);

  return (
    <>
      <ContentWrap>
        <MenuAutenticado color='black' />
        <Cabecalho />
        {possuiMovimentacoes || enviandoSimulacao ? (
          <Conteudo />
        ) : (
          <ZeroState />
        )}
      </ContentWrap>
      <BotaoCadastro />
      <Footer />
      {etapaIntroducao && possuiMovimentacoes && <StyledDivIntroducao />}
    </>
  );
};

Simulacao.defaultProps = {
  etapaIntroducao: null,
};

Simulacao.propTypes = {
  possuiMovimentacoes: PropTypes.bool.isRequired,
  enviandoSimulacao: PropTypes.bool.isRequired,
  etapaIntroducao: PropTypes.number,
 };

const mapStateToProps = (state) => ({
  possuiMovimentacoes: state.simulacao.movimentacoes.length > 0,
  enviandoSimulacao: state.simulacao.enviandoSimulacao,
  etapaIntroducao: state.simulacao.etapaIntroducao,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  zerarSimulacao,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Simulacao);
