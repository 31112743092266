import React from 'react';
import PropTypes from 'prop-types';
import { AZUL_300 } from '../utils/color';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const StyledLink = styled(Link)`
  color: ${AZUL_300};
  border-bottom: 1px solid ${AZUL_300};
  align-self: center;
  padding: 2px 0px;
  margin-bottom: 10px;
  ${props => props.bold && `
    font-weight: bold;
  `}
`;

const LinkSublinhado = ({
  to,
  bold,
  children,
  onClick,
  component = RouterLink,
  href,
  ...props
}) => (
  <StyledLink
    to={to}
    href={href}
    component={component}
    bold={bold ? bold.toString() : null}
    onClick={onClick}
    {...props}
  >
    {children}
  </StyledLink>
);

LinkSublinhado.propTypes = {
  to: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func
}

export default LinkSublinhado;
