import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import RoundButton from './RoundButton';
import styled from 'styled-components';

const StyledRoundButton = styled(RoundButton)`
  margin-left: 10px;
  padding: 4px 15px;
  font-size: 10px;
  text-transform: uppercase;
`;

 const CopyToClipboard = ({ value, onClick = () => {} }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    var dummy = document.createElement('input')
    document.body.appendChild(dummy)
    dummy.setAttribute('id', 'dummy_id')
    document.getElementById('dummy_id').value = value
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    setCopySuccess(true);
    onClick();

    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };

  return (
    <Box display="flex" justifyContent="right" alignItems="center">
      <span style={{ alignSelf: 'flex-end' }}>{value}</span>
      <StyledRoundButton
        value={copySuccess ? 'Copiado!' : 'Copiar'}
        color="primary"
        bold
        style={{ marginTop: 0 }}
        onClick={copyToClipboard}
      />
    </Box>
  );
}

export default CopyToClipboard
