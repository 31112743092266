import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import { CINZA_700 } from '../../../utils/color';

export const CadastroContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: auto;

  h1 {
    color: ${CINZA_700};
    font-size: 1.75vw;
  }

  .container-etapas {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
  }

  .conteudo {
    width: 100%;
    margin: 50px auto;
  }

  .container-navegacao {
    width: 50vw;
    display: flex;
    justify-content: space-around;

    button {
      padding: 0.9vw 3.5vw;
    }

    span {
      text-transform: uppercase;
      font-size: 1vw;
      font-weight: bold;
    }

    .icone-botao {
      font-size: 1.4vw;
    }
  }

  @media ${queries.mobileDown} {
    h1 {
      font-size: 4vw;
    }

    .container-etapas {
      gap: 2.4vw;
    }

    .container-navegacao {
      width: 86vw;

      button {
        padding: 2.5vw 5.5vw;
        border-radius: 7vw;
      }

      span {
        font-size: 3.5vw;
      }

      .icone-botao {
        font-size: 4vw;
      }
    }
  }

  @media ${queries.desktopUp} {
    h1 {
      font-size: 1.5rem;
    }

    .container-etapas {
      gap: 1rem;
    }

    .container-navegacao {
      span {
        font-size: 14px;
      }

      button {
        padding: 12px 40px;
      }

      .icone-botao {
        font-size: 16px;
      }
    }
  }
`;
