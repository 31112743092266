import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { setValorDevolucao, setDataDevolucao } from '../../../../store/simulacao/actions';
import { selectSaldoDaUltimaMovimentacao } from '../../../../store/simulacao/selectors';
import { AZUL_400, CINZA_600 } from '../../../../utils/color';
import CampoDeMoeda from '../../../../components/CampoDeMoeda';
import DataInput from '../../../../components/DataInput';
import FormatadorDeMoeda from '../../../../components/FormatadorDeMoeda';
import TituloModal from '../TituloModal';

const StyledConteudo = styled.div`
  text-align: center;

  .simulacao-devolucao-imput-container {
    padding-bottom: 1rem;
  }

  h2 {
    margin-bottom: 0;
    color: ${AZUL_400};
    font-size: 1.2rem;
    text-align: center;
  }

  .simulacao-devolucao-data-titulo {
    padding-top: .5rem;
  }

  .simulacao-devolucao-rodape {
    color: ${CINZA_600};
    font-size: small;
  }

  .simulacao-devolucao-data-input {
    padding-bottom: 1.5rem;
  }

  @media (max-width: 375px) {
    .simulacao-devolucao-rodape {
      font-size: .6rem;
    }
  }
`

export const Conteudo = ({
  valorDevolucao,
  errors,
  onValorDevolucaoChange,
  dataDevolucao,
  handleDateChange,
  saldoDaUltimaMovimentacao,
}) => {
  const devolucaoHelperText = (
    <>
      {'O saldo da sua conta é '}
      <span><FormatadorDeMoeda valor={saldoDaUltimaMovimentacao} comSimbolo /></span>
    </>
  );

  return (
    <StyledConteudo>
      <div className='simulacao-devolucao-imput-container'>
        <TituloModal>Quanto deseja devolver?</TituloModal>
        <CampoDeMoeda
          value={valorDevolucao}
          placeholder='0,00'
          error={errors?.valorDevolucao}
          helperText= { errors?.valorDevolucao || devolucaoHelperText }
          onChange={onValorDevolucaoChange}
          />
      </div>
      <TituloModal>Escolha a data da devolução</TituloModal>
      <div className='simulacao-devolucao-data-input'>
        <DataInput
          handleDateChange={handleDateChange}
          value={dataDevolucao}
          error={errors?.dataDevolucao  }
          helperText={errors?.dataDevolucao }
        />
      </div>

      <span className='simulacao-devolucao-rodape'>
        O valor devolvido amortiza sua conta. Este <br />
        valor é acrescido no seu limite disponível e <br />
        pode ser reutilizado a qualquer momento.
      </span>
    </StyledConteudo>
  );
};

Conteudo.defaultProps = {
  valorDevolucao: '',
  dataDevolucao: null,
  errors: {},
};

Conteudo.propTypes = {
  valorDevolucao: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string),
  onValorDevolucaoChange: PropTypes.func.isRequired,
  dataDevolucao: PropTypes.string,
  handleDateChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  valorDevolucao: state.simulacao.valorDevolucao,
  dataDevolucao: state.simulacao.dataDevolucao,
  errors: state.simulacao.errors,
  saldoDaUltimaMovimentacao: selectSaldoDaUltimaMovimentacao(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onValorDevolucaoChange: setValorDevolucao,
  handleDateChange: setDataDevolucao,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
