import { SacadoAntecipacao } from '../antecipacao/types';

export enum RecebaAntesActionTypes {
  FETCH_LISTAGEM_SUCCESS = 'recebaAntes/FETCH_LISTAGEM_SUCCESS',
  SET_FILTRO_PERIODO_ERRO = 'recebaAntes/SET_FILTRO_PERIODO_ERRO',
  ETAPA_CADASTRO_PROXIMA = 'recebaAntes/ETAPA_CADASTRO_PROXIMA',
  ETAPA_CADASTRO_ANTERIOR = 'recebaAntes/ETAPA_CADASTRO_ANTERIOR',
  BUSCA_SACADO_REQUEST = 'recebaAntes/BUSCA_SACADO_REQUEST',
  BUSCA_SACADO_SUCCESS = 'recebaAntes/BUSCA_SACADO_SUCCESS',
  BUSCA_SACADO_ERROR = 'recebaAntes/BUSCA_SACADO_ERROR',
  LIMPAR_BUSCA_SACADO = 'recebaAntes/LIMPAR_BUSCA_SACADO',
  ALTERA_BTN_PROXIMA_ETAPA = 'recebaAntes/ALTERA_BTN_PROXIMA_ETAPA',
  SET_ETAPA_FINAL = 'recebaAntes/SET_ETAPA_FINAL',
  SET_ETAPA_INICIAL = 'recebaAntes/SET_ETAPA_INICIAL',
  SET_CARREGANDO = 'recebaAntes/SET_CARREGANDO',
  SET_CARREGANDO_FILTRO = 'recebaAntes/SET_CARREGANDO_FILTRO',
  SET_FILTRO_MODAL_OPEN = 'recebaAntes/SET_FILTRO_MODAL_OPEN',
  SET_FILTRO = 'recebaAntes/SET_FILTRO',
}

export interface ListagemRecebaAntes {
  limiteTotal: number;
  limiteDisponivel: number;
  aumentoLimitePendente: boolean;
  idPedidoAndamento?: number;
  boletos: BoletoRecebaAntes[];
  carregando: boolean;
  usaRecebaAntes: boolean;
  filtroStatus?: string;
  filtroPeriodo?: { dataInicial: string; dataFinal: string };
  isFiltroModalOpen: boolean;
  filtroPeriodoErro?: string;
  carregandoFiltro?: boolean;
}

export interface BoletoRecebaAntes {
  id: number;
  numeroOperacao: number;
  numeroBoleto: number;
  cliente: string;
  valor: number;
  vencimento: string;
  dataCredito: string;
  status: string;
}

export interface CadastroAntecipacao {
  etapaAtiva: number;
  estadoBotaoContinuar: EstadosBotaoContinuar;
}

export interface BuscaSacado {
  buscandoSacados: boolean;
  listaSacados: Array<SacadoAntecipacao>;
}

export enum EstadosBotaoContinuar {
  habilitado,
  desabilitado,
  escondido,
  carregando,
}

export type RecebaAntesState = ListagemRecebaAntes &
  CadastroAntecipacao &
  BuscaSacado;
