import {
  CircularProgress,
  IconButton,
  InputBase,
  Link,
  MenuItem,
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../../store';
import { setSacado } from '../../../../store/antecipacao/actions';
import { mostrarFormCadastro } from '../../../../store/cadastroSacado/actions';
import {
  buscaSacado,
  limparBuscaSacado,
  habilitaBtnContinuar,
  esconderBtnContinuar,
} from '../../../../store/recebaAntes/actions';
import IconeBuscaSacado from '../../../IconesSvg/IconeBuscaSacado';
import { BuscaSacado } from './BuscaSacado.style';
import FormCadastroSacado from './FormCadastroSacado';

interface SacadoProps extends ConnectedProps<typeof connector> {}

const Sacado = ({
  buscaSacado,
  limparBuscaSacado,
  habilitaBtnContinuar,
  esconderBtnContinuar,
  mostrarFormCadastro,
  listaSacados,
  buscandoSacados,
  showForm,
  sacadoSelecionado,
  setSacado,
}: SacadoProps) => {
  const [textoBusca, setTextoBusca] = useState('');
  const [fezBusca, setFezBusca] = useState(false);
  const [ultimoTextoBusca, setUltimoTextoBusca] = useState('');
  const opcaoCadastrarSacado =
    (fezBusca && !buscandoSacados && listaSacados.length === 0) || showForm;
  const [valorTimeout, setValorTimeout] = useState(null);

  const handleMudaBusca = ({ target: { value } }) => {
    setTextoBusca(value);
    if (value.length === 0) {
      setSacado(null);
      limparBuscaSacado();
      setFezBusca(false);
      return;
    }
    let timeout = valorTimeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (value.length > 0) {
        handleBusca(value);
      }
    }, 500);
    setValorTimeout(timeout);
  };

  const handlekeyPress = ({ key }) => {
    if (key === 'Enter') {
      handleBusca(textoBusca);
    }
  };

  const handleLimparInput = () => {
    setTextoBusca('');
    setSacado(null);
    limparBuscaSacado();
    setFezBusca(false);
  };

  const handleBusca = (texto) => {
    if (texto.length && texto !== ultimoTextoBusca) {
      buscaSacado(texto);
      setFezBusca(true);
      setUltimoTextoBusca(texto);
    }
  };

  useEffect(() => {
    if (opcaoCadastrarSacado) {
      esconderBtnContinuar();
    }
  }, [opcaoCadastrarSacado, esconderBtnContinuar]);

  const handleSelecionaSacado = (sacado) => {
    setSacado(sacado);
    limparBuscaSacado();
  };

  useEffect(() => {
    setTextoBusca(sacadoSelecionado?.nomeRazaoSocial || '');
    if (sacadoSelecionado) {
      habilitaBtnContinuar();
    }
  }, [sacadoSelecionado, habilitaBtnContinuar]);

  const handleClickCadastrar = () => {
    limparBuscaSacado();
    mostrarFormCadastro(textoBusca);
  };

  const afix = sacadoSelecionado ? (
    <Link
      component='button'
      onClick={handleLimparInput}
      className='link trocar'
    >
      Trocar
    </Link>
  ) : (
    <IconButton onClick={handleLimparInput}>
      <HighlightOff color='primary' />
    </IconButton>
  );

  const linkCadastrar = (
    <Link
      component='button'
      className='link'
      onClick={handleClickCadastrar}
      disabled={showForm}
      data-cy='link-cadastrar-sacado'
    >
      Cadastrar cliente
    </Link>
  );

  return (
    <BuscaSacado>
      <div className='input-sacado'>
        <IconButton
          onClick={handleBusca}
          disabled={!!sacadoSelecionado || !textoBusca || buscandoSacados}
        >
          {buscandoSacados ? (
            <CircularProgress size='1em' />
          ) : (
            <IconeBuscaSacado />
          )}
        </IconButton>
        <InputBase
          name='input-busca-sacado'
          disabled={!!sacadoSelecionado}
          placeholder='Digite o nome, CPF ou CNPJ do seu cliente'
          onChange={(e) => handleMudaBusca(e)}
          onKeyPress={(e) => handlekeyPress(e)}
          value={textoBusca}
          onBlur={() => handleBusca(textoBusca)}
        />
        {textoBusca && afix}
      </div>
      {listaSacados.length > 0 && (
        <div className='bottom-container'>
          {listaSacados?.map((s) => (
            <MenuItem
              className='item-lista'
              key={s.id}
              value={s.id}
              onClick={() => {
                handleSelecionaSacado(s);
              }}
            >
              {s.nomeRazaoSocial}
            </MenuItem>
          ))}
          <MenuItem
            style={{ justifyContent: 'center' }}
            onClick={handleClickCadastrar}
          >
            {linkCadastrar}
          </MenuItem>
        </div>
      )}

      {opcaoCadastrarSacado && (
        <div className='bottom-container'>
          {!sacadoSelecionado && listaSacados.length === 0 && (
            <div className='sem-cadastro'>
              <p>Esse cliente ainda não possui cadastro.</p>
              {linkCadastrar}
            </div>
          )}
          {showForm && <FormCadastroSacado />}
        </div>
      )}
    </BuscaSacado>
  );
};

const mapStateToProps = (state: RootState) => ({
  listaSacados: state.recebaAntes.listaSacados,
  buscandoSacados: state.recebaAntes.buscandoSacados,
  showForm: state.cadastroSacado.formAberto,
  sacadoSelecionado: state.antecipacao.sacado,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buscaSacado,
      limparBuscaSacado,
      habilitaBtnContinuar,
      esconderBtnContinuar,
      mostrarFormCadastro,
      setSacado,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Sacado);
