import React from 'react';
import styled from 'styled-components';
import BotaoDeIcone from '../../../components/BotaoDeIcone';
import { CINZA_700 } from '../../../utils/color';

const StyledDicaSaque = styled.p`
    margin-top: .5rem;
    margin-bottom: 0;
    padding-left: .5rem;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    text-transform: uppercase;

  .botao-dica-saque {
    padding-bottom: 1rem;

    svg {
      position: absolute;
      color: ${CINZA_700};
      font-size: 1rem;
    }
  }
`;

const conteudoDaDica = `
  Ao efetuar um novo saque, os juros acumulados são descontados.
  Assim, no dia do vencimento, só haverá cobrança de juros pelos dias entre o último saque e a data do boleto.
`;

const DicaSaque = () => (
  <StyledDicaSaque>
    ENTENDA SEU SAQUE
    <BotaoDeIcone
      className='botao-dica-saque'
      icone='ajuda'
      dica={conteudoDaDica}
      placement='right'
    />
  </StyledDicaSaque>
);

export default DicaSaque;
