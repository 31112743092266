import * as React from 'react';

const IconeExcluirAntecipacao = (props) => (
  <svg
    id='Camada_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 94.89 112.22'
    style={{
      enableBackground: 'new 0 0 94.89 112.22',
    }}
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
  >
    <style>{'.st15{fill:#af016f}'}</style>
    <path
      d='m1.56 54.65 42.93 27.32c.49.36 1.07.54 1.65.54s1.16-.18 1.65-.54l42.93-27.32-42.9-32.88a2.79 2.79 0 0 0-3.34 0L1.56 54.65z'
      style={{
        fill: '#92d4f5',
      }}
    />
    <path
      d='M74.01 21.21H18.28c-3.07 0-5.57 2.5-5.57 5.57v61.3c0 1.54 1.25 2.79 2.79 2.79h61.3c1.54 0 2.79-1.25 2.79-2.79v-61.3c-.01-3.06-2.51-5.57-5.58-5.57z'
      style={{
        fill: '#eceff1',
      }}
    />
    <linearGradient
      id='SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={1.564}
      y1={82.511}
      x2={90.724}
      y2={82.511}
    >
      <stop
        offset={0.018}
        style={{
          stopColor: '#004898',
        }}
      />
      <stop
        offset={0.13}
        style={{
          stopColor: '#0065af',
        }}
      />
      <stop
        offset={0.248}
        style={{
          stopColor: '#007fc3',
        }}
      />
      <stop
        offset={0.425}
        style={{
          stopColor: '#0093d2',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#0068b1',
        }}
      />
    </linearGradient>
    <path
      d='M47.79 81.97c-.49.36-1.07.54-1.65.54-.58 0-1.16-.18-1.65-.54L1.56 54.65v50.15c0 3.08 2.5 5.57 5.57 5.57h78.02c3.08 0 5.57-2.5 5.57-5.57V54.65L47.79 81.97z'
      style={{
        fill: 'url(#SVGID_1_)',
      }}
    />
    <linearGradient
      id='SVGID_00000078739382280023029160000005613488449564491410_'
      gradientUnits='userSpaceOnUse'
      x1={46.144}
      y1={110.374}
      x2={46.144}
      y2={74.152}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#0067b0',
        }}
      />
      <stop
        offset={0.268}
        style={{
          stopColor: '#007fc3',
        }}
      />
      <stop
        offset={0.79}
        style={{
          stopColor: '#0093d2',
        }}
      />
    </linearGradient>
    <linearGradient
      id='SVGID_00000043455615906512466690000009851985658561911464_'
      gradientUnits='userSpaceOnUse'
      x1={1.16}
      y1={92.263}
      x2={91.127}
      y2={92.263}
    >
      <stop
        offset={0.018}
        style={{
          stopColor: '#004898',
        }}
      />
      <stop
        offset={0.13}
        style={{
          stopColor: '#0065af',
        }}
      />
      <stop
        offset={0.248}
        style={{
          stopColor: '#007fc3',
        }}
      />
      <stop
        offset={0.425}
        style={{
          stopColor: '#0093d2',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#0068b1',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#SVGID_00000078739382280023029160000005613488449564491410_)',
        stroke:
          'url(#SVGID_00000043455615906512466690000009851985658561911464_)',
        strokeWidth: 0.8072,
        strokeMiterlimit: 10,
      }}
      d='M85.15 110.37H7.14c-3.13 0-5.57-2.45-5.57-5.57 0-.89.42-1.72 1.14-2.25L44.5 74.69c.49-.36 1.07-.54 1.65-.54s1.16.18 1.65.54l41.79 27.86a2.8 2.8 0 0 1 1.14 2.25c-.01 3.13-2.45 5.57-5.58 5.57z'
    />
    <path
      className='st15'
      d='M32.15 37.65h4.16v18.73h-4.16zM37.46 37.65H39v18.73h-1.54zM40.53 37.65h1.54v18.73h-1.54zM43.99 37.65h3.46v18.73h-3.46zM48.92 37.65h1.54v18.73h-1.54zM51.99 37.65h1.54v18.73h-1.54zM55.45 37.65h3.46v18.73h-3.46zM68.45 38.39h-2.39v-5.05a.29.29 0 0 0-.29-.29H61.9v-2.39h3.87c1.48 0 2.68 1.2 2.68 2.68v5.05zM25 38.39h-2.39v-5.05c0-1.48 1.2-2.68 2.68-2.68h3.86v2.39h-3.86a.29.29 0 0 0-.29.29v5.05zM29.15 63.98h-3.86a2.68 2.68 0 0 1-2.68-2.68v-5.05H25v5.05c0 .16.13.29.29.29h3.86v2.39zM65.76 63.98H61.9v-2.39h3.87c.16 0 .29-.13.29-.29v-5.05h2.39v5.05c0 1.48-1.21 2.68-2.69 2.68z'
    />
    <linearGradient
      id='SVGID_00000021117139718892508310000003105411194012282291_'
      gradientUnits='userSpaceOnUse'
      x1={107.696}
      y1={1.505}
      x2={17.617}
      y2={65.727}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#af016f',
        }}
      />
      <stop
        offset={0.193}
        style={{
          stopColor: '#cb3666',
        }}
      />
      <stop
        offset={0.464}
        style={{
          stopColor: '#f07c59',
        }}
      />
      <stop
        offset={0.894}
        style={{
          stopColor: '#f39965',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#SVGID_00000021117139718892508310000003105411194012282291_)',
      }}
      d='M92.89 44.91c-2.56-3.09-5.29-6.33-8.34-9.9-2.24-2.61-4.45-5.24-6.58-7.82L92.85 9.83a3.315 3.315 0 0 0-.25-4.57l-2.54-2.37A3.24 3.24 0 0 0 87.62 2c-.9.05-1.74.47-2.33 1.16L71.38 19.5 57.53 3.09c-.6-.71-1.46-1.13-2.38-1.17-.94-.05-1.82.31-2.47.96l-2.45 2.45a3.289 3.289 0 0 0-.2 4.47c2.66 3.15 5.44 6.42 8.29 9.72 2.27 2.63 4.46 5.19 6.53 7.64L50.02 44.9a3.294 3.294 0 0 0 .2 4.45l2.53 2.54c.62.62 1.46.97 2.34.97h.16c.93-.05 1.8-.48 2.39-1.2 2.46-2.99 5.18-6.24 8.1-9.64 1.96-2.29 3.88-4.56 5.74-6.79l13.9 16.38c.6.7 1.46 1.13 2.38 1.16.92.03 1.82-.31 2.47-.97l2.45-2.45c1.21-1.2 1.3-3.12.21-4.44z'
    />
  </svg>
);

export default IconeExcluirAntecipacao;
