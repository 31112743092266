import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Grid } from '@material-ui/core';
import { selectNomeError, selectEmailError, selectMensagemError } from '../../../store/contato/selectors';
import {
  setNome,
  setEmail,
  setMensagem,
  enviarContato,
  dispensarNotificacaoDeSucesso,
} from '../../../store/contato/actions';
import RoundButton from '../../../components/RoundButton';
import Notification from '../../../components/Notification';
import { AZUL_300, BRANCO } from '../../../utils/color';

const StyledFormContato = styled(Grid)`
  width: 100%;
  padding: 2rem 5rem;
  background: linear-gradient(141deg, rgba(0,127,196,1), rgba(0,127,196,.7));
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;

  .form-button {
    text-align: center;
  }

  .MuiButton-label {
    color: ${AZUL_300};
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 2rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .fale-conosco-container .email {
      font-size: 1rem;
    }
  }
`;

const StyledTextField = withStyles({
  root: {
    'margin': '.3rem',
    '& .MuiOutlinedInput-root': {
      'color': `${BRANCO}`,
      '& fieldset': {
        borderColor: `${BRANCO}`,
      },
      '&:hover fieldset': {
        borderColor: `${BRANCO}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: `${BRANCO}`,
      },
      '&.Mui-error fieldset': {
        borderColor: `${BRANCO}`,
      },
      '&.Mui-disabled fieldset': {
        borderColor: `${BRANCO}`,
      },
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: `${BRANCO}`,
    },
  },
})(TextField);

export const FormContato = ({
  nome,
  nomeError,
  email,
  emailError,
  mensagem,
  mensagemError,
  isSending,
  enviadoComSucesso,
  onNomeChange,
  onEmailChange,
  onMensagemChange,
  onEnviarClick,
  onSuccessMessageClose,
}) => {
  return (
    <StyledFormContato item sm={12} md={6}>
      <Notification
          open={enviadoComSucesso}
          onClose={onSuccessMessageClose}
          severity='success'
          message='Mensagem enviada com sucesso.'
      />
      <StyledTextField
        id='contato-nome-input'
        placeholder='Nome'
        value={nome}
        error={!!nomeError}
        helperText={nomeError}
        disabled={isSending}
        variant='outlined'
        fullWidth
        onChange={(e) => onNomeChange(e.target.value)}
      />

      <StyledTextField
        id='contato-email-input'
        placeholder='E-mail'
        variant='outlined'
        fullWidth
        value={email}
        error={!!emailError}
        helperText={emailError}
        disabled={isSending}
        onChange={(e) => onEmailChange(e.target.value)}
      />

      <StyledTextField
        id='contato-mensagem-input'
        placeholder='Escreva sua mensagem aqui'
        multiline
        rows={6}
        variant='outlined'
        fullWidth
        value={mensagem}
        error={!!mensagemError}
        helperText={mensagemError}
        disabled={isSending}
        onChange={(e) => onMensagemChange(e.target.value)}
      />

      <div className='form-button'>
        <RoundButton
          value={'Enviar'}
          size='small'
          bold='bold'
          onClick={onEnviarClick}
          loading={isSending}
        />
      </div>
    </StyledFormContato>
  );
};

FormContato.propTypes = {
  nome: PropTypes.string.isRequired,
  nomeError: PropTypes.string,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string,
  mensagem: PropTypes.string.isRequired,
  mensagemError: PropTypes.string,
  isSending: PropTypes.bool.isRequired,
  onNomeChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onMensagemChange: PropTypes.func.isRequired,
  onEnviarClick: PropTypes.func.isRequired,
};

FormContato.defaultProps = {
  nomeError: '',
  emailError: '',
  mensagemError: '',
};

const mapStateToProps = (state) => ({
  nome: state.contato.nome,
  email: state.contato.email,
  mensagem: state.contato.mensagem,
  nomeError: selectNomeError(state),
  emailError: selectEmailError(state),
  mensagemError: selectMensagemError(state),
  isSending: state.contato.isSending,
  enviadoComSucesso: state.contato.enviadoComSucesso,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onNomeChange: setNome,
  onEmailChange: setEmail,
  onMensagemChange: setMensagem,
  onEnviarClick: enviarContato,
  onSuccessMessageClose: dispensarNotificacaoDeSucesso,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormContato);
