const tamanho = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

const queries = {
  mobileDown: `(max-width: ${tamanho.sm - 1}px)`,
  tabletDown: `(max-width: ${tamanho.md - 1}px)`,
  desktopDown: `(max-width: ${tamanho.lg - 1}px)`,
  fullHdDown: `(max-width: ${tamanho.xl - 1}px)`,
  mobileUp: `(min-width: ${tamanho.sm}px)`,
  tabletUp: `(min-width: ${tamanho.md}px)`,
  desktopUp: `(min-width: ${tamanho.lg}px)`,
  fullHdUp: `(min-width: ${tamanho.xl}px)`,
}


export { tamanho, queries }
