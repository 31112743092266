import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Hidden } from '@material-ui/core';
import { AZUL_300, VERMELHO } from '../../utils/color';
import RoundButton from '../../components/RoundButton';
import { simularSaque as simularSaqueAction } from '../../store/simulacao/actions';
import CampoValorSaque from '../../pages/Simulacao/CampoValorSaque';
import { analyticsEvents } from '../../utils/analytics';
import bgDesktop from '../../assets/home_simulacao_desktop.png';
import bgMobile from '../../assets/home_simulacao_mobile.png';
import { queries } from '../../utils/breakpoints';

const BlueView = styled.div`
  width: 100%;
  min-height: 26vw;
  background-color: ${AZUL_300};
  background-image: url(${bgDesktop});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding: 3rem 4vw 3rem 9vw;

  .container-simulacao {
    align-items: center;
  }

  .container-field-simulacao b {
    padding-left: 1em;
  }

  @media ${queries.mobileDown} {
    padding: 21vw 5vw 22vw 8vw;
    background-image: url(${bgMobile});

    .container-field-simulacao {
      padding-top: 13vw;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .container-field-simulacao b {
      padding-left: 2em;
    }
  }

  .simular-button {
    font-size: 1.9rem;
    padding: 0 3rem;
    line-height: 40px;
  }
`;

const TituloBrancoBold = styled.b`
  color: white;
  font-size: 2.3vw;
  @media ${queries.mobileDown} {
    font-size: 6.8vw;
  }
`;

const TextoBrancoBold = styled.b`
  color: white;
  display: block;
  padding-bottom: 0em;
  font-size: 1.4vw;

  @media ${queries.mobileDown} {
    font-size: 4vw;
    padding-bottom: 0;
  }
`;

const StyledTitle = styled.b`
  display: inline-block;
  font-size: 2.3vw;
  color: white;
  background: ${VERMELHO};
  border-radius: 1em;
  padding: 0 1vw;
  line-height: .8em;

  @media ${queries.mobileDown} {
    font-size: 6.8vw;
    padding: 0 2vw;
  }
`;

const SimulacaoField = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  height: 10vh;
  border-radius: 4.5vw;
  padding-right: 2vw;
  padding-bottom: .8vw;
  margin-bottom: 1rem;
  min-width: 200px;
  max-width: 42vw;

  @media ${queries.mobileUp} and ${queries.tabletDown} {
    flex-direction: column;
    padding: 1rem;

    .simular-button {
      font-size: 1.2rem;
      padding: 0px 3rem;
      line-height: 35px;
    }

    .MuiTextField-root {
      width: 80%;
      margin: 0;
    }

  }

  .MuiTextField-root {
    flex: 1 1 0;
    margin: 0 3vw;
  }

  .MuiInputBase-root {
    display: inline-flex;
  }

  .MuiFormHelperText-root {
    text-align: left;
  }

  .MuiInputBase-input {
    width: 80%;
    padding-top: 6px;
    font-size: 1.1rem;
    text-align: left;
  }

  .Mui-error input {
    margin-top: 1rem;
  }

  .MuiInputBase-input::placeholder {
    text-align: left;
  }

  .MuiFormHelperText-root {
      min-width: 100%;
      font-size: 12px;
  }

  @media (max-width: 960px) {
    .MuiInputBase-input {
      font-size: 1vw;
    }
  }
  @media ${queries.mobileDown} {
    padding: 0 5vw 1vw 3vw;
    height: 20vw;
    border-radius: 9vw;
    margin: 0 2vw 1vw 2vw;
    max-width: 90vw;

    .MuiInputBase-input {
      font-size: 4.2vw;
    }
  }
`;

const Simulacao = ({ simularSaque, limiteDeCreditoPadraoFormatado }) => {
  const history = useHistory();
  const dataSaque = new Date().toLocaleDateString('pt-BR');

  const simular = () => {
    simularSaque({ dataSaque });
    analyticsEvents('click_simular')
    history.push('/simulacao');
  };

  const windowWidth = window.innerWidth;
  let valorSaquePlaceholder = `até R$${limiteDeCreditoPadraoFormatado}`;
  if (windowWidth > 350) {
    valorSaquePlaceholder = `valor ${valorSaquePlaceholder}`;
  }
  if (windowWidth > 460) {
    valorSaquePlaceholder = `Digite um ${valorSaquePlaceholder}`;
  }
  valorSaquePlaceholder = valorSaquePlaceholder.charAt(0).toUpperCase() + valorSaquePlaceholder.slice(1);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      simular();
    }
  };

  return (
    <BlueView name='simulacao' id='simulacao'>
      <Grid container className='container-simulacao'>
        <Grid item xs={12} sm={5}>
          <TituloBrancoBold>FAÇA UMA</TituloBrancoBold> <StyledTitle>SIMULAÇÃO</StyledTitle>
          <TextoBrancoBold>E veja na prática como funciona</TextoBrancoBold>
        </Grid>
        <Grid item xs={12} sm={7} className='container-field-simulacao'>
          <TextoBrancoBold>Quanto deseja sacar?</TextoBrancoBold>
          <SimulacaoField>
            <CampoValorSaque
              placeholder={valorSaquePlaceholder}
              onKeyPress={handleKeyPress}
            />
            <Hidden xsDown>
              <RoundButton
                value='Simular'
                color='destaque'
                size='large'
                bold
                onClick={simular}
                className='simular-button'
              />
            </Hidden>
          </SimulacaoField>
          <Hidden smUp>
            <RoundButton
              value='Simular'
              color='destaque'
              size='large'
              bold
              onClick={simular}
              style={{ fontSize: '4vw' }}
            />
          </Hidden>
        </Grid>
      </Grid>
    </BlueView>
  );
};

const mapStateToProps = (state) => ({
  limiteDeCreditoPadraoFormatado: state.configuracoes.limiteDeCreditoPadraoFormatado,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  simularSaque: simularSaqueAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Simulacao);
