import Api from '../../Api';

const getListagemRecebaAntes = (params) => Api.get('receba-antes', params);

const postSolicitaAumentoLimite = () => Api.post('aumento-limite-receba-antes');

const getListaSacados = (busca) => Api.get(`sacados?busca=${busca}`);

const getEstados = () => Api.get('estados');

const getCidadesPorEstado = (estadoId) =>
  Api.get(`cidades?estado_id=${estadoId}`);

const postCadastroSacado = (dadosSacado) => Api.post('sacados', dadosSacado);

export {
  getListagemRecebaAntes,
  postSolicitaAumentoLimite,
  getListaSacados,
  getEstados,
  getCidadesPorEstado,
  postCadastroSacado,
};
