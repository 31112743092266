import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { CINZA_400, AZUL_300 } from '../../../utils/color';
import DicaSaque from './DicaSaque';
import {
  fetchDadosSaque as fetchDadosSaqueAction,
  setValorSaque,
  solicitarTokenSaque as solicitarTokenSaqueAction,
  enviarSaque as enviarSaqueAction,
} from '../../../store/saque/actions';
import ContasBancariasRadioGroup from './ContasBancariasRadioGroup';
import Valores from './Valores';
import CampoDeMoeda from '../../../components/CampoDeMoeda';
import ConfirmarComToken from '../../../components/ConfirmarComToken';

const StyledSaque = styled(Container)`
    padding: 4rem 0 2rem 0;
    text-align: center;

  .informacoes-saque {
    border: 2px solid ${CINZA_400};
    border-radius: 10px;
  }

  .informacoes-saque h1 {
    margin-bottom: 0;
    color: ${AZUL_300};
    font-size: 18px;
    text-align: center;
  }

  @media (max-width: 960px) {
    padding: 0;
    padding-bottom: 2rem;

    .informacoes-saque {
      border: none;
      border-radius: 0;
    }
  }
`;

export const Saque = ({
  valorSaque,
  fetchDadosSaque,
  onValorSaqueChange,
  solicitarTokenSaque,
  enviarSaque,
  enviandoSaque,
  error,
  contasBancarias,
}) => (
  <StyledSaque>
    <ConfirmarComToken
      tipoDeConfirmacao='e-mail'
      disabled={contasBancarias <= 0}
      loadingBotao={enviandoSaque}
      onNextButtonClick={solicitarTokenSaque}
      onConfirmButtonClick={enviarSaque}
      tokenInvalido={Boolean(error)}
    >
      <div className='informacoes-saque'>
        <h1>Quanto deseja sacar?</h1>
        <CampoDeMoeda
          value={valorSaque}
          placeholder='R$ 0,00'
          error={!!error}
          helperText={error || 'Saque mínimo de R$ 100,00.'}
          onStopTyping={fetchDadosSaque}
          onChange={onValorSaqueChange}
        />
        <Valores />
        <ContasBancariasRadioGroup hideButton />
      </div>
      <DicaSaque />
    </ConfirmarComToken>
  </StyledSaque>
);


Saque.defaultProps = {
  error: '',
  contasBancarias: [],
};

Saque.propTypes = {
  fetchDadosSaque: PropTypes.func.isRequired,
  onValorSaqueChange: PropTypes.func.isRequired,
  solicitarTokenSaque: PropTypes.func.isRequired,
  enviarSaque: PropTypes.func.isRequired,
  error: PropTypes.string,
  contasBancarias: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = (state) => ({
  valorSaque: state.saque.valorSaque,
  valorJuros: state.saque.valorJuros,
  valorIof: state.saque.valorIof,
  valorFinal: state.saque.valorFinal,
  enviandoSaque: state.saque.enviandoSaque,
  error: state.saque.error,
  contasBancarias: state.contaBancaria.contasBancarias,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDadosSaque: fetchDadosSaqueAction,
  onValorSaqueChange: setValorSaque,
  solicitarTokenSaque: solicitarTokenSaqueAction,
  enviarSaque: enviarSaqueAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Saque);
