import * as React from 'react';
import { SVGProps } from 'react';

const IconePedidoEmAndamento = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 16.74 20.81"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".st1{fill:url(#SVGID_00000092457095674504143420000007250113298665694910_)}.st2{fill:url(#SVGID_00000063616962126069154830000015472301362083231635_)}.st3{fill:url(#SVGID_00000178198215932685295010000016530725305898722950_)}.st4{fill:url(#SVGID_00000124158910060938272730000008096441257678416057_)}.st5{fill:url(#SVGID_00000092434071336728531690000008249506643166557838_)}.st6{fill:#fff}.st7{fill:#5e6c7f}.st8{fill:#007fc4}.st9{fill:url(#SVGID_00000062183304775521147810000002468134738670188184_)}.st10{fill:url(#SVGID_00000128463730180724718000000000649016068072547218_)}.st11{fill:#92d4f5}.st12{fill:#eceff1}.st13{fill:url(#SVGID_00000080207713017799934510000017357895130302484610_)}.st14{fill:url(#SVGID_00000067946790313099576350000003662513887517228210_);stroke:url(#SVGID_00000141420356171779306510000007987642763205160879_);stroke-width:.8968;stroke-miterlimit:10}.st15{fill:#af016f}.st16{fill:url(#SVGID_00000142148138723527921340000018172896661772565181_)}.st17{fill:url(#SVGID_00000079444389291400118420000001502692851062319803_)}.st18{fill:url(#SVGID_00000168076485028656251440000012749505645587152780_);stroke:url(#SVGID_00000167391124468855023610000016585435084963878314_);stroke-width:.8072;stroke-miterlimit:10}.st19{fill:url(#SVGID_00000006686565695427895290000011050154409875108280_)}"
      }
    </style>
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={0.321}
      y1={10.404}
      x2={16.407}
      y2={10.404}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#188bc9",
        }}
      />
      <stop
        offset={0.916}
        style={{
          stopColor: "#2290cb",
        }}
      />
    </linearGradient>
    <path
      d="M16.11 20.65H.62c-.17 0-.3-.13-.3-.3V.46c0-.17.13-.3.3-.3h15.49c.17 0 .3.13.3.3v19.89a.3.3 0 0 1-.3.3zm-15.19-.6H15.8V.76H.92v19.29z"
      style={{
        fill: "url(#SVGID_1_)",
      }}
    />
    <linearGradient
      id="SVGID_00000176017061665948771110000012537554713527325366_"
      gradientUnits="userSpaceOnUse"
      x1={2.813}
      y1={4.686}
      x2={13.915}
      y2={4.686}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#188bc9",
        }}
      />
      <stop
        offset={0.916}
        style={{
          stopColor: "#2290cb",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000176017061665948771110000012537554713527325366_)",
      }}
      d="M13.61 4.99H3.11c-.17 0-.3-.13-.3-.3 0-.17.13-.3.3-.3h10.5c.17 0 .3.13.3.3.01.16-.13.3-.3.3z"
    />
    <linearGradient
      id="SVGID_00000183945998108679840000000010259725325421203859_"
      gradientUnits="userSpaceOnUse"
      x1={2.813}
      y1={7.356}
      x2={13.915}
      y2={7.356}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#188bc9",
        }}
      />
      <stop
        offset={0.916}
        style={{
          stopColor: "#2290cb",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000183945998108679840000000010259725325421203859_)",
      }}
      d="M13.61 7.66H3.11c-.17 0-.3-.13-.3-.3 0-.17.13-.3.3-.3h10.5c.17 0 .3.13.3.3.01.16-.13.3-.3.3z"
    />
    <linearGradient
      id="SVGID_00000003813513582505481280000004454777886749854356_"
      gradientUnits="userSpaceOnUse"
      x1={2.813}
      y1={10.025}
      x2={13.915}
      y2={10.025}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#188bc9",
        }}
      />
      <stop
        offset={0.916}
        style={{
          stopColor: "#2290cb",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000003813513582505481280000004454777886749854356_)",
      }}
      d="M13.61 10.33H3.11c-.17 0-.3-.13-.3-.3 0-.17.13-.3.3-.3h10.5c.17 0 .3.13.3.3.01.16-.13.3-.3.3z"
    />
    <linearGradient
      id="SVGID_00000121991063786089840720000005556440424625404292_"
      gradientUnits="userSpaceOnUse"
      x1={2.813}
      y1={12.695}
      x2={13.915}
      y2={12.695}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#188bc9",
        }}
      />
      <stop
        offset={0.916}
        style={{
          stopColor: "#2290cb",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000121991063786089840720000005556440424625404292_)",
      }}
      d="M13.61 13H3.11c-.17 0-.3-.13-.3-.3s.13-.3.3-.3h10.5c.17 0 .3.13.3.3s-.13.3-.3.3z"
    />
    <linearGradient
      id="SVGID_00000039092295023792454720000009478477632311350711_"
      gradientUnits="userSpaceOnUse"
      x1={2.813}
      y1={15.365}
      x2={10.429}
      y2={15.365}
    >
      <stop
        offset={0}
        style={{
          stopColor: "#188bc9",
        }}
      />
      <stop
        offset={0.916}
        style={{
          stopColor: "#2290cb",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000039092295023792454720000009478477632311350711_)",
      }}
      d="M10.13 15.67H3.11c-.17 0-.3-.13-.3-.3 0-.17.13-.3.3-.3h7.02c.17 0 .3.13.3.3 0 .16-.14.3-.3.3z"
    />
  </svg>
)

export default IconePedidoEmAndamento
