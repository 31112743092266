export const SET_VALOR_SAQUE = 'simulacao/SET_VALOR_SAQUE';
export const SET_VALOR_DEVOLUCAO = 'simulacao/SET_VALOR_DEVOLUCAO';
export const SET_DATA_DEVOLUCAO = 'simulacao/SET_DATA_DEVOLUCAO';
export const SET_DATA_SAQUE = 'simulacao/SET_DATA_SAQUE';
export const ADICIONAR_SAQUE = 'simulacao/ADICIONAR_SAQUE';
export const ENVIAR_SIMULACAO_REQUEST = 'simulacao/ENVIAR_SIMULACAO_REQUEST';
export const ENVIAR_SIMULACAO_SUCCESS = 'simulacao/ENVIAR_SIMULACAO_SUCCESS';
export const ENVIAR_SIMULACAO_ERROR = 'simulacao/ENVIAR_SIMULACAO_ERROR';
export const ADICIONAR_DEVOLUCAO = 'simulacao/ADICIONAR_DEVOLUCAO';
export const ZERAR_SIMULACAO = 'simulacao/ZERAR_SIMULACAO ';
export const SET_ETAPA_INTRODUCAO= 'simulacao/SET_ETAPA_INTRODUCAO';
export const FETCH_DADOS_SAQUE_REQUEST = 'simulacao/FETCH_DADOS_SAQUE_REQUEST';
export const FETCH_DADOS_SAQUE_SUCCESS = 'simulacao/FETCH_DADOS_SAQUE_SUCCESS';
export const FETCH_DADOS_SAQUE_ERROR = 'simulacao/FETCH_DADOS_SAQUE_ERROR';
export const SET_MODAL_SAQUE_ABERTA = 'contaBancaria/SET_MODAL_SAQUE_ABERTA';
export const SET_MODAL_DEVOLUCAO_ABERTA = 'contaBancaria/SET_MODAL_DEVOLUCAO_ABERTA';
