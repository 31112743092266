import { InputAdornment } from '@material-ui/core';
import format from 'date-fns/format';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { atualizaSomatorioParcela } from '../../../../store/antecipacao/actions';
import { toCurrency, toNumber } from '../../../../utils/number';
import CampoDeMoeda from '../../../CampoDeMoeda';
import { ValoresStyle } from './Valores.style';

const Valores = ({ parcela, index, atualizaSomatorio }) => {
  const [valor, setValor] = useState(toCurrency(parcela.valor));

  const handleChange = (valorBoleto) => {
    setValor(valorBoleto);
    const valorNumero = toNumber(valorBoleto);
    const novaParcela = { ...parcela, valor: valorNumero };
    atualizaSomatorio(novaParcela, index);
  };

  return (
    <ValoresStyle>
      <span className='boleto'>{index + 1}º Boleto</span>
      <span className='data'>{format(parcela.vencimento, 'dd/MM/yyyy')}</span>
      <div className='valor'>
        <CampoDeMoeda
          value={valor}
          onChange={handleChange}
          dataCy={`input-valor-parcela-${index}`}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>R$</InputAdornment>
            ),
          }}
        />
      </div>
    </ValoresStyle>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      atualizaSomatorio: atualizaSomatorioParcela,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Valores);
