import { Container, Grid, Hidden } from '@material-ui/core';
import React from 'react';

import StatusPedido from '../../components/RecebaAntes/StatusPedido';
import CardLimite from '../../components/RecebaAntes/Limite/CardLimte';
import AcompanhamentoBoletos from '../RecebaAntes/AcompanhamentoBoletos';
import ContainerLateral from '../RecebaAntes/ContainerLateral';
import Banner from '../../components/RecebaAntes/Banner';
import styled from 'styled-components';
import { queries } from '../../utils/breakpoints';

const StyledAbaRecebaAntes = styled(Container)`
  padding: 0;
  width: 82vw;
  max-width: 1050px;

  @media ${queries.mobileUp} {
    .container-principal {
      padding: 2rem 1rem;
    }
    .MuiGrid-grid-sm-4 {
      flex-basis: 31%;
    }

    .MuiGrid-grid-sm-8 {
      max-width: 68%;
      flex-basis: 68%;
    }
  }

  @media ${queries.mobileDown} {
    padding-bottom: 0.5rem;
    width: 100vw;

    .container-principal {
      padding: 2rem 0;
    }
  }
`;

const AbaRecebaAntes: React.FunctionComponent = () => (
  <StyledAbaRecebaAntes>
    <Hidden smUp>
      <Banner />
    </Hidden>
    <Grid container justify='space-between' className='container-principal'>
      <Grid item xs={12} sm={4}>
        <ContainerLateral>
          <CardLimite />
          <StatusPedido />
        </ContainerLateral>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Hidden xsDown>
          <Banner />
        </Hidden>
        <AcompanhamentoBoletos />
      </Grid>
    </Grid>
  </StyledAbaRecebaAntes>
);

export default AbaRecebaAntes;
