import React from 'react';
import { Typography, Box, Link} from '@material-ui/core';
import MenuAutenticado from '../../components/MenuAutenticado';
import ContentWrap from '../../components/ContentWrap';
import Footer from '../../components/Footer';

const PoliticaDePrivacidade = () => (
  <>
    <ContentWrap>
      <MenuAutenticado />
      <Box px={4} pt={5} pb={3} textAlign="center">
        <Typography variant="h1" textAlign="center" gutterBottom>
          Política de Privacidade e Segurança Crédito Rotativo
        </Typography>
      </Box>

      <Box maxWidth={800} mx="auto" pb={5} px={4}>
        <Typography variant="body1" paragraph>
          O Crédito Rotativo tem o compromisso com a privacidade e a segurança de seus clientes
          durante todo o processo de navegação e compra pelo site.
          Nós não iremos vender, alugar ou transferir seus dados para terceiros.
          Essas informações podem ser agrupadas e utilizadas apenas internamente,
          como estatísticas genéricas, visando obter um melhor entendimento do perfil
          dos usuários para aperfeiçoamento dos produtos e serviços oferecidos no site.
        </Typography>

        <Typography variant="body1" paragraph>
          Com o objetivo de assegurar o direito à privacidade e à proteção de dados
          pessoais dos usuários da plataforma, seja em relação ao público geral, colaboradores,
          funcionários e todos aqueles que acessem por quaisquer meios nossos sistemas,
          apresentamos a política de privacidade de nosso sistema, em atendimento à
          legislação vigente e aos princípios que norteiam nossas atividades.
        </Typography>

        <Typography variant="body1" paragraph>
          Ao utilizar nossas plataformas, o usuário declara estar ciente e de acordo
          com o disposto nesta Política de Privacidade, autorizando a
          Rinoceronte Branco Empresa Simples de Crédito LTDA (Crédito Rotativo),
          de forma livre e expressa, tratar dados e informações fornecidos pelo usuário nos termos aqui estabelecidos.
        </Typography>

        <Typography variant="body1" paragraph>
          Nesta página você será informado sobre:
        </Typography>

        <ul >
          <Link href="#item1" underline="always" variant="body1">
            <li>
              Que tipo de dados são considerados pela LGPD?
            </li>
          </Link>

          <Link href="#item2" underline="always" variant="body1">
            <li>
              Quais são os seus direitos enquanto titular dos dados?
          </li>
          </Link>

          <Link href="#item3" underline="always" variant="body1">
          <li>
              Como o Crédito Rotativo coleta seus dados pessoais?
          </li>
          </Link>

          <Link href="#item4" underline="always" variant="body1">
            <li>
              O que significa tratamento de dados?
            </li>
          </Link>

          <Link href="#item5" underline="always" variant="body1">
            <li>
              Quais as ferramentas utilizadas por nós para o tratamento de seus dados?
            </li>
          </Link>

          <Link href="#item6" underline="always" variant="body1">
            <li>
              O que são e para que servem os cookies?
            </li>
          </Link>

          <Link href="#item7" underline="always" variant="body1">
            <li>
              Por que é necessário coletar e tratar seus dados?
            </li>
          </Link>

          <Link href="#item8" underline="always" variant="body1">
            <li>
              Como é feito o armazenamento dos seus dados?
            </li>
          </Link>

          <Link href="#item9" underline="always" variant="body1">
            <li>
              Links externos
            </li>
          </Link>

          <Link href="#item10" underline="always" variant="body1">
            <li>
              Alterações nesta Política de Privacidade
            </li>
          </Link>
        </ul>

        <Typography variant="h5" gutterBottom id="item1">
          Que tipo de dados são considerados pela LGPD?
        </Typography>
        <Typography variant="body1" paragraph>
          De acordo com a lei nº 13.709/2018 (LGPD), dados são quaisquer informações pessoais,
          analógicas ou digitais, de pessoas físicas ou jurídicas, tanto de direito privado como
          direito público, exceto nas hipóteses do artigo 4º da referida Lei. Essas informações são
          classificadas como dados identificados, dados identificáveis, dados sensíveis e dados anonimizados.
        </Typography>

        <Typography variant="h5" gutterBottom id="item2">
          Quais são os seus direitos enquanto titular dos dados?
        </Typography>
        <Typography variant="body1" paragraph>
          O artigo 18 da LGPD garante que o titular dos dados confirme a existência de tratamento
          dos seus dados pessoais, além de poder acessá-los e corrigi-los, sendo assegurado ao titular,
          a possibilidade de solicitar a anonimização, o bloqueio ou a eliminação de dados pessoais desnecessários,
          excessivos ou tratados em desconformidade com a LGPD. A lei nº 13.709 também possibilita a obtenção de
          informações sobre as entidades públicas e privadas com as quais a empresa realizou o compartilhamento
          dos seus dados pessoais e, ainda, a obtenção de informações sobre a possibilidade de não consentir
          com o tratamento de dados pessoais e sobre eventuais consequências negativas.
        </Typography>

        <Typography variant="h5" gutterBottom  id="item3">
          Quais dados dos usuários são coletados pelo Crédito Rotativo
        </Typography>
        <Typography variant="body1" paragraph>
          O Crédito Rotativo coleta de seus usuários, os dados cadastrais, como tipo da empresa,
          nome empresarial, inscrição no CNPJ e endereço da empresa tomadora do crédito, bem como dados pessoais de seu Representante Legal.
          Para as operações de crédito, são coletados ainda os dados do Responsável Solidário pelo crédito tomado,
          como nome, estado civil, número de RG e CPF, celular, e-mail e endereço, além dos dados bancários para a realização da transação.
          Dentre os dados de navegação, são coletados dos usuários, apenas cookies e histórico de navegação.
          Todos os dados coletados pelo Crédito Rotativo são armazenados em servidores específicos para este fim,
          seguindo os mais rigorosos padrões de segurança, com acesso por criptografia e sem compartilhamento de
          dados com terceiros de qualquer natureza, exceto em caso de solicitação de acesso apresentada por autoridade competente.
        </Typography>

        <Typography variant="h5" gutterBottom id="item4">
          O que significa tratamento de dados?
        </Typography>
        <Typography variant="body1" paragraph>
          Logo após a coleta de dados, iniciamos a fase de tratamento dos mesmos e,
          de acordo com as diretrizes da LGPD, você, usuário, deve estar ciente da descrição
          deste tratamento e quais as ferramentas utilizadas pelo Crédito Rotativo,
          para que ele ocorra de forma segura, respeitando o seu direito à privacidade
          e a exigência legal de proteção aos seus dados.O tratamento de dados diz respeito
          a todas as operações posteriores à coleta. É, basicamente, tudo o que é feito
          com as informações pessoais que você disponibiliza. De acordo com o inciso X
          do artigo 5 da lei nº 13.709/2018, são permitidas as seguintes operações:
          coleta, produção, recepção, classificação, utilização, acesso, reprodução,
          transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação ou controle da informação, comunicação, transferência, difusão ou extração.
          O encerramento do tratamento de seus dados pessoais se dá quando a finalidade for
          alcançada e o tratamento não for mais necessário para alcançar as finalidade a que se destina.
        </Typography>

        <Typography variant="h5" gutterBottom id="item5">
          Quais as ferramentas utilizadas pelo Crédito Rotativo para o tratamento dos seus dados?
        </Typography>
        <Typography variant="body1" paragraph>
          Dentre as operações de tratamento de dados previstas em Lei,
          utilizamos apenas armazenamento de ‘cookies’ pelo prazo de 30 dias
          e cadastro de pessoal como dados pessoais de seu Representante Legal,
          além dos dados do Responsável Solidário pelo crédito tomado, como nome,
          estado civil, número de RG e CPF, celular, e-mail e endereço e os dados bancários para a realização da transação.
        </Typography>

        <Typography variant="h5" gutterBottom id="item6">
          O que são e para que servem os cookies?
        </Typography>
        <Typography variant="body1" paragraph>
          Algumas ferramentas de tratamento de dados fazem uso de cookies e outras ferramentas de rastreamento de dados.
          Para compreender o porquê é necessário estar de acordo com o uso de cookies, além da Política de Privacidade,
          para navegar em nosso site, é preciso ter acesso às suas definições de sistema.
          Cookies são pequenos arquivos de dados que solicitamos que o seu navegador armazene,
          seja no computador ou em dispositivos móveis. Dessa forma, o principal objetivo dos cookies
          é permitir que ao acessar o nosso portal, seu navegador se lembre dos mecanismos de usabilidade
          desenvolvidos pelo Crédito Rotativo com mais facilidade, eliminando a necessidade de introduzir repetidamente as mesmas informações.
          Por fim, é importante esclarecer que os cookies coletados são apagados pelo sistema de navegação do usuário,
          caso não utilizados por prazo superior a 30 dias e preferir, você pode desabilitar o salvamento de cookies em seu navegador,
          deletá-los e gerenciar sua utilização por meio da configuração que utiliza para acessar o site do Crédito Rotativo.
        </Typography>

        <Typography variant="h5" gutterBottom id="item7">
          Por que é necessário coletar e tratar seus dados?
        </Typography>
        <Typography variant="body1" paragraph>
          As informações identificadas ou identificáveis que você compartilha com o
          Crédito Rotativo têm como finalidade a gestão, administração, prestação,
          ampliação e melhoramento de navegação e oferta de serviços. Além disso,
          a partir da coleta e do tratamento de dados, é possível oferecer a possibilidade
          de o usuário moldar a sua navegação em relação aos dados que lhe são relevantes.
        </Typography>

        <Typography variant="h5" gutterBottom id="item8">
          Como é feito o armazenamento dos seus dados?
        </Typography>

        <Typography variant="body1" paragraph>
          Ao estar ciente desta Política de Privacidade, o usuário autoriza
          o Crédito Rotativo a armazenar a totalidade dos dados coletados,
          com o objetivo de possibilitar a criação de um banco de dados para uso próprio,
          incluindo o desenvolvimento de soluções e serviços, além de análises internas.
        </Typography>

        <Typography variant="h5" gutterBottom id="item9">
          Links externos
        </Typography>
        <Typography variant="body1" paragraph>
          Este site pode conter links para outros sites.
          Aconselhamos que você leia atentamente a Política de Privacidade
          de todos os sites que visitar. Não temos controle e não assumimos nenhuma
          responsabilidade pelo conteúdo, políticas ou práticas de privacidade de sites ou serviços de terceiros.
        </Typography>

        <Typography variant="h5" gutterBottom id="item10">
          Alterações nesta Política de Privacidade
        </Typography>
        <Typography variant="body1" paragraph>
          O Crédito Rotativo pode, periodicamente, realizar alterações nesta Política de Privacidade,
          para garantir a contínua melhoria dos nossos serviços, por isso, recomendamos a leitura desta
          página de tempos em tempos para tomar conhecimento de quaisquer mudanças em nossas diretrizes.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Ficou com alguma dúvida? Fale conosco!
        </Typography>
        <Typography variant="body1" paragraph>
          Qualquer solicitação que você tenha interesse em fazer a respeito de seus dados – informações,
          solicitação de alteração; cancelamento, mais informações, entre outros – entre em contato através do nossos canais de atendimento.
        </Typography>
      </Box>
    </ContentWrap>
    <Footer />
  </>
);



export default PoliticaDePrivacidade;
