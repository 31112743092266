import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h2`
  border-radius: 20px;
  height: 0.8em;
  width: max-content;
  padding: 0 0.5em;
  background: #93d5f6;
  color: #007fc4;
  font-weight: bold;
  font-size: 3.2vw;
  line-height: 0.5em;
  text-transform: lowercase;

  @media (max-width: 599px) {
    height: 0.9em;
    font-size: 4.8vw;
    line-height: 0.7em;
  }
`;

const Title = ({ style, children }) => (
  <StyledTitle style={style}>{children}</StyledTitle>
);

export default Title;
