const validaDigitacaoCampoMoeda = (event) => {
  // [backspace, tab, end, home, left, right, delete]
  const allowedKeys = [8, 9, 35, 36, 37, 39, 46];
  if (allowedKeys.includes(event.keyCode)) {
    return;
  }

  const regex = new RegExp(/\d|,|\./);
  const isValid = regex.test(event.key);
  if (isValid) {
    return;
  }

  event.preventDefault();
}

export { validaDigitacaoCampoMoeda }
