import Api from '../../Api';

export const postInfosJuros = ({ valorSaque }) => (
  Api.post(
    'saque',
    {
      valor_saque: valorSaque,
    },
  )
);

export const postSolicitarTokenSaque = ({ valorSaque, contaBancariaId }) => (
  Api.post(
    'token/saque/efetuar',
    {
      valor: valorSaque,
      conta_bancaria_id: contaBancariaId,
    },
  )
);

export const postSaque = ({ valorSaque, contaBancariaId, token }) => (
  Api.post(
    'saque/efetuar',
    {
      valor: valorSaque,
      conta_bancaria_id: contaBancariaId,
      token,
    },
  )
);
