import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import { VERMELHO } from '../../../utils/color';

export const StyledFiltroData = styled.div`
  &.container-filtro {
    .opcoes {
      row-gap: 0.4vw;
      column-gap: 0.7vw;

      button {
        line-height: 1.5;
      }
    }
  }

  .erro {
    color: ${VERMELHO};
  }

  .opcao-periodo {
    min-width: 4.3vw;
  }

  .informa-data {
    margin-top: 0.3vw;
    margin-bottom: 2vw;
    .MuiInputBase-root {
      width: 7.6vw;
      font-size: 0.7vw;
      border-radius: 4px;
    }

    .MuiFilledInput-input {
      padding: 0.5vw 0;
      text-align: center;
    }

    .MuiTextField-root {
      margin-top: 0.5vw;
    }

    .MuiFilledInput-underline:after,
    .MuiFilledInput-underline:before {
      border-bottom: none;
    }
  }
  .botoes {
    display: flex;
    justify-content: flex-end;
    button {
      font-weight: bold;
    }
  }

  @media ${queries.desktopUp} {
    &.container-filtro {
      .opcoes {
        row-gap: 6px;
        column-gap: 10px;
      }
    }

    .opcao-periodo {
      min-width: 54px;
    }

    .informa-data {
      margin-top: 4px;
      margin-bottom: 26px;

      .MuiTextField-root {
        margin-top: 8px;
      }

      .MuiInputBase-root {
        width: 98px;
        font-size: 10px;
        border-radius: 4px;
      }

      .MuiFilledInput-input {
        padding: 8px 0;
      }
    }
  }

  @media ${queries.mobileDown} {
    &.container-filtro {
      strong {
        margin-top: 2.7vw;
      }
      .opcoes {
        row-gap: 2.6vw;
        column-gap: 3.2vw;
        margin-bottom: 8.3vw;

        button {
          line-height: 2.05;
        }
      }
    }
    .opcao-periodo {
      min-width: 23.8vw;
    }

    .informa-data {
      margin-top: 4.1vw;
      margin-bottom: 2vw;

      .MuiInputBase-root {
        width: 41vw;
        font-size: 4vw;
      }

      .MuiFilledInput-input {
        padding: 2.5vw 0;
      }

      .MuiTextField-root {
        margin: 1.6vw 0 1.6vw 4.6vw;
      }
    }

    .botoes {
      flex-direction: column-reverse;
      width: fit-content;
      margin: 12vw auto;
      gap: 4vw;

      button {
        padding: 5px 7vw;
      }
    }
  }
`;
