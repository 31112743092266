import * as types from './types';
import * as saqueTypes from '../saque/types';
import * as devolucaoTypes from '../devolucao/types';

const INITIAL_STATE = {
  etapaCadastro: 0,
  comErro: false,
  enviandoDocumentos: false,
  buscandoInfos: true,
  limiteDisponivel: 0,
  limiteTotal: 0,
  valorPagamento: 0,
  dataPagamento: '',
  emAtraso: false,
  saquePendente: false,
  statusDevolucao: false,
  razaoSocial: '',
  nomeEmpresa: '',
  cnpj: '',
  tipoEmpresa: '',
  nomeSocio: '',
  cpf: '',
  rg: '',
  diaVencimentoJuros: null,
  email: '',
  configuracoes: {
    banco: '',
    agencia: '',
    conta: '',
    titular: '',
    cnpj: '',
    chave_pix: '',
    link_qrcode: ''
  },
  avisoPendencias: false,
};

const minhaConta = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_INFO_REQUEST:
      return {
        ...state,
        buscandoInfos: true,
        enviandoDocumentos: false,
      };
    case types.FETCH_INFO_SUCCESS:
      return {
        ...state,
        buscandoInfos: false,
        enviandoDocumentos: false,
        etapaCadastro: action.etapaCadastro,
        comErro: action.comErro,
        limiteDisponivel: action.limiteDisponivel,
        limiteTotal: action.limiteTotal,
        valorPagamento: action.valorPagamento,
        dataPagamento: action.dataPagamento,
        emAtraso: action.emAtraso,
        saquePendente: action.saquePendente,
        statusDevolucao: action.statusDevolucao,
        razaoSocial: action.razaoSocial,
        nomeEmpresa: action.nomeEmpresa,
        cnpj: action.cnpj,
        tipoEmpresa: action.tipoEmpresa,
        nomeSocio: action.nomeSocio,
        cpf: action.cpf,
        rg: action.rg,
        diaVencimentoJuros: action.diaVencimentoJuros,
        email: action.email,
        configuracoes: action.configuracoes,
        avisoPendencias: action.avisoPendencias,
      };
    case types.ENVIAR_DOCUMENTOS_REQUEST:
      return {
        ...state,
        enviandoDocumentos: true,
      };
    case types.ENVIAR_DOCUMENTOS_SUCCESS:
      return {
        ...state,
        enviandoDocumentos: false,
      };
    case saqueTypes.ENVIAR_SAQUE_SUCCESS: {
      return {
        ...state,
        saquePendente: true,
      };
    }
    case devolucaoTypes.ENVIAR_DEVOLUCAO_SUCCESS: {
      return {
        ...state,
        statusDevolucao: action.status,
      };
    }
    default:
      return state;
  }
};

export default minhaConta;
