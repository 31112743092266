import { Reducer } from 'redux';
import {
  PedidoAntecipacaoState,
  PedidoAntecipacaoActionTypes as types,
  StatusPedidoAntecipacao,
} from './types';

const INITIAL_STATE: PedidoAntecipacaoState = {
  antecipacoes: [],
  status: StatusPedidoAntecipacao.naoEnviado,
  valorDevolucao: 0,
  carregando: true,
  modalFinalizacaoAberto: false,
};

const pedidoAntecipacao: Reducer<PedidoAntecipacaoState, any> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case types.FETCH_PEDIDO_SUCESS: {
      return {
        ...state,
        ...action.pedido,
      };
    }
    case types.ALTERAR_ANTECIPACOES: {
      return {
        ...state,
        antecipacoes: action.antecipacoes,
      };
    }
    case types.ALTERAR_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }
    case types.LIMPAR_PEDIDO: {
      return INITIAL_STATE;
    }
    case types.SET_CARREGANDO: {
      return {
        ...state,
        carregando: action.carregando,
      };
    }
    case types.SET_VALOR_DEVOLUCAO: {
      return {
        ...state,
        valorDevolucao: action.valorDevolucao,
      };
    }
    case types.SET_ERRO_VALOR_DEVOLUCAO: {
      return {
        ...state,
        erroValorDevolucao: action.erro,
      };
    }
    case types.SET_CONTA_ID: {
      return {
        ...state,
        contaBancariaId: action.contaBancariaId,
      };
    }
    case types.SET_MODAL_FINALIZACAO: {
      return {
        ...state,
        modalFinalizacaoAberto: action.aberto,
      };
    }
    case types.CONCLUIR_PEDIDO_FAILURE: {
      return {
        ...state,
        erroValorDevolucao: true,
        mensagemErro: action.mensagemErro,
      };
    }
    case types.FINALIZAR_PEDIDO_FAILURE: {
      return {
        ...state,
        mensagemErro: action.mensagemErro,
      };
    }
    case types.DISPERSAR_MENSAGEM_ERRO: {
      return {
        ...state,
        mensagemErro: '',
      };
    }
    default:
      return state;
  }
};

export default pedidoAntecipacao;
