import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Conteudo from './Conteudo';

const StyledBase = withStyles((theme) => ({
  tooltip: {
    maxWidth: 450,
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0,0,0,.87)',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      maxWidth: 350,
    },
    '@media (max-width: 375px)':{
      maxWidth: 300,
    },
  },
  arrow: {
    fontSize: '2rem',
    color: theme.palette.common.white,
  },
}))(Tooltip);

export const Base = ({
  etapaIntroducao,
  etapaAtual,
  placement,
  children,
  proximaEtapa,
  etapaAnterior,
  descricao,
}) => (
  <StyledBase
    open={etapaIntroducao === etapaAtual}
    title={<Conteudo proximaEtapa={proximaEtapa} etapaAnterior={etapaAnterior} descricao={descricao} />}
    arrow
    placement={placement}
  >
    {children}
  </StyledBase>
);

Base.defaultProps = {
  etapaIntroducao: null,
  etapaAtual: null,
  proximaEtapa: null,
  etapaAnterior: null,
  placement: 'bottom',
};

Base.propTypes = {
  etapaIntroducao: PropTypes.number,
  etapaAtual: PropTypes.number,
  proximaEtapa: PropTypes.number,
  etapaAnterior: PropTypes.number,
  descricao: PropTypes.node.isRequired,
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  etapaIntroducao: state.simulacao.etapaIntroducao,
});

export default connect(mapStateToProps)(Base);
