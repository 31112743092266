import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import {
  AZUL_400,
  BRANCO,
  CINZA_100,
  CINZA_200,
  CINZA_300,
  CINZA_700,
} from '../../../../utils/color';

export const BuscaSacado = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input-sacado {
    width: 32.6vw;
    background-color: ${CINZA_100};
    display: flex;
    align-items: center;
    border-radius: 8px;
    gap: 8px;
    z-index: 1;
    font-size: 1.1vw;

    svg,
    .MuiCircularProgress-root {
      font-size: 1.7vw;
    }

    .MuiInputBase-root {
      font-size: 1.1vw;
      width: 100%;
    }

    .Mui-disabled {
      font-weight: bold;
      color: ${CINZA_700};
    }
  }

  .trocar {
    padding-right: 8px;
  }

  .item-lista {
    font-size: 1.1vw;
  }

  .link {
    color: ${AZUL_400};
    font-size: 0.8rem;
    text-decoration: underline;

    &:disabled {
      color: ${CINZA_700};
    }
  }

  .bottom-container {
    width: 32vw;
    border: solid ${CINZA_200} 1px;
    padding-top: 10px;
    margin-top: -10px;
    border-top-style: none;
  }

  .sem-cadastro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: 12vw;
    padding-bottom: 2vw;
    font-size: 1.1vw;
  }

  .link {
    font-family: inherit;
    font-size: 1.1vw;
  }

  @media ${queries.mobileDown} {
    .input-sacado {
      width: 88vw;
      background-color: ${BRANCO};
      border: solid 1px ${CINZA_300};
      z-index: auto;

      .MuiInputBase-root {
        font-size: 3.3vw;
      }

      svg,
      .MuiCircularProgress-root {
        font-size: 4vw;
      }
    }

    .link {
      font-size: 4.8vw;
    }

    .trocar {
      font-size: 3vw;
    }

    .item-lista {
      font-size: 3.3vw;
    }

    .bottom-container {
      width: 87vw;
      border: none;
    }

    .sem-cadastro {
      font-size: 3.3vw;
      min-height: 32vw;
      padding-bottom: 2rem;
      justify-content: space-between;
    }
  }

  @media ${queries.desktopUp} {
    .input-sacado {
      width: 493px;

      .MuiInputBase-root {
        font-size: 1rem;
      }

      svg,
      .MuiCircularProgress-root {
        font-size: 24px;
      }
    }

    .bottom-container {
      width: 481px;
    }

    .sem-cadastro {
      font-size: 1rem;
      min-height: 150px;
      padding-bottom: 2rem;
    }

    .link {
      font-size: 1rem;
    }
  }
`;
