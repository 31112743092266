import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { fetchInfo as fetchInfoAction } from '../../store/minhaConta/actions';

const StyledAbaContainer = styled(Container)`
  padding: 0;

  @media (max-width: 600px) {
    padding-bottom: .5rem;
  }

  @media (min-width: 961px) and (max-width: 1280px) {
    max-width: 900px;
  }
`;

export const AbaContainer = ({ children, fetchInfo }) => {
  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  return (
    <StyledAbaContainer>
      {children}
    </StyledAbaContainer>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchInfo: fetchInfoAction,
}, dispatch);

export default connect(null, mapDispatchToProps)(AbaContainer);
