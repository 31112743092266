import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { excluirAntecipacao } from '../../../../store/pedidoAntecipacao/actions';

import IconeExcluirAntecipacao from '../../../IconesSvg/IconeExcluirAntecipacao';
import Modal from '../../../Modal';
import RoundButton from '../../../RoundButton';
import { StyledConteudo } from './StyledConteudo';

interface ModalExcluirAntecipacaoProps {
  modalAberto: boolean;
  toggleModal: () => void;
  index: number;
  excluirAntecipacao: (index: number) => void;
}

const ModalExcluirAntecipacao = ({
  modalAberto,
  toggleModal,
  index,
  excluirAntecipacao,
}: ModalExcluirAntecipacaoProps) => {
  const excluir = () => {
    excluirAntecipacao(index);
    toggleModal();
  };

  const ConteudoModal = () => (
    <StyledConteudo>
      <IconeExcluirAntecipacao />
      <p className='conteudo-modal'>
        Você tem certeza que deseja excluir essa antecipação?
      </p>
      <div className='botoes'>
        <RoundButton
          value='Excluir'
          color='primaryLinearGradient'
          size='small'
          onClick={excluir}
          style={{ padding: '5px 40px' }}
          data-cy='botao-ok-modal-excluir-antecipacao'
        />
        <RoundButton
          value='Cancelar'
          color='primaryLinearGradient'
          size='small'
          onClick={toggleModal}
          style={{ padding: '5px 40px' }}
        />
      </div>
    </StyledConteudo>
  );
  return (
    <>
      <Modal
        fullWidth={false}
        maxWidth='sm'
        open={modalAberto}
        conteudo={<ConteudoModal />}
        onClose={toggleModal}
        exibirBotao={false}
        icone=' '
        style={{
          padding: '0rem 2rem 1rem',
        }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      excluirAntecipacao,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ModalExcluirAntecipacao);
