import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import RoundButton, {
  RoundButtonColors,
} from '../../../components/RoundButton';
import { abrirModalAdicionar } from '../../../store/contaBancaria/actions';
import ModalAdicionarContaBancaria from './ModalAdicionarContaBancaria';

const StyledBotaoAdicionar = styled.div`
  .adicionar-conta {
    padding: 10px 40px;
    font-size: 12px;
  }

  @media (max-width: 600px) {
    text-align: center;
  }
`;

interface BotaoAdicionarProps {
  onClick: () => void;
  cor?: RoundButtonColors;
  texto?: string;
}

export const BotaoAdicionar = ({
  onClick,
  cor = 'primary',
  texto = 'Adicionar nova conta',
}: BotaoAdicionarProps) => (
  <StyledBotaoAdicionar>
    <RoundButton
      value={texto}
      color={cor}
      size='small'
      bold
      className='adicionar-conta'
      onClick={onClick}
      data-cy='botao-adicionar-conta'
    />
    <ModalAdicionarContaBancaria />
  </StyledBotaoAdicionar>
);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClick: abrirModalAdicionar,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(BotaoAdicionar);
