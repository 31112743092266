import React from 'react';
import RoundButton from '../../RoundButton';
import styled from 'styled-components';
import { RootState } from '../../../store';
import { selectHaPedidoEmAndamento } from '../../../store/recebaAntes/selectors';
import { connect } from 'react-redux';
import Texto from '../../Texto';
import PedidoEmAndamento from './PedidoEmAndamento';
import { queries } from '../../../utils/breakpoints';

const StyledRoundButton = styled(RoundButton)`
  padding: 1vw 0;
  width: 90%;
  font-size: 0.9vw;
  text-transform: uppercase;
  margin-top: 1.5rem;
  align-self: center;

  @media ${queries.desktopUp} {
    font-size: 14px;
    padding: 15px 0;
  }

  @media ${queries.mobileDown} {
    font-size: 4.2vw;
    width: 69%;
    margin-top: 1.5rem;
    padding: 3vw 0;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .texto-aguarde {
    padding: 0.5rem 10%;
  }

  @media (max-width: 599px) {
    .texto-aguarde {
      font-size: 3.5vw;
    }
  }
`;

interface StatusPedidoProps {
  pedidoEmAndamento?: boolean;
}

const StatusPedido = ({ pedidoEmAndamento = false }: StatusPedidoProps) => {
  return (
    <StyledContainer>
      <PedidoEmAndamento />
      <StyledRoundButton
        value='+ Novo Pedido'
        color='primaryLinearGradient'
        bold
        data-cy='botao-novo-pedido'
        disabled={pedidoEmAndamento}
        style={{
          marginTop: '2.5rem',
        }}
        href='recebaantes/pedidoantecipacao'
      />
      {pedidoEmAndamento && (
        <Texto className='texto-aguarde'>
          Aguarde a aprovação para realizar um novo pedido
        </Texto>
      )}
    </StyledContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  pedidoEmAndamento: selectHaPedidoEmAndamento(state),
});

export default connect(mapStateToProps)(StatusPedido);
