import { Hidden, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import Progresso from '../../components/Progresso';
import TabelaBoletos from '../../components/RecebaAntes/TabelaBoletos';
import FiltroBoletos from '../../components/RecebaAntes/TabelaBoletos/FiltroBoletos';
import ListaMobile from '../../components/RecebaAntes/TabelaBoletos/ListaMobile';
import { RootState } from '../../store';
import { queries } from '../../utils/breakpoints';
import { CINZA_200_20, CINZA_700 } from '../../utils/color';

const StyledHeader = styled.div`
  color: ${CINZA_700};
  h2 {
    font-size: 1.15vw;
    margin-bottom: 0;
  }

  @media ${queries.desktopUp} {
    h2 {
      font-size: 1rem;
    }
  }
  @media ${queries.mobileDown} {
    margin: 1.8vw 3vw 3vw 3vw;
    h2 {
      font-size: 4.2vw;
      margin-top: 0;
    }
  }
`;

const StyledContainer = styled.div`
  @media ${queries.mobileDown} {
    width: 100vw;
    background-color: ${CINZA_200_20};
    padding: 1vw 3.5vw 1vw 5vw;
    margin-top: 4vw;
  }
`;
interface Props extends ConnectedProps<typeof connector> {}

const AcompanhamentoBoletos = ({ carregando, usaRecebaAntes }: Props) => {
  const isMobile = useMediaQuery(queries.mobileDown);

  if (!usaRecebaAntes && isMobile) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledHeader>
        <h2>Acompanhe seus boletos</h2>
        <FiltroBoletos />
      </StyledHeader>
      {carregando ? (
        <Progresso />
      ) : (
        <>
          <Hidden xsDown>
            <TabelaBoletos />
          </Hidden>
          <Hidden smUp>
            <ListaMobile />
          </Hidden>
        </>
      )}
    </StyledContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  carregando: state.recebaAntes.carregandoFiltro,
  usaRecebaAntes: state.recebaAntes.usaRecebaAntes,
});

const connector = connect(mapStateToProps);

export default connector(AcompanhamentoBoletos);
