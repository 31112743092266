import * as types from './types';
import * as contaBancariaTypes from '../contaBancaria/types';
import { toNumber } from '../../utils/number';

const INITIAL_STATE = {
  valorSaque: '',
  valorSaqueRaw: '',
  valorJuros: 0,
  valorIof: 0,
  valorFinal: 0,
  contaBancariaId: '',
  enviandoSaque: false,
  error: '',
};

const saque = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_VALOR_SAQUE:
      return {
        ...state,
        valorSaque: action.valorSaque,
        valorSaqueRaw: toNumber(action.valorSaque),
      };
    case types.FETCH_DADOS_SAQUE_REQUEST:
      return {
        ...state,
        error: '',
      };
    case types.FETCH_DADOS_SAQUE_SUCCESS:
      return {
        ...state,
        valorJuros: action.valorJuros,
        valorIof: action.valorIof,
        valorFinal: action.valorFinal,
        error: '',
      };
    case types.FETCH_DADOS_SAQUE_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        valorJuros: 0,
        valorIof: 0,
        valorFinal: 0,
      };
    }
    case types.SET_CONTABANCARIA_ID:
      return {
        ...state,
        contaBancariaId: action.contaBancariaId,
      };
    case types.SOLICITAR_TOKEN_SAQUE_REQUEST:
      return {
        ...state,
        enviandoSaque: true,
        error: '',
      };
    case types.SOLICITAR_TOKEN_SAQUE_SUCCESS:
      return {
        ...state,
        enviandoSaque: false,
        error: '',
      };
    case types.SOLICITAR_TOKEN_SAQUE_ERROR: {
      const { error } = action;
      return {
        ...state,
        enviandoSaque: false,
        error,
      };
    }
    case types.ENVIAR_SAQUE_REQUEST:
      return {
        ...state,
        enviandoSaque: true,
        error: '',
      };
    case types.ENVIAR_SAQUE_SUCCESS:
      return {
        ...state,
        valorSaque: '',
        valorJuros: 0,
        valorIof: 0,
        valorFinal: 0,
        enviandoSaque: false,
        error: '',
      };
    case types.ENVIAR_SAQUE_ERROR: {
      const { error } = action;
      return {
        ...state,
        enviandoSaque: false,
        error,
      };
    }
    case contaBancariaTypes.FETCH_CONTAS_SUCCESS: {
      return {
        ...state,
        contaBancariaId: action.contasBancarias?.length > 0 ? action.contasBancarias[0].id : null,
      };
    }
    case contaBancariaTypes.ENVIAR_CONTA_BANCARIA_SUCCESS: {
      return {
        ...state,
        contaBancariaId: state.contaBancariaId || action.contaBancaria.id,
      };
    }
    default:
      return state;
  }
};

export default saque;
