import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Link } from '@material-ui/core';
import { AZUL_300, CINZA_600 } from '../../../utils/color';
import Modal from '../../Modal';
import RoundButton from '../../RoundButton';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import { bindActionCreators } from 'redux';
import { solicitaAumentoLimite } from '../../../store/recebaAntes/actions';
import { queries } from '../../../utils/breakpoints';

const StyledLink = styled(Link)`
  color: ${AZUL_300};
  font-size: 1vw;
  font-family: Montserrat;
  margin-top: 14%;

  ${(props) =>
    props.disabled &&
    `
     color: ${CINZA_600};
    `}

  @media ${queries.desktopUp} {
    font-size: 14px;
  }

  @media ${queries.mobileDown} {
    font-size: 3.5vw;
    margin-top: 4vw;
    margin-bottom: 2vw;
  }
`;

const StyledSpan = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: ${CINZA_600};
  margin-bottom: 2rem;
`;

interface LinkAumentoLimiteProps {
  aumentoLimitePendente?: boolean;
  onSolicitaAumento: () => void;
}

const LinkAumentoLimite = ({
  aumentoLimitePendente,
  onSolicitaAumento,
}: LinkAumentoLimiteProps) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    onSolicitaAumento();
    setOpenModal(true);
  };

  const handleClickCloseModal = () => {
    setOpenModal(false);
  };

  const ConteudoModal = () => {
    return (
      <Box>
        <StyledSpan className='conteudo-modal'>
          Sua solicitação de aumento de limite foi enviada. Aguarde a aprovação.
        </StyledSpan>
        <RoundButton
          value='OK'
          color='primaryLinearGradient'
          size='small'
          bold='bold'
          onClick={handleClickCloseModal}
          style={{ padding: '5px 60px' }}
        />
      </Box>
    );
  };

  return (
    <>
      <StyledLink
        disabled={aumentoLimitePendente}
        component='button'
        onClick={handleClickOpenModal}
      >
        Solicitar aumento de limite
      </StyledLink>
      <Modal
        fullWidth
        maxWidth='sm'
        open={openModal}
        conteudo={<ConteudoModal />}
        onClose={handleClickCloseModal}
        exibirBotao={false}
        icone=' '
        style={{
          padding: '1rem 2rem 3rem',
        }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  aumentoLimitePendente: state.recebaAntes.aumentoLimitePendente,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSolicitaAumento: solicitaAumentoLimite,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LinkAumentoLimite);
