import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Container } from '@material-ui/core';
import styled from 'styled-components';
import EmailIcon from '@material-ui/icons/Email';
import Logo from './Logo';
import SocialMediaIcons from './SocialMediaIcons';

const StyledFooter = styled.footer`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
  padding-top: 1rem;
  background: #DAE1E5;
  overflow-x: hidden;
  overflow-y: hidden;

  .contact-container h3 {
    margin: 0;
    font-size: .7rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .contact-container .email {
    font-size: .8rem;
  }

  .contact-container .email-icon {
    vertical-align: middle;
    font-size: 1rem;
  }

  .social-media-icon {
    vertical-align: bottom;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .about {
    font-size: .6rem;
    text-align: center;
  }

  @media (max-width: 576px) {
    .contact-container {
      line-height: .7rem;
    }

    .contact-container .email {
      font-size: .6rem;
    }

    .contact-container .email-icon {
      font-size: .7rem;
    }

    .social-media-icon {
      font-size: .8rem;
    }

    .about {
      font-size: .5rem;
      padding: 0 .5rem;
    }
  }

  @media (max-width: 600px) {
    height: 105px;
  }
`;

const Footer = () => {
  const contact = 'Fale conosco';
  const email = 'atendimento@creditorotativo.com.br';
  const about = `
    O Crédito Rotativo é um produto da Rinoceronte Branco Administração de Recebíveis
    | CNPJ 31.777.921/0001-99 | Rua Emygdia Campolim, 125 - Sorocaba - SP  | CEP 18.047-626
`;

  return (
    <StyledFooter>
      <Container maxWidth='md'>
        <Grid container justify='center' alignItems='center'>
          <Grid item xs={5} sm={3} md={3}>
            <Link to='/'>
              <Logo width={110} height={35} />
            </Link>
          </Grid>

          <Grid item xs={7} sm={6} md={6}>
            <Grid container justify='center' alignItems='center'>
              <Grid className='contact-container' item xs={12} sm={9} md={9}>
                <h3>{contact}</h3>
                <a href={`mailto:${email}`} target='_blank' rel='noopener noreferrer' className='email'>
                  <EmailIcon className='email-icon' />
                  {` ${email}`}
                </a>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <SocialMediaIcons className='social-media-icon' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <p className='about'>{about}</p>
    </StyledFooter>
  );
};

export default Footer;
