import axios from 'axios';
import Api from '../../Api';

const getEstados = () => Api.get('estados');

const getCidadesPorEstado = (estadoId) =>
  Api.get(`cidades?estado_id=${estadoId}`);

const postCadastroSacado = (dadosSacado) => Api.post('sacados', dadosSacado);

const getEnderecoPorCep = (cep) =>
  axios.get(`https://viacep.com.br/ws/${cep}/json/`);

export {
  getEstados,
  getCidadesPorEstado,
  postCadastroSacado,
  getEnderecoPorCep,
};
