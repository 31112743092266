import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import './index.css';

const root = document.getElementById('root');

const render = (Component) => {
  return ReactDOM.render(
    <Router>
      <Component />
    </Router>,
    root
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
