import React from 'react';
import { connect } from 'react-redux';
import { Stepper, Step, StepLabel, StepContent, StepConnector } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { CINZA_700, AZUL_300, AZUL_200, ROXO, BRANCO } from '../../utils/color';
import ConteudoEtapaDois from './ConteudoEtapaDois';

const StyledStepper = makeStyles({
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: `${AZUL_300}`,
    margin: 1,
  },
  completed: {
    color: `${BRANCO}`,
    backgroundColor: `${AZUL_300}`,
    width: 26,
    height: 26,
    borderRadius: '50%',
    marginLeft: -5,
  },
  error: {
    color: `${BRANCO}`,
    backgroundColor: `${ROXO}`,
    width: 28,
    height: 28,
    borderRadius: '50%',
    marginLeft: -7,
  },
});

const StyledStepLabel = withStyles({
  'root': {
    height: 0,
    paddingLeft: 6.5,
  },
  'label': {
    paddingLeft: 24,
    fontSize: 26,
    lineHeight: 1,
  },
  '@media (max-width: 600px)': {
    label: {
      'fontSize': 18,
    },
  },
})(StepLabel);

const StyledStepContent = withStyles({
  'root': {
    'color': `${CINZA_700}`,
    'borderLeft': `3px solid ${AZUL_200}`,
    'marginTop': 4,
    'paddingLeft': 45,
    'fontSize': 18,
  },
  '@media (max-width: 600px)': {
    root: {
      'fontSize': 14,
    },
  },
})(StepContent);

const StyledConnector = withStyles({
  root: {
    padding: 0,
  },
  lineVertical: {
    borderColor: `${AZUL_200}`,
    borderLeftWidth: 3,
  },
})(StepConnector);

const styledStepIcon = ({ completed, error }) => {
  const classes = StyledStepper();

  if (error) {
    return <CloseIcon className={classes.error} />;
  }

  if (completed) {
    return <Check className={classes.completed} />;
  }

  return <div className={classes.circle} />;
};

const getSteps = (comErro) => ([
  'Cadastro enviado para análise',
  comErro ? 'Cadastro com pendência' : 'Cadastro aprovado.',
  'Aguardando documentos',
  'Documentos em análise.',
  'Limite liberado.',
]);

const getStepContent = (step, comErro) => {
  switch (step) {
    case 1:
      return 'Verifique seu email para mais informações.';
    case 2:
      return <ConteudoEtapaDois />;
    case 3:
      return comErro ? 'Documentação divergente. Por favor verifique seu e-mail.' : <br />;
    case 4:
      return 'Você já pode realizar seus saques sempre que precisar.';
    default:
      return <br />;
  }
};

export const Etapas = ({
  etapaCadastro,
  comErro,
}) => {
  const steps = getSteps(comErro);
  const classes = StyledStepper();

  return (
    <Stepper orientation='vertical' connector={<StyledConnector />} className={classes.root}>
      {steps.map((label, index) => (
          <Step key={label} active>
            <StyledStepLabel
              StepIconComponent={styledStepIcon}
              completed={index <= etapaCadastro}
              error={index - 1 === etapaCadastro && comErro}
            >
              {label}
            </StyledStepLabel>

            <StyledStepContent>
              <div style={{ padding: '16px 0'}}>{getStepContent(index, comErro)}</div>
            </StyledStepContent>
          </Step>
      ))}
    </Stepper>
  );
};

const mapStateToProps = (state) => ({
  etapaCadastro: state.minhaConta.etapaCadastro,
  comErro: state.minhaConta.comErro,
});

export default connect(mapStateToProps)(Etapas);
