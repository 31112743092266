import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from '../../components/Footer';
import MenuAutenticado from '../../components/MenuAutenticado';
import NavbarInterna from '../../components/NavbarInterna';
import AbaConta from './AbaConta';
import AcompanhamentoCadastro from '../AcompanhamentoCadastro';
import { fetchInfo as fetchInfoAction } from '../../store/minhaConta/actions';
import {
  fetchContasBancarias as fetchContasBancariasAction,
  fetchBancos as fetchBancosAction,
} from '../../store/contaBancaria/actions';
import { fetchMovimentacoes as fetchMovimentacoesAction } from '../../store/conta/actions';
import { fetchListagem as fetchRecebaAntesAction } from '../../store/recebaAntes/actions';
import AbaSaque from './AbaSaque';
import ContentWrap from '../../components/ContentWrap';
import AbaCadastro from './AbaCadastro';
import AbaDevolucao from './AbaDevolucao';
import AbaRecebaAntes from './AbaRecebaAntes';
import PedidoAntecipacao from '../RecebaAntes/PedidoAntecipacao';

const Rotas = ({ comErro, aprovado, mostrarRecebaAntes }) => {
  const { path } = useRouteMatch();

  if (!aprovado || comErro) {
    return (
      <Route path={path}>
        <AcompanhamentoCadastro />
      </Route>
    );
  }

  return (
    <>
      <Route exact path={path}>
        <AbaConta />
      </Route>
      <Route path={`${path}/saque`}>
        <AbaSaque />
      </Route>
      <Route path={`${path}/devolucao`}>
        <AbaDevolucao />
      </Route>
      {mostrarRecebaAntes && (
        <>
          <Route exact path={`${path}/recebaantes`}>
            <AbaRecebaAntes />
          </Route>
          <Route path={`${path}/recebaantes/pedidoantecipacao`}>
            <PedidoAntecipacao />
          </Route>
        </>
      )}
      <Route path={`${path}/cadastro`}>
        <AbaCadastro />
      </Route>
    </>
  );
};

export const MinhaContaContainer = ({
  fetchBancos,
  fetchInfo,
  fetchContasBancarias,
  fetchMovimentacoes,
  fetchRecebaAntes,
  etapaCadastro,
  comErro,
}) => {
  const mostrarRecebaAntes =
    process.env.REACT_APP_RECEBAANTES_ENABLED === 'true';
  const aprovado = !(etapaCadastro < 4);

  useEffect(() => {
    fetchInfo();

    if (aprovado) {
      fetchBancos();
      fetchContasBancarias();
      fetchMovimentacoes();
      fetchRecebaAntes();
    }
  }, [
    aprovado,
    fetchBancos,
    fetchInfo,
    fetchContasBancarias,
    fetchMovimentacoes,
    fetchRecebaAntes,
  ]);

  return (
    <>
      <ContentWrap>
        <MenuAutenticado />
        <NavbarInterna
          aprovado={aprovado}
          mostrarRecebaAntes={mostrarRecebaAntes}
        />
        <Switch>
          <Rotas
            comErro={comErro}
            aprovado={aprovado}
            mostrarRecebaAntes={mostrarRecebaAntes}
          />
        </Switch>
      </ContentWrap>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  buscandoInfos: state.minhaConta.buscandoInfos,
  etapaCadastro: state.minhaConta.etapaCadastro,
  comErro: state.minhaConta.comErro,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchBancos: fetchBancosAction,
      fetchInfo: fetchInfoAction,
      fetchContasBancarias: fetchContasBancariasAction,
      fetchMovimentacoes: fetchMovimentacoesAction,
      fetchRecebaAntes: fetchRecebaAntesAction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MinhaContaContainer);
