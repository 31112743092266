import Api from '../../Api';

const postArquivo = (arquivo) => {
  const formData = new FormData();
  formData.append('arquivo', arquivo);

  return Api.post('arquivos', formData);
};

export { postArquivo };
