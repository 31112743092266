import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CardAzul from './CardAzul';
import ModalSaque from '../ModalSaque';
import ModalDevolucao from '../ModalDevolucao';
import EtapaDois from '../Introducao/EtapaDois';
import EtapaCinco from '../Introducao/EtapaCinco';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setModalSaqueAberta,
  setModalDevolucaoAberta
} from '../../../../store/simulacao/actions';

const AdicionaMovimentacoes = styled.div`
  margin-top: 20px;
  padding: 10px;
    h4.titulo {
    margin-top: 0;
    margin-bottom: .3rem;
    padding-left: 1.3rem;
    text-align: center;
  }
  .cards {
    display: flex;
    flex-direction: row;
  }

  @media (min-width: 960px) {
    padding-right: 4rem;
    .cards {
      flex-direction: column;
      max-width: 500px;
    }
    h4.titulo {
      text-align: left;
      margin-left: 60px;
    }
  }
`;
const AddMovimentacao = ({
  modalSaqueAberta,
  abrirModalSaque,
  fecharModalSaque,
  modalDevolucaoAberta,
  abrirModalDevolucao,
  fecharModalDevolucao,
}) => (
  <AdicionaMovimentacoes>
    <h4 className='titulo'>Adicione movimentações em sua conta:</h4>

    <div className='cards'>
      <EtapaCinco>
        <CardAzul
          titulo='Você pode realizar saques sempre que precisar'
          descricao='Dentro do seu limite, você pode fazer novos saques, independente de quantos tenha feito e também de quanto possa ter devolvido.'
          textoBotao='Adicionar saque'
          icone='simulacao'
          onClick={abrirModalSaque}
        />
      </EtapaCinco>
        <ModalSaque
          open={modalSaqueAberta}
          onClose={fecharModalSaque}
        />

      <EtapaDois>
        <CardAzul
          titulo='Você devolve o dinheiro do jeito que achar melhor'
          descricao='Devolva sempre que puder, mesmo que seja pouco e tenha que sacar novamente. Fazendo assim, você contribui para o aumento do seu limite.'
          textoBotao='Devolver valor'
          icone='barcode'
          onClick={abrirModalDevolucao}
        />
      </EtapaDois>
      <ModalDevolucao
        open={modalDevolucaoAberta}
        onClose={fecharModalDevolucao}
      />
    </div>
  </AdicionaMovimentacoes>
);

AddMovimentacao.propTypes = {
  modalSaqueAberta: PropTypes.bool.isRequired,
  modalDevolucaoAberta: PropTypes.bool.isRequired,
  abrirModalSaque: PropTypes.func.isRequired,
  fecharModalSaque: PropTypes.func.isRequired,
  abrirModalDevolucao: PropTypes.func.isRequired,
  fecharModalDevolucao: PropTypes.func.isRequired,
 };

const mapStateToProps = (state) => ({
  modalSaqueAberta: state.simulacao.modalSaqueAberta,
  modalDevolucaoAberta: state.simulacao.modalDevolucaoAberta,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  abrirModalSaque: () => setModalSaqueAberta(true),
  fecharModalSaque: () => setModalSaqueAberta(false),
  abrirModalDevolucao: () => setModalDevolucaoAberta(true),
  fecharModalDevolucao: () => setModalDevolucaoAberta(false),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddMovimentacao);
