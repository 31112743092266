import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Fade } from '@material-ui/core';
import CampoDeMoeda from '../../../components/CampoDeMoeda';
import { CINZA_600, AZUL_300, PRETO } from '../../../utils/color';
import {
  setValorDevolucao,
  setTipoDevolucao as setTipoDevolucaoAction
} from '../../../store/devolucao/actions';
import TransferenciaBancaria from './TransferenciaBancaria';
import BoletoBancario from './BoletoBancario';
import Detalhes from './Detalhes';
import FormatadorDeMoeda from '../../../components/FormatadorDeMoeda';
import Progresso from '../../../components/Progresso';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { toNumber } from '../../../utils/number';
import Modal from '../../../components/Modal';
import RoundButton from '../../../components/RoundButton';

const StyledDevolucao = styled(Container)`
  padding: 4rem 0 2rem 0;

  .devolucao-input-container {
    padding-left: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .devolucao-input-container h1 {
    margin-bottom: 0;
    color: ${AZUL_300};
    font-size: 18px;
    text-align: center;
  }

  h2 {
    margin-bottom: .6rem;
    padding-bottom: .2rem;
    color: ${AZUL_300};
    border-bottom: .5px solid ${CINZA_600};
    font-size: 18px;
  }

  .devolucao-conta-container {
    padding-left: 2rem;
    padding-bottom: 1rem;
    border-bottom: .5px solid ${CINZA_600};
  }

  .devolucao-rodape {
    margin-top: 1rem;
    color: ${CINZA_600};
  }

  .titulo-pix {
    margin-bottom:0;
    font-weight: bold;
    color: ${PRETO}
  }

  @media (max-width: 960px) {
    padding: 0 1rem;

    .devolucao-input-container {
      text-align: center;
      padding-bottom: 1rem;
    }

    .devolucao-input-container h1 {
      width: 100%;
    }

    .devolucao-conta-container {
      padding-bottom: 0;
    }
  }

  @media (max-width: 375px) {
    .devolucao-rodape {
      font-size: 12px;
    }
  }
`;

const AvisoDevolucao = () => (
  <p style={{ margin: '14px 16px 0 16px' }}>
    Devoluções e cobrança de juros são contabilizados separadamente.
    Uma devolução não substitui uma cobrança de juros.
  </p>
);

export const Devolucao = ({
  onValorDevolucaoChange,
  error,
  valorDevolucao,
  enviandoDevolucao,
  banco,
  agencia,
  conta,
  titular,
  cnpj,
  limiteTotal,
  limiteDisponivel,
  setTipoDevolucao,
  chavePix,
  linkQrCode
}) => {
  const [valorPreenchido, setValorPreenchido] = useState(false);
  const [pixCopiado, setPixCopiado] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [avisoExibido, setAvisoExibido] = useState(false);

  if (enviandoDevolucao) {
    return (
      <Progresso />
    );
  }

  const saldoDosSaques = limiteTotal - limiteDisponivel;

  const devolucaoHelperText = (
    <>
      {'Saldo dos saque efetuados: '}
      <span><FormatadorDeMoeda valor={saldoDosSaques} comSimbolo /></span>
    </>
  );

  const handleOnChangeDevolucao = (value) => {
    onValorDevolucaoChange(value);

    if (value && toNumber(value) > 0 && avisoExibido) {
      setValorPreenchido(true);
      return;
    }

    setValorPreenchido(false);
  };

  const handleCopyToClipBoard = () => {
    setTipoDevolucao('transferencia');
    setPixCopiado(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setValorPreenchido(true);
    setAvisoExibido(true);
  };

  const handleAvancar = () => {
    setOpenModal(true)
  };

  return (
    <StyledDevolucao>
      <div className='devolucao-input-container'>
        <h1>Quanto deseja devolver?</h1>
        <CampoDeMoeda
          value={valorDevolucao}
          placeholder='R$ 100,00'
          error={!!error}
          helperText={error || devolucaoHelperText}
          onChange={handleOnChangeDevolucao}
        />

        {valorDevolucao && toNumber(valorDevolucao) > 0 && !avisoExibido && (
          <RoundButton
            value='Avançar'
            color='primary'
            size='small'
            bold
            style={{ margin: '1rem', textTransform: 'uppercase' }}
            onClick={handleAvancar}
          />
        )}
      </div>

      <Modal
        open={openModal}
        titulo='Importante'
        conteudo={<AvisoDevolucao />}
        botaoLabel='Confirmar'
        onConfirm={handleCloseModal}
        onClose={handleCloseModal}
      />

      {valorPreenchido
        && (
          <Fade in={valorPreenchido} timeout={500}>
            <div>
              <h2>Como deseja devolver?</h2>
              <BoletoBancario />
              <TransferenciaBancaria
                shouldOpenModal={pixCopiado}
                onClose={() => setPixCopiado(false)}
              />

              <div className='devolucao-conta-container'>
                <Detalhes
                  titulo='Conta para transferência'
                  conteudo={(
                    <>
                      Banco {banco}
                      <br />
                      Agência: {agencia}
                      <br />
                      Conta Corrente: {conta}
                      <br />
                      {titular}
                      <br />
                      {cnpj}

                      <p className='titulo-pix'>Chave Pix</p>
                      <CopyToClipboard
                        value={chavePix}
                        onClick={handleCopyToClipBoard}
                      />

                      <p className='titulo-pix'>QR Code Pix </p>
                      <img
                        src={linkQrCode}
                        alt='QR code Pix'
                        width={150}
                        style={{ marginLeft: -8 }}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </Fade>
        )}

      <p className='devolucao-rodape'>
        O valor pago será usado para amortizar seu saldo
        devedor. Os juros dos saques efetuados serão cobrados
        no vencimento estabelecido no contrato.
      </p>
    </StyledDevolucao>
  );
};

Devolucao.defaultProps = {
  error: '',
  valorDevolucao: '',
  enviandoDevolucao: false,
  banco: '',
  agencia: '',
  conta: '',
  titular: '',
  cnpj: '',
  chavePix: '',
  linkQrCode: '',
};

Devolucao.propTypes = {
  valorDevolucao: PropTypes.string,
  error: PropTypes.string,
  onValorDevolucaoChange: PropTypes.func.isRequired,
  enviandoDevolucao: PropTypes.bool,
  banco: PropTypes.string,
  agencia: PropTypes.string,
  conta: PropTypes.string,
  titular: PropTypes.string,
  cnpj: PropTypes.string,
  chavePix: PropTypes.string,
  linkQrCode: PropTypes.string,
};

const mapStateToProps = (state) => ({
  valorDevolucao: state.devolucao.valorDevolucao,
  error: state.devolucao.error,
  enviandoDevolucao: state.devolucao.enviandoDevolucao,
  tipoDevolucao: state.devolucao.tipoDevolucao,
  banco: state.minhaConta.configuracoes.banco,
  agencia: state.minhaConta.configuracoes.agencia,
  conta: state.minhaConta.configuracoes.conta,
  titular: state.minhaConta.configuracoes.titular,
  cnpj: state.minhaConta.configuracoes.cnpj,
  chavePix: state.minhaConta.configuracoes.chave_pix,
  linkQrCode: state.minhaConta.configuracoes.link_qrcode,
  limiteTotal: state.minhaConta.limiteTotal,
  limiteDisponivel: state.minhaConta.limiteDisponivel,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onValorDevolucaoChange: setValorDevolucao,
  setTipoDevolucao: setTipoDevolucaoAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Devolucao);
