import Api from "../../Api";

export const signInUser = ({ email, password }) =>
  Api.post('login', {
    email,
    password,
  });

export const signInUserAs = ({ userId, token }) => {
  Api.setToken(token)
  return Api.post('simulacao/login', {
    userId: userId,
  });
}

export const esqueciSenha = ({ email }) =>
  Api.post('esqueci-senha', {
    email,
  });
