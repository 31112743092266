import React from 'react';
import IconeCalendario from '@material-ui/icons/CalendarToday';
import FiltroStatus from './FiltroStatus';
import FiltroData from './FiltroData';
import { StyledConteudoFiltro } from './ConteudoFiltro.style';

const ConteudoFiltro = ({ tipo = 'status' }) => {
  const isData = tipo === 'data';
  return (
    <StyledConteudoFiltro>
      <span>
        <IconeCalendario color='primary' /> Filtre por
      </span>
      {isData ? <FiltroData /> : <FiltroStatus />}
    </StyledConteudoFiltro>
  );
};

export default ConteudoFiltro;
