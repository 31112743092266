import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const SocialMediaIcons = ({ className }) => (
  <>
    <a
      href='https://www.instagram.com/credito.rotativo'
      target='_blank'
      rel='noopener noreferrer'
    >
      <InstagramIcon className={className} />
    </a>
    <a
      href='https://www.facebook.com/creditorotativo'
      target='_blank'
      rel='noopener noreferrer'
    >
      <FacebookIcon className={className} />
    </a>
  </>
);


export default SocialMediaIcons;
