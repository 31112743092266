import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CINZA_200, CINZA_700, AZUL_300 } from '../../utils/color';

const StyledExpansionPanel = withStyles({
  root: {
    'position': 'inherit',
    'border': `2px solid ${CINZA_200}`,
    'boxShadow': 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:first-child': {
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    '&:last-child': {
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    '&$expanded': {
      margin: 'auto',
      background: 'rgba(147,213,246,.25)',
    },
  },
  expanded: {},
})(Accordion);

const StyledExpansionPanelSummary = withStyles((theme) => ({
  root: {
    'color': `${CINZA_700}`,
    'fontSize': 14,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    '&:hover': {
      background: 'rgba(147,213,246,.25)',
    },
  },
  content: {
    'margin': 0,
    '&$expanded': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  expanded: {
    '&.MuiExpansionPanelSummary-root': {
      'minHeight': 'initial',
    },
    '&:hover': {
      'background': 'none',
    },
  },
}))(AccordionSummary);

const StyledExpansionPanelDetails = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: '0 24px 24px',
    fontWeight: 400,
    lineHeight: 1.5,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('sm')]: {
      padding: '0 18px 24px',
      fontSize: 12,
      whiteSpace: 'normal',
    },
  },
}))(AccordionDetails);

const Item = ({ title, description }) => (
  <StyledExpansionPanel>
    <StyledExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ fill: `${AZUL_300}` }} />}>
      <h3>{title}</h3>
    </StyledExpansionPanelSummary>

    <StyledExpansionPanelDetails>
      {description}
    </StyledExpansionPanelDetails>
  </StyledExpansionPanel>
);

Item.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Item;
