import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store';
import { queries } from '../../../utils/breakpoints';

import {
  BRANCO,
  CINZA_200,
  CINZA_300,
  PRIMARY_LINEAR_GRADIENT,
} from '../../../utils/color';

interface IndicadorEtapaProps {
  numero: number;
  texto: string;
  etapaAtiva?: number;
}

const CirculoFundo = styled.div`
  background-image: ${PRIMARY_LINEAR_GRADIENT};
  width: 8vw;
  aspect-ratio: 1;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .circulo-interno {
    width: calc(8vw - 2px);
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-weight: bold;
      color: ${CINZA_200};
      opacity: 0.4;
    }

    .numero {
      font-size: 3.5vw;
      line-height: 1;
    }

    .texto {
      font-size: 0.9vw;
    }
  }

  &.inativo {
    background-image: none;

    .circulo-interno {
      border: solid 1px ${CINZA_300};
      span {
        color: ${CINZA_300};
        opacity: 1;
      }
    }
  }

  &.atual {
    justify-content: flex-end;
    width: 8.3vw;

    .circulo-interno {
      width: 8vw;
      border: solid 0.35vw ${BRANCO};

      span {
        color: ${BRANCO};
        opacity: 1;
      }
    }
  }

  @media ${queries.desktopUp} {
    width: 120px;

    .circulo-interno {
      width: 117px;

      .numero {
        font-size: 45px;
      }

      .texto {
        font-size: 12px;
      }
    }

    &.atual {
      width: 125px;

      .circulo-interno {
        width: 122px;
        border: solid 4px ${BRANCO};
      }
    }
  }

  @media ${queries.mobileDown} {
    width: 16vw;

    .circulo-interno {
      width: 15.5vw;

      .numero {
        font-size: 6vw;
        line-height: 0.9;
      }

      .texto {
        font-size: 2.5vw;
      }
    }

    &.atual {
      justify-content: flex-end;
      width: 16.8vw;

      .circulo-interno {
        width: 16.3vw;
        border: solid 0.6vw ${BRANCO};
      }
    }
  }
`;

const IndicadorEtapa = ({ numero, texto, etapaAtiva }: IndicadorEtapaProps) => {
  let classe = '';
  if (etapaAtiva < numero) classe = 'inativo';
  else if (etapaAtiva === numero) classe = 'atual';

  return (
    <CirculoFundo className={classe}>
      <div className='circulo-interno'>
        <span className='numero'>{numero}</span>
        <span className='texto'>{texto}</span>
      </div>
    </CirculoFundo>
  );
};

const mapStateToProps = (state: RootState) => ({
  etapaAtiva: state.recebaAntes.etapaAtiva,
});

export default connect(mapStateToProps)(IndicadorEtapa);
