import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import { AZUL_300, CINZA_600 } from '../../../utils/color';

export const StyledModalPedidoEnvidado = styled(Box)`
  padding: 1.2vw 5vw 4.1vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    font-size: 7vw;
    margin-bottom: 2.1vw;
  }
  p {
    font-size: 1.3vw;
    font-weight: bold;
  }

  .titulo-modal {
    color: ${CINZA_600};
    margin: 0vw;
  }

  .obrigado {
    color: ${AZUL_300};
    font-style: italic;
    margin: 1.2vw 0;
  }

  span {
    color: ${CINZA_600};
    font-size: 0.95vw;
    text-align: center;
    line-height: 1.7;
  }

  .email {
    font-weight: 500;
  }

  @media ${queries.desktopUp} {
    padding: 22px 60px 55px;
    svg {
      font-size: 80px;
      margin-bottom: 28px;
    }
    p {
      font-size: 18px;
    }

    .obrigado {
      margin: 14px 0;
    }

    span {
      font-size: 12px;
    }
  }

  @media ${queries.mobileDown} {
    padding: 1vw 10vw 2vw;
    svg {
      font-size: 13vw;
      margin-bottom: 4vw;
    }
    p {
      font-size: 3vw;
    }
    .obrigado {
      font-size: 2.8vw;
      margin: 4.5vw 0 1.5vw 0;
    }
    span {
      font-size: 2.8vw;
      line-height: 1.3;
    }
  }
`;
