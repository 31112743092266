import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

// import bannerDesktop from '../../assets/banner-ra-desktop.png';
// import bannerMobile from '../../assets/banner-ra-mobile.png';
import { RootState } from '../../store';
import { queries } from '../../utils/breakpoints';

const StyledBanner = styled.div`
  img {
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 8%) 2px 2px 4px 2px;
  }

  @media ${queries.mobileDown} {
    img {
      border-radius: 0;
      box-shadow: none;
      margin-top: 2vw;
    }
  }
`;

interface Props extends ConnectedProps<typeof connector> {}

const Banner = ({ esconderBanner }: Props) => {
  const isMobile = useMediaQuery(queries.mobileDown);
  const imageUrl = isMobile
    ? 'https://res.cloudinary.com/tesseract/image/upload/v1682360534/credito-rotativo/Banner_Receba_Antes-02.webp'
    : 'https://res.cloudinary.com/tesseract/image/upload/v1682360538/credito-rotativo/Banner_Receba_Antes_Prancheta_1.webp';

  if (esconderBanner) {
    return null;
  }

  return (
    <StyledBanner>
      <img src={imageUrl} alt='Banner Receba Antes' />
    </StyledBanner>
  );
};

const mapStateToProps = (state: RootState) => ({
  esconderBanner: state.recebaAntes.usaRecebaAntes,
});

const connector = connect(mapStateToProps);

export default connector(Banner);
