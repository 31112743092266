import React from 'react';
import { connect } from 'react-redux';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import Icone from '../../components/Icone';
import { CINZA_700 } from '../../utils/color';
import Progresso from '../../components/Progresso';
import Etapas from './Etapas';

const StyledAcompanhamentoCadastro = styled(Container)`
  padding: 5rem 0;

  .titulo-container {
    float: left;
    padding: 0 4rem;
  }

  .titulo-container h2 {
    color: ${CINZA_700};
    text-align: center;
    text-transform: uppercase;
  }

  .titulo-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 120px;
  }

  .MuiStepContent-last {
    border-left: 0;
  }

  .MuiStepLabel-label {
    color: ${CINZA_700};
  }

  @media (max-width: 560px) {
    padding: 1rem;

    .titulo-container {
      float: none;
      padding: 1rem 0;
    }

    .titulo-container img {
      display: inline;
      margin-left: 1rem;;
      margin-right: 0;
    }

    .titulo-container h2 {
      position: absolute;
      display: inline-block;
      margin-top: 5rem;
      text-align: left;
      font-size: 18px;
    }

    div[icon='3'] {
      padding-top: 14px;
    }
  }

  @media (min-width: 561px) and (max-width: 960px) {
    padding: 1rem 1.5rem;

    .titulo-container {
      float: none;
      padding: 2rem 0;
    }

    .titulo-container img {
      display: inline;
      margin-left: 1rem;;
      margin-right: 0;
    }

    .titulo-container h2 {
      position: absolute;
      display: inline-block;
      margin-top: 5rem;
      text-align: left;
    }
  }
`;

export const AcompanhamentoCadastro = ({
  buscandoInfos,
}) => {
  if (buscandoInfos) {
    return <Progresso />;
  }

  return (
  <StyledAcompanhamentoCadastro maxWidth='md'>
    <div className='titulo-container'>
      <Icone name='statusCadastro' />
      <h2>
        Acompanhe
        <br />
        seu cadastro
      </h2>
    </div>

    <Etapas />
  </StyledAcompanhamentoCadastro>
  );
};

const mapStateToProps = (state) => ({
  buscandoInfos: state.minhaConta.buscandoInfos,
});

export default connect(mapStateToProps)(AcompanhamentoCadastro);
