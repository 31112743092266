import { format } from 'date-fns';
import { RootState } from '..';
import { fecharModaleForm } from '../cadastroSacado/actions';
import {
  getListagemRecebaAntes,
  getListaSacados,
  postSolicitaAumentoLimite,
} from './api';
import {
  EstadosBotaoContinuar,
  ListagemRecebaAntes,
  RecebaAntesActionTypes as types,
} from './types';

const fetchListagemSuccess = (listagem: ListagemRecebaAntes) => ({
  type: types.FETCH_LISTAGEM_SUCCESS,
  listagem,
});

const setFiltroPeriodoErro = (filtroPeriodoErro) => ({
  type: types.SET_FILTRO_PERIODO_ERRO,
  filtroPeriodoErro,
});

const retrocederEtapa = () => ({
  type: types.ETAPA_CADASTRO_ANTERIOR,
});

const avancarEtapa = () => ({
  type: types.ETAPA_CADASTRO_PROXIMA,
});

const alteraBotaoContinuar = (estado: EstadosBotaoContinuar) => ({
  type: types.ALTERA_BTN_PROXIMA_ETAPA,
  estadoBotaoContinuar: estado,
});

const getParamsListagem = (state: RootState) => {
  const {
    recebaAntes: { filtroPeriodo, filtroStatus },
  } = state;
  let params = {};
  if (filtroPeriodo) {
    params = {
      data_inicio: filtroPeriodo.dataInicial,
      data_fim: filtroPeriodo.dataFinal,
    };
  }

  if (filtroStatus) {
    params = {
      ...params,
      status: filtroStatus,
    };
  }

  return params;
};

const fetchListagem = () => async (dispatch, getState) => {
  try {
    dispatch(fecharModaleForm());
    const params = getParamsListagem(getState());

    const response = await getListagemRecebaAntes(params);
    if (response.data.success) {
      const info = response.data.data.data;
      const boletos = info.boletos.map((boleto) => {
        const vencimento = format(new Date(boleto.vencimento), 'dd/MM/yyyy');
        const dataCredito = boleto.data_compensacao ? format(new Date(boleto.data_compensacao), 'dd/MM/yyyy') : '';
        return {
          id: boleto.id,
          numeroOperacao: boleto.numero_operacao,
          numeroBoleto: boleto.numero_boleto,
          cliente: boleto.cliente,
          valor: boleto.valor,
          vencimento,
          dataCredito,
          status: boleto.status,
        };
      });
      const objInfo = {
        limiteTotal: info.limite_total || 0,
        limiteDisponivel: info.limite_disponivel,
        aumentoLimitePendente: info.aumento_limite_pendente,
        idPedidoAndamento: info.id_pedido_andamento,
        boletos,
        carregando: false,
        usaRecebaAntes:
          info.usa_receba_antes !== undefined ? info.usa_receba_antes : true,
        isFiltroModalOpen: false,
      };

      dispatch(fetchListagemSuccess(objInfo));
      dispatch(setCarregandoFiltro(false));
    }
  } catch (error) {
    console.log('erro no fetchListagem', error.response);
    const errors = error.response?.data?.errors;
    if (errors?.data_inicio || errors?.data_fim) {
      dispatch(setFiltroPeriodoErro(errors?.data_inicio || errors?.data_fim));
      dispatch(setFiltro({ filtroPeriodo: null }));
    }
    dispatch(setCarregandoFiltro(false));
  }
};

const setCarregandoFiltro = (carregando) => ({
  type: types.SET_CARREGANDO_FILTRO,
  carregandoFiltro: carregando,
});

const setFiltroModalOpen = (isFiltroModalOpen) => ({
  type: types.SET_FILTRO_MODAL_OPEN,
  isFiltroModalOpen,
});

const setFiltro = (filtros: {
  filtroStatus?: string;
  filtroPeriodo?: { dataInicial: string; dataFinal: string };
}) => ({
  type: types.SET_FILTRO,
  ...filtros,
});

const resetFiltros = () => ({
  type: types.SET_FILTRO,
  filtroStatus: null,
  filtroPeriodo: null,
});

const setFiltroStatus = (filtroStatus) => (dispatch) => {
  dispatch(setCarregandoFiltro(true));
  dispatch(setFiltro({ filtroStatus }));
  dispatch(fetchListagem());
};

const setFiltroPeriodo =
  (filtroPeriodo: { dataInicial: string; dataFinal: string }) => (dispatch) => {
    dispatch(setCarregandoFiltro(true));
    dispatch(setFiltro({ filtroPeriodo }));
    dispatch(fetchListagem());
  };

const solicitaAumentoLimite = () => (dispatch) => {
  postSolicitaAumentoLimite();
  dispatch(fetchListagem());
};

const buscaSacadoRequest = () => ({
  type: types.BUSCA_SACADO_REQUEST,
});

const buscaSacadoSuccess = ({ listaSacados }) => ({
  type: types.BUSCA_SACADO_SUCCESS,
  listaSacados,
});

const buscaSacadoFailure = (error) => ({
  type: types.BUSCA_SACADO_ERROR,
  error,
});

const limpaBuscaSacado = () => ({
  type: types.LIMPAR_BUSCA_SACADO,
});

const limparBuscaSacado = () => (dispatch) => {
  dispatch(limpaBuscaSacado());
  dispatch(fecharModaleForm());
};

const buscaSacado = (textoBusca) => async (dispatch) => {
  dispatch(buscaSacadoRequest());
  try {
    dispatch(fecharModaleForm());
    const response = await getListaSacados(textoBusca);
    const listaSacados =
      response?.data?.data.map((sacado) => {
        return {
          id: sacado.id,
          nomeRazaoSocial: sacado.nome_razao_social,
          documentoSacado: sacado.documento || '00000000000',
        };
      }) || [];
    dispatch(buscaSacadoSuccess({ listaSacados }));
  } catch (error) {
    const data = error?.response?.data?.message;
    dispatch(buscaSacadoFailure(data));
  }
};

const habilitaBtnContinuar = () => ({
  type: types.ALTERA_BTN_PROXIMA_ETAPA,
  estadoBotaoContinuar: EstadosBotaoContinuar.habilitado,
});

const desabilitaBtnContinuar = () => ({
  type: types.ALTERA_BTN_PROXIMA_ETAPA,
  estadoBotaoContinuar: EstadosBotaoContinuar.desabilitado,
});

const esconderBtnContinuar = () => ({
  type: types.ALTERA_BTN_PROXIMA_ETAPA,
  estadoBotaoContinuar: EstadosBotaoContinuar.escondido,
});

const setEtapaFinal = () => ({
  type: types.SET_ETAPA_FINAL,
});

const setEtapaInicial = () => ({
  type: types.SET_ETAPA_INICIAL,
});

export {
  fetchListagem,
  solicitaAumentoLimite,
  avancarEtapa,
  retrocederEtapa,
  alteraBotaoContinuar,
  buscaSacado,
  limparBuscaSacado,
  habilitaBtnContinuar,
  esconderBtnContinuar,
  desabilitaBtnContinuar,
  setEtapaFinal,
  setEtapaInicial,
  setFiltroModalOpen,
  setFiltroStatus,
  setFiltroPeriodo,
  setFiltroPeriodoErro,
  resetFiltros,
};
