import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';

import { CINZA_700 } from '../utils/color';
import { toCurrency } from '../utils/number';
import { validaDigitacaoCampoMoeda } from '../utils/validation';

const StyledTextField = styled(TextField)`
  text-align: center;

  .MuiInput-formControl {
    display: block;
    text-align: center;
  }

  input {
    display: inline-block;
    padding-top: 0;
    width: 200px;
    color: ${CINZA_700};
    text-align: center;
    font-size: 38px;
  }

  input::placeholder {
    text-align: center;
  }

  .MuiFormHelperText-root {
    min-width: 276px;
    font-size: 14px;
    text-align: center;
  }

  @media (max-width: 375px) {
    input {
      font-size: 27px;
    }

    .MuiFormHelperText-root {
      min-width: 100%;
      font-size: 12px;
    }
  }

  /* Corrige problema de estilo no iOS */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      input {
        font-size: 27px;
      }
    }
  }
`;

interface CampoDeMoedaProps {
  value: string;
  placeholder?: string;
  error?: boolean;
  helperText?: string | React.ReactNode;
  className?: string;
  onChange: (valor: string) => void;
  onStopTyping?: () => void;
  InputProps?: any;
  onBlur?: () => void;
  dataCy?: string;
}

const CampoDeMoeda = ({
  value = '',
  placeholder = '0,00',
  error = false,
  helperText = '',
  className = '',
  onChange,
  onStopTyping,
  InputProps,
  onBlur,
  dataCy,
}: CampoDeMoedaProps) => {
  const [valorTimeout, setValorTimeout] = useState(null);

  const handleKeyDown = (e) => {
    const timeout = valorTimeout;
    clearTimeout(timeout);
    setValorTimeout(timeout);
    validaDigitacaoCampoMoeda(e);
  };

  const handleKeyUp = () => {
    let timeout = valorTimeout;
    clearTimeout(timeout);

    timeout = setTimeout(onStopTyping, 1000);
    setValorTimeout(timeout);
  };

  const handleChange = ({ target: { value: newValue } }) => {
    onChange(newValue);
  };

  const handleBlur = ({ target: { value: newValue } }) => {
    const valorComoDinheiro = toCurrency(newValue);
    onChange(valorComoDinheiro);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <StyledTextField
      value={value}
      placeholder={placeholder}
      error={Boolean(error)}
      helperText={helperText}
      className={className}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onChange={handleChange}
      onBlur={handleBlur}
      InputProps={InputProps}
      data-cy={dataCy}
    />
  );
};

export default CampoDeMoeda;
