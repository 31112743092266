import React from 'react';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { CINZA_700, CINZA_400 } from '../../../../utils/color';
import DicaMovimentacoes from './DicaMovimentacoes';

const StyledLink = styled(Link)`
  display: inline-block;
  color: ${CINZA_700};
  border-bottom: 2px solid ${CINZA_400};
  font-size: .9rem;
  font-weight: bold;
  font-family: Montserrat;

  @media (max-width: 960px) {
    margin-left: 1.5rem;
    text-transform: uppercase;
  }

  @media (min-width: 961px) and (max-width: 1280px) {
    font-size: .75rem;
  }

  @media (max-width: 375px) {
    font-size: .7rem;
  }
`;

const EntendaMovimentacoes = () => (
  <DicaMovimentacoes openOnChildrenClick>
    <StyledLink component='button'>
      Entenda suas movimentações
    </StyledLink>
  </DicaMovimentacoes>
);

export default EntendaMovimentacoes;
