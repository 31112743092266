export enum CadastroSacadoActionTypes {
  MOSTRA_FORM_CADASTRO = 'cadastroSacado/MOSTRA_FORM_CADASTRO',
  FETCH_ESTADOS_SUCCESS = 'cadastroSacado/FETCH_ESTADOS_SUCCESS',
  FETCH_CIDADES_SUCCESS = 'cadastroSacado/FETCH_CIDADES_SUCCESS',
  CADASTRO_SACADO_REQUEST = 'cadastroSacado/CADASTRO_SACADO_REQUEST',
  CADASTRO_SACADO_SUCCESS = 'cadastroSacado/CADASTRO_SACADO_SUCCESS',
  CADASTRO_SACADO_ERROR = 'cadastroSacado/CADASTRO_SACADO_ERROR',
  FECHAR_MODAL = 'cadastroSacado/FECHAR_MODAL',
  SET_DADOS_SACADO = 'cadastroSacado/SET_DADOS_SACADO',
  BUSCA_ENDERECO_POR_CEP = 'cadastroSacado/BUSCA_ENDERECO_POR_CEP',
  BUSCA_ENDERECO_POR_CEP_FINALIZADA = 'cadastroSacado/BUSCA_ENDERECO_POR_CEP_FINALIZADA',
  SET_CAMPOS_DESABILITADOS = 'cadastroSacado/SET_CAMPOS_DESABILITADOS',
}

export interface DadosSacado {
  documento: string;
  nomeRazaoSocial: string;
  email: string;
  email2?: string;
  email3?: string;
  telefone: string;
  telefone2?: string;
  endereco: string;
  cep: string;
  cobrarMulta: boolean;
  cidade: Cidade;
  estado: Estado;
  rua: string;
  numero: string;
}

export interface CadastroSacado {
  formAberto: boolean;
  loadingCadastro: boolean;
  erros?: { [key in keyof Partial<DadosSacadoApi>]: string };
  estados: Array<Estado>;
  cidades: Array<Cidade>;
  modalAberto: boolean;
  buscandoEndereco: boolean;
  dadosSacado?: DadosSacado;
  desabilitar: {
    estado: boolean;
    cidade: boolean;
    rua: boolean;
  };
}

export interface DadosSacadoApi {
  cpf_cnpj: string;
  nome_razao_social: string;
  email: string;
  email_2?: string;
  email_3?: string;
  telefone: string;
  telefone_2?: string;
  endereco: string;
  cep: string;
  cobrar_multa: boolean;
  cidade_id: number | string;
}

export interface Estado {
  id: number;
  nome: string;
  sigla: string;
}

export interface Cidade {
  id: number;
  nome: string;
}

export type CadastroSacadoState = CadastroSacado;

export interface RetornoApiViaCep {
  data: {
    bairro: string;
    cep: string;
    complemento: string;
    ddd: string;
    gia: string;
    ibge: string;
    localidade: string;
    logradouro: string;
    siafi: string;
    uf: string;
  };
}
