import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../components/Modal';
import Conteudo from './Conteudo';
import { enviarSolicitacaoRegularizacao as enviarSolicitacaoRegularizacaoAction } from '../../store/conta/actions';

export const SolicitarBoletoRegularizacao = ({
  openModal,
  onSuccess,
  enviarSolicitacaoRegularizacao,
  enviadoComSucesso,
  enviandoSolicitacao
}) => {
  useEffect(() => {
    if (enviadoComSucesso) {
      onSuccess();
    }
  }, [enviadoComSucesso, onSuccess])

  return (
    <Modal
      fullWidth
      maxWidth='xs'
      open={openModal}
      titulo='Existem pendências na sua conta'
      conteudo={<Conteudo />}
      onClose={onSuccess}
      botaoLabel='Solicitar boleto'
      onConfirm={enviarSolicitacaoRegularizacao}
      botaoLoading={enviandoSolicitacao}
    />
  );
};

SolicitarBoletoRegularizacao.propTypes = {
  openModal: PropTypes.bool,
  onSuccess: PropTypes.func,
  enviarSolicitacaoRegularizacao: PropTypes.func.isRequired,
  enviadoComSucesso: PropTypes.bool.isRequired,
  enviandoSolicitacao: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  enviadoComSucesso: state.conta.enviadoComSucesso,
  enviandoSolicitacao: state.conta.enviandoSolicitacao,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  enviarSolicitacaoRegularizacao: enviarSolicitacaoRegularizacaoAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SolicitarBoletoRegularizacao);
