import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { RootState } from '../../../store';
import { queries } from '../../../utils/breakpoints';
import { AZUL_300, BRANCO, CINZA_600 } from '../../../utils/color';
import { toCurrency } from '../../../utils/number';

const ContainerCirculos = styled.div`
  position: relative;

  .circulo-fundo {
    color: ${BRANCO};
  }

  .circulo-principal {
    color: ${AZUL_300};
    position: absolute;
    left: 0;
  }

  .container-textos-meio {
    position: absolute;
    top: -0.8em;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 60%;

    span {
      text-align: center;
      font-size: 0.8vw;
      color: ${CINZA_600};
      display: block;
    }

    .valor-limite {
      padding-top: 0.2vw;
      font-weight: bold;
      color: ${AZUL_300};
    }
  }

  @media ${queries.desktopUp} {
    .container-textos-meio {
      span {
        font-size: 10px;
      }

      .valor-limite {
        padding-top: 0;
      }
    }
  }

  @media ${queries.mobileDown} {
    .container-textos-meio {
      width: 70%;

      span {
        font-size: 3.1vw;
      }

      .valor-limite {
        padding-top: 0;
      }
    }
  }
`;
interface CirculoLimiteProps {
  limiteDisponivel: number;
  limiteTotal: number;
}

const CirculoLimite = ({
  limiteDisponivel,
  limiteTotal,
}: CirculoLimiteProps) => {
  const progressoPorcentagem = (limiteDisponivel / limiteTotal) * 100;

  return (
    <ContainerCirculos>
      <CircularProgress
        variant='static'
        className='circulo-fundo'
        size={'100%'}
        thickness={4.5}
        value={100}
      />
      <CircularProgress
        variant='static'
        className='circulo-principal'
        size={'100%'}
        thickness={4.5}
        value={progressoPorcentagem}
        style={{ height: 'auto', transform: 'rotate(-90deg) scaleY(-1)' }}
      />
      <div className='container-textos-meio'>
        <span>{`Limite disponível`}</span>
        <span className='valor-limite'>
          {`R$ ${toCurrency(limiteDisponivel)}`}
        </span>
      </div>
    </ContainerCirculos>
  );
};

const mapStateToProps = (state: RootState) => ({
  limiteDisponivel: state.recebaAntes.limiteDisponivel,
  limiteTotal: state.recebaAntes.limiteTotal,
});

export default connect(mapStateToProps)(CirculoLimite);
