import * as types from './types';
import { getDataAtual } from '../../utils/data'

const INITIAL_STATE = {
  movimentacoes: [],
  buscandoMovimentacoes: true,
  limiteDias: 60,
  dataParaRegularizar: getDataAtual(),
  valorParaRegularizar: null,
  buscandoValorParaRegularizar: false,
  enviadoComSucesso: false,
  enviandoSolicitacao: false,
  error: '',
};

const conta = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_MOVIMENTACOES_REQUEST:
      return {
        ...state,
        buscandoMovimentacoes: true,
      };
    case types.FETCH_MOVIMENTACOES_SUCCESS:
      return {
        ...state,
        movimentacoes: action.movimentacoes,
        buscandoMovimentacoes: false,
      };
    case types.SET_LIMITE_DIAS:
      return {
        ...state,
        limiteDias: action.limiteDias,
      };
    case types.SET_DATA_PARA_REGULARIZAR:
      return {
        ...state,
        dataParaRegularizar: action.dataParaRegularizar,
      };
    case types.FETCH_VALOR_PARA_REGULARIZAR_REQUEST:
      return {
        ...state,
        buscandoValorParaRegularizar: true,
        error: '',
      };
    case types.FETCH_VALOR_PARA_REGULARIZAR_SUCCESS:
      return {
        ...state,
        valorParaRegularizar: action.valorParaRegularizar,
        buscandoValorParaRegularizar: false,
        error: '',
      };
    case types.FETCH_VALOR_PARA_REGULARIZAR_ERROR: {
      const { error } = action;
      return {
        ...state,
        buscandoValorParaRegularizar: false,
        error,
      };
    }
    case types.ENVIAR_SOLICITACAO_REGULARIZACAO_REQUEST:
      return {
        ...state,
        error: '',
        enviadoComSucesso: false,
        enviandoSolicitacao: true,
      };
    case types.ENVIAR_SOLICITACAO_REGULARIZACAO_SUCCESS:
      return {
        ...state,
        dataParaRegularizar: getDataAtual(),
        valorParaRegularizar: null,
        enviadoComSucesso: true,
        enviandoSolicitacao: false,
        error: '',
      };
    case types.ENVIAR_SOLICITACAO_REGULARIZACAO_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        enviadoComSucesso: false,
        enviandoSolicitacao: false,
      };
    }
    case types.DISPENSAR_NOTIFICACAO_DE_SUCESSO: {
      return {
        ...state,
        enviadoComSucesso: false,
      };
    }
    default:
      return state;
  }
};

export default conta;
