import { useMediaQuery } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../store';
import { enviaArquivo, ordenaLista } from '../../../store/antecipacao/actions';
import { salvarAntecipacao } from '../../../store/pedidoAntecipacao/actions';
import { haAntecipacoes } from '../../../store/pedidoAntecipacao/selectors';
import {
  avancarEtapa,
  retrocederEtapa,
  setEtapaFinal,
} from '../../../store/recebaAntes/actions';
import { EstadosBotaoContinuar } from '../../../store/recebaAntes/types';
import { queries } from '../../../utils/breakpoints';
import RoundButton from '../../RoundButton';
import { CadastroContainer } from './CadastroContainer.style';
import IndicadorEtapa from './IndicadorEtapa';
import NotaFiscal from './NotaFiscal';
import ParcelasBoletos from './ParcelasBoletos';
import Sacado from './Sacado';
import ValoresBoleto from './ValoresBoleto';

interface CadastroAntecipacaoProps extends ConnectedProps<typeof connector> {}

const CadastroAntecipacao = ({
  avancaEtapa,
  retrocedeEtapa,
  etapaAtiva,
  estadoBotaoContinuar,
  enviaArquivo,
  ordenaLista,
  salvaAntecipacao,
  haAntecipacaoEmAndamento,
  cancelaCadastroAntecipacao,
}: CadastroAntecipacaoProps) => {
  const isMobile = useMediaQuery(queries.mobileDown);
  const textoBotaoContinuar = isMobile ? 'Continuar' : 'Continuar antecipação';

  const avancarEtapa = () => {
    if (etapaAtiva === 2) {
      return enviaArquivo();
    }
    if (etapaAtiva === 3) {
      return ordenaLista();
    }
    if (etapaAtiva === 4) {
      return salvaAntecipacao();
    }
    return avancaEtapa();
  };

  const handleAvancaEtapa = () => {
    avancarEtapa();
  };

  const handleRetrocedeEtapa = () => {
    if (etapaAtiva !== 1) {
      return retrocedeEtapa();
    }

    if (haAntecipacaoEmAndamento) {
      return cancelaCadastroAntecipacao();
    }
  };

  return (
    <CadastroContainer>
      <h1>Cadastre sua antecipação</h1>
      <div className='container-etapas'>
        <IndicadorEtapa numero={1} texto='Cliente' />
        <IndicadorEtapa numero={2} texto='Valor' />
        <IndicadorEtapa numero={3} texto='Parcelas' />
        <IndicadorEtapa numero={4} texto='Boletos' />
      </div>
      <div className='conteudo'>
        {etapaAtiva === 1 && <Sacado />}
        {etapaAtiva === 2 && <NotaFiscal />}
        {etapaAtiva === 3 && <ParcelasBoletos />}
        {etapaAtiva === 4 && <ValoresBoleto />}
      </div>

      {estadoBotaoContinuar !== EstadosBotaoContinuar.escondido && (
        <div className='container-navegacao'>
          <RoundButton
            size='small'
            color='secondary'
            onClick={handleRetrocedeEtapa}
            href={
              !haAntecipacaoEmAndamento && etapaAtiva === 1
                ? '/minhaconta/recebaantes'
                : ''
            }
            data-cy='botao-voltar'
            value={
              <>
                <ArrowBackIosIcon className='icone-botao' fontSize='inherit' />{' '}
                Voltar
              </>
            }
          />
          <RoundButton
            size='small'
            color='primaryLinearGradient'
            onClick={handleAvancaEtapa}
            data-cy='botao-continuar'
            disabled={
              estadoBotaoContinuar === EstadosBotaoContinuar.desabilitado
            }
            loading={estadoBotaoContinuar === EstadosBotaoContinuar.carregando}
            value={
              <>
                {`${textoBotaoContinuar} `}
                <ArrowForwardIosIcon
                  className='icone-botao'
                  fontSize='inherit'
                />
              </>
            }
          />
        </div>
      )}
    </CadastroContainer>
  );
};

const mapStateToProps = (state: RootState) => ({
  etapaAtiva: state.recebaAntes.etapaAtiva,
  estadoBotaoContinuar: state.recebaAntes.estadoBotaoContinuar,
  haAntecipacaoEmAndamento: haAntecipacoes(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      avancaEtapa: avancarEtapa,
      retrocedeEtapa: retrocederEtapa,
      enviaArquivo,
      ordenaLista,
      salvaAntecipacao: salvarAntecipacao,
      cancelaCadastroAntecipacao: setEtapaFinal,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CadastroAntecipacao);
