import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RoundButton from '../../../components/RoundButton';

const StyledRoundButton = styled(RoundButton)`
  padding: 10px 10px;
  font-size: 10px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    padding: 8px 25px;
    line-height: 1.2;
}

  @media (max-width: 375px) {
      font-size: 8px;
  }
`;

const BotaoDevolucao = ({ value, onClick, disabled }) => (
  <StyledRoundButton
    value={value}
    color='primary'
    size='small'
    bold
    onClick={onClick}
    disabled={disabled}
  />
);

BotaoDevolucao.defaultProps = {
  disabled: false,
};

BotaoDevolucao.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default BotaoDevolucao;
