import { createMuiTheme } from '@material-ui/core';
import { AZUL_300, ROXO, CINZA_600 } from './utils/color';

const fontFamily = [
  'Montserrat',
  'Roboto',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const theme = createMuiTheme({
  typography: {
    fontFamily,
    h1: {
      fontSize: '1.6em',
      fontWeight: 'bold',
    }
  },
  palette: {
    primary: {
      main: AZUL_300,
    },
    secondary: {
      main: ROXO,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fontFamily,
      },
    },
    MuiLink: {
      root: {
        color: '#626262',
      },
      underlineHover: {
       '&:hover': {
          textDecoration: 'none',
        }
      },
    },
    MuiTextField: {
      root: {
        marginTop: '1em',
      }
    },
    MuiInputLabel: {
      shrink: {
        color: CINZA_600,
        fontWeight: 'bold',
        fontSize: '1.4rem',
      },
    },
  },
});

export default theme;
