import React from 'react';
import { Container, Grid, Box } from '@material-ui/core';
import MeusDados from './MeusDados';
import ListaContasBancarias from './ListaContasBancarias';
import InfosContato from './InfosContato';

const Cadastro = () => (
  <Container>
    <Grid container>
    <Grid item xs={false} md={1} lg={2} />
      <Grid item xs={12} md={5} lg={4}>
        <MeusDados />
        <Box display={{ xs: 'none', sm: 'none', md: 'block', lg: 'block' }}>
          <InfosContato />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <ListaContasBancarias />
      </Grid>
      <Box display={{ xs: 'block', sm: 'block', md: 'none', lg: 'none' }}>
        <Grid item xs={12}>
          <InfosContato mobile />
        </Grid>
      </Box>
    </Grid>
  </Container>
);

export default Cadastro;
