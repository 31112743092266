import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { format, parse } from 'date-fns';
import { InputAdornment } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Icone from './Icone';
import { CINZA_600, AZUL_300 } from '../utils/color';

const StyledDataInput = styled(DatePicker)`
  .MuiInputBase-root  {
    width: 115px;
    margin: 0 auto;
    text-align: center;
  }

  input {
    padding-top: 0;
    padding-bottom: 4px;
    color: ${CINZA_600};
  }

  svg {
    margin-bottom: .3rem;
    font-size: 1rem;
    color: ${AZUL_300};
  }
`

const DataInput = ({ handleDateChange, value, error, helperText }) => {
  const valueAsDate = value === null ? null : parse(value, 'dd/MM/yyyy', new Date());

  const onChange = (value) => {
    const dataFormatada = format(value, 'dd/MM/yyyy')
      handleDateChange(dataFormatada)
    }

   return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <StyledDataInput
          cancelLabel='Cancelar'
          id='date-picker-dialog'
          label=''
          format='dd/MM/yyyy'
          value={valueAsDate}
          error={Boolean(error)}
          helperText={error ? helperText : ''}
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icone name='arrowDown' />
              </InputAdornment>
            ),
          }}
        />
    </MuiPickersUtilsProvider>
  );
};

DataInput.defaultProps = {
  value: null,
  error: '',
  helperText: '',
};

DataInput.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
};

export default DataInput;
