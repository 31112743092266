import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ErrorIcon from '@material-ui/icons/Error';
import HelpIcon from '@material-ui/icons/Help';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import arrowLeft from '../assets/arrow_left.png';
import arrowRight from '../assets/arrow_right.png';
import barcodeAzul from '../assets/codigo-de-barras-azul.png';
import boleto from '../assets/boleto.png';
import contaBancaria from '../assets/conta_bancaria.png';
import erro from '../assets/erro.png';
import minhaConta from '../assets/minha_conta.png';
import saque from '../assets/saque.png';
import seta from '../assets/seta.png';
import setaCurvada from '../assets/seta_simulacao.png';
import simulacao from '../assets/simulacao.png';
import statusCadastro from '../assets/status_cadastro.png';
import transferencia from '../assets/transferencia.png';
import user from '../assets/home_cadastro.png';

const Icone = ({ name, alt }) => {
  const icons = {
    ajuda: <HelpIcon />,
    arrowDown: <KeyboardArrowDownIcon />,
    arrowLeft: <img src={arrowLeft} alt={alt} />,
    arrowRight: <img src={arrowRight} alt={alt} />,
    arrowUp: <KeyboardArrowUpIcon />,
    barcode: <img src={barcodeAzul} alt={alt} />,
    boleto: <img src={boleto} alt={alt} />,
    close: <CloseIcon />,
    contaBancaria: <img src={contaBancaria} alt={alt} />,
    erro: <img src={erro} alt={alt} />,
    error: <ErrorIcon />,
    minhaConta: <img src={minhaConta} alt={alt} />,
    saque: <img src={saque} alt={alt} />,
    seta: <img src={seta} alt={alt} />,
    setaCurvada: <img src={setaCurvada} alt={alt} />,
    setaDupla: <DoubleArrowIcon />,
    simulacao: <img src={simulacao} alt={alt} />,
    statusCadastro: <img src={statusCadastro} alt={alt} />,
    transferencia: <img src={transferencia} alt={alt} />,
    user: <img src={user} alt={alt} />,
    visibility: <VisibilityIcon />,
    whatsApp: <WhatsAppIcon />
  };

  return <>{icons[name]}</>;
};

Icone.defaultProps = {
  alt: 'icon',
};

Icone.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

export default Icone;
