export const FETCH_MOVIMENTACOES_REQUEST = 'conta/FETCH_MOVIMENTACOES_REQUEST';
export const FETCH_MOVIMENTACOES_SUCCESS = 'conta/FETCH_MOVIMENTACOES_SUCCESS';
export const SET_LIMITE_DIAS = 'conta/SET_LIMITE_DIAS';
export const SET_DATA_PARA_REGULARIZAR = 'conta/SET_DATA_PARA_REGULARIZAR';
export const FETCH_VALOR_PARA_REGULARIZAR_REQUEST = 'conta/FETCH_VALOR_PARA_REGULARIZAR_REQUEST';
export const FETCH_VALOR_PARA_REGULARIZAR_SUCCESS = 'conta/FETCH_VALOR_PARA_REGULARIZAR_SUCCESS';
export const FETCH_VALOR_PARA_REGULARIZAR_ERROR = 'conta/FETCH_VALOR_PARA_REGULARIZAR_ERROR';
export const ENVIAR_SOLICITACAO_REGULARIZACAO_REQUEST = 'conta/ENVIAR_SOLICITACAO_REGULARIZACAO_REQUEST';
export const ENVIAR_SOLICITACAO_REGULARIZACAO_SUCCESS = 'conta/ENVIAR_SOLICITACAO_REGULARIZACAO_SUCCESS';
export const ENVIAR_SOLICITACAO_REGULARIZACAO_ERROR = 'conta/ENVIAR_SOLICITACAO_REGULARIZACAO_ERROR';
export const DISPENSAR_NOTIFICACAO_DE_SUCESSO = 'conta/DISPENSAR_NOTIFICACAO_DE_SUCESSO';
