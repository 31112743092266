import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CadastroAntecipacao from '../../components/RecebaAntes/CadastroAntecipacao';
import InfoPedido from '../../components/RecebaAntes/InfoPedido';
import { RootState } from '../../store';
import { setEtapaFinal } from '../../store/recebaAntes/actions';
import { selectHaPedidoEmAndamento } from '../../store/recebaAntes/selectors';
import AbaContainer from '../MinhaConta/AbaContainer';
import Progresso from '../../components/Progresso';

const PedidoAntecipacao = ({
  etapaAtiva,
  haPedidoEmAndamento,
  setEtapaFinal,
  carregando,
}) => {
  useEffect(() => {
    if (haPedidoEmAndamento) {
      setEtapaFinal();
    }
  }, [haPedidoEmAndamento, setEtapaFinal]);

  const conteudo = etapaAtiva === 5 ? <InfoPedido /> : <CadastroAntecipacao />;

  return <AbaContainer>{carregando ? <Progresso /> : conteudo}</AbaContainer>;
};

const mapStateToProps = (state: RootState) => ({
  haPedidoEmAndamento: selectHaPedidoEmAndamento(state),
  etapaAtiva: state.recebaAntes.etapaAtiva,
  carregando: state.recebaAntes.carregando,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setEtapaFinal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PedidoAntecipacao);
