import * as React from "react"

const IconeCalendario = (props) => (
  <svg
    id="Camada_1"
    data-name="Camada 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 123.83 114.41"
    width="0.9em"
    height="1.1em"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#007fc4}"}</style>
    </defs>
    <path
      className="cls-1"
      d="M120 14.46H88.85v-11a3.06 3.06 0 1 0-6.09 0v11H40.11v-11a3.24 3.24 0 0 0-3-3.41 3.24 3.24 0 0 0-3 3.41v11H3.87A3.87 3.87 0 0 0 0 18.32v92.23a3.87 3.87 0 0 0 3.87 3.86H120a3.87 3.87 0 0 0 3.87-3.86V18.32a3.87 3.87 0 0 0-3.87-3.86Zm1.87 96.09a1.87 1.87 0 0 1-1.87 1.86H3.87A1.87 1.87 0 0 1 2 110.55V18.32a1.87 1.87 0 0 1 1.87-1.86H34v5.12a3.06 3.06 0 1 0 6.09 0v-5.12h42.67v5.12a3.06 3.06 0 1 0 6.09 0v-5.12H120a1.87 1.87 0 0 1 1.87 1.86Z"
    />
    <path
      className="cls-1"
      d="M33.07 52.23H13.71V31.8h19.36ZM15.74 50H31V34.07H15.74ZM58.09 52.23H38.74V31.8h19.35ZM40.77 50h15.29V34.07H40.77ZM108.15 52.23H88.79V31.8h19.36ZM90.82 50h15.3V34.07h-15.3ZM82.7 52.23H63.34V31.8H82.7ZM33.07 77.21H13.71V56.77h19.36Zm-17.33-2.27H31V59.05H15.74ZM58.09 77.21H38.74V56.77h19.35Zm-17.32-2.27h15.29V59.05H40.77ZM83.12 77.21H63.76V56.77h19.36Zm-17.33-2.27h15.3V59.05h-15.3ZM108.15 77.21H88.79V56.77h19.36Zm-17.33-2.27h15.3V59.05h-15.3ZM33.07 102.19H13.71V81.75h19.36Zm-17.33-2.27H31V84H15.74ZM58.09 102.19H38.74V81.75h19.35Zm-17.32-2.27h15.29V84H40.77ZM83.12 102.19H63.76V81.75h19.36Zm-17.33-2.27h15.3V84h-15.3ZM108.15 102.19H88.79V81.75h19.36Zm-17.33-2.27h15.3V84h-15.3Z"
    />
  </svg>
)

export default IconeCalendario
