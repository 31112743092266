import Api from '../../Api';

export const postSimulacao = ({ saques, devolucoes }) => (
  Api.post(
    'simulacao',
    {
      saques,
      devolucoes,
    },
  )
);

export const postInfosJuros = ({
  valorSaqueRaw,
  dataSaque,
  saques,
  devolucoes,
}) => (
  Api.post(
    'simulacao/saque',
    {
      valor_saque: valorSaqueRaw,
      data_saque: dataSaque,
      saques,
      devolucoes,
    },
  )
);
