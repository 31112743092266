import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import { CINZA_400, CINZA_600, CINZA_700 } from '../../../../utils/color';

export const StyledContasBancariasRecebaAntes = styled.div`
  padding: 0;
  margin-bottom: 2.4vw;
  text-align: left;

  .contas-bancarias-titulo {
    font-size: 0.9vw;
    color: ${CINZA_700};
    margin-top: 3.5vw;
    margin-bottom: 0.4vw;
  }

  .adicionar-conta {
    padding: 0.6vw 1.2vw;
    font-size: 1vw;
    border-radius: 0;
    margin-bottom: 10px;
  }

  .contas-bancarias-lista {
    color: ${CINZA_600};
    border: 1.5px solid ${CINZA_400};
    border-radius: 4px;
    padding: 1vw 0;
    font-size: 0.8vw;
    margin-right: 2vw;

    .radio svg {
      font-size: 1.5vw;
    }

    .remover-conta-bancaria {
      padding-top: 0;
      svg {
        font-size: 1.5vw;
      }
    }

    & > div:not(:last-child) {
      border-bottom: 1px solid ${CINZA_400};
      margin-bottom: 1.4vw;
      padding-bottom: 1.4vw;
    }
  }

  .aviso-credito-disponibilizado {
    font-size: 0.75vw;
    margin-left: 0.4vw;
    color: ${CINZA_600};
  }

  @media ${queries.desktopUp} {
    margin-bottom: 30px;

    .contas-bancarias-titulo {
      font-size: 12px;
      margin-top: 45px;
      margin-bottom: 6px;
    }

    .adicionar-conta {
      padding: 8px 14px;
      font-size: 12px;
    }

    .contas-bancarias-lista {
      padding: 12px 0;
      font-size: 12px;
      margin-right: 24px;

      .radio svg {
        font-size: 18px;
      }

      .remover-conta-bancaria svg {
        font-size: 18px;
      }

      & > div:not(:last-child) {
        margin-bottom: 18px;
        padding-bottom: 18px;
      }
    }

    .aviso-credito-disponibilizado {
      font-size: 10px;
      margin-left: 5px;
    }
  }

  @media ${queries.mobileDown} {
    padding: 0 1.4vw;
    .contas-bancarias-titulo {
      font-size: 3.9vw;
      margin-top: 11.2vw;
      margin-bottom: 2.2vw;
    }

    .adicionar-conta {
      font-size: 3.5vw;
      border-width: 2px;
      margin-bottom: 14vw;
      padding: 2.3vw 6.8vw;
    }

    .contas-bancarias-lista {
      padding: 6.8vw 0;
      font-size: 3.4vw;
      margin-right: 0;
      line-height: 1.2;
      border-radius: 8px;

      .radio {
        padding: 0 2.4vw;
      }

      .radio svg {
        font-size: 7.5vw;
      }

      .remover-conta-bancaria svg {
        font-size: 6vw;
      }

      & > div:not(:last-child) {
        border-bottom: 1px solid ${CINZA_400};
        margin-bottom: 4vw;
        padding-bottom: 6vw;
      }
    }

    .aviso-credito-disponibilizado {
      font-size: 2.8vw;
      margin-left: 1vw;
    }

    .container-contas {
      margin-bottom: 2.2vw;
    }
  }
`;
