import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import MobileStepper from '@material-ui/core/MobileStepper';
import { CINZA_600, AZUL_400 } from '../../../../utils/color';
import RoundButton from '../../../../components/RoundButton';
import Icone from '../../../../components/Icone';
import { setEtapaIntroducao, sairIntroducao } from '../../../../store/simulacao/actions';

const StyledConteudo = styled(Container)`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

  .simulacao-conteudo-titulo {
    display: inline-block;
    border-bottom: 2px solid ${CINZA_600};
    color: ${CINZA_600};
    font-size: .7rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .simulacao-conteudo-descricao {
    margin-top: 8px;
    color: ${CINZA_600};
    font-size: 1rem;
    text-align: center;
    line-height: 1.5;
  }

  .simulacao-conteudo-botoes-container {
    float: right;
  }

  .simulacao-botoes-conteudo {
    font-size: 14px;
    padding: 2px 20px;
    pointer-events: all;
  }

  img {
    width: 15px;
  }

  .MuiPaper-root {
    margin: 1rem;
    display: inline-flex;
    background: none;
  }

  .MuiMobileStepper-dot {
    background-color: initial;
    border: 1px solid ${CINZA_600};
  }

  .MuiMobileStepper-dotActive {
    background-color: ${AZUL_400}
  }

  @media (max-width: 600px) {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;

    .simulacao-botoes-conteudo {
      font-size: 12px;
      padding: 2px 15px;
    }
  }

  @media (max-width: 375px) {
    .simulacao-conteudo-descricao {
      font-size: 14px;
    }

    .simulacao-botoes-conteudo {
      font-size: 10px;
      padding: 2px 10px;
    }
  }
`;

const descricaoBotaoVoltar = (
  <>
   <Icone name='arrowLeft' />
   <span style={{marginLeft: '.5rem'}}>Voltar</span>
  </>
);

const descricaoBotaoContinuar = (
  <>
   <span style={{marginRight: '.5rem'}}>Continuar</span>
   <Icone name='arrowRight' />
  </>
);

export const Conteudo = ({
  etapa,
  setEtapa,
  descricao,
  proximaEtapa,
  etapaAnterior,
  onSairButtonClick,
}) => (
  <StyledConteudo>
    <h4 className='simulacao-conteudo-titulo'>Entenda sua conta</h4>
    <MobileStepper
      variant='dots'
      steps={5}
      position='static'
      activeStep={etapa - 1}
    />
    <p className='simulacao-conteudo-descricao'>{descricao}</p>

    <RoundButton
      value='Sair'
      color='primaryDark'
      bold
      size='small'
      className='simulacao-botoes-conteudo'
      onClick={onSairButtonClick}
    />

    <div className='simulacao-conteudo-botoes-container'>
      {etapa > 1 &&
        <RoundButton
          value={descricaoBotaoVoltar}
          color='primaryDark'
          bold
          size='small'
          className='simulacao-botoes-conteudo'
          style={{ marginRight: 10 }}
          onClick={() => setEtapa(etapaAnterior)}
        />
      }
      {etapa <= 4 &&
        <RoundButton
          value={descricaoBotaoContinuar}
          color='primaryDark'
          bold
          size='small'
          className='simulacao-botoes-conteudo'
          onClick={() => setEtapa(proximaEtapa)}
        />
      }
    </div>
  </StyledConteudo>
);

Conteudo.defaultProps = {
  etapa: null,
  proximaEtapa: null,
  etapaAnterior: null,
};

Conteudo.propTypes = {
  etapa: PropTypes.number,
  setEtapa: PropTypes.func.isRequired,
  descricao: PropTypes.node.isRequired,
  proximaEtapa: PropTypes.number,
  etapaAnterior: PropTypes.number,
  onSairButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  etapa: state.simulacao.etapaIntroducao,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setEtapa: setEtapaIntroducao,
  onSairButtonClick: sairIntroducao,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
