import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CINZA_600 } from '../../../utils/color';

const StyledDetalhes = styled.div`
  .detalhes-titulo {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
  }

  .detalhes-conteudo {
    margin-top: 0;
    margin-bottom: 14px;
    color: ${CINZA_600};
  }

  @media (max-width: 375px) {
    font-size: 12px;
  }
`;

const Detalhes = ({ titulo, conteudo }) => (
  <StyledDetalhes>
    <p className='detalhes-titulo '>{titulo}</p>
    <div className='detalhes-conteudo'>{conteudo}</div>
  </StyledDetalhes>
);

Detalhes.propTypes = {
  titulo: PropTypes.string.isRequired,
  conteudo: PropTypes.node.isRequired,
};

export default Detalhes;
