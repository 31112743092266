import * as React from 'react';

const IconeNotaFiscal = (props) => (
  <svg
    id='Camada_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 37.64 55.25'
    style={{
      enableBackground: 'new 0 0 37.64 55.25',
    }}
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
  >
    <style>
      {
        '.st0{fill:url(#SVGID_1_)}.st1{fill:url(#SVGID_00000127021553033456792590000011071527610363512971_)}.st2{fill:url(#SVGID_00000085967984500533084000000017345416334350375589_)}.st3{fill:url(#SVGID_00000093865146831576058870000001424699875888230832_)}.st4{fill:url(#SVGID_00000137093149106309118530000002037835014013114014_)}.st5{fill:url(#SVGID_00000134224390289012335190000001260817576619274893_)}.st7{fill:#5e6c7f}.st8{fill:#007fc4}.st9{fill:url(#SVGID_00000078763972729398974190000013936032830332083610_)}.st10{fill:url(#SVGID_00000031204479846248184720000005322740901003901610_)}.st11{fill:#92d4f5}.st12{fill:#eceff1}.st13{fill:url(#SVGID_00000104674593080141686510000002549425992784469394_)}.st14{fill:url(#SVGID_00000085231407309536805790000009157376400730197913_);stroke:url(#SVGID_00000011752230857466302380000015532456791208389511_);stroke-width:.8968;stroke-miterlimit:10}.st15{fill:#af016f}.st16{fill:url(#SVGID_00000057857704063174253390000000624347225330410942_)}.st17{fill:url(#SVGID_00000119099932738862501230000014589189051962533254_)}.st18{fill:url(#SVGID_00000085213596646044893440000007288145429374578111_);stroke:url(#SVGID_00000138537165093645127760000017369866008939544222_);stroke-width:.8072;stroke-miterlimit:10}.st19{fill:url(#SVGID_00000025422129551980965850000006504173581358066612_)}'
      }
    </style>
    <path
      d='M36.07 0H1.57C.7 0 0 .7 0 1.57v52.11c0 .87.7 1.57 1.57 1.57h34.5c.87 0 1.57-.7 1.57-1.57V1.57C37.64.7 36.93 0 36.07 0zM24.33 33.55c0 .31-.25.56-.56.56h-9.91a.56.56 0 0 1-.56-.56v-.09c0-.31.25-.56.56-.56h9.91c.31 0 .56.25.56.56v.09zm9.47-3.99c0 .4-.32.72-.72.72H4.56c-.4 0-.72-.32-.72-.72v-.15c0-.4.32-.72.72-.72h28.52c.4 0 .72.32.72.72v.15zm0-4.55c0 .4-.32.72-.72.72H4.56c-.4 0-.72-.32-.72-.72v-.15c0-.4.32-.72.72-.72h28.52c.4 0 .72.32.72.72v.15zm0-4.55c0 .4-.32.72-.72.72H4.56c-.4 0-.72-.32-.72-.72v-.15c0-.4.32-.72.72-.72h28.52c.4 0 .72.32.72.72v.15zm0-4.55c0 .4-.32.72-.72.72H4.56c-.4 0-.72-.32-.72-.72v-.15c0-.4.32-.72.72-.72h28.52c.4 0 .72.32.72.72v.15zm0-4.55c0 .4-.32.72-.72.72H4.56c-.4 0-.72-.32-.72-.72v-.15c0-.4.32-.72.72-.72h28.52c.4 0 .72.32.72.72v.15z'
      style={{
        fill: '#fff',
      }}
    />
  </svg>
);

export default IconeNotaFiscal;
