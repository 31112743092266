export const selectNomeEmpresa = (state) => {
  const { nomeEmpresa } = state.auth.attributes;

  return nomeEmpresa;
};

export const selectUserId = (state) => {
  const { id } = state.auth.attributes;

  return id;
};


export default {
  selectNomeEmpresa,
};
