import { TableBody, TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../store';
import FormatadorDeMoeda from '../../FormatadorDeMoeda';
import styled from 'styled-components';
import { CINZA_600 } from '../../../utils/color';
import { queries } from '../../../utils/breakpoints';
import StatusBoleto from './StatusBoleto';
import EstadoVazio from './EstadoVazio';

const StyledTableCell = styled(TableCell)`
  text-align: center;
  font-size: 0.9vw;
  color: ${CINZA_600};
  padding: 0.7vw 0;

  @media ${queries.desktopUp} {
    font-size: 12px;
    padding: 10px 0;
  }
`;

interface Props extends ConnectedProps<typeof connector> {}

// function to return if the dataCredito is before vencimento
function isDataCreditoAfterVencimento(dataCredito: string, vencimento: string) {
  const dataCreditoSplit = dataCredito.split('/');
  const vencimentoSplit = vencimento.split('/');
  const dataCreditoDate = new Date(
    `${dataCreditoSplit[2]}-${dataCreditoSplit[1]}-${dataCreditoSplit[0]}`
  );
  const vencimentoDate = new Date(
    `${vencimentoSplit[2]}-${vencimentoSplit[1]}-${vencimentoSplit[0]}`
  );
  return dataCreditoDate > vencimentoDate;
}

const ItensTabela = ({ boletos }: Props) => {
  console.log(boletos);
  if (boletos.length === 0) {
    return <EstadoVazio />;
  }
  return (
    <TableBody>
      {boletos.map((boleto, index) => (

        <TableRow key={index}>
          <StyledTableCell data-cy='numero-operacao'>
            {`${boleto.numeroOperacao}`.padStart(3, '0')}
          </StyledTableCell>
          <StyledTableCell data-cy='numero-boleto'>
            {boleto.numeroBoleto}
          </StyledTableCell>
          <StyledTableCell data-cy='nome-cliente'>
            {boleto.cliente}
          </StyledTableCell>
          <StyledTableCell data-cy='valor-boleto'>
            <FormatadorDeMoeda valor={boleto.valor} comSimbolo />
          </StyledTableCell>
          <StyledTableCell data-cy='vencimento-boleto'>
            {boleto.vencimento}
          </StyledTableCell>
          <StyledTableCell data-cy='data-credito'>
            {isDataCreditoAfterVencimento(boleto.dataCredito, boleto.vencimento) ? boleto.dataCredito : ''}
          </StyledTableCell>
          <StyledTableCell>
            <StatusBoleto status={boleto.status} />
          </StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

const mapStateToProps = (state: RootState) => ({
  boletos: state.recebaAntes.boletos,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ItensTabela);
