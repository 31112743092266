import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import EmailIcone from '../../../assets/home_email.png';
import { exibeChat as exibeChatAction } from '../../../store/contato/actions'
import LinkSublinhado from '../../../components/LinkSublinhado';
import Icone from '../../../components/Icone';
import { AZUL_300 } from '../../../utils/color';

const StyledContatoInfo = styled(Grid)`
    padding: .5rem 2rem;
    border: 2px solid ${AZUL_300};
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

  .contato-icone {
    width: 80px;
  }

  h2 {
    margin-bottom: 0;
    color: ${AZUL_300};
    font-size: 3rem;
    font-weight: bold;
    text-transform: lowercase;
  }

  .informacao {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .contato {
    margin-bottom: .2rem;
    font-size: 1rem;

    a {
      padding: 0;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 3px;
      vertical-align: text-bottom;
      align-self: center;
      color: ${AZUL_300};
    }
  }

  .email-icone {
    vertical-align: middle;
    color: ${AZUL_300};
    font-size: 1.2rem;
  }

  .email {
    color: inherit;
    text-decoration: none;
  }

  @media (max-width: 960px) {
    padding: 2rem;
    border: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .contato-icone {
      width: 30px;
    }

    h2 {
      margin-bottom: .5rem;
      font-size: 1.2rem;
    }

    .informacao {
      font-size: 1rem;
    }

    .contato {
      font-size: 1rem;
      margin-left: 4rem;
    }
  }
`;

export const ContatoInfo = ({ exibeChat, whatsAppAtivo, linkWhatsApp }) => {
  const creditoEmail = 'atendimento@creditorotativo.com.br';

  const WhatsApp = () => {
    if (whatsAppAtivo) {
      return (
        <>
          <Icone name="whatsApp" />
          <LinkSublinhado
            to={linkWhatsApp}
            href={linkWhatsApp}
            component="a"
            target="_blank"
          >
            WhatsApp
          </LinkSublinhado>
        </>
      )
    }

    return null
  }

  return (
    <StyledContatoInfo item sm={12} md={6}>
      <h2>
        <img src={`${EmailIcone}`} alt={'icon'} className='contato-icone' />
        &nbsp;Fale conosco
      </h2>

      <p className='informacao'>
        Não tem certeza se o Crédito Rotativo
        <br />
        se encaixa com as necessidades
        <br />
        da sua empresa?
        <br />
        Vamos conversar.
      </p>

      <p className='contato'>
        Entre em contato pelo formulário ao lado
        ou pelo nosso {' '}
        <WhatsApp />
      </p>

      <p>
        <a href={`mailto:${creditoEmail}`} target='_blank' rel='noopener noreferrer' className='email'>
          <EmailIcon className='email-icone' />
          {` ${creditoEmail}`}
        </a>
      </p>
    </StyledContatoInfo>
  );
};

const mapStateToProps = (state) => ({
  whatsAppAtivo: state.configuracoes.whatsAppAtivo,
  linkWhatsApp: state.configuracoes.linkWhatsApp
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({
    exibeChat: exibeChatAction
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContatoInfo);
