import * as React from "react"

const IconePercentage = (props) => (
  <svg
    data-name="Camada 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 94.14 81.9"
    width="1em"
    height="0.8em"
    {...props}
  >
    <path
      d="M34.2 6a18.44 18.44 0 0 0-14.29-6A18.63 18.63 0 0 0 5.55 6Q0 12 0 22.08t5.55 16.08a18.69 18.69 0 0 0 14.36 6 18.5 18.5 0 0 0 14.29-6q5.49-6 5.5-16.08T34.2 6ZM9.07 22.75c0-6.77 4.77-12.26 10.64-12.26S30.34 16 30.34 22.75 25.58 35 19.71 35 9.07 29.54 9.07 22.75Zm19.38-10.3a11.06 11.06 0 0 0-8.57-3.6 11.17 11.17 0 0 0-8.6 3.6Q8 16.05 8 22.08c0 4 1.11 7.24 3.33 9.63a11.21 11.21 0 0 0 8.6 3.6 11.1 11.1 0 0 0 8.57-3.6c2.19-2.39 3.29-5.61 3.29-9.63s-1.15-7.23-3.34-9.63ZM19.71 35c-5.87 0-10.64-5.46-10.64-12.25s4.77-12.26 10.64-12.26S30.34 16 30.34 22.75 25.58 35 19.71 35ZM77.3.92h-8.61a2.07 2.07 0 0 0-1.7.9L15.14 77.76A2.06 2.06 0 0 0 16.85 81h8.59a2.06 2.06 0 0 0 1.71-.89L79 4.16A2.07 2.07 0 0 0 77.3.92Zm11.29 42.84a18.61 18.61 0 0 0-14.35-6A19.76 19.76 0 0 0 64 40.43a18.23 18.23 0 0 0-7 7.73 25.86 25.86 0 0 0-2.51 11.66A25.84 25.84 0 0 0 57 71.49a18 18 0 0 0 7 7.72 19.57 19.57 0 0 0 10.24 2.69 18.64 18.64 0 0 0 14.35-6q5.55-6 5.55-16.08t-5.55-16.06Zm-14.9 28.89c-5.87 0-10.63-5.5-10.63-12.27s4.76-12.27 10.63-12.27 10.64 5.5 10.64 12.27-4.77 12.27-10.64 12.27Z"
      style={{
        fill: "#007fc4",
      }}
    />
  </svg>
)

export default IconePercentage
