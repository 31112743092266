import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import InfosMinhaConta from './InfosMinhaConta';
import Saque from './Saque';
import Resposta from './Resposta';
import AbaContainer from './AbaContainer';

export const AbaSaque = ({ saquePendente }) => {
  if (saquePendente) {
    return (
      <Resposta
        icone='saque'
        titulo='Seu pedido de saque foi enviado.'
        subtitulo='Seu dinheiro estará na sua conta até o próximo dia útil.'
      />
    );
  }

  return (
    <AbaContainer>
      <Grid container>
        <Grid item xs={false} md={1} lg={2} />
        <Grid item xs={12} md={3} lg={3}>
          <InfosMinhaConta />
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Saque />
        </Grid>
      </Grid>
    </AbaContainer>
  );
};

AbaSaque.propTypes = {
  saquePendente: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  saquePendente: state.minhaConta.saquePendente,
});

export default connect(mapStateToProps)(AbaSaque);
