import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ children, auth, ...rest }) {
  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => (auth === true
        ? children
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
    />
  );
}
