import Api from '../../Api';
import { CriaPedidoAntecipacaoDto } from './types';

const getPedidoAndamento = () => Api.get('pedido-andamento');

const postPedidosAntecipacoes = (pedido: CriaPedidoAntecipacaoDto) =>
  Api.post('pedidos_antecipacoes', pedido);

const patchFinalizaPedido = (props: {
  valor_devolucao: number;
  conta_bancaria_id: number;
}) => Api.patch('finalizacao-pedido-andamento', props);

const patchCancelaPedido = () => Api.patch('cancelar-pedido-andamento');

export {
  getPedidoAndamento,
  postPedidosAntecipacoes,
  patchFinalizaPedido,
  patchCancelaPedido,
};
