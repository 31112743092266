/* eslint-disable camelcase */
import { getMovimentacoes, postRegularizar, postSolicitarRegularizacao } from './api';
import * as types from './types';
import { formatarDataBRparaUSA } from '../../utils/data'

export const fetchMovimentacoesRequest = () => ({
  type: types.FETCH_MOVIMENTACOES_REQUEST,
});

export const fetchMovimentacoesSuccess = ({ movimentacoes }) => ({
  type: types.FETCH_MOVIMENTACOES_SUCCESS,
  movimentacoes,
});

export const fetchMovimentacoes = () => async (dispatch, getState) => {
  const { limiteDias } = getState().conta;
  try {
    dispatch(fetchMovimentacoesRequest());

    const res = await getMovimentacoes(limiteDias);
    const movimentacoes = res?.data?.data?.data;
    if (!movimentacoes) {
      throw new Error('Sem movimentações');
    }
    dispatch(fetchMovimentacoesSuccess({ movimentacoes }));
  }
  catch (err) {
    console.log('erro no tfetchMovimentacoes', err);
  }
};

export const setlimiteDias = (limiteDias) => ({
  type: types.SET_LIMITE_DIAS,
  limiteDias,
});

export const setDataParaRegularizar = (dataParaRegularizar) => ({
  type: types.SET_DATA_PARA_REGULARIZAR,
  dataParaRegularizar,
});

export const fetchValorParaRegularizarRequest = () => ({
  type: types.FETCH_VALOR_PARA_REGULARIZAR_REQUEST,
});

export const fetchValorParaRegularizarSuccess = ({ valorParaRegularizar }) => ({
  type: types.FETCH_VALOR_PARA_REGULARIZAR_SUCCESS,
  valorParaRegularizar,
});

export const fetchValorParaRegularizarFailure = (error) => ({
  type: types.FETCH_VALOR_PARA_REGULARIZAR_ERROR,
  error,
});

export const fetchValorParaRegularizar = () => (
  async (dispatch, getState) => {
    dispatch(fetchValorParaRegularizarRequest());

    const state = getState();
    const dataParaRegularizar = state.conta.dataParaRegularizar;
    const dataFormatada = formatarDataBRparaUSA(dataParaRegularizar)
    const payload = {
      dataFormatada,
    };
    try {
      const res = await postRegularizar(payload);
      const dadosRegularizacao = res?.data?.data;
      const { valor } = dadosRegularizacao;
      const data = {
        valorParaRegularizar: valor,
      };
      dispatch(fetchValorParaRegularizarSuccess(data));
    }
    catch (error) {
      const data = error?.response?.data?.message;
      dispatch(fetchValorParaRegularizarFailure(data));
    }
  }
);

export const enviarSolicitacaoRegularizacaoRequest = () => ({
  type: types.ENVIAR_SOLICITACAO_REGULARIZACAO_REQUEST,
});

export const enviarSolicitacaoRegularizacaoSuccess = () => ({
  type: types.ENVIAR_SOLICITACAO_REGULARIZACAO_SUCCESS,
});

export const enviarSolicitacaoRegularizacaoFailure = (error) => ({
  type: types.ENVIAR_SOLICITACAO_REGULARIZACAO_ERROR,
  error,
});

export const enviarSolicitacaoRegularizacao = () => (
  async (dispatch, getState) => {
    dispatch(enviarSolicitacaoRegularizacaoRequest());

    const state = getState();
    const { dataParaRegularizar, valorParaRegularizar } = state.conta;
    const dataFormatada = formatarDataBRparaUSA(dataParaRegularizar)

    const payload = {
      dataFormatada,
      valorParaRegularizar,
    };

    try {
      await postSolicitarRegularizacao(payload);
      dispatch(enviarSolicitacaoRegularizacaoSuccess());
    }
    catch (error) {
      const data = error?.response?.data?.message;
      dispatch(enviarSolicitacaoRegularizacaoFailure(data));
    }
  }
);

export const dispensarNotificacaoDeSucesso = () => ({
  type: types.DISPENSAR_NOTIFICACAO_DE_SUCESSO,
});
