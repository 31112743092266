import { Reducer } from 'redux';

import {
  AntecipacaoActionTypes as Types,
  AntecipacaoState,
  StatusAntecipacao,
  VENCIMENTO_INICIAL,
} from './types';

export const INITIAL_STATE: AntecipacaoState = {
  sacado: null,
  valorNotaFiscal: 0,
  nomeArquivoNotaFiscal: '',
  erroUploadArquivo: '',
  carregandoArquivo: false,
  parcelas: [VENCIMENTO_INICIAL],
  somatoriaValorDigitado: 0,
  status: StatusAntecipacao.naoEnviado,
};

const antecipacao: Reducer<AntecipacaoState, any> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case Types.SET_DADOS_ANTECIPACAO:
      return {
        ...state,
        ...action.antecipacao,
      };
    case Types.ENVIA_ARQUIVO_REQUEST: {
      return {
        ...state,
        erroUploadArquivo: '',
        carregandoArquivo: true,
      };
    }
    case Types.ENVIA_ARQUIVO_SUCCESS:
      return {
        ...state,
        idArquivoNotaFiscal: action.idArquivoNotaFiscal,
        carregandoArquivo: false,
      };
    case Types.ENVIA_ARQUIVO_ERROR:
      return {
        ...state,
        idArquivoNotaFiscal: null,
        carregandoArquivo: false,
        erroUploadArquivo: action.error,
      };
    case Types.ALTERAR_PARCELAS:
      return {
        ...state,
        parcelas: action.parcelas,
      };
    case Types.ALTERAR_SOMATORIA:
      return {
        ...state,
        somatoriaValorDigitado: action.novaSoma,
      };
    default:
      return state;
  }
};

export default antecipacao;
