import { FormHelperText } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import { RootState } from '../../../../store';
import { setArquivoNotaFiscal } from '../../../../store/antecipacao/actions';
import { queries } from '../../../../utils/breakpoints';
import IconeNotaFiscal from '../../../IconesSvg/IconeNotaFiscal';
import RoundButton from '../../../RoundButton';

const StyleNotaFiscal = styled.div`
  width: 18.6vw;
  margin-top: 0.5vw;

  .botao-nota {
    line-height: 1.2vw;
    border-radius: 4px;
    padding: 0.7vw 1.2vw 0.8vw;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
    font-size: 0.96vw;
    width: 100%;
    justify-content: flex-start;
  }

  .icone-nota {
    font-size: 2.8vw;
  }

  .MuiButton-startIcon {
    margin-right: 0.6vw;
  }

  .nome-arquivo {
    font-size: 0.8vw;
    margin-top: 0.5em;
    margin-bottom: 0;
  }

  .Mui-error {
    font-size: 0.9vw;
  }

  @media ${queries.mobileDown} {
    width: 62.4%;
    margin-top: 3.9vw;

    .botao-nota {
      line-height: 1.2;
      border-radius: 1vw;
      padding: 2.1vw 3vw 2.2vw;
      font-size: 3.3vw;
    }

    .icone-nota {
      font-size: 9vw;
    }

    .MuiButton-startIcon {
      margin-right: 1.8vw;
    }

    .nome-arquivo {
      font-size: 3.1vw;
    }

    .Mui-error {
      font-size: 2.2vw;
    }
  }

  @media ${queries.desktopUp} {
    width: 300px;
    margin-top: 10px;

    .botao-nota {
      line-height: 18px;
      padding: 12px 15px;
      font-size: 16px;
    }
    .icone-nota {
      font-size: 45px;
    }

    .nome-arquivo {
      font-size: 1rem;
    }

    .Mui-error {
      font-size: 12px;
    }
  }
`;

const AnexarNota = ({
  nomeArquivoNotaFiscal,
  erroUploadArquivo,
  setArquivoNotaFiscal,
}) => {
  const [openAnexarArquivos, setOpenAnexarArquivos] = useState(false);

  const handleClickOpenAnexarArquivos = () => {
    setOpenAnexarArquivos(true);
  };

  const onCloseAnexarArquivos = () => {
    setOpenAnexarArquivos(false);
  };

  const handleAnexarArquivosSave = (files) => {
    const arquivo = files[0];
    setArquivoNotaFiscal(arquivo);
    onCloseAnexarArquivos();
  };

  return (
    <StyleNotaFiscal>
      <RoundButton
        className='botao-nota'
        value={
          <span>
            Anexar a Nota
            <br /> Fiscal ou Contrato
          </span>
        }
        color='primary'
        onClick={handleClickOpenAnexarArquivos}
        startIcon={<IconeNotaFiscal className='icone-nota' />}
      />
      <p className='nome-arquivo'>{nomeArquivoNotaFiscal}</p>
      <FormHelperText error>{erroUploadArquivo}</FormHelperText>
      <DropzoneDialog
        dialogTitle=''
        data-cy='anexar-arquivo'
        cancelButtonText='Cancelar'
        submitButtonText='Enviar'
        previewText='Pré-visualização'
        dropzoneText='Arraste e solte a nota fiscal / contrato ou clique aqui'
        open={openAnexarArquivos}
        onSave={(files) => handleAnexarArquivosSave(files)}
        filesLimit={1}
        acceptedFiles={[
          'image/jpeg',
          'image/png',
          'image/bmp',
          'application/pdf',
        ]}
        showPreviews
        maxFileSize={6000000}
        onClose={onCloseAnexarArquivos}
        getFileAddedMessage={(filename) =>
          `Arquivo ${filename} adicionado com sucesso.`
        }
        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
          let message = `Arquivo ${rejectedFile.name} não é válido. `;
          if (!acceptedFiles.includes(rejectedFile.type)) {
            message += 'Tipo de arquivo não é suportado.';
          }
          if (rejectedFile.size > maxFileSize) {
            message += 'Arquivo muito grande. O tamanho máximo é 6 MB.';
          }
          return message;
        }}
        alertSnackbarProps={{
          autoHideDuration: 10000,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        }}
      />
    </StyleNotaFiscal>
  );
};

const mapStateToProps = (state: RootState) => ({
  nomeArquivoNotaFiscal: state.antecipacao.nomeArquivoNotaFiscal,
  erroUploadArquivo: state.antecipacao.erroUploadArquivo,
});

const mapDispatchToProps = (dispatch, getState) =>
  bindActionCreators(
    {
      setArquivoNotaFiscal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnexarNota);
