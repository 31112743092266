import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { scroller } from 'react-scroll';
import Details from './Details';
import Footer from '../../components/Footer';
import DuvidasFrequentes from './DuvidasFrequentes';
import Simulacao from './Simulacao';
import BannerPrincipal from './Banner';
import FaleConosco from './FaleConosco';
import ContentWrap from '../../components/ContentWrap';
import { fetchConfiguracoesIfNeeded as fetchConfiguracoesIfNeededAction } from '../../store/configuracoes/actions';
import Depoimentos from './Depoimentos';
import SecaoRecabaAntes from './RecebaAntes';
import SecaoParceriaSebrae from './SecaoParceriaSebrae';

const Home = ({ location: { hash }, fetchConfiguracoesIfNeeded }) => {
  useEffect(() => {
    fetchConfiguracoesIfNeeded()
    if (!hash) {
      return;
    }

    const elementName = hash.replace('#', '');
    scroller.scrollTo(elementName, { smooth: true });
  }, [hash, fetchConfiguracoesIfNeeded])

  return (
    <>
      <ContentWrap>
        <BannerPrincipal />
        <Details />
        <Simulacao />
        <SecaoRecabaAntes />
        <SecaoParceriaSebrae />
        <Depoimentos />
        <DuvidasFrequentes />
        <FaleConosco />
      </ContentWrap>
      <Footer />
    </>
  );
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchConfiguracoesIfNeeded: fetchConfiguracoesIfNeededAction,
}, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(Home));
