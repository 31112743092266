import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledDica = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0,0,0,.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: theme.palette.common.white,
  },
}))(Tooltip);

interface Props {
  dica: string | object;
  children: React.ReactElement;
  onChildrenClick?: () => void;
  placement?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
}

const Dica = ({
  dica,
  children,
  onChildrenClick = () => {},
  placement = 'top',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleChildrenClick = () => {
    setIsOpen(true);
    onChildrenClick();
  };
  const onClose = () => setIsOpen(false);
  const clonedChildren = React.cloneElement(children, {
    onClick: handleChildrenClick,
  });

  return (
    <StyledDica
      open={isOpen}
      title={dica}
      arrow
      disableFocusListener
      leaveTouchDelay={10000}
      placement={placement}
      onClose={onClose}
      onMouseEnter={handleChildrenClick}
      onMouseLeave={onClose}
    >
      {clonedChildren}
    </StyledDica>
  );
};

export default Dica;
