export const selectTipoEmpresaMEI = (state) => {
  const { tipoEmpresa } = state.minhaConta;
  const empresaMEI = tipoEmpresa === 'MEI'

  return empresaMEI;
};

export default {
  selectTipoEmpresaMEI,
};
