import { Divider } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../store';
import { selectValorFooter } from '../../../store/pedidoAntecipacao/selectors';
import { StatusPedidoAntecipacao as Status } from '../../../store/pedidoAntecipacao/types';
import Dica from '../../Dica';
import FormatadorDeMoeda from '../../FormatadorDeMoeda';
import StyledFooterPedido from './FooterPedido.style';
import HelpIcon from '@material-ui/icons/Help';

const FooterPedido = ({
  status,
  valor,
  jurosOperacao,
  iof,
  jurosAcumulados,
  valorBoletosPendentes,
  saldo,
  valorAprovado,
  valorDevolucao,
}) => {
  const isAguardandoCliente = status === Status.aguardandoCliente;
  const mostrarDebitos = isAguardandoCliente || status === Status.emFinalizacao;
  let textoValor =
    status === Status.emFinalizacao ? 'da operação' : 'solicitado';

  const dicaJurosAcumulados =
    'Esse valor se refere aos juros acumulados até a data de hoje na sua conta do Crédito Rotativo';

  const Linha = ({ texto, valor, dica = '' }) => (
    <div className='linha'>
      <span className='texto'>
        {texto}{' '}
        {dica && (
          <Dica dica={dica} placement='right'>
            <HelpIcon />
          </Dica>
        )}
      </span>
      <span className='valor'>
        <FormatadorDeMoeda valor={valor} comSimbolo />
      </span>
    </div>
  );

  return (
    <StyledFooterPedido data-cy='footer-pedido'>
      {mostrarDebitos && (
        <div className='debitos'>
          <div className='valor-container'>
            <Linha texto={`Valor total aprovado`} valor={valorAprovado} />
            <Divider />
          </div>
          <span className='subtitulo'>Valores a debitar:</span>
          <Linha texto='Juros da operação' valor={-jurosOperacao} />
          <Linha texto='IOF' valor={-iof} />
          <Linha
            texto='Juros acumulados (CR)'
            valor={-jurosAcumulados}
            dica={dicaJurosAcumulados}
          />
          <Linha texto='Boletos pendentes (CR)' valor={-valorBoletosPendentes} />
          {status === Status.emFinalizacao && (
            <Linha texto='Devolução' valor={-valorDevolucao} />
          )}
        </div>
      )}
      {isAguardandoCliente ? (
        <div className='valor-container'>
          <Divider className='divider-maior' />
          <Linha texto='Saldo' valor={saldo} />
          <Divider className='divider-maior' />
        </div>
      ) : (
        <div className='valor-container'>
          <Divider className='divider-maior' />
          <Linha texto={`Valor total ${textoValor}`} valor={valor} />
          <Divider className='divider-maior' />
        </div>
      )}
    </StyledFooterPedido>
  );
};

const mapStateToProps = (state: RootState) => ({
  status: state.pedidoAntecipacao.status,
  valor: selectValorFooter(state),
  jurosOperacao: state.pedidoAntecipacao.jurosOperacao,
  iof: state.pedidoAntecipacao.iof,
  jurosAcumulados: state.pedidoAntecipacao.jurosAcumulados,
  valorBoletosPendentes: state.pedidoAntecipacao.valorBoletosPendentes,
  saldo: state.pedidoAntecipacao.saldo,
  valorAprovado: state.pedidoAntecipacao.valorAprovado,
  valorDevolucao: state.pedidoAntecipacao.valorDevolucao,
});

export default connect(mapStateToProps)(FooterPedido);
