import { TableBody, TableCell, TableRow } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';

import { CINZA_600 } from '../../../utils/color';

const InitialStateCell = styled(TableCell)({
  padding: '6rem 0',
  textAlign: 'center',
  color: CINZA_600,
  fontSize: 16,
});

const EstadoInicial = () => {
  return (
    <TableBody>
      <TableRow>
        <InitialStateCell colSpan={6}>
          Seus boletos aparecerão aqui
        </InitialStateCell>
      </TableRow>
    </TableBody>
  );
};

export default EstadoInicial;
