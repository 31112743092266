import React from 'react';
import styled from 'styled-components';
import { queries } from '../../utils/breakpoints';

const SityledContainer = styled.div`
  padding: 0 3.9vw 0 2.8vw;

  @media ${queries.desktopUp} {
    padding: 0 36px 0 50px;
  }

  @media ${queries.mobileDown} {
    padding: 0 5.7vw;
  }
`;

const ContainerLateral: React.FC = ({ children }) => {
  return <SityledContainer>{children}</SityledContainer>;
};

export default ContainerLateral;
