import { Grid, useMediaQuery } from '@material-ui/core';
import { uniqueId } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Title from '../../../components/Home/Title';
import ItemDepoimento from './ItemDepoimento';
import DepoimentoJozi from '../../../assets/depoimento-jozi.jpeg';
import DepoimentoRaquel from '../../../assets/depoimento-raquel.jpeg';
import DepoimentoElisabete from '../../../assets/depoimento-elisabete.jpeg';
import MobileStepper from '../../../components/MobileStepper';
import useMobileStepper from '../../../hooks/useMobileStepper';

const StyledDepoimentos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;

  @media (max-width: 599px) {
    padding: 8vw;
  }
`;

const TitulosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;

  h2 {
    margin: 0.5rem;
    height: 2.3vw;
    font-size: 2.9vw;
    line-height: 1.5vw;
  }

  @media (max-width: 599px) {
    h2 {
        height: 6vw;
        font-size: 7vw;
        line-height: 4vw;
      }
  }
`;

const Depoimentos = () => {
  const isMobile = useMediaQuery('(max-width:599px)');

  const items = [
    {
      nome: 'ELISABETE',
      empresa: 'Corretora de imóveis',
      depoimento:
        'Estou <b>MUITO SATISFEITA</b> por ter esta modalidade em CRÉDITO, para me garantir entre uma comissão e outra.',
      imagem: DepoimentoElisabete,
      numero: 1
    },
    {
      nome: 'RAQUEL',
      empresa: 'Esmalteria Raquel Coelho',
      depoimento:
        'Estava precisando de caixa para fechar um negócio, quando uma amiga me indicou o Crédito Rotativo. Foi a <b>SOLUÇÃO PERFEITA</b>.',
      imagem: DepoimentoRaquel,
      numero: 2
    },
    {
      nome: 'JOZI',
      empresa: 'Estética Mari',
      depoimento:
        'Atendimento atencioso, <b>SEM BUROCRACIA</b> na aprovação, atendimento em todos os detalhes, inclusive no cadastro.',
      imagem: DepoimentoJozi,
      numero: 3
    },
  ];

  const { swipeableRef, activeStep, setActiveStep } = useMobileStepper(items.length)

  return (
    <StyledDepoimentos name='depoimentos' id='depoimentos'>
      <TitulosContainer>
        <Title>veja o que os nossos</Title>
        <Title>clientes dizem</Title>
      </TitulosContainer>
      <Grid container ref={swipeableRef}>
        {items.map((item) => (
          <ItemDepoimento
            key={uniqueId('depoimento-item-')}
            item={item}
            activeStep={isMobile && activeStep}
          />
        ))}
      </Grid>

      <MobileStepper
        isMobile={isMobile}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        numOfSteps={items.length}
        hideText
      />
    </StyledDepoimentos>
  );
};

export default Depoimentos;
