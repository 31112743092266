import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../store';
import EstadoVazio from './EstadoVazio';
import ItemLista from './ItemLista';
interface Props extends ConnectedProps<typeof connector> {}

const ListaMobile = ({ boletos }: Props) => {
  if (boletos.length === 0) {
    return <EstadoVazio />;
  }

  return (
    <div>
      {boletos.map((boleto) => (
        <ItemLista boleto={boleto} key={boleto.id} />
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  boletos: state.recebaAntes.boletos,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ListaMobile);
