import React from 'react';

interface FormatadorDeMoedaProps {
  valor: string | number;
  comSimbolo?: boolean;
}

const FormatadorDeMoeda = ({
  valor,
  comSimbolo = false,
}: FormatadorDeMoedaProps) => {
  const valorAsNumber = +(valor || '').toString().replace(',', '');
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  if (comSimbolo) {
    options.style = 'currency';
    options.currency = 'BRL';
  }

  return <>{valorAsNumber.toLocaleString('pt-br', options)}</>;
};

export default FormatadorDeMoeda;
