import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';

const descricao = 'Dentro do seu limite, você pode realizar quantos saques precisar.'

const EtapaTres = ({ children }) => (
  <Base
    proximaEtapa={4}
    etapaAnterior={2}
    descricao={descricao}
    etapaAtual={3}
  >
    {children}
  </Base>
);

EtapaTres.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EtapaTres;
