import React, { useEffect, useState } from 'react';
import { useMediaQuery, Button, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as ReactScrollLink } from 'react-scroll';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as AuthActions from '../store/auth/sagas';
import { AZUL_300, BRANCO, CINZA_700 } from '../utils/color';
import ItemMenuAutenticado from './ItemMenuAutenticado';
import Logo from './Logo';
import ItemMenuAutenticadoMobile from './ItemMenuAutenticadoMobile';
import SocialMediaIcons from './SocialMediaIcons';

const MenuContainer = styled.div`
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1;

  .logo-mobile {
    padding: 1.4rem 1rem;
  }

  .logo-container {
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) > a {
      padding: 0;
      border-bottom: 0;
    }
  }

  .exit-button {
    flex: 1;
    padding: 1.1em 0;
    font-size: 1rem;
    text-align: left;
    text-transform: initial;
    color: ${CINZA_700};

    > .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .social-icons {
    display: flex;
    justify-content: flex-start;

    > a {
      flex: 0;
    }
  }

  .icone-menu {
    color: ${AZUL_300};
    cursor: pointer;
    font-size: 3.2rem;
    z-index: 4;
    padding: 10px;
    background-color: #fff;
    border-radius: 50%;
    position: fixed;
    right: 1.4rem;
    top: 1rem;

    @media (min-width: 1000px) {
      display: none;
    }
  }

  @media (min-width: 1000px) {
    position: unset;
  }
`;

export const StyledMenu = styled.ul`
  background-color: ${BRANCO};
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: 0;
  padding: 2.8em;
  padding-top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  top: 0;
  left: 100vw;
  position: fixed;
  transition: left .25s ease-out;
  ${(props) => props.visible && `
    left: 0;
  `}

  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 999px) {
    justify-content: flex-start;
    align-items: flex-start;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  li {
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    font-size: .8rem;
    width: 100%;

    & > a {
      padding: 2.5em 0;
      color: ${CINZA_700};
      text-transform: uppercase;
    }

    @media (max-width: 999px) {
      flex-direction: row;
      text-align: left;

      & > a {
        font-size: 1rem;
        font-weight: normal;
        padding: 1.1em 0;
        text-transform: initial;
        flex: 1;

        & > .social-media-icon {
          margin: 0 6px;
        }
      }

      &:not(:last-child) > a {
        border-bottom: 1px solid #c1c1c1;
      }
    }
  }

  @media (min-width: 1000px) {
    position: relative;
    left: 0;
    box-shadow: none;
    height: auto;
    width: auto;
    padding: 0 0 0 1em;
    background-color: transparent;
    background-image: none;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
  }

  @media (min-width: 1460px) {
    padding: 0 0 0 1em;
    font-size: 1rem;
  }
`;

const ScrollLink = styled(ReactScrollLink)`
  cursor: pointer;
`;

const Menu = ({ signOut, isSignedIn }) => {
  const [visible, setVisible] = useState(false);
  const [pageYOffset, setPageYOffset] = useState(0);
  const isMobile = useMediaQuery('(max-width: 999px)');

  const IconeMenu = (props) =>
    !visible ? (
      <MenuIcon
        style={iconStyle}
        className='icone-menu'
        onClick={() => setVisible(!props.visible)}
      />
    ) : (
      <CloseIcon
        style={iconStyle}
        className='icone-menu'
        onClick={() => setVisible(!props.visible)}
      />
    );

  useEffect(() => {
    window.onscroll = () => {
      setPageYOffset(window.pageYOffset);
    };

    return () => {
      window.onscroll = null;
    }
  }, []);

  let iconStyle = { boxShadow: 'none' };
  if (pageYOffset > 100 && !visible) {
    iconStyle = { boxShadow: '2px 2px 4px 4px rgba(0,0,0,.075)' };
  }

  return (
    <MenuContainer>
      <Hidden mdUp>
        <div className="logo-mobile">
          <Logo width={128} />
        </div>
      </Hidden>
      <StyledMenu visible={visible} onClick={() => setVisible(!visible)}>

          <li className='logo-container'>
            <Link to='/'>
              <Logo width={205} />
            </Link>
          </li>

        <ItemMenuAutenticadoMobile />

        <li>
          <ScrollLink
            to='comofunciona'
            onClick={() => setVisible(!visible)}
            smooth
          >
            Como funciona
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to='simulacao'
            onClick={() => setVisible(!visible)}
            smooth
          >
            Simulação
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to='faleconosco'
            onClick={() => setVisible(!visible)}
            smooth
          >
            Fale conosco
          </ScrollLink>
        </li>
        <li>
          <ScrollLink
            to='duvidasfrequentes'
            onClick={() => setVisible(!visible)}
            smooth
          >
            Dúvidas frequentes
          </ScrollLink>
        </li>
        <li>
          <Link to='/minhaconta'>Minha conta</Link>
        </li>

        {isSignedIn && isMobile && (
          <li>
            <Button className='exit-button' onClick={signOut}>Sair</Button>
          </li>
        )}

        {!isMobile && <ItemMenuAutenticado color='black'/>}

        {isMobile && (
          <li className='social-icons'>
            <SocialMediaIcons className='social-media-icon' />
          </li>
        )}
      </StyledMenu>

      <IconeMenu visible={visible} />
    </MenuContainer>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
