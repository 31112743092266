import { Link, Snackbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../store';
import {
  dispersarMensagemErro,
  fetchPedidoEmAndamento,
} from '../../../store/pedidoAntecipacao/actions';
import { StatusPedidoAntecipacao as Status } from '../../../store/pedidoAntecipacao/types';
import { selectHaPedidoEmAndamento } from '../../../store/recebaAntes/selectors';
import Progresso from '../../Progresso';
import Antecipacao from './ListaAntecipacoes/Antecipacao';
import BotoesInfoPedido from './BotoesInfoPedido';
import ValoresFinalizacaoPedido from './FinalizacaoPedido/ValoresFinalizacaoPedido';
import FooterPedido from './FooterPedido';
import { InfoPedidoStyle } from './InfoPedidoStyle';
import ValidadeProposta from './ValidadeProposta';
import ContasBancariasRecebaAntes from './FinalizacaoPedido/ContasBancariasRecebaAntes';
import AguardandoAssinatura from './AguardandoAssinatura';
import { Alert } from '@material-ui/lab';

const InfoPedido = ({
  lista,
  haPedidoEmAndamento,
  fetchPedido,
  statusPedido,
  idPedido,
  carregando,
  mensagemErro,
  dispersarMensagemErro,
}) => {
  useEffect(() => {
    fetchPedido();
  }, [haPedidoEmAndamento, fetchPedido]);

  if (carregando) {
    return <Progresso />;
  }

  if (statusPedido === Status.aguardandoAssinatura) {
    return <AguardandoAssinatura />;
  }

  return (
    <InfoPedidoStyle>
      <Snackbar
        open={!!mensagemErro}
        onClose={dispersarMensagemErro}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity='error'>{mensagemErro}</Alert>
      </Snackbar>
      <div className='header-info-pedido'>
        <h1 className='titulo'>Seu pedido</h1>
        <div className='subtitulo'>
          <strong>
            {statusPedido === Status.emFinalizacao ? 'Finalização' : 'Resumo'}
          </strong>
          Operação {idPedido ? `${idPedido || ''}`.padStart(2, '0') : ''}
        </div>
      </div>
      <div className='lista-botoes-container'>
        <div className='lista'>
          {statusPedido !== Status.emFinalizacao ? (
            lista.map((item, index) => (
              <Antecipacao antecipacao={item} index={index} key={index} />
            ))
          ) : (
            <ValoresFinalizacaoPedido />
          )}
          <FooterPedido />
          <ContasBancariasRecebaAntes />
          <ValidadeProposta />
        </div>
        <BotoesInfoPedido />
      </div>
      {statusPedido === Status.pendente && (
        <Link href='/minhaconta/recebaantes' className='link-pagina-inicial'>
          Página inicial
        </Link>
      )}
    </InfoPedidoStyle>
  );
};

const mapStateToProps = (state: RootState) => ({
  lista: state.pedidoAntecipacao.antecipacoes,
  haPedidoEmAndamento: selectHaPedidoEmAndamento(state),
  statusPedido: state.pedidoAntecipacao.status,
  idPedido: state.pedidoAntecipacao.id,
  carregando: state.pedidoAntecipacao.carregando,
  mensagemErro: state.pedidoAntecipacao.mensagemErro,
});

const mapDispatchToProps = (dispatch, getState) =>
  bindActionCreators(
    {
      fetchPedido: fetchPedidoEmAndamento,
      dispersarMensagemErro,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InfoPedido);
