export const ABRIR_MODAL_ADICIONAR = 'contaBancaria/ABRIR_MODAL_ADICIONAR';
export const FECHAR_MODAL_ADICIONAR = 'contaBancaria/FECHAR_MODAL_ADICIONAR';
export const FETCH_BANCOS_REQUEST = 'contaBancaria/FETCH_BANCOS_REQUEST';
export const FETCH_BANCOS_SUCCESS = 'contaBancaria/FETCH_BANCOS_SUCCESS';
export const SET_BANCO_ID = 'contaBancaria/SET_BANCO_ID';
export const SET_AGENCIA = 'contaBancaria/SET_AGENCIA';
export const SET_CONTA = 'contaBancaria/SET_CONTA';
export const SOLICITAR_TOKEN_CONTA_BANCARIA_REQUEST = 'contaBancaria/SOLICITAR_TOKEN_CONTA_BANCARIA_REQUEST';
export const SOLICITAR_TOKEN_CONTA_BANCARIA_SUCCESS = 'contaBancaria/SOLICITAR_TOKEN_CONTA_BANCARIA_SUCCESS';
export const SOLICITAR_TOKEN_CONTA_BANCARIA_ERROR = 'contaBancaria/SOLICITAR_TOKEN_CONTA_BANCARIA_ERROR';
export const ENVIAR_CONTA_BANCARIA_REQUEST = 'contaBancaria/ENVIAR_CONTA_BANCARIA_REQUEST';
export const ENVIAR_CONTA_BANCARIA_SUCCESS = 'contaBancaria/ENVIAR_CONTA_BANCARIA_SUCCESS';
export const ENVIAR_CONTA_BANCARIA_ERROR = 'contaBancaria/ENVIAR_CONTA_ERROR';
export const DISPENSAR_NOTIFICACAO_DE_SUCESSO = 'contaBancaria/DISPENSAR_NOTIFICACAO_DE_SUCESSO';
export const FETCH_CONTAS_REQUEST = 'contaBancaria/FETCH_CONTAS_REQUEST';
export const FETCH_CONTAS_SUCCESS = 'contaBancaria/FETCH_CONTAS_SUCCESS';
export const DELETE_CONTA_BANCARIA_REQUEST = 'contaBancaria/DELETE_CONTA_BANCARIA_REQUEST';
export const DELETE_CONTA_BANCARIA_SUCCESS = 'contaBancaria/DELETE_CONTA_BANCARIA_SUCCESS';
export const DELETE_CONTA_BANCARIA_ERROR = 'contaBancaria/DELETE_CONTA_BANCARIA_ERROR';
export const SET_TIPO = 'contaBancaria/SET_TIPO';
export const CONFIRMAR_CELULAR_REQUEST = 'contaBancaria/CONFIRMAR_CELULAR_REQUEST';
export const CONFIRMAR_CELULAR_SUCCESS = 'contaBancaria/CONFIRMAR_CELULAR_SUCCESS';
export const CONFIRMAR_CELULAR_ERROR = 'contaBancaria/CONFIRMAR_CELULAR_ERROR';
