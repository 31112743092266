import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import RoundButton from '../../../components/RoundButton';
import { analyticsEvents } from '../../../utils/analytics';

export const BotaoCadastro = ({ isSignedIn }) => {
  const isMobile = useMediaQuery('(max-width: 540px)');

  return isMobile && !isSignedIn ? (
    <div style={{ paddingTop: '65px'}}>
      <Link
        to='/cadastro'
        smooth
        style={{ position: 'absolute', bottom: '105px' }}
      >
        <RoundButton
          blockXS
          value='Cadastre-se'
          color='primaryGradient'
          bold
          style={{ textTransform: 'uppercase'}}
          onClick={() => { analyticsEvents('click_cadastro')}}
        />
      </Link>
    </div>
    ) : (
      null
    )
  }

  BotaoCadastro.propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
  };

  const mapStateToProps = (state) => ({
    isSignedIn: state.auth.isSignedIn,
  });

  export default connect(mapStateToProps)(BotaoCadastro);
