import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Button, Link } from '@material-ui/core';
import { AZUL_400, AZUL_300, CINZA_600 } from '../../../../utils/color';
import Icone from '../../../../components/Icone';

const StyledCard = styled(Link)`
  border: 2px solid ${AZUL_400};
  margin: 3px;
  display: flex;
  cursor: pointer;
  .img-container {
    min-width: 60px;
    margin: 10px;
    display: none;
    align-items: center;
    justify-content: center;
  }
  .titulo {
    padding: 10px 10px 0px;
    flex-direction: row;
    display: flex;
    align-items: center;
    img {
      display: block;
      margin-right: 5px;
      width: 60px;
    }
  }
  .botao {
    text-transform: none;
    color: white;
    font-weight: bold;
    font-size: .8rem;
    width: 100%;
    border-radius: 0;
    background-image: linear-gradient(to right, ${AZUL_400}, ${AZUL_300});
  }

  @media (min-width: 960px) {
    margin: 20px 0;
    border: 0px;
    .img-container {
      display: flex;
    }
    .titulo {
      padding: 0px;
      min-height: auto;
      img {
        display: none;
        margin-right: 1em;
      }
    }
    .botao {
      background-image: none;
      background-color: ${AZUL_400};
      border-radius: 5px;
      font-size: 1rem;
    }
  }
`;

const H4Azul = styled.h4`
  color: ${AZUL_400};
  margin: 0;
  line-height: 1.2em;
  font-size: .7rem;
  @media (min-width: 408px) {
    font-size: .8rem;
  }
  @media (min-width: 600px) {
    font-size: 1rem;
  }
`;

const TextoCinza = styled.p`
  color: ${CINZA_600};
  padding: 10px;
  margin: 0;
  font-size: .7rem;
  @media (min-width: 400px) {
    font-size: .8rem;
  }
  @media (min-width: 600px) {
    font-size: 1rem;
  }
  @media (min-width: 800px) {
    font-size: .9rem;
    padding: 5px 0;
  }
`;

const CardAzul = forwardRef(({ titulo, icone, descricao, textoBotao , onClick }, ref) => {
  return (
    <StyledCard ref={ref} onClick={onClick}>
      <div className='img-container'>
        <Icone name={icone} />
      </div>
      <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
        <div className='titulo' style={{}}>
          <Icone name={icone} />
          <H4Azul>{titulo}</H4Azul>
        </div>
        <div>
          <TextoCinza className='descricao'>{descricao}</TextoCinza>
        </div>
        <Button onClick={onClick} className='botao'>{textoBotao}</Button>
      </div>
    </StyledCard>
  );
});

export default CardAzul;
