import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import RoundButton from '../../components/RoundButton';
import { enviarDocumentos } from '../../store/minhaConta/actions';

const StyledConteudoEtapaDois = styled.div`
  .enviar-documentos button {
    padding: 10px 25px;
    white-space: nowrap;
  }

  @media (max-width: 600px) {
   .enviar-documentos span {
    font-size: 12px;
   }
  }
`;

export const ConteudoEtapaDois = ({ etapaCadastro, enviandoDocumentos, onEnviarClick }) => (
  <StyledConteudoEtapaDois>
    { etapaCadastro === 1 ? (
      <div>
        <RoundButton
          value='Já li o contrato'
          color='primary'
          bold='bold'
          size='small'
          onClick={onEnviarClick}
          loading={enviandoDocumentos}
        />
      </div>
    ) : <br /> }
  </StyledConteudoEtapaDois>
);

const mapStateToProps = (state) => ({
  etapaCadastro: state.minhaConta.etapaCadastro,
  enviandoDocumentos: state.minhaConta.enviandoDocumentos,
});

ConteudoEtapaDois.propTypes = {
  etapaCadastro: PropTypes.number.isRequired,
  enviandoDocumentos: PropTypes.bool.isRequired,
  onEnviarClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ onEnviarClick: enviarDocumentos }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConteudoEtapaDois);
