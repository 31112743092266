import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import { AZUL_300, CINZA_400 } from '../../../../utils/color';

export const ParcelasBoletosStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .titulo {
    color: ${AZUL_300};
    font-size: 1.15vw;
  }

  .boletos-container {
    display: flex;
    flex-direction: column;
    border: 1px solid ${CINZA_400};
    width: 23vw;
    border-radius: 5px;
    padding: 0vw 2.3vw 1.7vw;
  }

  .botao-adicionar {
    font-size: 0.8vw;
    padding: 0.6vw 1.4vw;
    margin-top: 1vw;
  }

  .texto-limite {
    font-size: 0.95vw;
    padding: 0.5rem;
  }

  @media ${queries.mobileDown} {
    padding: 0vw 0.5vw 5.3vw;

    .titulo {
      font-size: 4.2vw;
    }

    .boletos-container {
      width: 85%;
      padding: 1.5vw 5.1vw 6.5vw;
    }

    .botao-adicionar {
      font-size: 3.3vw;
      font-weight: bold;
      padding: 2vw 1.4vw;
      margin-top: 3vw;

      svg {
        font-size: 5vw;
      }
    }

    .texto-limite {
      font-size: 3.1vw;
    }
  }

  @media ${queries.desktopUp} {
    .titulo {
      font-size: 16px;
    }
    .boletos-container {
      padding: 0px 30px 22px;
      width: 350px;
    }

    .botao-adicionar {
      font-size: 12px;
      padding: 8px 18px;
      margin-top: 12px;
    }

    .texto-limite {
      font-size: 12px;
    }
  }
`;
