import * as types from './types';
import { toNumber } from '../../utils/number';

const INITIAL_STATE = {
  valorDevolucao: '',
  valorDevolucaoRaw: '',
  error: '',
  enviandoDevolucao: false,
  tipoDevolucao: 'boleto',
};

const devolucao = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_VALOR_DEVOLUCAO:
      return {
        ...state,
        valorDevolucao: action.valorDevolucao,
        valorDevolucaoRaw: toNumber(action.valorDevolucao),
      };
    case types.ENVIAR_DEVOLUCAO_REQUEST:
      return {
        ...state,
        enviandoDevolucao: true,
        error: '',
      };
    case types.ENVIAR_DEVOLUCAO_SUCCESS:
      return {
        ...state,
        valorDevolucao: '',
        enviandoDevolucao: false,
        error: '',
      };
    case types.ENVIAR_DEVOLUCAO_ERROR: {
      const { error } = action;
      return {
        ...state,
        enviandoDevolucao: false,
        error,
      };
    }
    case types.SET_TIPO_DEVOLUCAO:
      return {
        ...state,
        tipoDevolucao: action.tipoDevolucao,
      };
    default:
      return state;
  }
};

export default devolucao;
