import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import InfosMinhaConta from './InfosMinhaConta';
import Movimentacoes from './Conta/Movimentacoes';
import FiltroMovimentacoes from './Conta/FiltroMovimentacoes';
import EntendaMovimentaoes from './Conta/Movimentacoes/EntendaMovimentacoes';
import AbaContainer from './AbaContainer';
import AlertaPendencias from '../../components/AlertaPendencias';

const AbaConta = () => (
  <AbaContainer>
    <AlertaPendencias />
    <Grid container>
      <Grid item xs={12} md={3} lg={3}>
        <InfosMinhaConta />
        <FiltroMovimentacoes />
        <Hidden only={['xs', 'sm']}>
          <EntendaMovimentaoes />
        </Hidden>
      </Grid>
      <Grid item xs={12} md={9} lg={9}>
        <Movimentacoes />
        <Hidden only={['md', 'lg', 'xl']}>
          <EntendaMovimentaoes />
        </Hidden>
      </Grid>
    </Grid>
  </AbaContainer>
);

export default AbaConta;
