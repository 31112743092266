import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../store';
import { setModalFinalizacaoAberto } from '../../../store/pedidoAntecipacao/actions';

import IconePedidoEnviado from '../../IconesSvg/IconePedidoEnviado';
import Modal from '../../Modal';
import { StyledModalPedidoEnvidado } from './StyledModalPedidoEnvidado';

interface ModalPedidoEnviadoProps extends ConnectedProps<typeof connector> {}

const ModalPedidoEnviado = ({
  modalAberto,
  toggleModal,
}: ModalPedidoEnviadoProps) => {
  const ConteudoModal = () => (
    <StyledModalPedidoEnvidado>
      <IconePedidoEnviado />
      <p className='titulo-modal'>Seu pedido foi enviado para aprovação.</p>
      <p className='titulo-modal'>Entraremos em contato por e-mail e SMS.</p>
      <p className='obrigado'>Obrigado por usar o Crédito Rotativo</p>
      <span>
        Qualquer dúvida entre em contato pelo email{' '}
        <span className='email'>atendimento@creditorotativo.com.br</span> ou
        pelo Whatsapp (11) 933-598-394.
      </span>
    </StyledModalPedidoEnvidado>
  );
  return (
    <>
      <Modal
        fullWidth={false}
        maxWidth='sm'
        open={modalAberto}
        conteudo={<ConteudoModal />}
        onClose={() => toggleModal(false)}
        exibirBotao={false}
        icone=''
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  modalAberto: state.pedidoAntecipacao.modalFinalizacaoAberto,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      toggleModal: setModalFinalizacaoAberto,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ModalPedidoEnviado);
