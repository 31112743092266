import { last, head } from 'lodash';

export const selectUltimaMovimentacao = (state) => {
  const { movimentacoes } = state.simulacao;

  return last(movimentacoes);
};

export const selectSaldoDaUltimaMovimentacao = (state) => {
  const ultimaMovimentacao = selectUltimaMovimentacao(state) || { saldo: '0' };

  return ultimaMovimentacao.saldo;
};

export const selectPrimeiraMovimentacao = (state) => {
  const { movimentacoes } = state.simulacao;

  return head(movimentacoes);
};

export const selectValorDaPrimeiraMovimentacao = (state) => {
  const primeiraMovimentacao = selectPrimeiraMovimentacao(state) || { valor: 0 };

  return primeiraMovimentacao.valor;
};

export default {
  selectUltimaMovimentacao,
  selectSaldoDaUltimaMovimentacao,
  selectPrimeiraMovimentacao,
  selectValorDaPrimeiraMovimentacao,
};
