import Api from '../../Api';

export const getBancos = () => (
  Api.get('bancos')
);

export const postSolicitarTokenContaBancaria = ({ bancoId, agencia, conta, tipo }) => (
  Api.post(
    'token/contas_bancarias',
    {
      banco_id: bancoId,
      agencia,
      conta,
      tipo,
    },
  )
);

export const postContaBancaria = ({ bancoId, agencia, conta, tipo, token }) => (
  Api.post(
    'contas_bancarias',
    {
      banco_id: bancoId,
      agencia,
      conta,
      tipo,
      token,
    },
  )
);

export const getContasBancarias = () => (
  Api.get('contas_bancarias')
);

export const deleteContaBancaria = (id) => (
  Api.delete(`contas_bancarias/${id}`)
);

export const postConfirmarCelular = ({ celular, payload: { bancoId, agencia, conta, tipo, cpfcnpj, titularidade }}) => (
  Api.post(`confirmacao-celular-conta-bancaria`, {
    celular,
    contaBancaria: {
      banco_id: bancoId,
      agencia,
      conta,
      tipo,
      cpfcnpj,
      titularidade,
    }
  })
);
