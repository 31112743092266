import * as types from './types';

const INITIAL_STATE = {
  limiteDeCreditoPadrao: 0,
  limiteDeCreditoPadraoFormatado: 0,
  whatsAppAtivo: false,
  linkWhatsApp: null,
  loaded: false,
  error: '',
};

const configuracoes = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FETCH_CONFIGURACOES_REQUEST:
      return {
        ...state,
        error: '',
        loaded: false
      };
    case types.FETCH_CONFIGURACOES_SUCCESS:
      return {
        ...state,
        limiteDeCreditoPadrao: action.limiteDeCreditoPadrao,
        limiteDeCreditoPadraoFormatado: action.limiteDeCreditoPadrao.toLocaleString(
          'pt-br',
          { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL' },
        ),
        whatsAppAtivo: action.whatsAppAtivo,
        linkWhatsApp: action.linkWhatsApp,
        loaded: true,
        error: '',
      };
    case types.FETCH_CONFIGURACOES_ERROR: {
      const { error } = action;
      return {
        ...state,
        loaded: false,
        error,
      };
    };
    default:
      return state;
  };
};

export default configuracoes;
