/* eslint-disable camelcase */
import { getConfiguracoes } from './api';
import * as types from './types';

export const fetchConfiguracoesRequest = () => ({
  type: types.FETCH_CONFIGURACOES_REQUEST,
});

export const fetchConfiguracoesSuccess = ({
  limiteDeCreditoPadrao,
  whatsAppAtivo,
  linkWhatsApp
}) => ({
  type: types.FETCH_CONFIGURACOES_SUCCESS,
  limiteDeCreditoPadrao,
  whatsAppAtivo,
  linkWhatsApp
});

export const fetchConfiguracoesFailure = (error) => ({
  type: types.FETCH_CONFIGURACOES_ERROR,
  error,
});

export const fetchConfiguracoes = () => (
  async (dispatch) => {
    try {
      const res = await getConfiguracoes();
      const configuracoes = res?.data?.data?.data;
      const {
        limite_credito_padrao,
        whatsapp_ativo,
        link_whatsapp
      } = configuracoes;

      const data = {
        limiteDeCreditoPadrao: limite_credito_padrao,
        whatsAppAtivo: whatsapp_ativo,
        linkWhatsApp: link_whatsapp
      };
      dispatch(fetchConfiguracoesSuccess(data));
    }
    catch (error) {
      const data = error?.response?.data?.message;
      dispatch(fetchConfiguracoesFailure(data));
    }
  }
);

export const fetchConfiguracoesIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  const { loaded } = state.configuracoes;

  if (!loaded) {
    return dispatch(fetchConfiguracoes())
  }
}
