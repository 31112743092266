import Api from '../../Api';

const postContatos = ({ usuarioId, nome, email, mensagem }) => (
  Api.post(
    'contatos',
    {
      usuario_id: usuarioId,
      nome,
      email,
      mensagem,
    },
  )
);

export default postContatos;
