import { Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../../store';
import CampoDeMoeda from '../../../CampoDeMoeda';
import CardInfos from '../../../CardInfos';
import FormatadorDeMoeda from '../../../FormatadorDeMoeda';
import { StyledValorNota } from '../../CadastroAntecipacao/NotaFiscal/StyledValorNota';
import { StyledValoresFinalizacaoPedido } from './ValoresFinalizacaoPedido.style';
import { toCurrency, toNumber } from '../../../../utils/number';
import {
  setErroValorDevolvido,
  setValorDevolvido,
} from '../../../../store/pedidoAntecipacao/actions';
import { mostrarSecaoDevolucao } from '../../../../store/pedidoAntecipacao/selectors';

interface ValoresFinalizacaoPedidoProps
  extends ConnectedProps<typeof connector> {}

const ValoresFinalizacaoPedido = ({
  limiteDisponivel,
  limiteTotal,
  valorPendenteCreditoRotativo,
  valorSugerido,
  valorMinimoExigido,
  saldo,
  setValorDevolvido,
  erro,
  setErroValorDevolvido,
  deveMostrarSecao,
}: ValoresFinalizacaoPedidoProps) => {
  const [iniciado, setIniciado] = useState(false);
  const valorSugeridoFormatado = toCurrency(valorSugerido);
  const [valorDevolucao, setValorDevolucao] = useState('0,00');
  // se o valor for zero deixa no placeholder para facilitar o clicar e digitar
  const valorCampoDevolucao = valorDevolucao === '0,00' ? '' : valorDevolucao;
  const valormaximoPossivel =
    saldo > valorPendenteCreditoRotativo ? valorPendenteCreditoRotativo : saldo;
  const textoExtra = valorMinimoExigido
    ? 'Você está negativado. Devolva a quantidade mínima para continuar o processo.'
    : 'Você pode diminuir a quantia que deve devolver ao Cédito Rotativo nessa etapa';

  const getValorDevolucao = (devolucao: string, respeitaValorMinimo=true): number => {
    const valorDigitado = toNumber(devolucao);
    if (valorDigitado < valorMinimoExigido) {
      setErroValorDevolvido(respeitaValorMinimo);
      return respeitaValorMinimo ? valorMinimoExigido : valorDigitado;
    }
    setErroValorDevolvido(false);
    if (valorDigitado <= valormaximoPossivel) {
      return valorDigitado;
    }
    return valormaximoPossivel;
  };

  const handleOnChangeDevolucao = (devolucao) => {
    setValorDevolucao(devolucao);
  };

  const validaValor = (respeitaValorMinimo=true, formatar=false) => {
    const novoValorDevolucao = getValorDevolucao(valorDevolucao, respeitaValorMinimo);
    setValorDevolucao(
      formatar ? toCurrency(novoValorDevolucao) : ''+novoValorDevolucao
    );
    setValorDevolvido(novoValorDevolucao);
  };

  const onStopTyping = () => {
    validaValor(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!iniciado) {
      setIniciado(true);
      validaValor(false,true);
    }
  });

  if (!deveMostrarSecao) return null;

  return (
    <StyledValoresFinalizacaoPedido>
      <Grid container spacing={2}>
        <Grid item>
          <p className='texto-valor-devolvido'>
            Você possui um valor a ser devolvido para a sua conta do Crédito
            Rotativo, gostaria de devolver parte dele agora?
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          className={limiteDisponivel < 0 ? 'limite-negativo' : ''}
        >
          <CardInfos
            titulo='Limite disponível'
            valor={limiteDisponivel}
            tituloRodape='Valor a ser devolvido:'
            textoRodape={
              <FormatadorDeMoeda
                valor={valorPendenteCreditoRotativo}
                comSimbolo
              />
            }
            rodapeExtra={{
              titulo: 'Limite total:',
              texto: <FormatadorDeMoeda valor={limiteTotal} comSimbolo />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <StyledValorNota className={'valor-debitar'}>
            <h1 className='titulo'>Valor de devolução</h1>
            <p className='texto subtitulo'>(Valor sugerido)</p>
            <Hidden smUp>
              <p className='texto'>{textoExtra}</p>
            </Hidden>
            <div className='valor'>
              <span className='cifrao'>R$</span>
              <CampoDeMoeda
                value={valorCampoDevolucao}
                placeholder={valorSugeridoFormatado}
                onChange={handleOnChangeDevolucao}
                dataCy='input-valor-devolucao'
                className='cifrao'
                error={!!erro}
                helperText={erro ? 'Valor mínimo' : ''}
                onStopTyping={onStopTyping}
                onBlur={() => validaValor(true,true)}
              />
            </div>
          </StyledValorNota>
        </Grid>
      </Grid>
    </StyledValoresFinalizacaoPedido>
  );
};

const mapStateToProps = (state: RootState) => ({
  limiteDisponivel: state.minhaConta.limiteDisponivel,
  limiteTotal: state.minhaConta.limiteTotal,
  valorMinimoExigido: state.pedidoAntecipacao.valorMinimoDevolucao,
  valorSugerido: state.pedidoAntecipacao.valorSugeridoDevolucao,
  valorTotalAprovado: state.pedidoAntecipacao.valorAprovado,
  saldo: state.pedidoAntecipacao.saldo,
  erro: state.pedidoAntecipacao.erroValorDevolucao,
  deveMostrarSecao: mostrarSecaoDevolucao(state),
  valorPendenteCreditoRotativo: state.pedidoAntecipacao.valorPendente,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setValorDevolvido,
      setErroValorDevolvido,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ValoresFinalizacaoPedido);
