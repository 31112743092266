import * as React from "react"

const IconeBuscaSacado = (props) => (
  <svg
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 31.68 27.67"
    style={{
      enableBackground: "new 0 0 31.68 27.67",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    {...props}
  >
    <style>
      {
        ".st0{fill:url(#SVGID_1_)}.st1{fill:url(#SVGID_00000144333461607402988870000005736178367019968428_)}.st2{fill:url(#SVGID_00000149362426934982017790000003115800368833719217_)}.st3{fill:url(#SVGID_00000179607432536302144390000011691575185965222791_)}.st4{fill:url(#SVGID_00000036933041178519579570000002840715263580226702_)}.st5{fill:url(#SVGID_00000039835676986175955560000000490579240789057415_)}.st6{fill:#fff}.st7{fill:#5e6c7f}.st9{fill:url(#SVGID_00000007423194460407671560000012386727762276160668_)}.st10{fill:url(#SVGID_00000176007292769835342740000009989498323331376297_)}.st11{fill:#92d4f5}.st12{fill:#eceff1}.st13{fill:url(#SVGID_00000181777575425016290240000014730873943051912118_)}.st14{fill:url(#SVGID_00000002382622632966689950000000943020718863871159_);stroke:url(#SVGID_00000033366876904125535960000007301472753198434443_);stroke-width:.8968;stroke-miterlimit:10}.st15{fill:#af016f}.st16{fill:url(#SVGID_00000041978912860363218060000005770794899529575066_)}.st17{fill:url(#SVGID_00000009590525111476721060000008523154531002300562_)}.st18{fill:url(#SVGID_00000003806187086070296470000014065444686202042558_);stroke:url(#SVGID_00000163063015069119681340000007591365204872830611_);stroke-width:.8072;stroke-miterlimit:10}.st19{fill:url(#SVGID_00000169559072244677558680000009614249374927300530_)}"
      }
    </style>
    <path
      d="M28.53 4.5C24.21-.58 16.56-1.2 11.48 3.12c-3.72 3.16-5.03 8.09-3.79 12.5l-6.65 5.41c-1.35 1.1-1.23 3.31.26 4.95 1.49 1.64 3.79 2.08 5.14.98l5.87-4.77c4.4 3.13 10.53 3.02 14.83-.63 5.09-4.33 5.71-11.98 1.39-17.06zm-2.97 15.18c-4.05 3.44-10.14 2.95-13.58-1.1C8.54 14.53 9.03 8.44 13.08 5c4.05-3.44 10.14-2.95 13.58 1.1 3.44 4.04 2.95 10.14-1.1 13.58z"
      style={{
        fill: "#007fc4",
      }}
    />
  </svg>
)

export default IconeBuscaSacado
