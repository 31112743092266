import { Radio } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import BotaoRemover from './BotaoRemover';

const StyledContaBancaria = styled.div`
  display: flex;
  align-items: center;

  .info {
    flex: 1;
  }

  p {
    margin: 0;
  }
`;

interface ContaBancariaProps {
  contaBancaria: any;
  radioGroup?: boolean;
  selectedRadioGroupValue?: string | number;
  onRadioGroupChange?: (id: string | number) => void;
  bancos: any[];
}

export const ContaBancaria = ({
  bancos = [],
  radioGroup = false,
  selectedRadioGroupValue,
  onRadioGroupChange = () => {},
  contaBancaria,
}: ContaBancariaProps) => {
  const onRadioClick = ({ target: { value } }) => onRadioGroupChange(value);
  const banco = bancos.find((b) => b.id === contaBancaria.banco_id) || {};
  const nomeDoBanco = banco.nome;
  const codigoDoBanco = banco.codigo;

  return (
    <StyledContaBancaria key={contaBancaria.id}>
      <div className='radio'>
        {radioGroup && (
          <Radio
            checked={selectedRadioGroupValue === contaBancaria.id}
            onChange={onRadioClick}
            value={contaBancaria.id}
            color='primary'
          />
        )}
      </div>
      <div
        className='info'
        onClick={() => onRadioGroupChange(contaBancaria.id)}
      >
        <p className='banco-nome'>{`Banco ${codigoDoBanco} - ${nomeDoBanco}`}</p>
        <p className='agencia'>{`Ag: ${contaBancaria.agencia}`}</p>
        <p className='conta'>{`CC: ${contaBancaria.conta}`}</p>
      </div>
      <BotaoRemover contaBancariaId={contaBancaria.id} />
    </StyledContaBancaria>
  );
};

const mapStateToProps = (state) => ({
  bancos: state.contaBancaria.bancos,
});

export default connect(mapStateToProps)(ContaBancaria);
