import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import auth from './auth';
import cadastro from './cadastro';
import contato from './contato/reducer';
import contaBancaria from './contaBancaria/reducer';
import minhaConta from './minhaConta/reducer';
import conta from './conta/reducer';
import saque from './saque/reducer';
import devolucao from './devolucao/reducer';
import simulacao from './simulacao/reducer';
import configuracoes from './configuracoes/reducer';
import recebaAntes from './recebaAntes/reducer';
import cadastroSacado from './cadastroSacado/reducer';
import antecipacao from './antecipacao/reducer';
import pedidoAntecipacao from './pedidoAntecipacao/reducer';

const reducers = combineReducers({
  auth,
  cadastro,
  contato,
  contaBancaria,
  minhaConta,
  conta,
  saque,
  devolucao,
  simulacao,
  configuracoes,
  recebaAntes,
  cadastroSacado,
  antecipacao,
  pedidoAntecipacao,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers =
  window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
