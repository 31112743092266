import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import DataInput from '../../components/DataInput';
import FormatadorDeMoeda from '../../components/FormatadorDeMoeda'
import { setDataParaRegularizar, fetchValorParaRegularizar as fetchValorParaRegularizarAction } from '../../store/conta/actions';
import { CINZA_600, CINZA_300 } from '../../utils/color';
import CircularProgress from '@material-ui/core/CircularProgress';

const StyledConteudo = styled.div`
  margin: 0 auto;
  color: ${CINZA_600};
  text-align: center;

  .solicitar-boleto-titulo-data,
  .solicitar-boleto-titulo-valor {
    margin-bottom: 0;
    font-size: 1rem;
    text-align: center;
  }

  .solicitar-boleto-titulo-valor {
    margin-bottom: .5rem;
  }

  span {
    margin-top: 1rem;
    color: ${CINZA_300};
    font-size: 1.5rem;
  }
`
export const Conteudo = ({
  valorParaRegularizar,
  dataParaRegularizar,
  buscandoValorParaRegularizar,
  error,
  setData,
  fetchValorParaRegularizar,
}) => {
  useEffect(() => {
    fetchValorParaRegularizar();
  }, [fetchValorParaRegularizar]);

  const handleDateChange = (value) => {
    setData(value);
    fetchValorParaRegularizar();
  };

  return (
    <StyledConteudo>
      <h3 className='solicitar-boleto-titulo-data'>Escolha a data para o vencimento do boleto</h3>

      <DataInput
        handleDateChange={handleDateChange}
        value={dataParaRegularizar}
        error={error}
        helperText={error}
      />

      <h3 className='solicitar-boleto-titulo-valor'>Valor a ser pago</h3>
      <span>
        {buscandoValorParaRegularizar ? <CircularProgress size={20} /> : <FormatadorDeMoeda valor={valorParaRegularizar} comSimbolo /> }
      </span>
    </StyledConteudo>
  );
};

Conteudo.defaultProps = {
  valorParaRegularizar: 0,
  buscandoValorParaRegularizar: false,
};

Conteudo.propTypes = {
  valorParaRegularizar: PropTypes.number,
  dataParaRegularizar: PropTypes.string.isRequired,
  buscandoValorParaRegularizar: PropTypes.bool,
  error: PropTypes.string,
  setData: PropTypes.func.isRequired,
  fetchValorParaRegularizar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  valorParaRegularizar: state.conta.valorParaRegularizar,
  dataParaRegularizar: state.conta.dataParaRegularizar,
  buscandoValorParaRegularizar: state.conta.buscandoValorParaRegularizar,
  error: state.conta.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setData: setDataParaRegularizar,
  fetchValorParaRegularizar: fetchValorParaRegularizarAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
