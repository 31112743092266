import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../../store';

import { setValorNotaFiscal } from '../../../../store/antecipacao/actions';
import { toCurrency, toNumber } from '../../../../utils/number';
import CampoDeMoeda from '../../../CampoDeMoeda';
import { StyledValorNota } from './StyledValorNota';

const ValorNota = ({ setValor, valor }) => {
  const [valorNotaFiscal, setValorNotaFiscal] = useState(
    valor > 0 ? toCurrency(valor) : ''
  );

  useEffect(() => {
    setValor(toNumber(valorNotaFiscal));
  }, [valorNotaFiscal, setValor]);

  return (
    <StyledValorNota>
      <h1 className='titulo'>Qual é o valor do documento?</h1>
      <p className='texto'>
        Esse valor precisa ser o mesmo da nota ﬁscal ou do contrato anexado.
      </p>
      <div className='valor'>
        <span className='cifrao'>R$</span>
        <CampoDeMoeda
          value={valorNotaFiscal}
          placeholder='0,00'
          onChange={setValorNotaFiscal}
          className='cifrao'
          dataCy='input-valor-nota-fiscal'
        />
      </div>
    </StyledValorNota>
  );
};

const mapStateToProps = (state: RootState) => ({
  valor: state.antecipacao.valorNotaFiscal,
});

const mapDispatchToProps = (dispatch, getState) =>
  bindActionCreators(
    {
      setValor: setValorNotaFiscal,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ValorNota);
