export const toNumber = (valor: string) => {
  const valorSeparadoPorPonto = valor.replace(/,/g, '.').split('.');
  const valorComApenasUmPonto = [
    valorSeparadoPorPonto.slice(0, -1).join(''),
    valorSeparadoPorPonto.slice(-1),
  ]
    .filter((parte) => parte.length > 0)
    .join('.');
  const valorComoNumero = +(+valorComApenasUmPonto).toFixed(2);

  return valorComoNumero;
};

export const toCurrency = (valor: string | number) => {
  let valorComoNumero = valor;
  if (typeof valor === 'string') {
    valorComoNumero = toNumber(valor);
  }
  const valorComoDinheiro = valorComoNumero.toLocaleString('pt-br', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'BRL',
  });

  return valorComoDinheiro;
};
