import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Container, Grid } from '@material-ui/core';
import { CINZA_600, CINZA_200, AZUL_400 } from '../../../utils/color';

const StyledInfos = styled(Container)`
  margin-top: 1rem;
  padding: .5rem;
  background-color: ${CINZA_200};

  .simulacao-infos-titulo {
    margin-top: 0;
    margin-bottom: .5rem;
    color: ${AZUL_400};
  }

  .simulacao-infos-detalhes {
    margin: 0;
    color: ${CINZA_600};
  }

  .simulacao-infos-linha {
    display: inline-flex;
    height: 4px;
    width: 16px;
    margin: .2rem;
    margin-right: .5rem;
    background-color: ${CINZA_600};
  }

  @media (max-width: 375px) {
    .simulacao-infos-titulo {
      font-size: 12px;
    }

    .simulacao-infos-detalhes {
      font-size: 12px;
    }
  }
`;

const GridInfos = styled(Grid)`
  padding: .5rem;

  @media (min-width: 960px) {
    padding: 0;
  }
`
export const Infos = ({ taxaJuros }) => (
  <GridInfos item xs={12} md={11} lg={8}>
    <StyledInfos>
      <h4 className='simulacao-infos-titulo'>O que você deve saber sobre o Crédito Rotativo</h4>
      <div className='simulacao-infos-detalhes'>
        <div className='simulacao-infos-linha' />
        Este exemplo não contempla o IOF.
      </div>
      <div className='simulacao-infos-detalhes'>
        <div className='simulacao-infos-linha' />
        Juros atuais aplicados neste exemplo: {taxaJuros} a.m. Os juros pactuados
        nos contratos são os vigentes no dia. Uma vez definidos não se alteram
        até a renovação do contrato. Sem surpresas.
      </div>
    </StyledInfos>
  </GridInfos>
);

Infos.propTypes = {
  taxaJuros: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  taxaJuros: state.simulacao.taxaJuros,
});

export default connect(mapStateToProps)(Infos);
