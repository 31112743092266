import { Hidden } from '@material-ui/core';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../store';
import { iniciaNovaAntecipacao } from '../../../store/antecipacao/actions';
import {
  alterarStatus,
  cancelaPedido,
  concluiPedido,
  finalizaPedido,
} from '../../../store/pedidoAntecipacao/actions';
import { StatusPedidoAntecipacao as Status } from '../../../store/pedidoAntecipacao/types';
import RoundButton, { RoundButtonProps } from '../../RoundButton';
import StyledBotoesInfoPedido from './BotoesInfoPedido.style';
import ModalPedidoEnviado from './ModalPedidoEnviado';

interface BotoesInfoPedidoProps extends ConnectedProps<typeof connector> {}

const BotoesInfoPedido = ({
  statusPedido,
  novaAntecipacao,
  alterarStatusPedido,
  cancelarPedido,
  finalizarPedido,
  concluirPedido,
}: BotoesInfoPedidoProps) => {
  const handleNovaAntecipacao = () => {
    novaAntecipacao();
  };

  const handleFinalizarPedido = () => {
    finalizarPedido();
  };

  const handleCancelaPedido = () => {
    cancelarPedido();
  };

  const handleContinuar = () => {
    alterarStatusPedido(Status.emFinalizacao);
  };

  const handleConcluirPedido = () => {
    concluirPedido();
  };

  const botoes: { [key: string]: RoundButtonProps & { 'data-cy': string } } = {
    novaAntecipacao: {
      onClick: handleNovaAntecipacao,
      value: '+ Nova Antecipação',
      color: 'secondary',
      'data-cy': 'botao-nova-antecipacao',
    },
    finalizarPedido: {
      onClick: handleFinalizarPedido,
      value: 'Finalizar pedido',
      color: 'primaryLinearGradient',
      'data-cy': 'botao-finalizar-pedido',
    },
    cancelaPedido: {
      onClick: handleCancelaPedido,
      value: 'Cancelar pedido',
      color: 'secondary',
      'data-cy': 'botao-cancelar-pedido',
    },
    continuar: {
      onClick: handleContinuar,
      value: (
        <>
          Continuar
          <Hidden xsDown>
            <ArrowForwardIos className='icone-botao' fontSize='inherit' />
          </Hidden>
        </>
      ),
      color: 'primaryLinearGradient',
      'data-cy': 'botao-continuar',
    },
    concluirPedido: {
      onClick: handleConcluirPedido,
      value: 'Concluir pedido',
      color: 'primaryLinearGradient',
      'data-cy': 'botao-concluir-pedido',
    },
    aguardandoResposta: {
      value: 'aguardando resposta',
      disabled: true,
      'data-cy': 'botao-aguardando-resposta',
    },
  };

  const botoesDoStatus = {
    [Status.naoEnviado]: [botoes.novaAntecipacao, botoes.finalizarPedido],
    [Status.pendente]: [botoes.aguardandoResposta],
    [Status.aguardandoCliente]: [botoes.cancelaPedido, botoes.continuar],
    [Status.emFinalizacao]: [botoes.cancelaPedido, botoes.concluirPedido],
  };

  return (
    <StyledBotoesInfoPedido
      className={`${
        statusPedido === Status.pendente ? 'inverter-mobile' : ''
      } `}
    >
      <div
        className={`${statusPedido === Status.pendente ? 'centralizar' : ''} ${
          statusPedido === Status.naoEnviado ? 'inverter' : ''
        } botoes`}
      >
        {botoesDoStatus[statusPedido].map((botao, index) => (
          <RoundButton size='small' {...botao} className='botao' key={index} />
        ))}
        <ModalPedidoEnviado />
      </div>
      {statusPedido === Status.pendente && (
        <p className='texto-aguarde'>
          Entraremos em contato por e-mail e SMS quando for aprovado
        </p>
      )}
    </StyledBotoesInfoPedido>
  );
};

const mapStateToProps = (state: RootState) => ({
  statusPedido: state.pedidoAntecipacao.status,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      novaAntecipacao: iniciaNovaAntecipacao,
      alterarStatusPedido: alterarStatus,
      cancelarPedido: cancelaPedido,
      finalizarPedido: finalizaPedido,
      concluirPedido: concluiPedido,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(BotoesInfoPedido);
