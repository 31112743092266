import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../../../components/Modal'
import Conteudo from './Conteudo';
import { simularDevolucao } from '../../../../store/simulacao/actions';

export const ModalDevolucao = ({
  onConfirm,
  enviadoComSucesso,
  open,
  onClose,
}) => {

  useEffect(() => {
    if(enviadoComSucesso) {
      onClose();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ enviadoComSucesso ])

  return (
    <Modal
      fullWidth
      maxWidth='xs'
      open={open}
      conteudo={<Conteudo/>}
      botaoLabel='Devolver valor'
      botaoColor='primaryDarkGradient'
      onClose={onClose}
      onConfirm={onConfirm}
    />
  );
};

ModalDevolucao.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  enviadoComSucesso: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  enviadoComSucesso: state.simulacao.enviadoComSucesso,
});

const onConfirm = () => (dispatch, getState) => {
  const { dataDevolucao } = getState().simulacao;
  dispatch(simularDevolucao({ dataDevolucao }));
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onConfirm,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalDevolucao);
