import React from 'react';
import {
  BRANCO,
  CINZA_600,
  LARANJA_100,
  VERDE,
  VERMELHO,
} from '../../../utils/color';

import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';

const StyledChip = styled.div`
  color: ${BRANCO};
  border-radius: 25px;
  padding: 0.1vw 0;
  font-size: 0.7vw;
  display: grid;
  place-items: center;

  @media ${queries.desktopUp} {
    font-size: 12px;
    padding: 2px 0;
  }

  @media ${queries.mobileDown} {
    font-size: 3vw;
    width: 24vw;
    padding: 0.8vw 0;
  }
`;

const StatusBoleto = ({ status }) => {
  const converteStatus = (status) => {
    switch (status) {
      case 'Pago':
        return { texto: 'PAGO', cor: VERDE };
      case 'Pendente':
        return { texto: 'A VENCER', cor: LARANJA_100 };
      case 'Debitado Cliente':
        return { texto: 'VENCIDO', cor: VERMELHO };
      default:
        return { texto: status, cor: CINZA_600 };
    }
  };
  const configStatus = converteStatus(status);

  return (
    <StyledChip
      data-cy={`status-boleto-${configStatus.texto}`}
      style={{ backgroundColor: configStatus.cor }}
    >
      {configStatus.texto}
    </StyledChip>
  );
};

export default StatusBoleto;
