import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';

const descricao = 'Mais fácil, impossível! Para sua conta estar em dia, basta pagar os juros no dia do vencimento.'

const EtapaQuatro = ({ children }) => (
  <Base
    proximaEtapa={5}
    etapaAtual={4}
    etapaAnterior={3}
    descricao={descricao}
  >
    {children}
  </Base>
);

EtapaQuatro.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EtapaQuatro;
