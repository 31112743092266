import React from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import ContasBancarias from '../ContasBancarias';
import { CINZA_700, CINZA_400 } from '../../../utils/color';

const StyledListaContasBancarias = styled(Container)`
  padding: 1rem;
  padding-left: 5rem;

  .contas-bancarias-titulo {
    margin-bottom: 0;
    padding-left: .3em;
    padding-bottom: .3em;
  }

  .contas-bancarias-lista {
    color: ${CINZA_700};
    border: 1.5px solid ${CINZA_400};
    border-radius: 10px;
    padding-bottom: .5rem;

    .banco-nome {
      margin-top: 1rem;
      margin-right: 1.5rem;
      padding-left: 1rem;
    }

    .agencia {
      margin: 0;
      padding-left: 1rem;
    }

    .conta {
      margin: 0;
      padding-left: 1rem;
    }

    & > div:not(:last-child) {
      border-bottom: 1px solid ${CINZA_400};
      padding-bottom: 1em;
    }
  }

  @media (max-width: 375px) {
    padding: 0;

    .contas-bancarias-lista  {
      font-size: 10px;
    }
  }

  @media (max-width: 960px) {
    padding: 0 .5rem;
  }
`;

const ListaContasBancarias = () => (
  <StyledListaContasBancarias>
    <ContasBancarias titulo='Contas bancárias cadastradas' />
  </StyledListaContasBancarias>
);

export default ListaContasBancarias;
