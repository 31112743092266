import React from 'react';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { CINZA_600, AZUL_300 } from '../../utils/color';
import Icone from '../../components/Icone';

const StyledResposta = styled(Container)`
  text-align: center;
  height: 70vh;
  position: relative;
  display: table;

  .conteudo-container {
    vertical-align: middle;
    display: table-cell;
  }

  h2 {
    color: ${CINZA_600};
    font-weight: bold;
  }

  h3 {
    color: ${AZUL_300};
    font-style: italic;
    font-weight: bold;
    font-size: 1.2rem;
  }

  p {
    color: ${CINZA_600};
    font-size: 1.2rem;
  }

  @media (max-width: 600px) {
    h2 {
      font-size: 1.1rem;
    }

    h3 {
      font-size: 1rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media (max-width: 375px) {
    h2 {
      font-size: .8rem;
    }

    h3 {
      font-size: .8rem;
    }

    p {
      font-size: .7rem;
    }
  }
`;

const Resposta = ({ titulo, subtitulo, icone }) => (
  <StyledResposta maxWidth='sm'>
    <div className='conteudo-container'>
      <Icone name={icone} />
      <h2>
        {titulo}
        <br />
        {subtitulo}
      </h2>
      <h3>Obrigado por usar o Crédito Rotativo.</h3>
      <p>
        Qualquer dúvida entre em contato pelo email atendimento@creditorotativo.com.br ou fale
        <br />
        conosco através do chat.
      </p>
    </div>
  </StyledResposta>
);

export default Resposta;
