import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BotaoNovaSimulacao from './BotaoNovaSimulacao';
import Valores from '../Conteudo/Valores'
import BotaoEntendaConta from './BotaoEntendaConta';
import Movimentacoes from './Movimentacoes';
import Infos from './Infos';
import AddMovimentacao from './AddMovimentacao/AddMovimentacao';
import { Hidden, Container, Grid } from '@material-ui/core';
import Progresso from '../../../components/Progresso';
import { setEtapaIntroducao as setEtapaIntroducaoAction } from '../../../store/simulacao/actions';

const ConteudoResponsivo = styled(Container)`
  display: flex;
  padding: 0;
  flex-direction: column;
  max-width: 1200px;

  @media (min-width: 960px) {
    padding: 1rem 0;
    flex-direction: row;
    .main-desktop {
      order: 2;
    }
    .lateral-desktop {
      padding-right: 10px;
      order: 1;
    }
  }

  @media (min-width: 1600px){
    max-width: 1400px;
  }

  @media (min-width: 1920px){
    max-width: 1500px;
  }
`;

const BotoesContainer = styled.div`
  padding-top: .5rem;
  padding-left: 1rem;

  @media (min-width: 960px) {
    padding-left: 5rem;
  }
`
export const Conteudo = ({
  enviandoSimulacao,
  modalSaqueAberta,
  modalDevolucaoAberta,
  setEtapaIntroducao
}) => {
  useEffect(() => {
    setEtapaIntroducao(1);
  }, [setEtapaIntroducao]);

  if (enviandoSimulacao && !modalDevolucaoAberta && !modalSaqueAberta) {
    return <Progresso />;
  }

  return (
    <ConteudoResponsivo>
      <div className='main-desktop'>
        <Valores />
        <Movimentacoes />
        <Hidden smDown>
          <Infos />
        </Hidden>
      </div>
      <Grid item xs={12} lg={8} xl={5}>
        <div className='lateral-desktop'>
          <AddMovimentacao />
          <BotoesContainer>
            <BotaoNovaSimulacao />
            <BotaoEntendaConta />
          </BotoesContainer>
        </div>
      </Grid>
      <Hidden mdUp>
        <Infos />
      </Hidden>
    </ConteudoResponsivo>
  );
};

Conteudo.propTypes = {
  enviandoSimulacao: PropTypes.bool.isRequired,
  modalSaqueAberta: PropTypes.bool.isRequired,
  modalDevolucaoAberta: PropTypes.bool.isRequired,
  setEtapa: PropTypes.func.isRequired,
  exibirIntro: PropTypes.bool.isRequired,
 };

const mapStateToProps = (state) => ({
  modalSaqueAberta: state.simulacao.modalSaqueAberta,
  modalDevolucaoAberta: state.simulacao.modalDevolucaoAberta,
  enviandoSimulacao: state.simulacao.enviandoSimulacao,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setEtapaIntroducao: setEtapaIntroducaoAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
