import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import Base from './Base';

const descricao = 'Você pode devolver o saldo que pegou da forma que achar melhor.'

const EtapaDois = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');

  return (
    <Base
      proximaEtapa={3}
      etapaAnterior={1}
      descricao={descricao}
      etapaAtual={2}
      placement={isMobile ? 'top' : 'right'}
    >
      {children}
    </Base>
  );
};

EtapaDois.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EtapaDois;
