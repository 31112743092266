import React from 'react';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Container, Grid, Typography, Hidden } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import { CINZA_700, CINZA_600, CINZA_200 } from '../../utils/color';
import LinkSublinhado from '../../components/LinkSublinhado';
import * as AuthActions from '../../store/auth';
import FormCadastro from './FormCadastro';
import { FacaSeuLogin } from './FacaSeuLogin';
import ContentWrap from '../../components/ContentWrap';

const InfoView = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
  padding-left: 0;
  padding-right: 3em;
`;

const LogoContainer = styled(Container)`
  display: flex;
  justify-content: flex-start;
  padding: 0;
  img {
    max-width: 60%;
  }

  @media (min-width: 960px) {
    padding: 0 3em 0 0;
    justify-content: flex-end;
    img {
      max-width: 100%;
    }
  }
`;

const ContainerCadastro = styled(Container)`
  padding: 3em 3em 0;
  overflow: hidden;
  @media (min-width: 540px) {
    padding: 4em;
  }
`;

const Cadastro = ({ auth }) => {
  const { isSignedIn } = auth;

  return (
    <>
      <ContentWrap>
        {
          isSignedIn
          && <Redirect to='/minhaconta' />
        }
        <FacaSeuLogin />
        <ContainerCadastro maxWidth='lg'>
          <Grid container justify='center' alignItems='flex-start'>
            <Grid item xs={12} sm={12} md={6}>
              <LogoContainer>
                <Link
                  to='/'
                  style={{
                    maxWidth: 380,
                    marginBottom: '2em',
                    display: 'inline-block',
                  }}
                >
                  <Logo />
                </Link>
              </LogoContainer>
              <Hidden smDown>
                <InfoView>
                  <Typography
                    variant='h1'
                    gutterBottom
                    style={{ maxWidth: 280, color: CINZA_700, fontSize: '1.3em' }}
                  >
                    Preencha os dados <br /> para fazer seu cadastro
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    style={{
                      maxWidth: 320,
                      fontSize: '1.3em',
                      lineHeight: '1.25em',
                      color: CINZA_600,
                      paddingRight: '1em',
                      marginTop: '1em',
                      borderRight: `1px solid ${CINZA_600}`,
                    }}
                    gutterBottom
                  >
                    Em seguida faça seu login e <br />
                    acompanhe todo o <br />
                    processo de aprovação.
                  </Typography>
                </InfoView>
              </Hidden>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormCadastro />
            </Grid>
          </Grid>
        </ContainerCadastro>
        <Hidden smUp>
          <Container style={{ backgroundColor: CINZA_200, padding: '2em' }}>
            <Typography styled={{ color: CINZA_700 }}>
              Em seguida faça seu login e acompanhe todo o processo de aprovação.
            </Typography>
          </Container>
          <Container
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
              padding: '3em 2em 2em',
            }}
          >
            <b style={{ display: 'inline-block' }}>Já possui cadastro?</b>
            <LinkSublinhado to='/login'>
              Clique aqui para acessar sua conta
            </LinkSublinhado>
          </Container>
        </Hidden>
      </ContentWrap>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...AuthActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);
