import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import { CINZA_600 } from '../../../utils/color';

const StyledBotoesInfoPedido = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  order: 2;
  margin-top: 1.2vw;

  .botoes {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &.centralizar {
      justify-content: center;
    }

    button {
      padding: 0.9vw 3.5vw;
    }

    span {
      text-transform: uppercase;
      font-size: 1vw;
      font-weight: bold;
    }

    .icone-botao {
      font-size: 1.4vw;
      margin: 0 0.5vw;
    }
  }

  .texto-aguarde {
    font-size: 0.9vw;
    color: ${CINZA_600};
  }

  @media ${queries.desktopUp} {
    margin-top: 16px;

    .botoes {
      span {
        font-size: 14px;
      }

      button {
        padding: 12px 40px;
      }

      .icone-botao {
        font-size: 16px;
        margin: 0 6px;
      }
    }

    .texto-aguarde {
      font-size: 12px;
    }
  }

  @media ${queries.mobileDown} {
    margin: 3vw 0 6.8vw;
    &.inverter-mobile {
      order: 1;
    }

    .botoes {
      flex-direction: column-reverse;

      &.inverter {
        flex-direction: column;
      }

      button {
        padding: 2.5vw 5.5vw;
      }

      .MuiButton-containedPrimary {
        width: 73%;
      }

      .Mui-disabled {
        width: 100%;
      }

      span {
        font-size: 2.9vw;
      }

      .icone-botao {
        font-size: 4vw;
        margin: 0 2vw;
      }
    }

    .texto-aguarde {
      font-size: 2.5vw;
      margin: 2vw 0;
    }
  }
`;

export default StyledBotoesInfoPedido;
