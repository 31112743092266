import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../../store';
import { toCurrency } from '../../../../utils/number';
import FormatadorDeMoeda from '../../../FormatadorDeMoeda';
import Texto from '../../../Texto';
import Valores from './Valores';
import { ValoresBoletoStyle } from './ValoresBoleto.style';

const ValoresBoleto = ({
  totalAntecipacao,
  listaParcelas,
  somatoriaValorDigitado,
}) => {
  const somaCorreta = somatoriaValorDigitado === totalAntecipacao;
  return (
    <ValoresBoletoStyle>
      <h1 className='titulo'>Valor dos boletos</h1>
      <div className='total-antecipacao'>
        <span>Valor total da antecipação</span>
        <span className='valor-fixo'>R$ {toCurrency(totalAntecipacao)}</span>
      </div>
      <div className='boleto-container'>
        {listaParcelas.map((parcela, index) => (
          <Valores parcela={parcela} index={index} key={index} />
        ))}
      </div>
      <div className='somatoria'>
        <span>Somatória dos boletos</span>
        <span
          className={somaCorreta ? 'valor-variavel' : 'valor-variavel-errado'}
        >
          <FormatadorDeMoeda valor={somatoriaValorDigitado} comSimbolo />
        </span>
      </div>
      {!somaCorreta && (
        <Texto className='texto-soma-errada'>
          A somatória precisa ser igual ao valor total da nota
        </Texto>
      )}
    </ValoresBoletoStyle>
  );
};
const mapStateToProps = (state: RootState) => ({
  totalAntecipacao: state.antecipacao.valorNotaFiscal,
  listaParcelas: state.antecipacao.parcelas,
  somatoriaValorDigitado: state.antecipacao.somatoriaValorDigitado,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ValoresBoleto);
