import React from 'react';
import { connect } from 'react-redux';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { fetchListagem, resetFiltros } from '../store/recebaAntes/actions';
import IconeCadastroMenu from './IconesSvg/IconeCadastroMenu';
import IconeContaMenu from './IconesSvg/IconeContaMenu';
import IconeDevolucaoMenu from './IconesSvg/IconeDevolucaoMenu';
import IconeRecebaAntesMenu from './IconesSvg/IconeRecebaAntesMenu';
import IconeSaqueMenu from './IconesSvg/IconeSaqueMenu';
import { Navbar } from './Navbar.style';

const NavbarInterna = ({
  aprovado,
  mostrarRecebaAntes,
  fetchRecebaAntes,
  resetFiltros,
}) => {
  const { url } = useRouteMatch();

  const recarregaAbaRecebaAntes = () => {
    resetFiltros();
    fetchRecebaAntes();
  };

  if (!aprovado) {
    return (
      <Navbar className='menu-desativado'>
        <li className='active' data-cy='navlink-conta'>
          <IconeContaMenu />
          <span>conta</span>
        </li>
        <li data-cy='navlink-saque'>
          <IconeSaqueMenu />
          <span>saque</span>
        </li>
        <li data-cy='navlink-devolucao'>
          <IconeDevolucaoMenu />
          <span>devolução</span>
        </li>
        {mostrarRecebaAntes && (
          <li data-cy='navlink-recebaantes'>
            <IconeRecebaAntesMenu className='icone-receba-antes' />
            <span>receba antes</span>
          </li>
        )}
        <li data-cy='navlink-cadastro'>
          <IconeCadastroMenu />
          <span>cadastro</span>
        </li>
      </Navbar>
    );
  }

  return (
    <Navbar>
      <li>
        <NavLink
          data-cy='navlink-conta'
          to={`${url}`}
          exact
          activeClassName='active'
        >
          <IconeContaMenu />
          <span>conta</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy='navlink-saque'
          to={`${url}/saque`}
          activeClassName='active'
        >
          <IconeSaqueMenu />
          <span>saque</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy='navlink-devolucao'
          to={`${url}/devolucao`}
          activeClassName='active'
        >
          <IconeDevolucaoMenu />
          <span>devolução</span>
        </NavLink>
      </li>
      {mostrarRecebaAntes && (
        <li>
          <NavLink
            data-cy='navlink-recebaantes'
            to={`${url}/recebaantes`}
            activeClassName='active'
            onClick={recarregaAbaRecebaAntes}
          >
            <IconeRecebaAntesMenu className='icone-receba-antes'/>
            <span>receba antes</span>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          data-cy='navlink-cadastro'
          to={`${url}/cadastro`}
          activeClassName='active'
        >
          <IconeCadastroMenu />
          <span>cadastro</span>
        </NavLink>
      </li>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRecebaAntes: fetchListagem,
      resetFiltros: resetFiltros,
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);

export default connector(NavbarInterna);
