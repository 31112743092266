import React from 'react';
import styled from 'styled-components';
import { Container } from '@material-ui/core';
import IndicadorMovimentacao from '../IndicadorMovimentacao';
import { CINZA_700, CINZA_200, BRANCO } from '../../../../../utils/color';

const StyledConteudoDaDica = styled(Container)`
  padding-left: 0;
  padding-right: 0;
  color: ${CINZA_700};

  .brackground-cinza {
    background-color: ${CINZA_200};
  }

  .brackground-branco {
    background-color: ${BRANCO};
  }

  .brackground-branco p {
    padding: 0 .6rem;
  }

  .brackground-branco h4 {
    padding: 0 .6rem;
  }

  h4 {
    margin-bottom: .3rem;
    padding: .6rem .6rem 0;
  }

  p {
    margin-top: 0;
    padding: 0 .6rem .6rem;
    font-size: .9rem;
    line-height: 1.5
  }
`;

const ConteudoDaDica = () => (
  <StyledConteudoDaDica>
    <div className='brackground-cinza'>
      <h4>
        <IndicadorMovimentacao tipo='saque' />
        Saque
      </h4>
      <p>
        São os valores que você solicita através da tela Saque.
        A cada saque efetuado, são descontados
        os juros acumulados até a data.
      </p>
    </div>
    <div className='brackground-branco'>
      <h4>
        <IndicadorMovimentacao tipo='cobrancajuro' />
        Juros
      </h4>
      <p>
        Juros incidentes sobre o saldo da sua conta desde o
        último saque ou de seu último pagamento. É cobrado
        por boleto bancário, uma vez por mês, desde que seja
        igual ou superior a R$10,00.
      </p>
    </div>
    <div className='brackground-cinza'>
      <h4>
        <IndicadorMovimentacao tipo='pagamento' />
        Valor devolvido
      </h4>
      <p>
        Você pode diminuir o saldo de sua conta requisitando
        boleto a partir de R$100,00, ou pode enviar qualquer
        valor através de TED.
      </p>
    </div>
  </StyledConteudoDaDica>
);

export default ConteudoDaDica;
