import { Divider } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { setFiltroStatus } from '../../../store/recebaAntes/actions';

import { queries } from '../../../utils/breakpoints';
import RoundButton from '../../RoundButton';

const StyledFiltroData = styled.div`
  margin-bottom: 0.6vw;

  .opcao-status {
    min-width: 5.7vw;
  }

  @media ${queries.desktopUp} {
    margin-bottom: 8px;

    .opcao-status {
      min-width: 74px;
    }
  }

  @media ${queries.mobileDown} {
    .opcao-status {
      min-width: 26vw;
    }
  }
`;

interface Props extends ConnectedProps<typeof connector> {}

const FiltroStatus = ({ setFiltro }: Props) => {
  const opcoes = [
    { label: 'A vencer', id: 'A Vencer' },
    { label: 'Vencido', id: 'Vencido' },
    { label: 'Pago', id: 'Pago' },
  ];

  const handleClick = ({ currentTarget }) => {
    setFiltro(currentTarget.getAttribute('id'));
  };

  return (
    <StyledFiltroData className='container-filtro'>
      <strong>Status</strong>
      <Divider />
      <div className='opcoes'>
        {opcoes.map((opcao) => (
          <RoundButton
            onClick={handleClick}
            className='opcao-status'
            color='secondaryCinza'
            size='extra-small'
            value={opcao.label}
            id={opcao.id}
            key={opcao.id}
          />
        ))}
      </div>
    </StyledFiltroData>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFiltro: setFiltroStatus,
    },
    dispatch
  );

const connector = connect(null, mapDispatchToProps);

export default connector(FiltroStatus);
