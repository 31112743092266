import React from 'react';
import styled from 'styled-components';
import simulacaoDesktop from '../../../assets/simulacao_desktop.jpg'
import simulacaoMobile from '../../../assets/simulacao_mobile.jpg'
import { BRANCO } from '../../../utils/color';

const StyledCabecalho = styled.div`
  display: flex;
  height: 160px;
  background-image: url(${simulacaoDesktop});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: ${BRANCO};
  align-items: flex-end;
  justify-content: space-around;

  .simulacao-cabecalho-titulo {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    text-transform: uppercase;
  }

  span {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .simulacao-cabecalho-descricao {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 600px) {
    display: grid;
    height: 200px;
    background-image: url(${simulacaoMobile});
    justify-content: center;
    align-items: center;

  .simulacao-cabecalho-titulo {
    padding-right: 4rem;
    font-size: 2.2rem;
    text-align: left;
    text-transform: uppercase;
  }

  .simulacao-cabecalho-descricao {
    display: grid;
    margin-bottom: 1rem;
  }

  .simulacao-cabecalho-experimente {
    padding-left: 4rem;
    text-align: center;
  }

  .simulacao-cabecalho-diferente {
    text-align: right;
  }
}
`
export const Cabecalho = () => (
  <StyledCabecalho>
    <h1 className='simulacao-cabecalho-titulo'>Simulação</h1>
    <div className='simulacao-cabecalho-descricao'>
      <span className='simulacao-cabecalho-experimente'>Experimente. </span>
      <span className='simulacao-cabecalho-diferente'>É diferente.</span>
    </div>
  </StyledCabecalho>
);

export default Cabecalho;
