import {
  Container,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';

import RoundButton from '../../components/RoundButton';
import * as AuthActions from '../../store/auth/sagas';
import * as CadastroActions from '../../store/cadastro';
import { analyticsEvents } from '../../utils/analytics';
import { CINZA_600, VERMELHO } from '../../utils/color';
import { toCurrency } from '../../utils/number';
import { validaDigitacaoCampoMoeda } from '../../utils/validation';

const tiposEmpresa = [
  {
    tipo: 0,
    descricao: 'MEI',
  },
  {
    tipo: 1,
    descricao: 'ME',
  },
  {
    tipo: 2,
    descricao: 'EPP',
  },
];

const tiposDeEstadoCivil = [
  {
    tipo: 'Solteiro',
    descricao: 'Solteiro(a)',
  },
  {
    tipo: 'Casado',
    descricao: 'Casado(a)',
  },
  {
    tipo: 'Separado',
    descricao: 'Separado(a)',
  },
  {
    tipo: 'Divorciado',
    descricao: 'Divorciado(a)',
  },
  {
    tipo: 'Viúvo',
    descricao: 'Viúvo(a)',
  },
];

const diasVencimento = Array.from(Array(5), (_, index) => (index + 2) * 4);

const View = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 0;
  margin-bottom: 2em;
  padding-right: 1em;

  .small-label,
  .small-label-text label {
    font-size: .85em;
    min-width: 260px;
    &.MuiInputLabel-shrink {
      font-size: .9rem;
    }

    @media (min-width: 310px) {
      font-size: .95em;
      min-width: 290px;
      &.MuiInputLabel-shrink {
        font-size: 1.1rem;
      }
    }

    @media (min-width: 460px) {
      font-size: 1em;
      &.MuiInputLabel-shrink {
        font-size: 1.4rem;
      }
    }

    @media (min-width: 560px) {
      font-size: 1rem;
    }
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: .6em;
  width: 100%;

  @media (min-width: 960px) {
    max-width: 470px;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    flex-direction: initial;
  }
`;

const FormCadastro = ({
  cadastro,
  auth,
  signIn,
  signUp,
  signOut,
  isSignedIn,
  validarCodigoPromocional
}) => {
  const urlParams = new URLSearchParams(useLocation().search);
  const authLoading = auth.isLoading;
  const cadastroLoading = cadastro.isLoading;
  const isLoading = authLoading || cadastroLoading;

  const [tipoEmpresa, setTipoEmpresa] = useState('');
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [cnpj, setCNPJ] = useState('');
  const [cep, setCep] = useState('');
  const [faturamento, setFaturamento] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [celular, setCelular] = useState('');
  const [cpf, setCPF] = useState('');
  const [rg, setRG] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [diaVencimento, setDiaVencimento] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaConfirmacao, setSenhaConfirmacao] = useState('');
  const [erroConfirmacao, setErroConfirmacao] = useState('');
  const [codigoPromocional, setCodigoPromocional] = useState('');
  const [divulgadorId, setDivulgadorId] = useState('');
  const [valorTimeout, setValorTimeout] = useState(null);

  const getDivulgadorId = async (codigo) => {
    if (!!codigo && codigo !== '') {
      const divulgadorId = await validarCodigoPromocional(codigo)
      setDivulgadorId(divulgadorId)
    }
  };

  useEffect(() => {
    getDivulgadorId(urlParams?.get("cod_promo"))
    setCodigoPromocional(urlParams?.get("cod_promo"))
    // eslint-disable-next-line
  }, []);

  const enviaSolicitacao = () => {
    signUp({
      usuario: {
        email,
        cpf,
        rg,
        celular,
        nome: nomeCompleto,
        password: senha,
        endereco: 'endereço teste',
        estado_civil: estadoCivil,
      },
      empresa: {
        cnpj,
        cep,
        nome_empresa: nomeEmpresa,
        tipo_empresa: tipoEmpresa,
        dia_fechamento: diaVencimento,
        divulgador_id: divulgadorId || null,
        faturamento_mensal: faturamento.replace('.', '')
      },
    });
    analyticsEvents('click_enviar_cadastro')
  };

  const authLogin = useCallback(() => {
    signIn({
      email,
      password: senha,
    });
  }, [email, senha, signIn]);

  useEffect(() => {
    if (!isSignedIn) {
      signOut();
    }
  }, [isSignedIn, signOut]);

  useEffect(() => {
    const { usuario, empresa } = cadastro;
    // Testa se ambos usuario e empresa
    // foram cadastrados com sucesso
    if (usuario && usuario.id && empresa && empresa.id) {
      authLogin();
    }
  }, [cadastro, authLogin]);

  const validaSenhas = () => {
    setErroConfirmacao('');

    if (senhaConfirmacao === '') {
      setErroConfirmacao('O campo de confirmação de senha não pode estar vazio');
    }

    if (senhaConfirmacao !== senha && senhaConfirmacao !== '') {
      setErroConfirmacao('Os campos de senha não coincidem');
    }
  };

  const handleKeyPress = (event) => {
    const timeout = valorTimeout;
    clearTimeout(timeout);
    setValorTimeout(timeout);

    if (event.key === 'Enter') {
      enviaSolicitacao();
    }
  };

  const handleKeyUpCodigoPromocional = () => {
    let timeout = valorTimeout;
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      getDivulgadorId(codigoPromocional)
    }, 500);
    setValorTimeout(timeout);
  };

  const onChangeCodigoPromocional = ({ target: { value } }) => {
    setCodigoPromocional(value.trim());
  };

  return (
    <>
      <View maxWidth='sm'>
        <Typography>
          <b>SOBRE SUA EMPRESA</b>
        </Typography>
        <FormControl style={{ maxWidth: '400px', paddingTop:'2rem' }} error={!!cadastro.error && !!cadastro.error.tipo_empresa}>
          <InputLabel classes={{ root: 'small-label' }} htmlFor='tipoEmpresa'>
            Selecione seu tipo de empresa
          </InputLabel>
          <Select
            id='tipoEmpresa'
            autoFocus
            value={tipoEmpresa}
            onChange={(e) => setTipoEmpresa(e.target.value)}
          >
            {tiposEmpresa.map((tipoEmpresa) => (
              <MenuItem value={tipoEmpresa.tipo} key={tipoEmpresa.tipo}>
                {tipoEmpresa.descricao}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {
              cadastro.error &&
              cadastro.error.tipo_empresa &&
              cadastro.error.tipo_empresa[0]
            }
          </FormHelperText>
        </FormControl>
        <StyledTextField
          id='nomeEmpresa'
          label='Nome da empresa'
          value={nomeEmpresa}
          onChange={(e) => setNomeEmpresa(e.target.value)}
          error={!!cadastro.error && !!cadastro.error.nome_empresa}
          helperText={
            cadastro.error &&
            cadastro.error.nome_empresa &&
            cadastro.error.nome_empresa[0]
          }
          onKeyPress={handleKeyPress}
        />
        <InputMask
          mask='99.999.999/9999-99'
          value={cnpj}
          id='nomeEmpresa'
          disabled={false}
          maskChar=' '
          onChange={(e) => setCNPJ(e.target.value)}
        >
          {() => (
            <StyledTextField
              label='CNPJ'
              error={!!cadastro.error && !!cadastro.error.cnpj}
              helperText={
                cadastro.error && cadastro.error.cnpj && cadastro.error.cnpj[0]
              }
              onKeyPress={handleKeyPress}
            />
          )}
        </InputMask>
        <InputMask
          mask='99999-999'
          value={cep}
          id='cep'
          disabled={false}
          maskChar=' '
          onChange={(e) => setCep(e.target.value)}
        >
          {() => (
            <StyledTextField
              label='CEP'
              error={!!cadastro.error && !!cadastro.error.cep}
              helperText={
                cadastro.error && cadastro.error.cep && cadastro.error.cep[0]
              }
              onKeyPress={handleKeyPress}
            />
          )}
        </InputMask>
        <StyledTextField
          id='faturamento'
          label='Faturamento mensal'
          value={faturamento}
          error={!!cadastro.error && !!cadastro.error.faturamento_mensal}
          helperText={
            cadastro.error && cadastro.error.faturamento_mensal && cadastro.error.faturamento_mensal[0]
          }
          onChange={(e) => setFaturamento(e.target.value)}
          onKeyPress={handleKeyPress}
          onKeyDown={validaDigitacaoCampoMoeda}
          onBlur={(e) => {
            setFaturamento(toCurrency(faturamento))
          }}
        />
      </View>
      <View maxWidth='sm'>
        <Typography>
          <b>SOBRE VOCÊ</b>
        </Typography>
        <StyledTextField
          id='nomeCompleto'
          label='Nome Completo'
          value={nomeCompleto}
          error={!!cadastro.error && !!cadastro.error.nome}
          helperText={
            cadastro.error && cadastro.error.nome && cadastro.error.nome[0]
          }
          onChange={(e) => setNomeCompleto(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <InputMask
          id='celular'
          mask='(99)999999999'
          value={celular}
          disabled={false}
          maskChar=' '
          onChange={(e) => setCelular(e.target.value)}
        >
          {() => (
            <StyledTextField
              label='Celular'
              error={!!cadastro.error && !!cadastro.error.celular}
              helperText={
                cadastro.error &&
                cadastro.error.celular &&
                cadastro.error.celular[0]
              }
              onKeyPress={handleKeyPress}
            />
          )}
        </InputMask>
        <InputMask
          id='cpf'
          value={cpf}
          onChange={(e) => setCPF(e.target.value)}
          mask='999.999.999-99'
          disabled={false}
          maskChar=' '
        >
          {() => (
            <StyledTextField
              label='CPF'
              error={!!cadastro.error && !!cadastro.error.cpf}
              helperText={
                cadastro.error && cadastro.error.cpf && cadastro.error.cpf[0]
              }
              onKeyPress={handleKeyPress}
            />
          )}
        </InputMask>
        <StyledTextField
          id='rg'
          label='RG'
          value={rg}
          error={!!cadastro.error && !!cadastro.error.rg}
          helperText={
            cadastro.error && cadastro.error.rg && cadastro.error.rg[0]
          }
          onChange={(e) => setRG(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <FormControl
          style={{ maxWidth: '470px', marginTop: 10 }}
          error={!!cadastro.error && !!cadastro.error.estado_civil}>
          <InputLabel htmlFor='estadoCivil'>
            Estado civil
          </InputLabel>
          <Select
            id='estadoCivil'
            value={estadoCivil}
            onChange={(e) => setEstadoCivil(e.target.value)}
          >
            {tiposDeEstadoCivil.map((estadoCivil) => (
              <MenuItem value={estadoCivil.tipo} key={estadoCivil.tipo}>
                {estadoCivil.descricao}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {
              cadastro.error &&
              cadastro.error.estado_civil &&
              cadastro.error.estado_civil[0]
            }
          </FormHelperText>
        </FormControl>
        <FormControl error={!!cadastro.error && !!cadastro.error.dia_fechamento}>
          <div style={{ marginTop: '1em' }}>
            <b
              style={{
                color:
                  cadastro.error && cadastro.error.dia_fechamento
                    ? VERMELHO
                    : CINZA_600,
                fontSize: '1rem',
                maxWidth: '200px',
                display: 'inline-block',
              }}
            >
              Qual melhor dia para o vencimento dos juros:
            </b>
            <Select
              id='diaVencimento'
              value={diaVencimento}
              onChange={(e) => setDiaVencimento(e.target.value)}
            >
              {diasVencimento.map((dia) => (
                <MenuItem value={dia} key={dia}>
                  {dia}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {
                cadastro.error &&
                cadastro.error.dia_fechamento &&
                cadastro.error.dia_fechamento[0]
              }
            </FormHelperText>
          </div>
        </FormControl>
      </View>

      <View maxWidth='sm'>
        <InputMask
          id='codigoPromocional'
          mask='99999'
          value={codigoPromocional}
          disabled={false}
          maskChar=' '
          onChange={onChangeCodigoPromocional}
        >
          {() => (
            <StyledTextField
              label='Código promocional (se houver)'
              error={!!cadastro.error && codigoPromocional}
              /* helperText={cadastro.error && codigoPromocional} */
              onKeyPress={handleKeyPress}
              onKeyUp={handleKeyUpCodigoPromocional}
            />
          )}
        </InputMask>
      </View>

      <View maxWidth='sm'>
        <StyledTextField
          id='email'
          label='E-mail'
          value={email}
          error={!!cadastro.error && !!cadastro.error.email}
          helperText={
            (cadastro.error &&
              cadastro.error.email &&
              cadastro.error.email[0]) ||
            'Esse é o e-mail que você utilizará para fazer o login.'
          }
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
        />

        <StyledTextField
          type='password'
          id='senha'
          label='Crie sua senha'
          error={!!cadastro.error && !!cadastro.error.password}
          helperText={
            (cadastro.error &&
              cadastro.error.password &&
              cadastro.error.password[0]) ||
            'A senha precisa ter no mínimo 10 caracteres.'
          }
          value={senha}
          onChange={(e) => setSenha(e.target.value)}
          onBlur={() => validaSenhas()}
          onKeyPress={handleKeyPress}
        />
        <StyledTextField
          type='password'
          id='senhaConfirmacao'
          label='Confirmação de senha'
          classes={{ root: 'small-label-text' }}
          value={senhaConfirmacao}
          error={erroConfirmacao !== ''}
          helperText={erroConfirmacao}
          onChange={(e) => setSenhaConfirmacao(e.target.value)}
          onBlur={() => validaSenhas()}
          onKeyPress={handleKeyPress}
        />
      </View>
      <StyledContainer maxWidth='sm'>
        <RoundButton
          blockXS
          value='Enviar solicitação de cadastro'
          color='primary'
          onClick={enviaSolicitacao}
          loading={isLoading}
        />
      </StyledContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  cadastro: state.cadastro,
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...CadastroActions, ...AuthActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormCadastro);
