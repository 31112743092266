import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import { AZUL_300, CINZA_400, CINZA_600 } from '../../../../utils/color';

export const StyledValorNota = styled.div`
  border: 1px solid ${CINZA_400};
  border-radius: 5px;
  padding: 0.2vw 20px 2.4vw;
  width: 26vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .titulo {
    margin-bottom: 0;
    color: ${AZUL_300};
    font-size: 1.15vw;
  }

  .texto {
    color: ${CINZA_600};
    font-size: 0.7vw;
    width: 70%;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .cifrao,
  input {
    color: ${CINZA_600};
    font-size: 1.9vw;
  }

  .MuiTextField-root {
    margin-top: 0;
  }

  input {
    width: 9vw;
  }

  .valor {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 10px;

    .MuiFormHelperText-root {
      min-width: unset;
    }
  }

  @media ${queries.mobileDown} {
    width: 83%;
    padding: 0.2vw 20px 9vw;
    border-radius: 8px;

    .titulo {
      font-size: 4.2vw;
    }

    .texto {
      font-size: 3vw;
      width: 80%;
      margin: 2vw 0;
    }

    .cifrao,
    input {
      font-size: 9vw;
    }

    input {
      width: 34vw;
    }
  }

  @media ${queries.desktopUp} {
    padding: 5px 20px 40px;
    width: 45%;

    .titulo {
      font-size: 18px;
    }

    .texto {
      font-size: 12px;
    }

    .valor {
      font-size: 38px;
    }

    .cifrao,
    input {
      font-size: 28px;
    }
    input {
      width: 120px;
    }
  }
`;
