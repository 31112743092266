import React, { useState } from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import * as AuthActions from '../store/auth/sagas';
import { selectNomeEmpresa } from '../store/auth/selectors';
import { AZUL_300, CINZA_700 } from '../utils/color';
import ItemMenuAutenticado from './ItemMenuAutenticado';
import Logo from './Logo';
import { StyledMenu } from './Menu';
import ItemMenuAutenticadoMobile from './ItemMenuAutenticadoMobile';
import SocialMediaIcons from './SocialMediaIcons';

const MenuContainer = styled.div`
  margin: 0;
  padding: 0 1rem;
  right: 0;
  z-index: 1;

  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
  }

  .logo-container {
    @media (max-width: 1000px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 30px auto;
    }

    &:not(:last-child) > a {
      padding: 0;
      border-bottom: 0;
    }
  }

  .logo-container-mobile {
    line-height: 1;
  }

  .exit-button {
    flex: 1;
    padding: 1.1em 0;
    font-size: 1rem;
    text-align: left;
    text-transform: initial;
    color: ${CINZA_700};

    > .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .social-icons {
    display: flex;
    justify-content: flex-start;

    > a {
      flex: 0;
    }
  }

  .icone-menu {
    color: ${AZUL_300};
    margin-left: auto;
    cursor: pointer;
    font-size: 2rem;
    z-index: 4;

    &.fixed-position {
      position: fixed;
      right: 1.4rem;
    }

    @media (min-width: 1000px) {
      display: none;
    }
  }

  .active {
    font-weight: bold;
  }

  @media (max-width: 1000px) {
    padding: .85rem 1rem;
  }

  @media (min-width: 1000px) {
    position: unset;
  }
`;

const StyledMenuAuth = styled(StyledMenu)`
  > li {
    padding: 0;
    > a {
      font-weight: 400;
    }
  }

  @media (min-width: 1000px) and (max-width: 1280px) {
    padding: 0 1em;
  }
`;

const MenuAutenticado = ({ signOut, isSignedIn, nomeEmpresa, color }) => {
  const [visible, setVisible] = useState(false);
  const isMobile = useMediaQuery('(max-width: 999px)');

  const IconeMenu = (props) =>
    !visible ? (
      <MenuIcon
        className='icone-menu'
        onClick={() => setVisible(!props.visible)}
      />
    ) : (
      <CloseIcon
        className='icone-menu fixed-position'
        onClick={() => setVisible(!props.visible)}
      />
    );

  return (
    <MenuContainer>
      {isMobile && (
        <Link to='/' className='logo-container-mobile'>
          <Logo width={120} />
        </Link>
      )}

      <StyledMenuAuth visible={visible} onClick={() => setVisible(!visible)}>
        <li className='logo-container'>
          <Link to='/'>
            <Logo width={132} />
          </Link>
        </li>

        <ItemMenuAutenticadoMobile />

        <li>
          <Link to='/#comofunciona'>Como funciona</Link>
        </li>
        <li>
          <NavLink to='/#simulacao' isActive={(_match, location) => location.pathname === '/simulacao'}>Simulação</NavLink>
        </li>
        <li>
          <Link to='/#faleconosco'>Fale conosco</Link>
        </li>
        <li>
          <Link to='/#duvidasfrequentes'>Dúvidas frequentes</Link>
        </li>
        <li>
          <NavLink to='/minhaconta' exact>Minha conta</NavLink>
        </li>

        {isSignedIn && isMobile && (
          <li>
            <Button className='exit-button' onClick={signOut}>Sair</Button>
          </li>
        )}

        {!isMobile && <ItemMenuAutenticado color='black' />}

        {isMobile && (
          <li className='social-icons'>
            <SocialMediaIcons className='social-media-icon' />
          </li>
        )}
      </StyledMenuAuth>

      <IconeMenu visible={visible} />
    </MenuContainer>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  nomeEmpresa: selectNomeEmpresa(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuAutenticado);
