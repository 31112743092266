import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import Base from './Base';
import { selectValorDaPrimeiraMovimentacao } from '../../../../store/simulacao/selectors';
import FormatadorDeMoeda from '../../../../components/FormatadorDeMoeda';

const Descricao = ({ valorDoPrimeiroSaque, proximoPagamento }) => {
  const valorSaque = <FormatadorDeMoeda valor={valorDoPrimeiroSaque} comSimbolo />;
  const valorPagamento = <FormatadorDeMoeda valor={proximoPagamento} comSimbolo />;

  return (
    <span>
      Fazendo um saque de <strong>{valorSaque}</strong> no dia do pagamento você só terá que pagar <strong>{valorPagamento}</strong> referentes ao juros dos dias de uso.
    </span>
  );
}

export const EtapaUm = ({ children, valorDoPrimeiroSaque, proximoPagamento }) => {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const descricao = (
    <Descricao
      valorDoPrimeiroSaque={valorDoPrimeiroSaque}
      proximoPagamento={proximoPagamento}
    />
  );

  return (
    <Base
      proximaEtapa={2}
      descricao={descricao}
      etapaAtual={1}
      placement={isMobile ? 'bottom' : 'left'}
    >
      {children}
    </Base>
  );
};

EtapaUm.defaultProps = {
  valorDoPrimeiroSaque: 0,
  proximoPagamento: 0,
};

EtapaUm.propTypes = {
  children: PropTypes.node.isRequired,
  valorDoPrimeiroSaque: PropTypes.number,
  proximoPagamento: PropTypes.number,
};


const mapStateToProps = (state) => ({
  valorDoPrimeiroSaque: selectValorDaPrimeiraMovimentacao(state),
  proximoPagamento: state.simulacao.proximoPagamento,
});

export default connect(mapStateToProps)(EtapaUm);
