import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from '../../../../../components/Modal';
import { CINZA_700 } from '../../../../../utils/color';
import BotaoDeIcone from '../../../../../components/BotaoDeIcone';
import ConteudoDaDica from './ConteudoDaDica';

const StyledDicaMovimentacoes = styled.span`
  .botao-dica {
    padding-bottom: 1.2rem;
  }

  svg {
    position: absolute;
    color: ${CINZA_700};
    font-size: 1rem;
  }
`;

const DicaMovimentacoes = ({ children, openOnChildrenClick }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const clonedChildren = React.cloneElement(children, openOnChildrenClick && { onClick: handleClickOpenModal });

  return (
    <StyledDicaMovimentacoes>
      {clonedChildren}
      <BotaoDeIcone
        icone='ajuda'
        className='botao-dica'
        onClick={handleClickOpenModal}
      />
      <Modal
        fullWidth
        maxWidth='xs'
        open={openModal}
        titulo='Entenda suas movimentações'
        conteudo={<ConteudoDaDica />}
        onClose={onCloseModal}
        exibirBotao={false}
      />
    </StyledDicaMovimentacoes>
  );
};

DicaMovimentacoes.defaultProps = {
  openOnChildrenClick: false,
};

DicaMovimentacoes.propTypes = {
  children: PropTypes.node.isRequired,
  openOnChildrenClick: PropTypes.bool,
};

export default DicaMovimentacoes;
