import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CINZA_600 } from '../../utils/color';
import user from '../../assets/cadastro_user.png';

const AbsoluteLink = styled(Link)`
  position: absolute;
  display: none;
  right: 0;
  top: 0;
  padding: 2em;

  .column {
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    padding-right: .7em;

    b,
    span {
      display: block;
      color: ${CINZA_600};
      text-align: right;
    }

    b {
      padding-bottom: 0;
      border-bottom: 2px solid ${CINZA_600};
      float: right;
    }
  }

  @media (min-width: 600px) {
    display: flex;
  }

  @media (min-width: 540px) {
    padding: 3em 2em;
  }

  @media (min-width: 1240px) {
    padding: 4em;
  }
`;

export const FacaSeuLogin = () => (
  <AbsoluteLink to='/login'>
    <div className='column'>
      <span>Já possui cadastro?</span>
      <b>Faça seu login</b>
    </div>
    <img src={user} alt={'Faça seu login'} />
  </AbsoluteLink>
);
