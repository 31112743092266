import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { setTipo } from '../../../../store/contaBancaria/actions';
import { selectTipoEmpresaMEI } from '../../../../store/minhaConta/selectors';

export const SelectTipoDeContaBancaria = ({
  empresaMEI,
  tipo,
  onChange,
}) => {
  const tiposConta = ['Pessoa Jurídica', 'Pessoa Física']

  if(empresaMEI){
    return (
      <FormControl style={{ minWidth: '50%', maxWidth: '50%' }}>
        <InputLabel>
          Tipo
        </InputLabel>
        <Select
          id='tipo-de-conta'
          value={tipo}
          onChange={onChange}
        >
          {tiposConta.map((tipo) => (
            <MenuItem value={tipo} key={tipo}>
              {tipo}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return null
};

SelectTipoDeContaBancaria.defaultProps = {
  tipo: 'Pessoa Jurídica',
};

SelectTipoDeContaBancaria.propTypes = {
  empresaMEI: PropTypes.bool.isRequired,
  tipo: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  empresaMEI: selectTipoEmpresaMEI(state),
  tipoEmpresa: state.minhaConta.tipoEmpresa,
  tipo: state.contaBancaria.tipo,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onChange: (e) => setTipo(e.target.value),
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectTipoDeContaBancaria);
