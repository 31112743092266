import * as React from 'react';

const IconePedidoEnviado = (props) => (
  <svg
    id='Camada_1'
    xmlns='http://www.w3.org/2000/svg'
    x={0}
    y={0}
    viewBox='0 0 125.33 133.11'
    style={{
      enableBackground: 'new 0 0 125.33 133.11',
    }}
    xmlSpace='preserve'
    width='1em'
    height='1em'
    {...props}
  >
    <style>{'.st15{fill:#af016f}'}</style>
    <path
      d='m1.58 69.79 47.7 30.36c.54.4 1.19.6 1.83.6s1.29-.2 1.83-.6l47.7-30.36-47.67-36.53a3.12 3.12 0 0 0-3.72 0L1.58 69.79z'
      style={{
        fill: '#92d4f5',
      }}
    />
    <path
      d='M82.07 32.64H20.15c-3.41 0-6.19 2.78-6.19 6.19v68.11c0 1.71 1.39 3.1 3.1 3.1h68.11c1.71 0 3.1-1.39 3.1-3.1V38.83c-.01-3.41-2.78-6.19-6.2-6.19z'
      style={{
        fill: '#eceff1',
      }}
    />
    <linearGradient
      id='SVGID_1_'
      gradientUnits='userSpaceOnUse'
      x1={1.579}
      y1={100.744}
      x2={100.645}
      y2={100.744}
    >
      <stop
        offset={0.018}
        style={{
          stopColor: '#004898',
        }}
      />
      <stop
        offset={0.13}
        style={{
          stopColor: '#0065af',
        }}
      />
      <stop
        offset={0.248}
        style={{
          stopColor: '#007fc3',
        }}
      />
      <stop
        offset={0.425}
        style={{
          stopColor: '#0093d2',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#0068b1',
        }}
      />
    </linearGradient>
    <path
      d='M52.94 100.14c-.54.4-1.19.6-1.83.6-.64 0-1.29-.2-1.83-.6L1.58 69.79v55.73c0 3.42 2.77 6.19 6.19 6.19h86.68c3.42 0 6.19-2.77 6.19-6.19V69.79l-47.7 30.35z'
      style={{
        fill: 'url(#SVGID_1_)',
      }}
    />
    <linearGradient
      id='SVGID_00000005971783430023710100000006355598905879145916_'
      gradientUnits='userSpaceOnUse'
      x1={51.112}
      y1={131.703}
      x2={51.112}
      y2={91.457}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#0067b0',
        }}
      />
      <stop
        offset={0.268}
        style={{
          stopColor: '#007fc3',
        }}
      />
      <stop
        offset={0.79}
        style={{
          stopColor: '#0093d2',
        }}
      />
    </linearGradient>
    <linearGradient
      id='SVGID_00000108268220623879017400000017324535256801083536_'
      gradientUnits='userSpaceOnUse'
      x1={1.13}
      y1={111.58}
      x2={101.094}
      y2={111.58}
    >
      <stop
        offset={0.018}
        style={{
          stopColor: '#004898',
        }}
      />
      <stop
        offset={0.13}
        style={{
          stopColor: '#0065af',
        }}
      />
      <stop
        offset={0.248}
        style={{
          stopColor: '#007fc3',
        }}
      />
      <stop
        offset={0.425}
        style={{
          stopColor: '#0093d2',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#0068b1',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#SVGID_00000005971783430023710100000006355598905879145916_)',
        stroke:
          'url(#SVGID_00000108268220623879017400000017324535256801083536_)',
        strokeWidth: 0.8968,
        strokeMiterlimit: 10,
      }}
      d='M94.45 131.7H7.77c-3.47 0-6.19-2.72-6.19-6.19 0-.98.47-1.91 1.26-2.5l46.44-30.96c.54-.4 1.19-.6 1.83-.6s1.29.2 1.83.6l46.44 30.96c.79.58 1.26 1.51 1.26 2.5.01 3.47-2.71 6.19-6.19 6.19z'
    />
    <path
      className='st15'
      d='M35.56 50.9h4.62v20.81h-4.62zM41.46 50.9h1.71v20.81h-1.71zM44.87 50.9h1.71v20.81h-1.71zM48.71 50.9h3.84v20.81h-3.84zM54.2 50.9h1.71v20.81H54.2zM57.61 50.9h1.71v20.81h-1.71zM61.45 50.9h3.84v20.81h-3.84zM75.89 51.72h-2.66V46.1c0-.18-.14-.32-.32-.32h-4.29v-2.66h4.29c1.64 0 2.98 1.34 2.98 2.98v5.62zM27.62 51.72h-2.66V46.1c0-1.64 1.34-2.98 2.98-2.98h4.29v2.66h-4.29c-.18 0-.32.14-.32.32v5.62zM32.24 80.16h-4.29c-1.64 0-2.98-1.34-2.98-2.98v-5.62h2.66v5.62c0 .18.14.32.32.32h4.29v2.66zM72.91 80.16h-4.29V77.5h4.29c.18 0 .32-.14.32-.32v-5.62h2.66v5.62c0 1.64-1.33 2.98-2.98 2.98z'
    />
    <linearGradient
      id='SVGID_00000139277001712677180210000011525154353536357003_'
      gradientUnits='userSpaceOnUse'
      x1={57.611}
      y1={35.211}
      x2={123.884}
      y2={35.211}
    >
      <stop
        offset={0}
        style={{
          stopColor: '#af016f',
        }}
      />
      <stop
        offset={0.104}
        style={{
          stopColor: '#cb3666',
        }}
      />
      <stop
        offset={0.251}
        style={{
          stopColor: '#f07c59',
        }}
      />
      <stop
        offset={0.726}
        style={{
          stopColor: '#f39965',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ca215a',
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: 'url(#SVGID_00000139277001712677180210000011525154353536357003_)',
      }}
      d='M57.61 28.45S76.96 64.31 78.8 67.57c-3.7 10.06 45.09-56.22 45.09-56.22L111.35 1.8 82.47 52.5 67.79 19.33l-10.18 9.12z'
    />
  </svg>
);

export default IconePedidoEnviado;
