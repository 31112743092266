import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import RoundButton from '../../../../components/RoundButton';
import Tabela from './Tabela';
import { CINZA_700 } from '../../../../utils/color';

const StyledMovimentacoes = styled(Container)`
  padding: 2.3rem 0;
  color: ${CINZA_700};

  .movimentacoes-titulo {
    margin-top: 0;
    margin-bottom: .3rem;
    padding-left: .7rem;
    font-size: 1rem;
   }

  .movimentacoes-rodape {
    margin-top: 4px;
    font-size: .9rem;
  }

  .container-botoes {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
  }

  .container-botoes button {
    margin: 0 2rem 0 0;
    text-transform: uppercase;
  }

  @media (max-width: 960px) {
    padding-top: 1rem;
    padding-bottom: 0;

    .movimentacoes-titulo {
      display: none;
    }

    .movimentacoes-rodape {
      padding-left: 1rem;
      font-size: .8rem;
    }

    .container-botoes {
      padding-top: 0;
    }

    .container-botoes button {
      margin-right: 1rem;
      padding: 10px 35px;
      font-size: 12px;
    }
  }

  @media (max-width: 375px) {
    .container-botoes button {
      padding: 10px 30px;
      font-size: 10px;
    }

    .movimentacoes-rodape {
      font-size: .7rem;
    }
  }

  @media (max-width: 320px) {
    .container-botoes button {
      padding: 10px 20px;
      font-size: 8px;
    }
  }
`;

const Movimentacoes = () => (
  <StyledMovimentacoes>
    <Grid item md={12}>
      <h1 className='movimentacoes-titulo'>Movimentações</h1>
      <Tabela />
      <p className='movimentacoes-rodape'>
        Para consultar outras informações entre em contato pelo email atendimento@creditorotativo.com.br
      </p>
      <div className='container-botoes'>
        <Link to='/minhaconta/saque'>
          <RoundButton
            value='Efetuar saque'
            color='primary'
            size='small'
            bold='bold'
          />
        </Link>
        <Link to='/minhaconta/devolucao'>
          <RoundButton
            value='Devolver valor'
            color='primary'
            size='small'
            bold='bold'
            style={{ marginRight: 0 }}
          />
        </Link>
      </div>
    </Grid>
  </StyledMovimentacoes>
);

export default Movimentacoes;
