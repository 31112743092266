import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, TextField, Container } from '@material-ui/core';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as AuthActions from '../store/auth/sagas';
import RoundButton from '../components/RoundButton';
import Notification from '../components/Notification';
import bgLogin from '../assets/bglogin.png';
import Logo from '../components/Logo';

const View = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
`;

const BackgroundContainer = styled(Container)`
  @media (min-width: 1076px) {
    background-image: url(${bgLogin});
    padding: 3vh;
  }
  background-size: cover;
  background-position: center center;
  max-width: none;
`;

const EsqueciSenha = ({ auth, recoverPassword, signOut }) => {
  const { error, isLoading, isSignedIn, recoverEmailSent } = auth;
  const [showAlert, setShowAlert] = useState(Boolean(error));
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!isSignedIn) {
      signOut();
    }
  }, [isSignedIn, signOut]);

  useEffect(() => {
    if (isLoading) {
      setShowAlert(false);
    } else {
      setShowAlert(!!error);
    }
  }, [error, isLoading]);

  const recuperarSenha = async () => {
    recoverPassword({
      email,
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      recuperarSenha();
    }
  };

  return (
    <BackgroundContainer>
      <View style={{ maxWidth: '700px', height: '100vh', padding: '5vh' }}>
        <RouterLink to='/' style={{ paddingRight: 10, paddingLeft: 10 }}>
          <Logo />
        </RouterLink>

        <View>
          <Notification
            open={recoverEmailSent || showAlert}
            onClose={() => setShowAlert(false)}
            severity={recoverEmailSent ? 'success': 'error'}
            message={recoverEmailSent ? 'Email de redefinição de senha enviado! Verifique sua caixa de email.' : error}
          />
          <TextField
            error={!!error}
            id='email'
            label='E-mail cadastrado'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress}
            helperText={error}
          />
        </View>
        <RoundButton
          color='primary'
          bold='bold'
          onClick={recuperarSenha}
          value='Recuperar senha'
          loading={isLoading}
        />

        <View maxWidth='sm'>
          <Link
            style={{ fontWeight: 'bold' }}
            component={RouterLink}
            to='/cadastro'
          >
            Ainda não possui cadastro?
          </Link>
          <Link component={RouterLink} to='/cadastro'>
            Clique aqui para criar sua conta
          </Link>
        </View>
      </View>
    </BackgroundContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  recoverEmailSent: state.recoverEmailSent,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EsqueciSenha);
