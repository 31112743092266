import postContatos from './api';
import * as types from './types';

export const setNome = (value) => ({
  type: types.SET_NOME,
  value,
});

export const setEmail = (value) => ({
  type: types.SET_EMAIL,
  value,
});

export const setMensagem = (value) => ({
  type: types.SET_MENSAGEM,
  value,
});

export const enviarContatoRequest = () => ({
  type: types.ENVIAR_CONTATO_REQUEST,
});

export const enviarContatoSuccess = () => ({
  type: types.ENVIAR_CONTATO_SUCCESS,
});

export const enviarContatoFailure = (errors) => ({
  type: types.ENVIAR_CONTATO_ERROR,
  errors,
});

export const enviarContato = () => (
  (dispatch, getState) => {
    dispatch(enviarContatoRequest());

    const state = getState();
    const { nome, email, mensagem } = state.contato;
    const usuarioId = state.auth.attributes.id;
    const payload = {
      usuarioId,
      nome,
      email,
      mensagem,
    };

    return (
      postContatos(payload).then(
        () => {
          dispatch(enviarContatoSuccess());
          dispatch(setNome(''));
          dispatch(setEmail(''));
          dispatch(setMensagem(''));
        },
        ({ response: { data: { errors } } }) => {
          dispatch(enviarContatoFailure(errors));
        },
      )
    );
  }
);

export const dispensarNotificacaoDeSucesso = () => ({
  type: types.DISPENSAR_NOTIFICACAO_DE_SUCESSO,
});

export const exibeChat = (value) => ({
  type: types.EXIBE_CHAT,
  value
});
