import * as types from './types';

const INITIAL_STATE = {
  modalAdicionarAberta: false,
  bancos: [],
  buscandoBancos: true,
  bancoId: '',
  agencia: '',
  conta: '',
  enviandoConta: false,
  enviadoComSucesso: false,
  errors: {},
  contasBancarias: [],
  buscandoContasBancarias: false,
  deletadoComSucesso: false,
  tipo: 'Pessoa Jurídica',
  celularValido: null
};

const contaBancaria = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ABRIR_MODAL_ADICIONAR:
      return { ...state, modalAdicionarAberta: true };
    case types.FECHAR_MODAL_ADICIONAR:
      return { ...state, modalAdicionarAberta: false, celularValido: null };
    case types.FETCH_BANCOS_REQUEST:
      return {
        ...state,
        buscandoBancos: true,
      };
    case types.FETCH_BANCOS_SUCCESS:
      return {
        ...state,
        buscandoBancos: false,
        bancos: action.bancos,
      };
    case types.SET_BANCO_ID:
      return { ...state, bancoId: action.value };
    case types.SET_AGENCIA:
      return { ...state, agencia: action.value };
    case types.SET_CONTA:
      return { ...state, conta: action.value };
    case types.SOLICITAR_TOKEN_CONTA_BANCARIA_REQUEST:
      return {
        ...state,
        enviandoConta: true,
        errors: {},
      };
    case types.SOLICITAR_TOKEN_CONTA_BANCARIA_SUCCESS:
      return {
        ...state,
        enviandoConta: false,
        errors: {},
      };
    case types.SOLICITAR_TOKEN_CONTA_BANCARIA_ERROR: {
      const { errors } = action;
      return {
        ...state,
        enviandoConta: false,
        errors,
      };
    }
    case types.ENVIAR_CONTA_BANCARIA_REQUEST:
      return {
        ...state,
        enviandoConta: true,
        enviadoComSucesso: false,
        errors: {},
      };
    case types.ENVIAR_CONTA_BANCARIA_SUCCESS:
      return {
        ...state,
        modalAdicionarAberta: false,
        bancoId: '',
        agencia: '',
        conta: '',
        enviandoConta: false,
        enviadoComSucesso: true,
        errors: {},
        contasBancarias: [...state.contasBancarias, action.contaBancaria],
        celularValido: null
      };
    case types.ENVIAR_CONTA_BANCARIA_ERROR: {
      const { errors } = action;
      return {
        ...state,
        enviandoConta: false,
        enviadoComSucesso: false,
        errors,
      };
    }
    case types.DISPENSAR_NOTIFICACAO_DE_SUCESSO: {
      return {
        ...state,
        enviadoComSucesso: false,
        deletadoComSucesso: false,
      };
    }
    case types.FETCH_CONTAS_REQUEST:
      return {
        ...state,
        buscandoContasBancarias: true,
      };
    case types.FETCH_CONTAS_SUCCESS:
      return {
        ...state,
        buscandoContasBancarias: false,
        contasBancarias: action.contasBancarias,
      };
    case types.DELETE_CONTA_BANCARIA_REQUEST:
      return {
        ...state,
        deletadoComSucesso: false,
        errors: {},
      };
    case types.DELETE_CONTA_BANCARIA_SUCCESS: {
      const contasBancarias = state.contasBancarias.filter((cb) => cb.id !== action.id);
      return {
        ...state,
        contasBancarias,
        deletadoComSucesso: true,
        errors: {},
      };
    }
    case types.DELETE_CONTA_BANCARIA_ERROR: {
      const { errors } = action;
      return {
        ...state,
        deletadoComSucesso: false,
        errors,
      };
    }
    case types.SET_TIPO:{
      return {
        ...state,
        tipo: action.value,
      };
    }
    case types.CONFIRMAR_CELULAR_REQUEST:
      return {
        ...state,
      };
    case types.CONFIRMAR_CELULAR_SUCCESS:
      return {
        ...state,
        celularValido: action.celularValido,
      };
    case types.CONFIRMAR_CELULAR_ERROR: {
      const { error } = action;
      return {
        ...state,
        celularValido: error
      };
    }
    default:
      return state;
  }
};

export default contaBancaria;
