import * as React from 'react';

const IconeContaMenu = (props) => (
  <svg
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18.75 18.94'
    {...props}
  >
    <circle
      cx={9.45}
      cy={9.46}
      r={6.82}
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeMiterlimit: 10,
        strokeWidth: 3,
      }}
    />
  </svg>
);

export default IconeContaMenu;
