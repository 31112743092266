import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import LogoSebrae from '../../../components/IconesSvg/LogoSebrae';
import LogoCreditoRotativo from '../../../components/IconesSvg/LogoCreditoRotativo';
import { AZUL_300, AZUL_PARCERIA } from '../../../utils/color';
import { queries } from '../../../utils/breakpoints';

const StyledSecaoParceria = styled.div`
  padding-top: 4rem;
  padding-bottom: 2rem;

  @media ${queries.mobileDown} {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }

  @media ${queries.mobileUp} and ${queries.desktopDown} {
    padding-top: 14rem;
    padding-bottom: 3rem;
  }

  .parceria-container {
    padding: 3% 6%;
    border: 4px solid ${AZUL_PARCERIA};
    border-radius: 4px;
    align-items: center;
    justify-content: space-between;
    max-width: 67vw;
    margin: auto;
    border-radius: 140px;

    @media ${queries.desktopDown} {
      max-width: 90vw;
      padding: 9% 6%;
      border-radius: 70px;
      flex-direction: row-reverse;
    }

    div {
      display: flex;
      justify-content: center;
    }

    p {
      color:  ${AZUL_300};
      font-size: 1.2vw;
      font-weight: bold;
      line-height: 1.25em;
      margin-right: 2rem;

      @media ${queries.mobileDown} {
        font-size: 0.8rem;
        line-height: 1.1rem;
        text-align: center;
        margin: auto;
        margin-bottom: 1rem;
      }

      @media ${queries.mobileUp} and ${queries.desktopDown} {
        font-size: 0.8rem;
        line-height: 1.1rem;
        text-align: center;
        padding-left: 2rem;
        margin: 0;
      }
    }

    svg {
      height: auto;
    }

    .logo-sebrae {
      width: 12vw;

      @media ${queries.mobileDown} {
        width: 25vw;
      }

      @media ${queries.mobileUp} ${queries.desktopDown} {
        width: 25vw;
      }

    }

    .logo-credito {
      width: 18vw;

      @media ${queries.mobileDown} {
        width: 35vw;
      }

      @media ${queries.mobileUp} ${queries.desktopDown} {
        width: 25vw;
      }
    }
  }
`;

const SecaoParceriaSebrae = () => (
  <StyledSecaoParceria>
    <Grid container item className='bottom-container'>
      <Grid
        container
        item
        xs={12}
        className='parceria-container'
      >
        <Grid item sm={3} xs={12} className="titulo">
          <p>O Crédito Rotativo é uma empresa parceira do Sebrae</p>
        </Grid>
        <Grid item sm={2} xs={6} >
          <div className='logo-parceria'>
            <LogoSebrae className='logo-sebrae' />
          </div>
        </Grid>
        <Grid item sm={4} xs={6} >
          <div className='logo-parceria'>
            <LogoCreditoRotativo className='logo-credito' />
          </div>
        </Grid>
      </Grid>
    </Grid>
  </StyledSecaoParceria>
);

export default SecaoParceriaSebrae;
