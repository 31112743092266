import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { AZUL_300 } from '../../../utils/color';
import { queries } from '../../../utils/breakpoints';

const StyledPaper = styled.div`
  .item-container {
    padding: 1rem;
    text-align: center;
    height: auto;
    aspect-ratio: 4.1;
    align-items: baseline;
    flex-direction: column;
    width: 90%;
    padding-left: 2rem;
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    font-size: 6vw;
  }

  .texto-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    height: 25%;
    width: 100%;
    margin-left: 1.7rem;

    h3 {
      width: 100%;
      padding-bottom: .2rem;
      margin: 0;
      color: ${AZUL_300};
      font-size: 1.3rem;
      text-align: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    p {
      margin: 0;
      font-size: 1vw;
      text-align: left;
    }
  }

  @media ${queries.mobileUp} and ${queries.fullHdDown} {
    .icon-container {
      .icone {
        font-size: 6rem;
        line-height: normal;
      }
    }
    .texto-container {
      h3 {
        font-size: 2rem;
      }

      p {
        font-size: 1.4rem;
      }
    }
  }

  @media ${queries.mobileDown} {
    margin-bottom : 2rem;

    .item-container {
      aspect-ratio: 4/1;
      padding: 6% 5%;
      width: 100%;
    }

    .icon-container {
      height: 100%;
      justify-content: flex-end;
      flex-direction: row;

      .icone {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 19vw;
      }
    }

    .texto-container {

      h3 {
        font-size: 1.1rem;
        max-width: 75%;
      }
      p {
        font-size: 0.7rem;
      }
  }
`;

const Item = ({ icon, title, description }) => {
  return (
    <StyledPaper>
      <Grid container className='item-container'>
        <Grid item xs={12} sm={12} className='icon-container'>
          <div className="icone">{icon}</div>
        </Grid>

        <Grid item xs={12} sm={12} className='texto-container'>
          <h3 dangerouslySetInnerHTML={{ __html: title }} />
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

Item.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Item;
