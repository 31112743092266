import { Grid, IconButton, Paper } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BoletoRecebaAntes } from '../../../store/recebaAntes/types';
import { CINZA_600 } from '../../../utils/color';
import FormatadorDeMoeda from '../../FormatadorDeMoeda';
import StatusBoleto from './StatusBoleto';

const StyledItemLista = styled(Paper)`
  margin-bottom: 4.2vw;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  display: flex;
  border-radius: 10px;
  .info {
    padding: 3vw 3.5vw;
    display: flex;
    flex-direction: column;
    color: ${CINZA_600};
    flex: 1;
  }

  .nome {
    font-size: 4.2vw;
    margin: 2.6vw 0 1.9vw;
  }

  .stats-container {
    padding: 1vw 1.3vw;
  }

  .stats {
    display: flex;
    flex-direction: column;
  }

  .rotulo {
    font-size: 2.8vw;
  }

  .valor {
    font-size: 3.5vw;
    line-height: 1.2;
  }

  .valor-negrito {
    font-size: 4.1vw;
    font-weight: bold;
    line-height: 1.2;
    padding-bottom: 0.5vw;
  }
`;

const ItemLista = ({ boleto }: { boleto: BoletoRecebaAntes }) => {
  const [expanded, setExpanded] = useState(false);
  const icon = expanded ? <ChevronRight /> : <ExpandMore />;

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledItemLista>
      <div className='info'>
        <StatusBoleto status={boleto.status} />
        <span className='nome' data-cy='nome-cliente'>
          {boleto.cliente}
        </span>
        <Grid container className='stats-container' spacing={2}>
          <Grid item xs={5} className='stats'>
            <span className='rotulo'>Vencimento</span>
            <span className='valor-negrito' data-cy='vencimento-boleto'>
              {boleto.vencimento}
            </span>
          </Grid>
          <Grid item xs={7} className='stats'>
            <span className='rotulo'>Valor</span>
            <span className='valor-negrito' data-cy='valor-boleto'>
              <FormatadorDeMoeda valor={boleto.valor} comSimbolo />
            </span>
          </Grid>
          {expanded && (
            <>
              <Grid item xs={5} className='stats'>
                <span className='rotulo'>N° da operação</span>
                <span className='valor' data-cy='numero-operacao'>
                  {boleto.numeroOperacao}
                </span>
              </Grid>
              <Grid item xs={7} className='stats'>
                <span className='rotulo'>N° do boleto</span>
                <span className='valor' data-cy='numero-boleto'>
                  {boleto.numeroBoleto}
                </span>
              </Grid>
              <Grid item xs={7} className='stats'>
                <span className='rotulo'>Data do crédito</span>
                <span className='valor' data-cy='data-credito'>
                  {boleto.dataCredito}
                </span>
              </Grid>
            </>
          )}
        </Grid>
      </div>
      <IconButton aria-label='delete' onClick={handleChange}>
        {icon}
      </IconButton>
    </StyledItemLista>
  );
};

export default ItemLista;
