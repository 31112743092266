import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import { AZUL_300, CINZA_400 } from '../../../../utils/color';

export const ParcelaStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 1vw 0vw 1vw;

  .parcela {
    display: flex;
    align-items: flex-end;
    flex: 1;
    justify-content: space-between;
  }

  .boleto {
    font-size: 0.95vw;
    font-weight: bold;
    margin: 0;
  }

  .data {
    padding: 0 0.3vw;
    width: 8vw;
    border-bottom: 1px solid ${AZUL_300};

    .MuiTextField-root {
      margin-top: 0;
      vertical-align: bottom;
    }

    .MuiInput-underline:before,
    .MuiInput-underline:after {
      display: none;
    }

    .MuiInputBase-input {
      margin-top: 0;
      font-size: 0.95vw;
      color: ${CINZA_400};
      line-height: 1;
      padding: 3px;
      text-align: center;
    }
  }

  .MuiIconButton-root {
    padding: 0.4vw 0.5vw 0vw;

    svg {
      font-size: 1.5vw;
      color: ${AZUL_300};
    }
  }

  @media ${queries.mobileDown} {
    padding: 4vw 0vw 4.5vw;

    .boleto {
      font-size: 3.6vw;
      padding-left: 1.2vw;
    }

    .data {
      padding: 0 1vw;
      width: 32vw;

      .MuiInputBase-input {
        font-size: 4vw;
      }
      .MuiInputBase-root {
        line-height: 1;
      }
    }

    .MuiIconButton-root {
      padding: 0.9vw 3vw 0;

      svg {
        font-size: 5vw;
      }
    }
  }

  @media ${queries.desktopUp} {
    height: 60px;

    .boleto {
      font-size: 14px;
    }

    .data {
      padding: 0 4px;
      width: 120px;

      .MuiInputBase-input {
        font-size: 14px;
      }
    }

    .MuiIconButton-root {
      padding: 5px 6px 0px;

      svg {
        font-size: 1.25rem;
      }
    }
  }
`;
