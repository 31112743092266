import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import { AZUL_300, CINZA_600 } from '../../../../utils/color';

export const StyledAguardandoAssinatura = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 39vw;
  margin: 0 auto;
  margin-top: 5.2vw;

  span,
  p {
    text-align: center;
    color: ${CINZA_600};
    line-height: 1.6;
  }
  .icone img {
    width: 6.3vw;
  }
  .pedido-concluido {
    font-size: 1.3vw;
    margin: 2.2vw 0 0.9vw;
  }
  .negrito {
    font-weight: bold;
  }
  .obrigado {
    color: ${AZUL_300};
    font-style: italic;
    margin: 1.2vw 0 1.7vw;
    font-size: 1.3vw;
  }

  .email {
    font-weight: 500;
  }

  .contato {
    font-size: 1.05vw;
  }

  .link-pagina-inicial {
    margin-top: 3.4vw;
    font-size: 1vw;
    font-weight: bold;
    text-align: center;
    color: ${AZUL_300};
    text-decoration: underline;
    text-transform: uppercase;
  }

  @media ${queries.desktopUp} {
    width: 40%;
    margin-top: 68px;
    .icone img {
      width: 80px;
    }

    .pedido-concluido {
      font-size: 16px;
      margin: 30px 0 12px;
    }

    .obrigado {
      font-size: 16px;
      margin: 16px 0 20px;
    }

    .contato {
      font-size: 13px;
    }

    .link-pagina-inicial {
      margin-top: 45px;
      font-size: 14px;
    }
  }

  @media ${queries.mobileDown} {
    width: 72vw;
    margin-top: 10.4vw;

    span,
    p {
      line-height: 1.4;
    }

    .icone img {
      width: 15vw;
    }
    .pedido-concluido {
      font-size: 3.1vw;
      margin: 3vw 0 6.3vw;
    }
    .obrigado {
      font-size: 2.8vw;
      margin-bottom: 2.2vw;
    }

    .contato {
      font-size: 2.75vw;
    }

    .link-pagina-inicial {
      margin-top: 11.4vw;
      font-size: 2.8vw;
    }
  }
`;
