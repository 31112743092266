import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { queries } from '../utils/breakpoints';

const StyledProgresso = styled.div`
  text-align: center;
    height: 50vh;
  align-items: center;
  display: flex;
  justify-content: center;

  @media ${queries.mobileDown} {
    height: 20vh;
  }
`;

const Progresso = () => (
  <StyledProgresso>
    <CircularProgress />
  </StyledProgresso>
);

export default Progresso;
