import styled from 'styled-components';
import { queries } from '../utils/breakpoints';

import { CINZA_600 } from '../utils/color';

const Texto = styled.span`
  text-align: center;
  font-size: 1vw;
  color: ${CINZA_600};
  display: block;
  @media ${queries.desktopUp} {
    font-size: 12px;
  }

  @media ${queries.mobileDown} {
    font-size: 3.5vw;
  }
`;

export default Texto;
