import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import { AZUL_300 } from '../../../utils/color';

export const InfoPedidoStyle = styled.div`
  margin: auto;
  margin-bottom: 50px;
  width: 40.7vw;
  display: flex;
  flex-direction: column;

  .header-info-pedido {
    padding: 1.2vw 0 1.5vw;

    .titulo {
      text-align: center;
      font-size: 1.8vw;
    }

    .subtitulo {
      font-size: 1.8vw;
      display: flex;
      align-items: baseline;
      gap: 1vw;
    }
  }

  .lista-botoes-container {
    display: flex;
    flex-direction: column;
  }

  .lista {
    order: 1;
  }

  .link-pagina-inicial {
    margin-top: 2.3vw;
    font-size: 1vw;
    font-weight: bold;
    text-align: center;
    color: ${AZUL_300};
    text-decoration: underline;
    text-transform: uppercase;
  }

  @media ${queries.desktopUp} {
    width: 525px;

    .header-info-pedido {
      padding: 16px 0 20px;

      .titulo {
        font-size: 24px;
      }

      .subtitulo {
        font-size: 24px;
        gap: 12px;
      }
    }

    .link-pagina-inicial {
      margin-top: 30px;
      font-size: 14px;
    }
  }

  @media ${queries.mobileDown} {
    width: 86vw;

    .header-info-pedido {
      padding: 1.2vw 0 1.5vw;

      .titulo {
        text-align: center;
        font-size: 4.2vw;
      }

      .subtitulo {
        font-size: 3.9vw;
        gap: 2.5vw;
        padding-left: 1.5vw;
      }
    }

    .lista {
      order: 2;
    }

    .link-pagina-inicial {
      margin-top: 6.1vw;
      font-size: 3vw;
    }
  }
`;
