import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';
import {
  setDataSaque as setDataSaqueAction,
  fetchDadosSaque as fetchDadosSaqueAction,
} from '../../../../store/simulacao/actions';
import { CINZA_700, CINZA_600, AZUL_300, BRANCO, CINZA_200,CINZA_400 } from '../../../../utils/color';
import DataInput from '../../../../components/DataInput';
import FormatadorDeMoeda from '../../../../components/FormatadorDeMoeda';
import CampoValorSaque from '../../CampoValorSaque';
import Icone from '../../../../components/Icone';
import TituloModal from '../TituloModal';

const StyledConteudo = styled.div`
  text-align: center;
  color: ${CINZA_600};

  .simulacao-saque-data-input {
    padding-bottom: .5rem;
  }

  .simulacao-saque-container {
    margin-top: 1rem;
    border: 1.5px solid ${CINZA_400};
    border-left: 0;
    border-right: 0;
  }

  .simulacao-saque-valores {
    margin-top: .5rem;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    background-color: ${CINZA_200};
  }

  .simulacao-saque-valores-titulo {
    padding: 1rem;
    padding-bottom: 4px;
    font-size: .8rem;
    background-color: ${BRANCO};
    text-transform: uppercase;
    color: ${CINZA_700};
  }

  .simulacao-saque-valores-descricao {
    padding: .6rem 1.2rem;
  }

  .simulacao-saque-valores-valor {
    padding: .6rem 1.2rem;
    text-align: right;
  }

  .valor-creditar {
    color: ${AZUL_300};
    position: relative;
  }

  .simulacao-linha-branca {
    background-color: ${BRANCO}
  }

  .simulacao-saque-rodape {
    padding: .5rem 1.2rem;
    padding-bottom: 1.2rem;
    font-size: .9rem;
    text-align: left;
  }

  img {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  @media (max-width: 600px) {
    .simulacao-saque-rodape {
      padding-bottom: 1.4rem;
      font-size: .7rem;
    }

    img {
      width: 20px;
    }
  }

  @media (max-width: 375px) {
    .simulacao-saque-valores,
    .simulacao-saque-valores-titulo,
    .simulacao-saque-valores-descricao,
    .simulacao-saque-valores-valor {
      font-size: .7rem;
    }

    .simulacao-saque-rodape {
      font-size: .6rem;
    }
  }
`
export const Conteudo = ({
  dataSaque,
  setDataSaque,
  fetchDadosSaque,
  valorJuros,
  valorFinal,
  errors,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleDateChange = async (value) => {
    await setDataSaque(value);
    fetchDadosSaque();
  };

  const conteudoRodapeMobile = (
    <div className='simulacao-saque-rodape'>
      Esse é o valor que será transferido para a
      conta bancária cadastrada. A cada saque são
      descontados os juros acumulados até o
      momento e o IOF dessa operação.
    </div>
  );


  const conteudoRodape = (
    <div className='simulacao-saque-rodape'>
      Este é o valor que será creditado em sua conta. <br />
      Ao efetuar um novo saque, os juros acumulados são descontados.
      Assim, no dia do vencimento, só haverá cobrança de juros pelos dias entre o último saque e a data do boleto.
    </div>
  );

  return (
    <StyledConteudo>
        <TituloModal>Escolha a data do saque</TituloModal>
        <div className='simulacao-saque-data-input'>
          <DataInput
            handleDateChange={handleDateChange}
            value={dataSaque}
            error={errors?.dataSaque}
            helperText={errors?.dataSaque}
          />
        </div>

      <div className='simulacao-saque-container'>
        <TituloModal>Quanto deseja sacar?</TituloModal>
        <CampoValorSaque
          helperText= {'Saque mínimo de R$ 100,00.'}
          onStopTyping={fetchDadosSaque}
        />

        <table className='simulacao-saque-valores'>
        <thead>
          <tr>
            <th colSpan={2} className='simulacao-saque-valores-titulo'>
            Valores debitados no saque
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className='simulacao-linha-branca'>
            <td className='simulacao-saque-valores-descricao'>Juros até o momento*</td>
            <td className='simulacao-saque-valores-valor'>
              R$
              {' '}
              <FormatadorDeMoeda valor={valorJuros} />
            </td>
          </tr>
          <tr>
            <td className='simulacao-saque-valores-descricao'>Valor a creditar</td>
            <td className='simulacao-saque-valores-valor valor-creditar'>
              R$
              {' '}
              <FormatadorDeMoeda valor={valorFinal} />
            <Icone name='setaCurvada' />
            </td>
          </tr>
        </tbody>
        </table>

        {isMobile ? conteudoRodapeMobile : conteudoRodape}
      </div>
    </StyledConteudo>
  );
};

Conteudo.defaultProps = {
  valorJuros: 0,
  dataSaque: null,
  valorFinal: 0,
  errors: {},
};

Conteudo.propTypes = {
  dataSaque: PropTypes.string,
  setDataSaque: PropTypes.func.isRequired,
  fetchDadosSaque: PropTypes.func.isRequired,
  valorJuros: PropTypes.number,
  valorFinal: PropTypes.number,
  errors: PropTypes.objectOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  dataSaque: state.simulacao.dataSaque,
  valorJuros: state.simulacao.valorJuros,
  valorFinal: state.simulacao.valorFinal,
  errors: state.simulacao.errors,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchDadosSaque: fetchDadosSaqueAction,
  setDataSaque: setDataSaqueAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Conteudo);
