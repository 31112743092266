import React from 'react';
import { Container, Grid } from '@material-ui/core';
import styled from 'styled-components';
import ExpansionPanel from '../../components/ExpansionPanel';
import Title from '../../components/Home/Title';

const StyledDuvidasFrequentes = styled(Container)`
  padding: 1rem 4rem;
  @media (max-width: 960px) {
    padding: 1rem;
  }

  .title-container {
    padding-left: 1rem;
  }
`;

const DuvidasFrequentes = () => {
  const duvidasFrequentes = [
    {
      title: 'Existe um valor mínimo nas operações?',
      description: `
        Para saques o valor mínimo é de R$ 100,00.
        Para devoluções por meio de boletos o valor mínimo é de R$ 100,00.
        Para devoluções via TED não há mínimo.
        Para pagamento de juros o valor mínimo é de R$ 10,00. Valores inferiores serão mantidos.
        na conta e o cliente fica isento de pagamento naquele mês.
      `,
    },
    {
      title: 'Se minha empresa ou meu CPF tiverem restrições, posso me cadastrar?',
      description: `
        Envie e-mail para atendimento@creditorotativo.com.br e conte sobre sua situação. Queremos
        saber de você.
      `,
    },
    {
      title: 'Posso fazer meu cadastro e não fazer nenhum saque?',
      description: `
        Sem dúvida. Quando você se cadastra no CRÉDITO ROTATIVO, você cria uma “gaveta
        a mais” no caixa de sua empresa para você abrir quando precisar.
        Uma vez cadastrado, o limite fica a disposição, não cancela.
        Quando precisar é só usar.
      `,
    },
    {
      title: `
        Caso eu devolva todo o valor que saquei, zerando o saldo,
        se necessitar novamente, preciso atualizar meu cadastro?
      `,
      description: `
        Fique tranquilo. Seu limite fica disponível para qualquer hora que precisar, durante a
        vigência do contrato, sem burocracia, sem surpresas, como combinamos.
      `,
    },
    {
      title: 'Quanto tempo tenho para devolver meus saques?',
      description: `
        Você tem toda a vigência do nosso contrato ( 1 ano) para devolver. E mais, antes de cada
        término de contrato encaminhamos um termo de renovação, possibilitando renovações
        anuais, o que na prática, amplia  este prazo indefinidamente.
      `,
    },
    {
      title: 'Posso efetuar novos saques sem ter devolvido um saque anterior?',
      description: `
        Claro. Dentro do seu limite você pode fazer saques quantas vezes precisar.
      `,
    },
    {
      title: 'O limite inicial é baixo para mim. Como faço para aumentar este limite?',
      description: `
        Nosso relacionamento se baseia em parceria. Conforme você utiliza o Crédito
        Rotativo, nosso sistema vai modificando o seu limite até se encaixar no seu perfil.
        Dica: Devolva um valor sempre que puder, mesmo que não seja muito,
        mesmo que precise sacar novamente dias depois. E não atrase o pagamento dos juros.
      `,
    },
    {
      title: `
        Fiz um pedido de saque e fui informado que devo aguardar compensação do boleto de juros.
        Porque devo aguardar se não há atraso no pagamento?
      `,
      description: `
        Para que você tenha uma maior tranquilidade para pagamento, os boletos de juros são
        emitidos com dois dias de antecedência. Um acréscimo na conta neste período alteraria o
        valor dos juros após o boleto emitido. Caso tenha urgência, você pode antecipar o
        pagamento e seu pedido será atendido em seguida.
      `,
    },
  ];

  return (
    <StyledDuvidasFrequentes maxWidth='lg' name='duvidasfrequentes' id='duvidasfrequentes'>
      <Grid item sm={12} md={8}>
        <div className='title-container'>
          <Title>Dúvidas frequentes</Title>
        </div>

        <ExpansionPanel items={duvidasFrequentes} />
      </Grid>
    </StyledDuvidasFrequentes>
  );
};

export default DuvidasFrequentes;
