import React from 'react';
import styled from 'styled-components';
import { AZUL_400 } from '../../../utils/color';

const StyledTituloModal = styled.h2`
  margin-bottom: 0;
  color: ${AZUL_400};
  font-size: 1.2rem;
  text-align: center;

  @media (max-width: 375px) {
    font-size: 1rem;
  }
`;

const TituloModal = ({ children }) => (
  <StyledTituloModal>
    {children}
  </StyledTituloModal>
);

export default TituloModal;
