import styled from 'styled-components';
import {
  AZUL_300,
  CINZA_400,
  CINZA_600,
  VERMELHO,
} from '../../../../utils/color';
import { queries } from '../../../../utils/breakpoints';

export const StyledValoresFinalizacaoPedido = styled.div`
  .texto-valor-devolvido {
    color: ${CINZA_600};
    font-size: 0.89vw;
    width: 85%;
  }

  .subtitulo {
    color: ${CINZA_400};
  }

  .MuiGrid-grid-md-5 {
    max-width: 40%;
    flex-basis: 40%;
  }

  .MuiFormHelperText-root {
    min-width: 0;
    font-size: 0.9vw;
  }

  .cabecalho,
  .cabecalho .titulo {
    font-size: 1vw;
  }

  .limite-negativo .cabecalho {
    color: ${VERMELHO};
  }

  .rodape {
    font-size: 0.75vw;
    padding-top: 0.4vw;
    padding-bottom: 0.5vw;
    padding-left: 0.6vw;
  }

  .MuiDivider-root {
    margin: 0.3vw 0.6vw;
  }

  .MuiGrid-grid-md-7 {
    max-width: 60%;
    flex-basis: 60%;
  }

  .valor-debitar {
    padding: 0.1vw 1.5vw 2.1vw;
    width: 100%;
    height: 86%;

    .texto {
      margin-bottom: 0.9vw;
      width: 100%;
    }

    .cifrao,
    input {
      font-size: 1.6vw;
    }

    .cifrao {
      color: ${AZUL_300};
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid ${AZUL_300};
    }
  }

  @media ${queries.desktopUp} {
    .texto-valor-devolvido {
      font-size: 12px;
    }

    .cabecalho,
    .cabecalho .titulo {
      font-size: 14px;
    }

    .rodape {
      font-size: 10px;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      padding-left: 0.6rem;
    }

    .valor-debitar {
      padding: 2px 20px 15px;
      width: 100%;
      height: 90%;

      .titulo {
        font-size: 14px;
      }

      .texto {
        font-size: 10px;
        margin-bottom: 5px;
      }

      .MuiFormHelperText-root {
        font-size: 10px;
      }

      .cifrao,
      input {
        font-size: 22px;
      }
    }
  }

  @media ${queries.mobileDown} {
    padding: 0 2vw 9.4vw;

    .texto-valor-devolvido {
      font-size: 3.3vw;
      width: 100%;
    }

    .cabecalho .titulo {
      font-size: 3.9vw;
    }

    .cabecalho {
      padding: 3.8vw 4vw 2.6vw;
      font-size: 4.9vw;
    }

    .rodape {
      font-size: 3.35vw;
      padding-top: 2.8vw;
      padding-bottom: 2.6vw;
      padding-left: 4vw;
    }

    .MuiDivider-root {
      margin: 0 4vw;
    }

    .valor-debitar {
      padding: 2px 0 15px;
      min-height: 47vw;

      .titulo {
        font-size: 3.8vw;
        margin-top: 4.3vw;
      }

      .texto {
        margin-bottom: 5px;
        font-size: 3.2vw;
        margin-top: 3.8vw;
        line-height: 1.3;
      }

      .subtitulo {
        font-size: 3vw;
        margin-top: 0.2vw;
      }

      .MuiFormHelperText-root {
        font-size: 2.7vw;
      }

      .cifrao {
        font-size: 8vw;
      }

      input {
        font-size: 6.5vw;
      }
    }
  }
`;
