import * as types from './types';

const INITIAL_STATE = {
  nome: '',
  email: '',
  mensagem: '',
  isSending: false,
  enviadoComSucesso: false,
  errors: {},
  exibirChat: false
};

const contato = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_NOME:
      return { ...state, nome: action.value };
    case types.SET_EMAIL:
      return { ...state, email: action.value };
    case types.SET_MENSAGEM:
      return { ...state, mensagem: action.value };
    case types.ENVIAR_CONTATO_REQUEST:
      return {
        ...state,
        isSending: true,
        enviadoComSucesso: false,
        errors: {},
      };
    case types.ENVIAR_CONTATO_SUCCESS:
      return {
        ...state,
        isSending: false,
        enviadoComSucesso: true,
        errors: {},
      };
    case types.ENVIAR_CONTATO_ERROR: {
      const { errors } = action;
      return {
        ...state,
        isSending: false,
        enviadoComSucesso: false,
        errors,
      };
    }
    case types.DISPENSAR_NOTIFICACAO_DE_SUCESSO: {
      return {
        ...state,
        enviadoComSucesso: false,
      };
    }
    case types.EXIBE_CHAT:
      return { ...state, exibirChat: action.value };
    default:
      return state;
  }
};

export default contato;
