import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Home from './pages/Home';
import Cadastro from './pages/Cadastro';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import Simulacao from './pages/Simulacao';
import EsqueciSenha from './pages/EsqueciSenha';
import MinhaContaContainer from './pages/MinhaConta/MinhaContaContainer';
import EntrarComo from './pages/EntrarComo';
import PoliticaDePrivacidade from './pages/PoliticaDePrivacidade';
import { useHistory } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

const Routes = ({ auth: { isSignedIn } }) => {
  const { location: { pathname }} = useHistory();
  hotjar.stateChange(pathname);

  return (
    <Switch>
      <Route exact path='/'>
        <Home />
      </Route>
      <Route path='/cadastro'>
        <Cadastro />
      </Route>
      <Route path='/esquecisenha'>
        <EsqueciSenha />
      </Route>
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/entrarcomo'>
        <EntrarComo />
      </Route>
      <Route path='/simulacao'>
        <Simulacao />
      </Route>
      <Route path='/simulate/:userId/:token'>
        <EntrarComo />
      </Route>
      <Route path='/politica-de-privacidade'>
        <PoliticaDePrivacidade />
      </Route>
      <PrivateRoute auth={isSignedIn} path='/minhaconta'>
        <MinhaContaContainer />
      </PrivateRoute>
    </Switch>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Routes);
