import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import {
  AZUL_300,
  CINZA_500,
  CINZA_600,
  CINZA_700,
  CINZA_200_20,
  VERMELHO,
  CINZA_400,
} from '../../../../utils/color';

const AntecipacaoStyled = styled.div`
  color: ${CINZA_700};
  font-size: 0.9vw;
  margin-bottom: 2.3vw;

  .header {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    align-items: baseline;
  }

  .header-reprovado {
    justify-content: flex-start;

    .titulo {
      color: ${CINZA_600};
    }
  }

  .titulo {
    margin-left: 0.4vw;
    color: ${AZUL_300};
  }
  .status {
    margin-left: 0.4vw;
    color: ${VERMELHO};
    text-transform: uppercase;
    font-size: 0.75vw;
  }
  .botoes {
    .MuiButtonBase-root {
      background-color: ${CINZA_200_20};
      margin-left: 0.4vw;
      font-size: 0.65vw;
    }
    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 1vw;
    }

    .MuiButton-startIcon {
      margin-right: 4px;
    }

    .MuiButton-text {
      padding: 3px 8px;
    }
  }
  .identificacao {
    margin-top: 0.4vw;
    background-color: ${CINZA_200_20};
    padding: 0.6vw 0.4vw 0.4vw;

    span {
      width: 33.3%;
      display: inline-block;
    }
  }

  .identificacao-reprovado {
    .documento,
    .valor {
      font-weight: normal;
      color: ${CINZA_500};
    }
  }

  .nome {
    text-align: left;
  }
  .documento {
    text-align: center;
  }
  .valor {
    text-align: right;
    font-weight: bold;
    color: ${CINZA_700};
  }
  .motivo-recusa {
    margin: 0.4vw 0 -0.4vw 0.4vw;
    color: ${CINZA_600};
    font-size: 0.75vw;
  }

  .lista-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.4vw;
  }

  .lista-boletos {
    width: 51%;
  }
  .boleto {
    margin: 0.9vw 0.4vw;

    span {
      width: 27%;
      display: inline-block;
    }

    .data {
      width: 46%;
      text-align: center;
      padding-right: 1.6vw;
    }
  }

  .boleto-reprovado {
    color: ${CINZA_500};
  }

  @media ${queries.desktopUp} {
    font-size: 12px;
    margin-bottom: 30px;

    .titulo {
      margin-left: 5px;
    }
    .status {
      margin-left: 5px;
      font-size: 10px;
    }
    .botoes {
      .MuiButtonBase-root {
        margin-left: 5px;
        font-size: 10px;
      }
      .MuiButton-iconSizeMedium > *:first-child {
        font-size: 12px;
      }

      .MuiButton-startIcon {
        margin-right: 4px;
      }

      .MuiButton-text {
        padding: 3px 8px;
      }
    }

    .identificacao {
      margin-top: 5px;
      padding: 8px 5px 5px;
    }

    .motivo-recusa {
      margin: 5px 0 -5px 5px;
      font-size: 10px;
    }

    .lista-container {
      margin-top: 5px;
    }

    .boleto {
      margin: 12px 5px;

      .data {
        padding-right: 20px;
      }
    }
  }

  @media ${queries.mobileDown} {
    font-size: 3.3vw;
    margin-bottom: 9.5vw;

    .titulo {
      margin-left: 2.5vw;
      font-size: 3.35vw;
    }

    .header {
      margin-bottom: 1.4vw;
    }

    .header-reprovado .titulo {
      color: ${CINZA_400};
    }

    .status {
      margin-left: 3.4vw;
      font-size: 3.35vw;
    }

    .botoes {
      .MuiButtonBase-root {
        margin-left: 1.4vw;
        font-size: 2.4vw;
      }
      .MuiButton-iconSizeMedium > *:first-child {
        font-size: 3vw;
      }

      .MuiButton-startIcon {
        margin-right: 8px;
      }

      .MuiButton-text {
        padding: 3px 10px;
      }
    }

    .identificacao {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      padding: 2.6vw 2.2vw;

      span {
        width: 100%;
      }

      .documento {
        text-align: left;
        font-size: 2.8vw;
      }

      .valor {
        grid-row-end: span 2;
        place-self: center;
      }
    }

    .motivo-recusa {
      margin: 1.4vw 0 -1.4vw 3vw;
      font-size: 3vw;
    }

    .lista-container {
      justify-content: center;
      margin-top: 0.9vw;
    }

    .lista-boletos {
      width: 94%;
      font-size: 2.8vw;
    }
    .boleto {
      margin: 5.9vw 0.4vw;

      span {
        width: 26%;
      }

      .data {
        width: 46%;
        padding-right: 0;
      }

      .valor-parcela {
        text-align: right;
      }
    }
  }
`;

export default AntecipacaoStyled;
