import * as types from './types';
import { toNumber } from '../../utils/number';

const INITIAL_STATE = {
  valorSaque: '',
  valorSaqueRaw: '',
  dataSaque: null,
  valorDevolucao: '',
  valorDevolucaoRaw: '',
  dataDevolucao: null,
  saques: [],
  devolucoes: [],
  limiteDisponivel: 0,
  proximoPagamento: 0,
  movimentacoes: [],
  taxaJuros: '',
  enviadoComSucesso: false,
  etapaIntroducao: null,
  valorJuros: 0,
  valorFinal: 0,
  error: null,
  enviandoSimulacao: false,
  modalSaqueAberta: false,
  modalDevolucaoAberta: false,
};

const simulacao = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_VALOR_SAQUE:
      return {
        ...state,
        valorSaque: action.valorSaque,
        valorSaqueRaw: toNumber(action.valorSaque),
      };
    case types.SET_VALOR_DEVOLUCAO:
      return {
        ...state,
        enviadoComSucesso: false,
        valorDevolucao: action.valorDevolucao,
        valorDevolucaoRaw: toNumber(action.valorDevolucao),
      };
    case types.SET_DATA_DEVOLUCAO:
      return {
        ...state,
        dataDevolucao: action.dataDevolucao,
      };
    case types.SET_DATA_SAQUE:
      return {
        ...state,
        dataSaque: action.dataSaque,
      };
    case types.ADICIONAR_SAQUE:
      const novoSaque = action.novoSaque;
      return {
        ...state,
        enviadoComSucesso: false,
        saques: [...state.saques, novoSaque],
      };
    case types.ADICIONAR_DEVOLUCAO:
      const novaDevolucao = action.novaDevolucao;
      return {
        ...state,
        enviadoComSucesso: false,
        devolucoes: [...state.devolucoes, novaDevolucao]
      };
    case types.ENVIAR_SIMULACAO_REQUEST:
      return {
        ...state,
        enviadoComSucesso: false,
        enviandoSimulacao: true,
        errors: null,
      };
    case types.ENVIAR_SIMULACAO_SUCCESS:
      return {
        ...state,
        limiteDisponivel: action.limiteDisponivel,
        proximoPagamento: action.proximoPagamento,
        movimentacoes: action.movimentacoes,
        taxaJuros: action.taxaJuros,
        enviadoComSucesso: true,
        enviandoSimulacao: false,
        errors: null,
        valorSaque: '',
        valorSaqueRaw: '',
        valorDevolucao: '',
        valorDevolucaoRaw: '',
        dataSaque: null,
        dataDevolucao: null,
      };
    case types.ENVIAR_SIMULACAO_ERROR: {
      const { errors } = action;
      return {
        ...state,
        enviadoComSucesso: false,
        enviandoSimulacao: false,
        errors,
      };
    }
    case types.ZERAR_SIMULACAO:
      return {
        ...state,
        valorSaque: '',
        valorSaqueRaw: '',
        valorDevolucao: '',
        valorDevolucaoRaw: '',
        saques: [],
        devolucoes: [],
        movimentacoes: [],
        errors: null,
      }
    case types.SET_ETAPA_INTRODUCAO:
      return {
        ...state,
        etapaIntroducao: action.etapaIntroducao,
      };
    case types.FETCH_DADOS_SAQUE_REQUEST:
      return {
        ...state,
        errors: null,
      };
    case types.FETCH_DADOS_SAQUE_SUCCESS:
      return {
        ...state,
        valorJuros: action.valorJuros,
        valorFinal: action.valorFinal,
        errors: null,
      };
    case types.FETCH_DADOS_SAQUE_ERROR: {
      const { errors } = action;
      return {
        ...state,
        errors,
      };
    }
    case types.SET_MODAL_SAQUE_ABERTA:
      return {
         ...state,
         modalSaqueAberta: action.aberta,
      };
    case types.SET_MODAL_DEVOLUCAO_ABERTA:
      return {
        ...state,
        modalDevolucaoAberta: action.aberta,
      };
    default:
      return state;
    }
};

export default simulacao;
