import React from 'react';
import { Grid, MenuItem, Select, useMediaQuery } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import RoundButton from '../../../components/RoundButton';
import { AZUL_300, CINZA_700 } from '../../../utils/color';
import {
  fetchMovimentacoes as fetchMovimentacoesAction,
  setlimiteDias as setlimiteDiasAction,
} from '../../../store/conta/actions';
import IconeCalendario from '../../../components/IconesSvg/IconeCalendario';

const StyledFiltroMovimentacoes = styled(Grid)`
  padding-bottom: 1.7rem;
  color: ${CINZA_700};
  text-align: center;

  .fitro-titulo {
    margin-bottom: .5rem;
    padding-left: .5rem;
    font-size: 1rem;
    text-align: left;
  }

  .filtro-icone {
    display: none;
  }

  .filtro-select {
    width: 100%;
    text-align: left;
  }

  .filtro-select svg {
    color: ${AZUL_300};
  }

  .MuiOutlinedInput-input {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .botao-consultar {
    text-transform: uppercase;
    margin-top: 10px;
  }

  @media (max-width: 960px) {
    display: flex;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;

    .fitro-titulo {
      padding-left: .5rem;
      font-size: .8rem;
      text-transform: uppercase;
    }

    .filtro-icone {
      width: 45px;
      display: inline-grid;
      font-size: 2.2rem;
    }

    .filtro-select-container {
      display: flex;
      width: 50%;
      justify-content: center;
      align-items: center;
    }

    .filtro-select {
      margin-left: .5rem;
    }

    .MuiOutlinedInput-input {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .botao-consultar {
      display: none;
    }
  }

  @media (max-width: 375px) {
    .fitro-titulo {
      font-size: .7rem;
    }
  }
`;

export const FiltroMovimentacoes = ({
  limiteDias,
  setlimiteDias,
  fetchMovimentacoes,
}) => {
  const isMobile = useMediaQuery('(max-width:960px)');
  const titulo = isMobile ? 'Movimentações' : 'Filtrar movimentações';

  const handleChange = ({ target: { value } }) => {
    setlimiteDias(value);
    if (isMobile) {
      fetchMovimentacoes();
    }
  };

  return (
    <StyledFiltroMovimentacoes item sm={12} md={10} lg={9}>
      <h2 className='fitro-titulo'>{titulo}</h2>
      <div className='filtro-select-container'>
        <div className='filtro-icone'>
          <IconeCalendario />
        </div>
        <Select
          id='filtro-movimentacoes'
          variant='outlined'
          className='filtro-select'
          IconComponent={KeyboardArrowDownIcon}
          value={limiteDias}
          onChange={handleChange}
        >
          <MenuItem value={30}>30 dias</MenuItem>
          <MenuItem value={60}>60 dias</MenuItem>
          <MenuItem value={90}>90 dias</MenuItem>
          <MenuItem value={180}>180 dias</MenuItem>
        </Select>
      </div>
      <RoundButton
        value='Consultar'
        color='primary'
        size='small'
        className='botao-consultar'
        onClick={fetchMovimentacoes}
        bold
      />
    </StyledFiltroMovimentacoes>
  );
};

FiltroMovimentacoes.propTypes = {
  limiteDias: PropTypes.number.isRequired,
  setlimiteDias: PropTypes.func.isRequired,
  fetchMovimentacoes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  limiteDias: state.conta.limiteDias,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setlimiteDias: setlimiteDiasAction,
  fetchMovimentacoes: fetchMovimentacoesAction,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FiltroMovimentacoes);
