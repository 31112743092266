import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { hotjar } from 'react-hotjar';
import { store, persistor } from './store';
import { logout } from './store/auth/actions';
import theme from './theme';
import Routes from './Routes';
import Api from './Api';
import './App.css';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper';
import WidgetWhatsApp from './components/WidgetWhatsApp';
import CookieConsentPopup from './components/CookieConsentPopup';

// Intercepta caso erro = 401 (Forbidden)
const { dispatch } = store;
Api.api.interceptors.response.use(
  (res) => res,
  (err) => {
    const error = err.response;
    if (error.status === 401) {
      Api.clearToken();
      dispatch(logout());
    }
    return Promise.reject(err);
  }
);

export default function App() {
  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
    const { token } = store.getState().auth;
    Api.setToken(token);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className='App'>
            <Helmet>
              <meta charset='utf-8' />
              <title>Crédito Rotativo</title>
              <link rel="canonical" href="https://creditorotativo.com.br" />
              <meta name="description" content="Uma nova forma de crédito para micro e pequenas empresas. Saques sem burocracia e devoluções sem data fixa." />
              <meta property="og:title" content="Crédito Rotativo" />
              <meta property="og:type" content="website" />
              <meta property="og:url" content="https://creditorotativo.com.br/" />
              <meta property="og:image" content="/logo_completo.jpg" />
              <meta property="og:description" content="Uma nova forma de crédito para micro e pequenas empresas. Saques sem burocracia e devoluções sem data fixa." />
              <meta property="og:site_name" content="Crédito Rotativo" />
              <meta name="twitter:title" content="Crédito Rotativo" />
              <meta name="twitter:description" content="Uma nova forma de crédito para micro e pequenas empresas. Saques sem burocracia e devoluções sem data fixa." />
              <meta name="twitter:image" content="/logo_completo.jpg" />

              {process.env.NODE_ENV !== 'production' && (
                <meta name='robots' content='noindex, nofollow' />
              )}
            </Helmet>
            <WidgetWhatsApp />
            <ServiceWorkerWrapper />
            <CookieConsentPopup />
            <Routes />
          </div>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}
