import { postDevolucao } from './api';
import * as types from './types';

export const setValorDevolucao = (valorDevolucao) => ({
  type: types.SET_VALOR_DEVOLUCAO,
  valorDevolucao,
});

export const enviarDevolucaoRequest = () => ({
  type: types.ENVIAR_DEVOLUCAO_REQUEST,
});

export const enviarDevolucaoSuccess = ({ status }) => ({
  type: types.ENVIAR_DEVOLUCAO_SUCCESS,
  status,
});

export const enviarDevolucaoFailure = (error) => ({
  type: types.ENVIAR_DEVOLUCAO_ERROR,
  error,
});

export const enviarDevolucao = (files = []) => (
  async (dispatch, getState) => {
    dispatch(enviarDevolucaoRequest());

    const state = getState();
    const { valorDevolucaoRaw: valor } = state.devolucao;
    const recibo = files[0];

    const payload = {
      valor,
      recibo,
    };

    try {
      const response = await postDevolucao(payload);
      const status = response?.data?.data?.status || 'Requisitado';
      dispatch(enviarDevolucaoSuccess({ status }));
    }
    catch (error) {
      const data = error?.response?.data?.message;
      dispatch(enviarDevolucaoFailure(data));
    }
  }
);

export const setTipoDevolucao = (tipoDevolucao) => ({
  type: types.SET_TIPO_DEVOLUCAO,
  tipoDevolucao,
});
