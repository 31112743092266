import DateFnsUtils from '@date-io/date-fns';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import { endOfMonth } from 'date-fns/esm';
import { ptBR } from 'date-fns/locale';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  alterarDataParcela,
  excluirParcela,
} from '../../../../store/antecipacao/actions';
import { Parcela as IParcela } from '../../../../store/antecipacao/types';
import { ParcelaStyle } from './Parcela.style';

interface ParcelaProps {
  numeroParcela: number;
  alterarDataParcela: (parcela: IParcela, index: number) => void;
  excluirParcela: (index: number) => void;
  parcela: IParcela;
}

const Parcela = ({
  numeroParcela,
  alterarDataParcela,
  excluirParcela,
  parcela,
}: ParcelaProps) => {
  const [data, setData] = useState<Date>(parcela.vencimento);
  const maxDate = endOfMonth(addMonths(new Date(), 18));
  const minDate = addDays(new Date(), 2);

  useEffect(() => {
    setData(parcela.vencimento);
  }, [parcela]);

  useEffect(() => {
    alterarDataParcela({ vencimento: data, valor: 0 }, numeroParcela);
  }, [data, alterarDataParcela, numeroParcela]);

  return (
    <ParcelaStyle>
      <div className='parcela'>
        <p className='boleto'>{numeroParcela + 1}º Boleto</p>
        <div className='data'>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <DatePicker
              disableToolbar
              autoOk
              variant='inline'
              format='dd/MM/yyyy'
              placeholder='dd/mm/aaaa'
              id={`data-parcela-${numeroParcela}`}
              value={data}
              onChange={setData}
              maxDate={maxDate}
              minDate={minDate}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <IconButton onClick={() => excluirParcela(numeroParcela)}>
        <Delete fontSize='small' />
      </IconButton>
    </ParcelaStyle>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      alterarDataParcela,
      excluirParcela,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Parcela);
