import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import bgLogin from '../assets/bglogin.png';
import LinkSublinhado from '../components/LinkSublinhado';
import Logo from '../components/Logo';
import Notification from '../components/Notification';
import * as AuthActions from '../store/auth/sagas';

const View = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const BackgroundContainer = styled(Container)`
  @media (min-width: 1076px) {
    background-image: url(${bgLogin});
    padding: 3vh;
  }
  background-size: cover;
  background-position: center center;
  max-width: none;
`;

const EntrarComo = (props) => {
  const { auth, signInAs, signOut } = props;
  const { error, isLoading, isSignedIn } = auth;
  const [showAlert, setShowAlert] = useState(Boolean(error));

  const { userId, token } = useParams();

  useEffect(() => {
    if (!isSignedIn) {
      signOut();
    }
  }, [isSignedIn, signOut]);

  useEffect(() => {
    if (!isLoading && !isSignedIn) {
      signInAs({
        userId,
        token
      });
    }
  }, [isLoading, isSignedIn, signInAs, token, userId]);

  useEffect(() => {
    if (isLoading) {
      setShowAlert(false);
    } else {
      setShowAlert(!!error);
    }
  }, [error, isLoading]);

  return (
    <BackgroundContainer>
      {isSignedIn && <Redirect to='/minhaconta' />}
      <View style={{ maxWidth: '700px', height: '100vh', padding: '5vh' }}>
        <RouterLink to='/' style={{ paddingRight: 10, paddingLeft: 10 }}>
          <Logo />
        </RouterLink>

        <View>
          <Notification
            open={showAlert}
            onClose={() => setShowAlert(false)}
            severity='error'
            message={error}
          />
          {error}
          { isLoading && <CircularProgress /> }
        </View>

        <View maxWidth='sm'>
          <LinkSublinhado to='/esquecisenha' bold>
            Esqueci minha senha
          </LinkSublinhado>

          <Link
            style={{ fontWeight: 'bold' }}
            component={RouterLink}
            to='/cadastro'
          >
            Ainda não possui cadastro?
          </Link>
          <Link component={RouterLink} to='/cadastro'>
            Clique aqui para criar sua conta
          </Link>
        </View>
      </View>
    </BackgroundContainer>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(AuthActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EntrarComo);
