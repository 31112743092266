import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

const StyledLink = styled.a`
  position: fixed;
	width: 60px;
	height: 60px;
	bottom: 25px;
	right: 20px;
  z-index: 100;
	border-radius: 50px;
	background-color: #25d366;
	color: #FFF;
  box-shadow: rgb(0 0 0 / 8%) 2px 2px 4px 4px;
	text-align: center;
`;

const StyledWhatsAppIcon = styled(WhatsAppIcon)`
  margin: 12px;
`;

const WidgetWhatsApp = ({ whatsAppAtivo, linkWhatsApp }) => {
  if(!whatsAppAtivo) {
    return null
  }

  return (
    <StyledLink href={linkWhatsApp} target="_blank">
      <StyledWhatsAppIcon fontSize="large" />
    </StyledLink>
  );
};

const mapStateToProps = (state) => ({
  whatsAppAtivo: state.configuracoes.whatsAppAtivo,
  linkWhatsApp: state.configuracoes.linkWhatsApp
});

export default connect(mapStateToProps)(WidgetWhatsApp);
