import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import { Link } from '@material-ui/core';
import { CINZA_700 } from '../../../utils/color';
import { setEtapaIntroducao } from '../../../store/simulacao/actions';
import Icone from '../../../components/Icone';

const StyledLink = styled(Link)`
  margin: 5px;
  .simulacao-entenda-conta-descricao {
    display: inline-block;
    color: ${CINZA_700};
    border-bottom: 2px solid ${CINZA_700};
    font-size: .9rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  svg {
    position: absolute;
    margin-left: .2rem;
    color: ${CINZA_700};
    font-size: 1rem;
  }
`;

export const BotaoEntendaConta = ({ setEtapa }) => (
  <StyledLink
    component='button'
    onClick={() => setEtapa(1)}
  >
    <span className='simulacao-entenda-conta-descricao'>Entenda sua conta</span>
    <Icone name='ajuda' />
  </StyledLink>
);

BotaoEntendaConta.propTypes = {
  setEtapa: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setEtapa: setEtapaIntroducao,
}, dispatch);

export default connect(null, mapDispatchToProps)(BotaoEntendaConta);
