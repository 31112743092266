import React from 'react';
import PropTypes from 'prop-types';
import Base from './Base';

const descricao = 'Prossiga esta simulação, devolvendo algum valor ou fazendo novo saque e perceba como você tem total controle sobre sua dívida.'

const EtapaCinco = ({ children }) => (
  <Base
    etapaAnterior={4}
    etapaAtual={5}
    descricao={descricao}
  >
    {children}
  </Base>
);

EtapaCinco.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EtapaCinco;
