import Api from "../../Api";

export const signUpUserEmpresa = ({ usuario, empresa }) =>
  Api.post('cadastro', {
    usuario,
    empresa,
  });

export const postValidarCodigoPromocional = (codigoPromocional) =>
Api.post('validar-codigo-promocional', {
  codigoPromocional,
});
