import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepButton, StepConnector } from '@material-ui/core';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

const StyledStepper = styled(Stepper)`
  padding: 1rem 0.7rem 3.5rem 0.7rem;

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #007fc4;
  }

  .MuiStepIcon-root {
    color: #dae1e5;
  }

  &.mostrar-texto {
    .MuiStepIcon-root {
      font-size: 2.5rem;
    }
  }
`;

const StyledStep = styled(Step)`
  &.mostrar-texto {
    padding: 0;

    span {
      padding: 0;
    }

    text {
      font-weight: bold;
      font-size: 1.2rem;
      transform: translate(0px, 3px);
    }
  }

  &.esconder-texto {
    text {
      display: none;
    }
  }
`;

const StyledConnector = withStyles({
  line: {
    borderColor: '#DAE1E5',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const MobileStepper = ({
  isMobile,
  setActiveStep,
  activeStep,
  numOfSteps,
  hideText,
}) => {
  const onStepClick = (step) => () => setActiveStep(step);

  if (!isMobile) {
    return null;
  }

  return (
    <StyledStepper
      nonLinear
      activeStep={activeStep - 1}
      connector={<StyledConnector />}
      className={!hideText && 'mostrar-texto'}
    >
      {Array.from({ length: numOfSteps }).map((_, index) => (
        <StyledStep className={hideText ? 'esconder-texto' : 'mostrar-texto'}>
          <StepButton onClick={onStepClick(index + 1)} />
        </StyledStep>
      ))}
    </StyledStepper>
  );
};

MobileStepper.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  numOfSteps: PropTypes.number.isRequired,
  hideText: PropTypes.bool,
};

export default MobileStepper;
