import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import YoutubeEmbed from '../../../components/YoutubeEmbed';
import { AZUL_300, AZUL_RA_BG_OPACITY, CINZA_100, LARANJA_VERMELHO } from '../../../utils/color';
import BGRecebaAntes from '../../../assets/home_receba_antes.jpg';
import BGRecebaAntesMobile from '../../../assets/home_receba_antes_mobile.png';
import LogoRABrancoFull from '../../../components/IconesSvg/LogoRecebaAntesBrancoFull';
import RoundButton from '../../../components/RoundButton';
import { Hidden } from '@material-ui/core';

import { queries } from '../../../utils/breakpoints';

const LinhaCinza = styled.div`
  width 100%;
  background-color: white;
  height: 5px;
`;

const StyledSecaoRecebaAntes = styled.div`
  width: 100%;
  margin-bottom: 6rem;
  padding-bottom: 2rem;

  h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: ${AZUL_300};
    font-size: 1.6rem;
    margin: 2rem 0 0 12rem;

    @media ${queries.tabletDown} {
      font-size: 1rem;
      margin-left: 2vw;
      margin-bottom: 0.5rem;
    }

  }

  .container-principal {
    background-image: url(${BGRecebaAntes});
    background-size: cover;
    height: 768px;
    @media ${queries.mobileDown} {
      background-image: url(${BGRecebaAntesMobile});
      background-position: 47% 0%;
      background-size: cover;
      height: 70vh;

      a {
        margin: auto;
        width: 70vw;
        display: block;
      }
    }

    @media ${queries.mobileUp} and ${queries.tabletDown} {
      background-image: url(${BGRecebaAntesMobile});
      background-position: 47% 0%;
      background-size: cover;
      height: 60vh;

      a {
        margin: auto;
        width: 70vw;
        display: block;
      }

    }

    .bg-azul {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      background: ${AZUL_RA_BG_OPACITY};
      mix-blend-mode: normal;
      border-bottom-left-radius: 250px;

      @media ${queries.tabletDown} {
        border-bottom-left-radius: 0px;
      }
    }

    .titulo-container {
      margin-left: 12vw;
      margin-top: 6.5rem;
      margin-bottom: 4rem;
      display: flex;
      grid-column-start: 1;
      grid-column-end: 3;

      @media ${queries.tabletDown} {
        margin: 1rem auto 2rem auto;
        justify-content: center;
      }

      svg {
        width: 771px;
        height: 80px;

        @media ${queries.tabletDown} {
          max-width: 86%;
          height: 40px;
          margin-top: 1rem;
          margin-bottom: 0rem;
        }
      }

      h3 {
        color: white;
        text-transform: uppercase;

      }
    }

    .cta-container {
      margin-left: 12vw;
      width: 100%;
      grid-column-start: 1;
      grid-column-end: 1;
      display: flex;
      flex-direction: column;
      max-width: 800px;

      @media ${queries.tabletDown} {
        justify-content: center;
        margin-left: 0vw;
        grid-column-end: 3;
      }

      p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        color: white;
        font-size: 2rem;

        @media ${queries.tabletDown} {
          font-size: 1.4rem;
          text-align: center;
        }

        span {
          text-transform: uppercase;
          background: ${LARANJA_VERMELHO};
          border-radius: 30px;
          padding: 0px 12px 1px;
        }
      }

      .cta-button {
        color: white;
        background-color: ${LARANJA_VERMELHO};
        margin-top: 3rem;
        font-size: 2.2rem;
        padding: 0.7rem 6rem;
        max-width: 530px;
        border-radius: 60px;

        @media ${queries.desktopUp} {
          font-size: 2.2rem;
          padding: 0.85rem 3.5rem;
        }

        &:hover {
          opacity:1;
          filter: brightness(1.05);
        }
    }
  }

  .cta-button {
    color: white;
    background-color: ${LARANJA_VERMELHO};
    margin-top: 3rem;
    font-size: 2.2rem;
    padding: 0.7rem 6rem;
    max-width: 530px;
    border-radius: 60px;

    &:hover {
      opacity:1;
      filter: brightness(1.05);
    }

    @media ${queries.mobileDown} {
      margin: 3rem 0;
      font-size: 1.2rem;
      padding: 0.6rem;
      width: 100%;
    }

    @media ${queries.mobileUp} and ${queries.tabletDown} {
      margin: 15rem 12.5%;
      font-size: 1.4rem;
      padding: 0.6rem;
      width: 75%;
    }
  }

  .video-container {
      background: ${CINZA_100};
      z-index:1;
      grid-column-start: 2;
      grid-column-end: 10;
      display: flex;
      position: relative;
      width: 88%;
      top: 7%;
      left: -3%;
      height: 56vh;

      @media ${queries.mobileUp} and ${queries.tabletDown} {
        width: 191%;
        height: 40vh;
        display: block;
        position: relative;
        left: -91%;
        top: 0;
        padding: 5px;
      }

      @media ${queries.mobileDown} {
        width: 200%;
        height: 28vh;
        display: block;
        position: relative;
        left: -100%;
        top: 0;
        padding: 5px;
      }
   }
}



`;

const SecaoRecabaAntes = ({isSignedIn}) => {
  const CallToActionButton = () => {
    const path = isSignedIn ? '/minhaconta' : '/cadastro';
    const buttonLabel = isSignedIn ? 'Acesse sua conta' : 'Crie sua conta';

    return (
      <Link to={path}>
        <RoundButton value={buttonLabel} color={'default'} bold={true} className="cta-button" />
      </Link>
    );
  };

  const youtubeId = 'Mg0rOkIuf_g';

  return (
    <StyledSecaoRecebaAntes>
      <h3>Conheça o nosso novo produto:</h3>
      <div className="container-principal" >
        <div className="bg-azul">
          <div className="titulo-container">
            <LogoRABrancoFull  />
          </div>
          <div className="cta-container">
            <p><span>antecipe</span> as parcelas <br/> da sua venda a prazo</p>
            <LinhaCinza />
            <p>maior <span>estabilidade</span> e <br/> poder de negociação <br/> para sua empresa</p>
            <Hidden smDown>
              <CallToActionButton />
            </Hidden>
          </div>
          <div className="video-container">
            <YoutubeEmbed embedId={youtubeId} />
          </div>
        </div>
          <Hidden mdUp>
            <CallToActionButton/>
          </Hidden>
      </div>
    </StyledSecaoRecebaAntes>

  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
});

export default connect(
  mapStateToProps,
)(SecaoRecabaAntes);

