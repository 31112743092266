import React from 'react';
import { Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { selectNomeEmpresa } from '../store/auth/selectors';

const StyledItemMenuAutenticadoMobile = styled.li`
  &:not(:last-child) > a {
    border-bottom: 0;
    font-size: .875rem;
  }
`;

const ItemMenuAutenticadoMobile = ({ isSignedIn, nomeEmpresa }) => {
  if (isSignedIn) {
    return (
      <Hidden mdUp>
        <StyledItemMenuAutenticadoMobile>
          <Link to='/minhaconta'>
            Olá, <b>{nomeEmpresa}</b>
          </Link>
        </StyledItemMenuAutenticadoMobile>
      </Hidden>
    );
  };

  return (
    <Hidden mdUp>
      <StyledItemMenuAutenticadoMobile>
        <Link to='login'>Criar conta / entrar</Link>
      </StyledItemMenuAutenticadoMobile>
    </Hidden>
  );
};

ItemMenuAutenticadoMobile.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  nomeEmpresa: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  nomeEmpresa: selectNomeEmpresa(state),
});

export default connect(
  mapStateToProps,
  null
)(ItemMenuAutenticadoMobile);
