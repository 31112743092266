const selectErrorByAttribute = (state, attribute) => (
  state.contaBancaria.errors && state.contaBancaria.errors[attribute] && state.contaBancaria.errors[attribute][0]
);

export const selectBancoIdError = (state) => selectErrorByAttribute(state, 'bancoId');
export const selectAgenciaError = (state) => selectErrorByAttribute(state, 'agencia');
export const selectContaError = (state) => selectErrorByAttribute(state, 'conta');

export const selectTipoDeContaPJ = (state) => {
  const contaPessoaJuridica = state.contaBancaria.tipo === 'Pessoa Jurídica'

  return contaPessoaJuridica;
};

export const selectTitularidade = (state) => {
  const titularidade = selectTipoDeContaPJ(state) ? state.minhaConta.razaoSocial : state.minhaConta.nomeSocio

  return titularidade;
};

export const selectDocumento = (state) => {
  const documento = selectTipoDeContaPJ(state) ? state.minhaConta.cnpj : state.minhaConta.cpf

  return documento;
};
