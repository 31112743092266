import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Dica from './Dica';
import Icone from './Icone';

const Wrapper = ({ children, dica, placement, onClick }) => {
  if (!dica) {
    return <>{children}</>;
  }

  return (
    <Dica dica={dica} placement={placement} onChildrenClick={onClick}>
      {children}
    </Dica>
  );
};

const BotaoDeIcone = ({ icone, dica, placement, className, onClick }) => (
  <Wrapper dica={dica} placement={placement} onClick={onClick}>
    <IconButton className={className} onClick={onClick}>
      <Icone name={icone} />
    </IconButton>
  </Wrapper>
);

BotaoDeIcone.defaultProps = {
  onClick: () => { },
  dica: '',
  className: '',
};

BotaoDeIcone.propTypes = {
  icone: PropTypes.string.isRequired,
  dica: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  placement: PropTypes.string,
};

export default BotaoDeIcone;
