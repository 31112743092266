import React from 'react';
import PropTypes from 'prop-types';
import logo from '../assets/logo_full.png';

const Logo = ({ width, height, style }) => (
  <img src={logo} alt='logo' width={width} height={height} style={style} />
);

Logo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Logo;
