import { Link } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../store';
import { selectHaPedidoEmAndamento } from '../../../store/recebaAntes/selectors';
import { queries } from '../../../utils/breakpoints';

import { AZUL_300 } from '../../../utils/color';
import IconePedidoEmAndamento from '../../IconesSvg/IconePedidoEmAndamento';

const PedidoAndamento = styled(Link)`
  padding: 0.6vw;
  svg {
    font-size: 1.7vw;
    margin-right: 0.5vw;
  }
  span {
    font-size: 0.95vw;
    color: ${AZUL_300};
    font-weight: bold;
    text-decoration: underline;
  }

  @media ${queries.desktopUp} {
    padding: 0.5rem;
    svg {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
    span {
      font-size: 0.8rem;
    }
  }

  @media ${queries.mobileDown} {
    margin-top: 3vw;
    svg {
      font-size: 6vw;
      margin-right: 2.1vw;
    }
    span {
      font-size: 5vw;
    }
  }
`;

interface PedidosAndamentoProps {
  haPedidoEmAndamento: boolean;
}

const PedidoEmAndamento = ({ haPedidoEmAndamento }: PedidosAndamentoProps) => {
  if (!haPedidoEmAndamento) return <></>;

  return (
    <PedidoAndamento
      href='recebaantes/pedidoantecipacao'
      data-cy='link-pedido-em-andamento'
    >
      <IconePedidoEmAndamento />
      <span>1 pedido em andamento</span>
    </PedidoAndamento>
  );
};

const mapStateToProps = (state: RootState) => ({
  haPedidoEmAndamento: selectHaPedidoEmAndamento(state),
});

export default connect(mapStateToProps)(PedidoEmAndamento);
