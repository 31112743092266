import styled from 'styled-components';

import { queries } from '../../../../utils/breakpoints';
import { AZUL_300, CINZA_500 } from '../../../../utils/color';

export const ValoresStyle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.95vw;
  padding: 1vw 0vw 1.4vw;

  .boleto {
    font-size: 0.95vw;
    font-weight: bold;
    margin: 0;
  }

  .data {
    padding-left: 1.1vw;
  }

  input {
    color: ${CINZA_500};
    font-size: 0.95vw;
    padding: 0;
  }

  .MuiTextField-root {
    margin-top: 0;
  }

  input {
    width: 100%;
  }

  .valor {
    width: 30%;
  }

  .MuiInput-formControl {
    display: inline-flex;
    align-items: baseline;
  }
  .MuiInputAdornment-positionStart p {
    font-size: 0.95vw;
  }

  .MuiInput-underline:before {
    border-bottom-color: ${AZUL_300};
  }

  @media ${queries.mobileDown} {
    font-size: 3.2vw;
    padding: 4vw 0vw 4vw;

    .boleto {
      font-size: 3.6vw;
    }

    input {
      font-size: 3.4vw;
    }

    .MuiInputAdornment-positionStart p {
      font-size: 3.4vw;
    }
  }

  @media ${queries.desktopUp} {
    font-size: 14px;
    padding: 12px 0px 18px;

    .boleto {
      font-size: 14px;
    }

    .valor {
      width: 90px;
    }

    input {
      font-size: 14px;
    }

    .MuiInputAdornment-positionStart p {
      font-size: 14px;
    }
  }
`;
