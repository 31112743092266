import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { AZUL_300 } from '../../../utils/color';
import BotaoAdicionar from './BotaoAdicionar';
import ContaBancaria from './ContaBancaria';
import ZeroState from './ZeroState';

const StyledContasBancarias = styled.div`
  .contas-bancarias-titulo {
    color: ${AZUL_300};
    font-weight: bold;
  }

  .contas-bancarias-lista {
    position: relative;
  }
`;

interface ContasBancariasProps {
  contasBancarias?: any[];
  titulo: string;
  radioGroup?: boolean;
  selectedRadioGroupValue?: string | number;
  onRadioGroupChange?: (id: string | number) => void;
  hideButton: boolean;
  emptyState?: React.ReactNode;
}

const ContasBancarias = ({
  titulo,
  contasBancarias,
  radioGroup,
  selectedRadioGroupValue,
  onRadioGroupChange,
  hideButton,
  emptyState,
}: ContasBancariasProps) => (
  <StyledContasBancarias className='contas-bancarias'>
    <h3 className='contas-bancarias-titulo'>{titulo}</h3>
    {contasBancarias.length > 0 ? (
      <div className='contas-bancarias-lista'>
        {contasBancarias.map((contaBancaria) => (
          <ContaBancaria
            key={contaBancaria.id}
            radioGroup={radioGroup}
            selectedRadioGroupValue={selectedRadioGroupValue}
            onRadioGroupChange={onRadioGroupChange}
            contaBancaria={contaBancaria}
          />
        ))}
      </div>
    ) : (
      emptyState || <ZeroState />
    )}
    {(contasBancarias.length > 0 || emptyState) && hideButton ? null : (
      <BotaoAdicionar />
    )}
  </StyledContasBancarias>
);

const mapStateToProps = (state) => ({
  contasBancarias: state.contaBancaria.contasBancarias,
});

export default connect(mapStateToProps)(ContasBancarias);
