import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import styled from 'styled-components';
import BotaoDeIcone from './BotaoDeIcone';
import RoundButton from './RoundButton';
import Icone from './Icone';
import { AZUL_300, BRANCO, CINZA_600 } from '../utils/color';

const StyledDialog = styled(Dialog)`
  .botao-de-acao {
    display: block;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0;
    padding-bottom: 1.5rem;
  }

  .botao-de-acao span {
    color: ${BRANCO};
    text-transform: uppercase;
  }


  .botao-de-fechar {
    position: absolute;
    top: 0;
    right: 5px;
    color: ${AZUL_300};
  }

  .titulo {
    padding-top: 1.5rem;
    padding-bottom: 0;
  }

  .titulo h2 {
    color: ${AZUL_300};
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .titulo-com-icone {
    padding-bottom: 0;
  }

  .titulo-com-icone h2 {
    padding-bottom: 0;
    color: ${CINZA_600};
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }

  .titulo-com-icone img {
    display: block;
    width: 70px;
    margin-right: auto;
    margin-left: auto;
    padding: 2rem 0 1rem 0;
  }

  .conteudo-sem-icone {
    margin-bottom: 0;
    padding: 0 .5rem 1rem .5rem;
  }

  .conteudo-com-icone {
    padding: 0rem 1.5rem 1rem 1.5rem;
    text-align: center;
  }
`;
const Modal = ({
  open,
  onClose,
  fullWidth,
  maxWidth,
  titulo,
  icone,
  conteudo,
  botaoLabel,
  botaoColor,
  onConfirm,
  exibirBotao,
  style,
  botaoLoading,
}) => {
  if (!open) {
    return null;
  }

  const tituloClassName = `titulo${icone ? '-com-icone' : ''}`;
  const conteudoClassName = `conteudo${icone ? '-com-icone' : '-sem-icone'}`;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle className={tituloClassName}>
        {icone && <Icone name={icone} />}
        {titulo}
        {!icone && <BotaoDeIcone className='botao-de-fechar' icone='close' onClick={onClose} />}
      </DialogTitle>

      <DialogContent className={conteudoClassName} style={style}>
        {conteudo}
      </DialogContent>

      {exibirBotao && (
        <DialogActions className='botao-de-acao'>
          <RoundButton
            value={botaoLabel}
            color={botaoColor}
            size='small'
            bold='bold'
            onClick={onConfirm}
            loading={botaoLoading}
          />
        </DialogActions>
      )}
    </StyledDialog>
  );
};

Modal.defaultProps = {
  icone: '',
  fullWidth: true,
  maxWidth: 'sm',
  botaoLabel: 'Ok',
  botaoColor: 'primaryDark',
  onConfirm: () => { },
  exibirBotao: true,
  titulo: '',
  botaoLoading: false,
  style: {}
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  titulo: PropTypes.string,
  icone: PropTypes.string,
  conteudo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  botaoLabel: PropTypes.string,
  botaoColor: PropTypes.string,
  onConfirm: PropTypes.func,
  exibirBotao: PropTypes.bool,
  botaoLoading: PropTypes.bool,
};

export default Modal;
