export enum AntecipacaoActionTypes {
  SET_DADOS_ANTECIPACAO = 'antecipacao/SET_DADOS_ANTECIPACAO',
  ENVIA_ARQUIVO_REQUEST = 'antecipacao/ENVIA_ARQUIVO_REQUEST',
  ENVIA_ARQUIVO_SUCCESS = 'antecipacao/ENVIA_ARQUIVO_SUCCESS',
  ENVIA_ARQUIVO_ERROR = 'antecipacao/ENVIA_ARQUIVO_ERROR',
  ALTERAR_PARCELAS = 'antecipacao/ALTERAR_PARCELAS',
  ALTERAR_SOMATORIA = 'antecipacao/ALTERAR_SOMATORIA',
}

export interface Antecipacao {
  sacado: SacadoAntecipacao;
  valorNotaFiscal: number;
  nomeArquivoNotaFiscal: string;
  idArquivoNotaFiscal?: number;
  erroUploadArquivo?: string;
  arquivoNotaFiscal?: any;
  carregandoArquivo: boolean;
  parcelas: Array<Parcela>;
  somatoriaValorDigitado: number;
  status: StatusAntecipacao;
  motivoRecusa?: string;
}

export interface SacadoAntecipacao {
  id: number;
  nomeRazaoSocial: string;
  documentoSacado: string;
}

export interface Parcela {
  vencimento: Date;
  valor: number;
  id?: number;
}

export const VENCIMENTO_INICIAL: Parcela = {
  vencimento: null,
  valor: 0,
};

export enum StatusAntecipacao {
  naoEnviado = 'Nao_enviado',
  pendente = 'Pendente',
  reprovado = 'Reprovado',
  aprovado = 'Aprovado',
}

export type AntecipacaoState = Antecipacao;
