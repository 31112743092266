import * as React from 'react';

const IconeCadastroMenu = (props) => (
  <svg
    data-name='Camada 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 18.95 18.94'
    {...props}
  >
    <g
      style={{
        fill: 'currentColor',
      }}
    >
      <path d='M9.42 18.4a8.34 8.34 0 1 1 8.34-8.34 8.35 8.35 0 0 1-8.34 8.34Zm0-16.19a7.85 7.85 0 1 0 7.85 7.85 7.86 7.86 0 0 0-7.85-7.85Z' />
      <circle cx={9.42} cy={8.36} r={2.77} />
      <path d='M3.66 15.6S4.3 12 9.42 12c4.9 0 5.32 3.29 5.54 3.82s-6.18 5.11-11.3-.22Z' />
    </g>
  </svg>
);

export default IconeCadastroMenu;
