import { add, compareAsc, isBefore } from 'date-fns';
import { RootState } from '..';
import {
  dispersarMensagemErro,
  excluirAntecipacao,
} from '../pedidoAntecipacao/actions';

import {
  alteraBotaoContinuar,
  avancarEtapa,
  desabilitaBtnContinuar,
  habilitaBtnContinuar,
  retrocederEtapa,
  setEtapaInicial,
} from '../recebaAntes/actions';
import { EstadosBotaoContinuar } from '../recebaAntes/types';
import { postArquivo } from './api';
import { INITIAL_STATE } from './reducer';
import {
  Antecipacao,
  AntecipacaoActionTypes as Types,
  Parcela,
  SacadoAntecipacao,
  VENCIMENTO_INICIAL,
} from './types';

const setDadosAntecipacao = (antecipacao: Partial<Antecipacao>) => ({
  type: Types.SET_DADOS_ANTECIPACAO,
  antecipacao,
});

const setSacado = (sacado: SacadoAntecipacao) => (dispatch) => {
  dispatch(setDadosAntecipacao({ sacado }));
};

const setValorNotaFiscal =
  (valorNotaFiscal: number) => (dispatch, getState) => {
    dispatch(setDadosAntecipacao({ valorNotaFiscal }));
    controlaBtnContinuarEtapa2(dispatch, getState);
  };

const setArquivoNotaFiscal = (arquivoNotaFiscal) => (dispatch, getState) => {
  dispatch(
    setDadosAntecipacao({
      arquivoNotaFiscal,
      nomeArquivoNotaFiscal: arquivoNotaFiscal.name,
    })
  );
  controlaBtnContinuarEtapa2(dispatch, getState);
};

const controlaBtnContinuarEtapa2 = (dispatch, getState) => {
  const state: RootState = getState();
  const { nomeArquivoNotaFiscal, valorNotaFiscal } = state.antecipacao;
  if (valorNotaFiscal && nomeArquivoNotaFiscal) {
    dispatch(habilitaBtnContinuar());
  } else {
    dispatch(desabilitaBtnContinuar());
  }
};

const enviaArquivoSuccess = (idArquivoNotaFiscal) => ({
  type: Types.ENVIA_ARQUIVO_SUCCESS,
  idArquivoNotaFiscal,
});

const enviaArquivoFailure = (error) => ({
  type: Types.ENVIA_ARQUIVO_ERROR,
  error,
});

const enviaArquivo = () => async (dispatch, getState) => {
  try {
    dispatch(alteraBotaoContinuar(EstadosBotaoContinuar.carregando));
    const state: RootState = getState();
    const { arquivoNotaFiscal } = state.antecipacao;
    const { data } = await postArquivo(arquivoNotaFiscal);
    const idArquivoNotaFiscal = data.data.id;
    dispatch(enviaArquivoSuccess(idArquivoNotaFiscal));
    dispatch(avancarEtapa());
    controlaBtnContinuarEtapa3(dispatch, getState);
  } catch (error) {
    console.error(error);
    dispatch(enviaArquivoFailure('Erro ao enviar arquivo'));
    dispatch(desabilitaBtnContinuar());
  }
};

const atualizarParcela = (parcelasAtualizadas) => ({
  type: Types.ALTERAR_PARCELAS,
  parcelas: parcelasAtualizadas,
});

const adicionarParcela = () => (dispatch, getState) => {
  const state: RootState = getState();
  const parcelas = [...state.antecipacao.parcelas];
  if (parcelas.length < 10) {
    const ultimoVencimento = parcelas[parcelas.length - 1]?.vencimento;
    if (ultimoVencimento !== null) {
      const novaData = add(ultimoVencimento, { months: 1 });
      const novaDataAntesDoLimite = isBefore(
        novaData,
        add(new Date(), { months: 18 })
      );

      parcelas.push({
        ...VENCIMENTO_INICIAL,
        vencimento: novaDataAntesDoLimite ? novaData : null,
      });
    } else {
      parcelas.push(VENCIMENTO_INICIAL);
    }
    dispatch(atualizarParcela(parcelas));
  }
};

const alterarParcela = (parcela, index) => (dispatch, getState) => {
  const state: RootState = getState();
  const novaListaParcelas = [...state.antecipacao.parcelas];
  novaListaParcelas[index] = parcela;
  dispatch(atualizarParcela(novaListaParcelas));
};

const alterarDataParcela = (parcela, index) => (dispatch, getState) => {
  dispatch(alterarParcela(parcela, index));
  controlaBtnContinuarEtapa3(dispatch, getState);
};

const excluirParcela = (index) => (dispatch, getState) => {
  const state: RootState = getState();
  const novaListaParcelas = [...state.antecipacao.parcelas];
  novaListaParcelas.splice(index, 1);
  dispatch(atualizarParcela(novaListaParcelas));
  controlaBtnContinuarEtapa3(dispatch, getState);
};

const controlaBtnContinuarEtapa3 = (dispatch, getState) => {
  const state: RootState = getState();
  const { parcelas } = state.antecipacao;
  if (parcelas.length === 0) {
    dispatch(desabilitaBtnContinuar());
  } else if (parcelas.some((parcela) => parcela.vencimento !== null)) {
    dispatch(habilitaBtnContinuar());
  } else {
    dispatch(desabilitaBtnContinuar());
  }
};

const sortParcelas = (parcela1: Parcela, parcela2: Parcela): number => {
  return compareAsc(parcela1.vencimento, parcela2.vencimento);
};

const calculaValorSugeridoParcelas = (parcelas, valorTotal) => {
  const valorDivido = +(valorTotal / parcelas.length).toFixed(2);

  const diferenca = valorDivido * parcelas.length - valorTotal;

  parcelas.forEach((_, index) => {
    parcelas[index].valor = valorDivido;
  });

  parcelas[parcelas.length - 1].valor = valorDivido - diferenca;

  return parcelas;
};

const ordenaLista = () => async (dispatch, getState) => {
  try {
    const state: RootState = getState();
    const { parcelas, valorNotaFiscal } = state.antecipacao;

    let listaLimpa = parcelas
      .filter((parcela) => parcela.vencimento !== null)
      .sort(sortParcelas);

    listaLimpa = calculaValorSugeridoParcelas(listaLimpa, valorNotaFiscal);

    dispatch(atualizarParcela(listaLimpa));
    dispatch(setNovaSoma(valorNotaFiscal));
    dispatch(avancarEtapa());
  } catch (error) {
    console.error(error);
    dispatch(desabilitaBtnContinuar());
  }
};

const setNovaSoma = (novaSoma: number) => ({
  type: Types.ALTERAR_SOMATORIA,
  novaSoma,
});

const controlaBtnContinuarEtapa4 = (dispatch, getState) => {
  const state: RootState = getState();
  const { valorNotaFiscal, somatoriaValorDigitado } = state.antecipacao;
  if (valorNotaFiscal === somatoriaValorDigitado) {
    dispatch(habilitaBtnContinuar());
  } else {
    dispatch(desabilitaBtnContinuar());
  }
};

const atualizaSomatorioParcela = (parcela, index) => (dispatch, getState) => {
  dispatch(alterarParcela(parcela, index));
  const state: RootState = getState();
  const { parcelas } = state.antecipacao;
  let novaSoma = parcelas.reduce(
    (valorAnterior, valorAtual) => valorAnterior + valorAtual.valor,
    0
  );
  dispatch(setNovaSoma(novaSoma));
  controlaBtnContinuarEtapa4(dispatch, getState);
};

const carregaDadosEdicao = (antecipacao, index) => (dispatch) => {
  dispatch(dispersarMensagemErro());
  dispatch(setDadosAntecipacao(antecipacao));
  dispatch(excluirAntecipacao(index));
  dispatch(habilitaBtnContinuar());
  dispatch(retrocederEtapa());
};

const iniciaNovaAntecipacao = () => (dispatch) => {
  dispatch(setDadosAntecipacao(INITIAL_STATE));
  dispatch(setEtapaInicial());
};

export {
  setSacado,
  setValorNotaFiscal,
  setArquivoNotaFiscal,
  enviaArquivo,
  adicionarParcela,
  alterarDataParcela,
  excluirParcela,
  ordenaLista,
  atualizaSomatorioParcela,
  carregaDadosEdicao,
  iniciaNovaAntecipacao,
};
