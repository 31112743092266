/* eslint-disable camelcase */
import { getInfosMinhaConta, postDocumentos } from './api';
import * as types from './types';

export const fetchInfoRequest = () => ({
  type: types.FETCH_INFO_REQUEST,
});

export const fetchInfoSuccess = ({
  etapaCadastro,
  comErro,
  limiteDisponivel,
  limiteTotal,
  valorPagamento,
  dataPagamento,
  emAtraso,
  saquePendente,
  statusDevolucao,
  razaoSocial,
  nomeEmpresa,
  cnpj,
  tipoEmpresa,
  nomeSocio,
  cpf,
  rg,
  diaVencimentoJuros,
  email,
  configuracoes,
  avisoPendencias,
}) => ({
  type: types.FETCH_INFO_SUCCESS,
  etapaCadastro,
  comErro,
  limiteDisponivel,
  limiteTotal,
  valorPagamento,
  dataPagamento,
  emAtraso,
  saquePendente,
  statusDevolucao,
  razaoSocial,
  nomeEmpresa,
  cnpj,
  tipoEmpresa,
  nomeSocio,
  cpf,
  rg,
  diaVencimentoJuros,
  email,
  configuracoes,
  avisoPendencias,
});

export const enviarDocumentosRequest = () => ({
  type: types.ENVIAR_DOCUMENTOS_REQUEST,
});

export const enviarDocumentosSuccess = () => ({
  type: types.ENVIAR_DOCUMENTOS_SUCCESS,
});

export const fetchInfo = () => async (dispatch) => {
  dispatch(fetchInfoRequest());

  try {
    const data = await getInfosMinhaConta();
    const infos = data?.data?.data?.data;
    if (!infos) {
      return;
    }
    const {
      etapa_cadastro,
      com_erro,
      limite_disponivel,
      limite_total,
      valor_juros,
      data_juros,
      em_atraso,
      saque_pendente,
      status_devolucao,
      razao_social,
      nome_empresa,
      cnpj,
      tipo_empresa,
      nome_socio,
      cpf,
      rg,
      dia_vencimento_juros,
      email,
      configuracoes,
      aviso_pendencias,
    } = infos;

    const objInfo = {
      etapaCadastro: etapa_cadastro,
      comErro: com_erro,
      limiteDisponivel: limite_disponivel || 0,
      limiteTotal: limite_total || 0,
      valorPagamento: valor_juros || 0,
      dataPagamento: data_juros || '',
      emAtraso: em_atraso,
      saquePendente: saque_pendente,
      statusDevolucao: status_devolucao,
      razaoSocial: razao_social,
      nomeEmpresa: nome_empresa,
      cnpj,
      tipoEmpresa: tipo_empresa,
      nomeSocio: nome_socio,
      cpf,
      rg,
      diaVencimentoJuros: dia_vencimento_juros,
      email,
      configuracoes,
      avisoPendencias: aviso_pendencias,
    };
    dispatch(fetchInfoSuccess(objInfo));
  }
  catch (err) {
    console.log('erro no fetchInfo', err);
  }
};

export const enviarDocumentos = () => (dispatch) => {
  dispatch(enviarDocumentosRequest());

  return postDocumentos().then(() => {
    dispatch(enviarDocumentosSuccess());
    dispatch(fetchInfo());
  });
};
