import React from 'react';
import { Hidden, TableBody, TableCell, TableRow } from '@material-ui/core';
import { CINZA_600 } from '../../../utils/color';
import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';

const StyledMensagem = styled.p`
  text-align: center;
  color: ${CINZA_600};
  font-size: 0.8vw;
  padding: 5.4vw 0;

  @media ${queries.desktopUp} {
    padding: 80px 0;
    font-size: 12px;
  }

  @media ${queries.mobileDown} {
    padding: 10vw 0 51vw;
    font-size: 4.3vw;
    width: 80vw;
    margin: auto;
    line-height: 1;
  }
`;

const EstadoVazio = () => {
  const mensagem = 'Não existem boletos com essas especiﬁcações.';

  return (
    <>
      <Hidden xsDown>
        <TableBody>
          <TableRow>
            <TableCell colSpan={6}>
              <StyledMensagem data-cy='texto-estado-vazio'>
                {mensagem}
              </StyledMensagem>
            </TableCell>
          </TableRow>
        </TableBody>
      </Hidden>
      <Hidden smUp>
        <StyledMensagem data-cy='texto-estado-vazio'>{mensagem}</StyledMensagem>
      </Hidden>
    </>
  );
};

export default EstadoVazio;
