/* eslint-disable import/prefer-default-export */
import Api from '../../Api';

export const getMovimentacoes = (limiteDias) => (
  Api.get(`extrato?limDias=${limiteDias}`)
);

export const postRegularizar = ({ dataFormatada }) => (
  Api.post(
    '/valor/regularizar',
    {
      data: dataFormatada,
    },
  )
);

export const postSolicitarRegularizacao = ({ dataFormatada, valorParaRegularizar }) => (
  Api.post(
    '/regularizar',
    {
      data: dataFormatada,
      valor: valorParaRegularizar,
    },
  )
);
