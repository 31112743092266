import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icone from '../../../components/Icone';
import { CINZA_200, AZUL_400, BRANCO } from '../../../utils/color';

const StyledInfosContato = styled.div`
  padding: 1rem;

  .container-titulo {
    display: flex;
    align-content: end;
  }
  .infos-contato-desktop {
    padding: 1rem;
    background-color: ${CINZA_200};
  }

  .infos-contato-mobile {
    padding: 1rem 2rem;
    background-color: ${BRANCO};
  }

  .icone-infos-contato img {
    float: left;
  }

  .infos-contato-titulo {
    margin-top: 35px;
    margin-bottom: 0;
    color: ${AZUL_400};
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
  }

  .infos-contato-email {
    color: ${AZUL_400};
    font-weight: bold;
  }

  @media (max-width: 375px) {
    .infos-contato-mobile {
      padding: 0;
      font-size: 12px;
    }

    .infos-contato-titulo {
      font-size: 16px;
    }
  }
`;

const InfosContato = ({ mobile }) => {
  const InfosContatoClassName = `infos-contato-${mobile ? 'mobile' : 'desktop'}`;
  const email = 'atendimento@creditorotativo.com.br';

  return (
    <StyledInfosContato>
      <div className={InfosContatoClassName}>
        <div className='container-titulo'>
          <div className='icone-infos-contato'>
            <Icone name='minhaConta' />
          </div>
          <h4 className='infos-contato-titulo'>
            Deseja atualizar
            <br />
            seus dados?
          </h4>
        </div>
        <p>
          Entre em contato pelo e-mail
          <br />
          <a
            href={`mailto:${email}`}
            target='_blank'
            rel='noopener noreferrer'
            className='infos-contato-email'
          >
            {` ${email}`}
          </a>
          <br />
          com as novas informações.
          <br />
          Nós retornaremos o mais breve possível.
        </p>
      </div>
    </StyledInfosContato>
  );
};

InfosContato.defaultProps = {
  mobile: false,
};

InfosContato.propTypes = {
  mobile: PropTypes.bool,
};

export default InfosContato;
