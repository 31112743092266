import * as types from './types';
import { postSimulacao, postInfosJuros } from './api';

export const setValorSaque = (valorSaque) => ({
  type: types.SET_VALOR_SAQUE,
  valorSaque,
});

export const setValorDevolucao = (valorDevolucao) => ({
  type: types.SET_VALOR_DEVOLUCAO,
  valorDevolucao,
});

export const setDataDevolucao = (dataDevolucao) => ({
  type: types.SET_DATA_DEVOLUCAO,
  dataDevolucao,
});

export const setDataSaque = (dataSaque) => ({
  type: types.SET_DATA_SAQUE,
  dataSaque,
});

export const adicionarSaque = (novoSaque) => ({
  type: types.ADICIONAR_SAQUE,
  novoSaque
});

export const adicionarDevolucao = (novaDevolucao) => ({
  type: types.ADICIONAR_DEVOLUCAO,
  novaDevolucao,
});

export const enviarSimulacaoRequest = () => ({
  type: types.ENVIAR_SIMULACAO_REQUEST,
});

export const enviarSimulacaoSuccess = ({
  limiteDisponivel,
  proximoPagamento,
  movimentacoes,
  taxaJuros,
}) => ({
  type: types.ENVIAR_SIMULACAO_SUCCESS,
  limiteDisponivel,
  proximoPagamento,
  movimentacoes,
  taxaJuros,
});

export const enviarSimulacaoFailure = (errors) => ({
  type: types.ENVIAR_SIMULACAO_ERROR,
  errors,
});

export const enviarSimulacao = ({ saques, devolucoes, onSuccess }) => (
  async (dispatch) => {
    dispatch(enviarSimulacaoRequest());

    try {
      const res = await postSimulacao({ saques, devolucoes });
      const infos = res?.data?.data;
      const {
        limite_disponivel,
        proximo_pagamento,
        movimentacoes,
        taxa_juros,
      } = infos;

      const objInfo = {
        limiteDisponivel: limite_disponivel || 0,
        proximoPagamento: proximo_pagamento || 0,
        movimentacoes,
        taxaJuros: taxa_juros || '0,00%',
      };
      dispatch(enviarSimulacaoSuccess(objInfo));
      dispatch(onSuccess());
    }
    catch (error) {
      dispatch(enviarSimulacaoFailure(error?.response?.data?.message));
    }
  }
);

export const simularSaque = ({ dataSaque }) => (
  async (dispatch, getState) => {
    const { simulacao } = getState();
    const { saques: saquesAtuais, devolucoes } = simulacao;

    const novoSaque = {
      valor: simulacao.valorSaqueRaw,
      data: dataSaque,
    }
    const saques = [...saquesAtuais, novoSaque];
    const onSuccess = () => adicionarSaque(novoSaque);

    dispatch(enviarSimulacao({ saques, devolucoes, onSuccess }));
  }
);

export const simularDevolucao = ({ dataDevolucao }) => (
  async (dispatch, getState) => {
    const { simulacao } = getState();
    const { saques, devolucoes: devolucoesAtuais } = simulacao;

    const novaDevolucao = {
      valor: simulacao.valorDevolucaoRaw,
      data: dataDevolucao
    };
    const devolucoes = [...devolucoesAtuais, novaDevolucao];
    const onSuccess = () => adicionarDevolucao(novaDevolucao);

    dispatch(enviarSimulacao({ saques, devolucoes, onSuccess }));
  }
);

export const zerarSimulacao = () => ({
  type: types.ZERAR_SIMULACAO,
});

export const setEtapaIntroducao = (etapaIntroducao) => ({
  type: types.SET_ETAPA_INTRODUCAO,
  etapaIntroducao,
});

export const sairIntroducao = () => (
  (dispatch) => {
    dispatch(setEtapaIntroducao(null));
  }
);

export const fetchDadosSaqueRequest = () => ({
  type: types.FETCH_DADOS_SAQUE_REQUEST,
});

export const fetchDadosSaqueSuccess = ({ valorJuros, valorFinal }) => ({
  type: types.FETCH_DADOS_SAQUE_SUCCESS,
  valorJuros,
  valorFinal,
});

export const fetchDadosSaqueFailure = (errors) => ({
  type: types.FETCH_DADOS_SAQUE_ERROR,
  errors,
});

export const fetchDadosSaque = () => (
  async (dispatch, getState) => {
    const state = getState();
    const { valorSaqueRaw, dataSaque, saques, devolucoes } = state.simulacao;
    if (valorSaqueRaw < 100 || valorSaqueRaw > 3000 || dataSaque === null) {
      return null;
    }

    dispatch(fetchDadosSaqueRequest());

    const payload = {
      valorSaqueRaw,
      dataSaque,
      saques,
      devolucoes,
    };
    try {
      const res = await postInfosJuros(payload);
      const dadosSaque = res?.data?.data;
      const { juros_acumulados, valor_creditar } = dadosSaque;
      const data = {
        valorFinal: valor_creditar,
        valorJuros: juros_acumulados,
      };
      dispatch(fetchDadosSaqueSuccess(data));
    }
    catch (err) {
      const data = err?.response?.data;
      dispatch(fetchDadosSaqueFailure(data));
    }
  }
);

export const setModalSaqueAberta = (aberta) => ({
  type: types.SET_MODAL_SAQUE_ABERTA,
  aberta,
});

export const setModalDevolucaoAberta = (aberta) => ({
  type: types.SET_MODAL_DEVOLUCAO_ABERTA,
  aberta,
});
