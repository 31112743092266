/* eslint-disable camelcase */
import { postInfosJuros, postSolicitarTokenSaque, postSaque } from './api';
import * as types from './types';

export const setValorSaque = (valorSaque) => ({
  type: types.SET_VALOR_SAQUE,
  valorSaque,
});

export const fetchDadosSaqueRequest = () => ({
  type: types.FETCH_DADOS_SAQUE_REQUEST,
});

export const fetchDadosSaqueSuccess = ({ valorJuros, valorIof, valorFinal }) => ({
  type: types.FETCH_DADOS_SAQUE_SUCCESS,
  valorJuros,
  valorIof,
  valorFinal,
});

export const fetchDadosSaqueFailure = (error) => ({
  type: types.FETCH_DADOS_SAQUE_ERROR,
  error,
});

export const fetchDadosSaque = () => (
  async (dispatch, getState) => {
    dispatch(fetchDadosSaqueRequest());

    const state = getState();
    const valorSaque = state.saque.valorSaqueRaw;
    const payload = {
      valorSaque,
    };
    try {
      const res = await postInfosJuros(payload);
      const dadosSaque = res?.data?.data;
      const { juros_acumulado, valor_iof, valor_final } = dadosSaque;
      const data = {
        valorJuros: juros_acumulado,
        valorIof: valor_iof,
        valorFinal: valor_final,
      };
      dispatch(fetchDadosSaqueSuccess(data));
    }
    catch (error) {
      const data = error?.response?.data?.message;
      dispatch(fetchDadosSaqueFailure(data));
    }
  }
);

export const setContaBancariaId = (contaBancariaId) => ({
  type: types.SET_CONTABANCARIA_ID,
  contaBancariaId,
});

export const solicitarTokenSaqueRequest = () => ({
  type: types.SOLICITAR_TOKEN_SAQUE_REQUEST,
});

export const solicitarTokenSaqueSuccess = () => ({
  type: types.SOLICITAR_TOKEN_SAQUE_SUCCESS,
});

export const solicitarTokenSaqueFailure = (error) => ({
  type: types.SOLICITAR_TOKEN_SAQUE_ERROR,
  error,
});

export const solicitarTokenSaque = ({ onSuccess }) => (
  async (dispatch, getState) => {
    dispatch(solicitarTokenSaqueRequest());

    const state = getState();
    const { valorSaqueRaw, contaBancariaId } = state.saque;

    const payload = {
      valorSaque: valorSaqueRaw,
      contaBancariaId,
    };

    try {
      await postSolicitarTokenSaque(payload);
        dispatch(solicitarTokenSaqueSuccess());
        onSuccess();
      }
    catch (error) {
      const data = error?.response?.data?.message
      dispatch(solicitarTokenSaqueFailure(data));
    }
  }
);

export const enviarSaqueRequest = () => ({
  type: types.ENVIAR_SAQUE_REQUEST,
});

export const enviarSaqueSuccess = () => ({
  type: types.ENVIAR_SAQUE_SUCCESS,
});

export const enviarSaqueFailure = (error) => ({
  type: types.ENVIAR_SAQUE_ERROR,
  error,
});

export const enviarSaque = (token) => (
  async (dispatch, getState) => {
    dispatch(enviarSaqueRequest());

    const state = getState();
    const { valorSaqueRaw, contaBancariaId } = state.saque;

    const payload = {
      valorSaque: valorSaqueRaw,
      contaBancariaId,
      token,
    };

    try {
      await postSaque(payload);
      dispatch(enviarSaqueSuccess());
    }
    catch (error) {
      const data = error?.response?.data?.message;
      dispatch(enviarSaqueFailure(data));
    }
  }
);
