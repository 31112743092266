import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CINZA_600 } from '../../../utils/color';
import {
  enviarDevolucao as enviarDevolucaoAction,
} from '../../../store/devolucao/actions';
import BotaoDevolucao from './BotaoDevolucao';
import Detalhes from './Detalhes';
import RadioDevolucao from './RadioDevolucao';

const StyledBoletoBancario = styled.div`
  display: grid;
  grid-template-columns: .1fr 1fr .5fr;
  margin-bottom: .5rem;
  padding-bottom: .8rem;
  border-bottom: .5px solid ${CINZA_600};
`;

export const BoletoBancario = ({ enviarDevolucao, botaoDesabilitado }) => (
  <StyledBoletoBancario>
    <div className='radio-devolucao'>
      <RadioDevolucao value='boleto' />
    </div>
    <Detalhes
      titulo='Boleto Bancário'
      conteudo='O boleto será enviado para seu email.'
    />
    <BotaoDevolucao
      value='Solicitar boleto'
      onClick={() => enviarDevolucao()}
      disabled={botaoDesabilitado}
    />
  </StyledBoletoBancario>
);

BoletoBancario.defaultProps = {
  botaoDesabilitado: false,
};

BoletoBancario.propTypes = {
  enviarDevolucao: PropTypes.func.isRequired,
  botaoDesabilitado: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  botaoDesabilitado: state.devolucao.valorDevolucao <= 0 || state.devolucao.tipoDevolucao !== 'boleto',
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  enviarDevolucao: enviarDevolucaoAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BoletoBancario);
