import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import HelpIcon from '@material-ui/icons/Help';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import InputMask from 'react-input-mask';
import { connect, ConnectedProps } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../../store';
import {
  buscaEnderecoPeloCep,
  cadastraSacado,
  fecharModaleForm,
  fetchCidades,
  setDadosSacado,
} from '../../../../store/cadastroSacado/actions';
import { Cidade } from '../../../../store/cadastroSacado/types';
import formataDocumento from '../../../../utils/formataDocumento';
import Dica from '../../../Dica';
import RoundButton from '../../../RoundButton';
import { StyledFormCadastroSacado } from './FormCadastroSacado.style';
import ModalCadastroSacado from './ModalCadastroSacado';

interface Props extends ConnectedProps<typeof connector> {}

const FormCadastroSacado = ({
  erros,
  estados,
  cidades,
  loading,
  buscandoEndereco,
  dados,
  desabilitar,
  cadastraSacado,
  fetchCidades,
  fecharForm,
  buscaEndereco,
  setDados,
}: Props) => {
  const handleSelecionaEstado = (_e, novoEstado) => {
    setDados({ estado: novoEstado });
    if (novoEstado) {
      fetchCidades(novoEstado.id);
    }
  };

  const handleBlurDocumento = () => {
    setDados({ documento: formataDocumento(dados.documento) });
  };

  const handleSubmit = () => {
    cadastraSacado();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleFecharForm = () => {
    fecharForm();
  };

  const dicaMulta =
    'Caso essa opção seja marcada, serão cobrados DO SACADO, multa e juros de mora nas parcelas em atraso';

  return (
    <>
      <StyledFormCadastroSacado container justify='center'>
        <Grid item sm={12}>
          <Hidden mdUp>
            <div className='header'>
              <IconButton onClick={handleFecharForm} color='inherit'>
                <ChevronLeft />
              </IconButton>
              <span>Novo cliente</span>
            </div>
          </Hidden>
        </Grid>
        <Grid item>
          <Typography className='titulo'>
            Preencha os dados do seu cliente
          </Typography>
        </Grid>
        <Grid container justify='center' className='form'>
          <TextField
            id='nome_razao_social'
            label='Razão Social ou Nome da Pessoa Física'
            value={dados.nomeRazaoSocial}
            error={!!erros?.nome_razao_social}
            helperText={erros?.nome_razao_social?.[0]}
            onChange={(e) => setDados({ nomeRazaoSocial: e.target.value })}
            onKeyPress={handleKeyPress}
            required
          />
          <TextField
            id='cpf_cnpj'
            label='CNPJ ou CPF'
            value={dados.documento}
            error={!!erros?.cpf_cnpj}
            helperText={erros?.cpf_cnpj?.[0]}
            onChange={(e) => setDados({ documento: e.target.value })}
            onKeyPress={handleKeyPress}
            onBlur={handleBlurDocumento}
            required
          />
          <Grid item xs={7} style={{ paddingRight: '1rem' }}>
            <InputMask
              mask='99999-999'
              value={dados.cep}
              maskChar=' '
              onChange={(e) => setDados({ cep: e.target.value })}
              onBlur={() => buscaEndereco(dados.cep)}
            >
              {() => (
                <TextField
                  label='CEP'
                  error={!!erros?.cep}
                  helperText={erros?.cep?.[0]}
                  onKeyPress={handleKeyPress}
                  id='cep'
                  required
                  InputProps={{
                    endAdornment: buscandoEndereco && (
                      <InputAdornment position='end'>
                        <CircularProgress size='1em' />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={5}>
            <FormControl error={!!erros?.cidade_id} style={{ width: '100%' }}>
              <Autocomplete
                id='estado_id'
                options={estados}
                getOptionLabel={(option) => option.sigla}
                value={dados.estado}
                disabled={buscandoEndereco || desabilitar.estado}
                renderInput={(params) => (
                  <TextField {...params} label='Estado *' variant='standard' />
                )}
                onChange={handleSelecionaEstado}
                clearText='Limpar'
                noOptionsText='Nenhum estado encontrado'
              />
            </FormControl>
          </Grid>
          <FormControl style={{ width: '100%' }} error={!!erros?.cidade_id}>
            <Autocomplete
              id='cidade_id'
              options={dados.estado ? cidades : []}
              getOptionLabel={(option) => option.nome}
              value={dados.cidade}
              disabled={buscandoEndereco || desabilitar.cidade}
              renderInput={(params) => (
                <TextField {...params} label='Cidade *' variant='standard' />
              )}
              onChange={(_event, newValue: Cidade) => {
                setDados({ cidade: newValue });
              }}
              clearText='Limpar'
              noOptionsText='Nenhuma cidade encontrada'
            />
            <FormHelperText>{erros?.cidade_id?.[0]}</FormHelperText>
          </FormControl>
          <Grid item xs={9} style={{ paddingRight: '1rem' }}>
            <TextField
              id='rua'
              label='Endereço'
              required
              disabled={buscandoEndereco}
              value={dados.rua}
              error={!!erros?.endereco && !dados.rua}
              helperText={!dados.rua && erros?.endereco?.[0]}
              onChange={(e) => setDados({ rua: e.target.value })}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id='numero'
              label='Número'
              required
              value={dados.numero}
              error={!!erros?.endereco && !dados.numero}
              onChange={(e) => setDados({ numero: e.target.value })}
              onKeyPress={handleKeyPress}
            />
          </Grid>
          <TextField
            id='email'
            required
            label='E-mail do sacado'
            value={dados.email}
            error={!!erros?.email}
            helperText={erros?.email?.[0]}
            onChange={(e) => setDados({ email: e.target.value })}
            onKeyPress={handleKeyPress}
          />
          <TextField
            id='email_2'
            label='E-mail 2'
            value={dados.email2}
            error={!!erros?.email_2}
            helperText={erros?.email_2?.[0]}
            onChange={(e) => setDados({ email2: e.target.value })}
            onKeyPress={handleKeyPress}
          />
          <TextField
            id='email_3'
            label='E-mail 3'
            value={dados.email3}
            error={!!erros?.email_3}
            helperText={erros?.email_3?.[0]}
            onChange={(e) => setDados({ email3: e.target.value })}
            onKeyPress={handleKeyPress}
          />
          <InputMask
            mask='99 999999999'
            value={dados.telefone}
            disabled={false}
            maskChar=' '
            onChange={(e) => setDados({ telefone: e.target.value })}
          >
            {() => (
              <TextField
                id='telefone'
                label='Telefone para contato com o sacado'
                error={!!erros?.telefone}
                helperText={erros?.telefone?.[0]}
                required
                onKeyPress={handleKeyPress}
              />
            )}
          </InputMask>
          <InputMask
            mask='99 999999999'
            value={dados.telefone2}
            disabled={false}
            maskChar=' '
            onChange={(e) => setDados({ telefone2: e.target.value })}
          >
            {() => (
              <TextField
                id='telefone_2'
                label='Telefone 2'
                error={!!erros?.telefone_2}
                helperText={erros?.telefone_2?.[0]}
                onKeyPress={handleKeyPress}
              />
            )}
          </InputMask>
          <Grid item xs={12} className='multa-container'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dados.cobrarMulta}
                  onChange={() => setDados({ cobrarMulta: !dados.cobrarMulta })}
                  id='cobrar_multa'
                  color='primary'
                  className='checkbox'
                />
              }
              label='Cobrar Multa'
            />
            <div className='dica-container'>
              <Dica dica={dicaMulta} placement='right'>
                <HelpIcon />
              </Dica>
            </div>
          </Grid>

          <RoundButton
            size='small'
            color='primary'
            value='Cadastrar'
            className='botao-submit'
            onClick={handleSubmit}
            loading={loading}
            data-cy='botao-submit-cadastro-sacado'
          />
        </Grid>
      </StyledFormCadastroSacado>
      <ModalCadastroSacado />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  estados: state.cadastroSacado.estados,
  cidades: state.cadastroSacado.cidades,
  erros: state.cadastroSacado.erros,
  loading: state.cadastroSacado.loadingCadastro,
  dados: state.cadastroSacado.dadosSacado,
  buscandoEndereco: state.cadastroSacado.buscandoEndereco,
  desabilitar: state.cadastroSacado.desabilitar,
});

const mapDispatchToProps = (dispatch, getState) =>
  bindActionCreators(
    {
      cadastraSacado,
      fetchCidades,
      fecharForm: fecharModaleForm,
      buscaEndereco: buscaEnderecoPeloCep,
      setDados: setDadosSacado,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FormCadastroSacado);
