import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
  enviarDevolucao as enviarDevolucaoAction,
} from '../../../store/devolucao/actions';
import BotaoDevolucao from './BotaoDevolucao';
import Detalhes from './Detalhes';
import RadioDevolucao from './RadioDevolucao';

const StyledTransferenciaBancaria = styled.div`
  display: grid;
  grid-template-columns: .1fr 1fr .5fr;
`;

export const TransferenciaBancaria = ({
  enviarDevolucao,
  botaoDesabilitado,
  shouldOpenModal,
  onClose
}) => {
  const [openAnexarArquivos, setOpenAnexarArquivos] = useState(false);

  if(shouldOpenModal) {
    setTimeout(() => {
      setOpenAnexarArquivos(true)
    }, 500);
  };

  const handleClickOpenAnexarArquivos = () => {
    setOpenAnexarArquivos(true);
  };

  const onCloseAnexarArquivos = () => {
    setOpenAnexarArquivos(false);
    onClose()
  };

  const handleAnexarArquivosSave = (files) => {
    enviarDevolucao(files);
    onCloseAnexarArquivos();
  };

  return (
    <StyledTransferenciaBancaria>
      <div className='radio-devolucao'>
        <RadioDevolucao value='transferencia' />
      </div>
      <Detalhes
        titulo='Transferência bancária'
        conteudo={(
        <>
          Se preferir, faça a transferência
            <br />
          e envie o comprovante por aqui.
        </>
      )}
      />
      <BotaoDevolucao
        value='Anexar comprovante'
        onClick={handleClickOpenAnexarArquivos}
        disabled={botaoDesabilitado}
      />
      <DropzoneDialog
        dialogTitle=''
        cancelButtonText='Cancelar'
        submitButtonText='Enviar'
        previewText='Pré-visualização'
        dropzoneText='Arraste e solte o comprovante da transferência ou clique aqui'
        open={openAnexarArquivos}
        onSave={(files) => handleAnexarArquivosSave(files)}
        filesLimit={1}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf']}
        showPreviews
        maxFileSize={6000000}
        onClose={onCloseAnexarArquivos}
        getFileAddedMessage={(filename) => `Arquivo ${filename} adicionado com sucesso.`}
        getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
          let message = `Arquivo ${rejectedFile.name} não é válido. `;
          if (!acceptedFiles.includes(rejectedFile.type)) {
              message += 'Tipo de arquivo não é suportado.';
          }
          if (rejectedFile.size > maxFileSize) {
              message += 'Arquivo muito grande. O tamanho máximo é 6MB.';
          }
          return message;
      }}
        alertSnackbarProps={{
          autoHideDuration: 10000,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          }
        }}
      />
    </StyledTransferenciaBancaria>
  );
};

TransferenciaBancaria.defaultProps = {
  botaoDesabilitado: false,
};

TransferenciaBancaria.propTypes = {
  enviarDevolucao: PropTypes.func.isRequired,
  botaoDesabilitado: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  botaoDesabilitado: state.devolucao.valorDevolucao <= 0 || state.devolucao.tipoDevolucao !== 'transferencia',
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  enviarDevolucao: enviarDevolucaoAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TransferenciaBancaria);
