import React, { ReactNode } from 'react';
import styled from 'styled-components';
import HelpIcon from '@material-ui/icons/Help';
import ErrorIcon from '@material-ui/icons/Error';
import Chip from '@material-ui/core/Chip';
import {
  CINZA_700,
  CINZA_300,
  CINZA_100,
  AZUL_300,
  VERMELHO,
  LARANJA_100,
} from '../utils/color';
import Dica from './Dica';
import FormatadorDeMoedas from './FormatadorDeMoeda';
import { Divider } from '@material-ui/core';

const StyledCardInfos = styled.div`
  margin-bottom: 1rem;
  border: 1px solid ${CINZA_300};
  border-radius: 5px;

  .cabecalho {
    margin: 0;
    padding: 0.6rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: ${CINZA_100};
    color: ${CINZA_700};
    font-size: 1rem;

    .titulo {
      margin: 0;
      margin-bottom: 0.1rem;
      color: ${AZUL_300};
      font-size: 0.8rem;
      font-weight: 600;

      svg {
        position: absolute;
        font-size: 0.8rem;
        margin-left: 0.2rem;
        margin-top: 0.1rem;
        cursor: pointer;
      }
    }
  }

  .rodape {
    margin: 0;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.6rem;
    color: ${CINZA_700};
    font-size: 0.8rem;
  }

  .MuiDivider-root {
    margin: 0.3rem 0.6rem;
    background-color: ${CINZA_300};
  }

  .texto-rodape-pagamento-pendente {
    color: ${VERMELHO};
  }

  .MuiChip-root {
    height: 20px;
    vertical-align: text-bottom;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .chip-pagamento-pendente {
    background-color: ${VERMELHO};
  }

  .chip-valor-minimo {
    background-color: ${LARANJA_100};
  }

  @media (max-width: 380px) {
    .cabecalho {
      .titulo {
        font-size: 0.6rem;
        svg {
          margin-top: 0;
        }
      }
    }

    .rodape {
      font-size: 0.6rem;
    }

    .MuiChip-root {
      font-size: 9px;
    }
  }
`;

interface CardInfosProps {
  titulo: string;
  valor: number;
  tituloRodape?: string;
  textoRodape?: ReactNode;
  dica?: string;
  erro?: boolean;
  pagamentoPendente?: boolean;
  valorMinimo?: number;
  rodapeExtra?: { titulo: string; texto: ReactNode };
}

const CardInfos = ({
  titulo,
  valor,
  tituloRodape,
  textoRodape,
  dica,
  pagamentoPendente,
  valorMinimo,
  rodapeExtra,
}: CardInfosProps) => {
  const textoRodapeClassName = pagamentoPendente
    ? 'texto-rodape-pagamento-pendente'
    : '';

  return (
    <StyledCardInfos>
      <div className='cabecalho'>
        <h6 className='titulo'>
          {titulo}
          {dica && (
            <Dica dica={dica} placement='right'>
              <HelpIcon />
            </Dica>
          )}
        </h6>
        <FormatadorDeMoedas valor={valor} comSimbolo />{' '}
        {pagamentoPendente ? (
          <Chip
            icon={<ErrorIcon />}
            label='Pendente'
            size='small'
            color='secondary'
            className='chip-pagamento-pendente'
          />
        ) : null}
        {valor > 0 && valor < valorMinimo ? (
          <Chip
            icon={<ErrorIcon />}
            label='Mínimo R$ 10,00'
            size='small'
            color='secondary'
            className='chip-valor-minimo'
          />
        ) : null}
      </div>
      <div className='rodape'>
        <b>{tituloRodape}</b>{' '}
        <span className={textoRodapeClassName}>{textoRodape}</span>
      </div>
      {rodapeExtra && (
        <>
          <Divider />
          <div className='rodape'>
            <b>{rodapeExtra.titulo}</b>{' '}
            <span className={textoRodapeClassName}>{rodapeExtra.texto}</span>
          </div>
        </>
      )}
    </StyledCardInfos>
  );
};

export default CardInfos;
