import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import {
  setBancoId,
  setAgencia,
  setConta,
} from '../../../../store/contaBancaria/actions';
import {
  selectBancoIdError,
  selectAgenciaError,
  selectContaError,
  selectTitularidade,
  selectDocumento
} from '../../../../store/contaBancaria/selectors';
import SelectTipoDeContaBancaria from './SelectTipoDeContaBancaria';

const StyledProgresso = styled.div`
  padding: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const FormContaBancaria = ({
  bancos,
  bancoId,
  agencia,
  conta,
  bancoError,
  agenciaError,
  contaError,
  buscandoBancos,
  enviandoConta,
  onChangeBanco,
  onChangeAgencia,
  onChangeConta,
  titularidade,
  documento,
}) => {
  if (buscandoBancos) {
    return (
    <StyledProgresso>
      <CircularProgress />
    </StyledProgresso>
    );
  }

  return (
    <Container style={{ paddingTop: '.5rem' }}>
      <SelectTipoDeContaBancaria />

      <TextField
        id='titularidade'
        label='Titularidade'
        value={titularidade}
        disabled
        fullWidth
      />

      <TextField
        id='documento'
        label='Documento'
        value={documento}
        disabled
        fullWidth
      />

      <FormControl style={{ minWidth: '100%', maxWidth: '100%' }} error={!!bancoError}>
        <InputLabel>
          Banco
        </InputLabel>
        <Select
          id='banco-id'
          value={bancoId}
          disabled={enviandoConta}
          onChange={(e) => {
            onChangeBanco(e.target.value);
          }}
        >
        {bancos.map((banco) => (
          <MenuItem
            value={banco.id}
            key={banco.id}
            style={{ display: 'block' }}
            dense
          >
            {banco.nome}
            <span style={{ float: 'right' }}>{banco.codigo}</span>
          </MenuItem>
        ))}
        </Select>
        <FormHelperText>{bancoError}</FormHelperText>
      </FormControl>

      <TextField
        id='agencia-input'
        label='Agência'
        value={agencia}
        error={!!agenciaError}
        helperText={agenciaError}
        disabled={enviandoConta}
        fullWidth
        onChange={(e) => onChangeAgencia(e.target.value)}
      />

      <TextField
        id='conta-input'
        label='Conta'
        value={conta}
        error={!!contaError}
        helperText={contaError}
        disabled={enviandoConta}
        fullWidth
        onChange={(e) => onChangeConta(e.target.value)}
      />
    </Container>
  );
};

FormContaBancaria.defaultProps = {
  bancos: [],
  bancoId: '',
  agencia: '',
  conta: '',
  bancoError: '',
  agenciaError: '',
  contaError: '',
};

FormContaBancaria.propTypes = {
  bancos: PropTypes.arrayOf(PropTypes.object),
  bancoId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  agencia: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  conta: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  bancoError: PropTypes.string,
  agenciaError: PropTypes.string,
  contaError: PropTypes.string,
  buscandoBancos: PropTypes.bool.isRequired,
  enviandoConta: PropTypes.bool.isRequired,
  onChangeBanco: PropTypes.func.isRequired,
  onChangeAgencia: PropTypes.func.isRequired,
  onChangeConta: PropTypes.func.isRequired,
  titularidade: PropTypes.string.isRequired,
  documento: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  bancos: state.contaBancaria.bancos,
  bancoId: state.contaBancaria.bancoId,
  agencia: state.contaBancaria.agencia,
  conta: state.contaBancaria.conta,
  bancoError: selectBancoIdError(state),
  agenciaError: selectAgenciaError(state),
  contaError: selectContaError(state),
  buscandoBancos: state.contaBancaria.buscandoBancos,
  enviandoConta: state.contaBancaria.enviandoConta,
  titularidade: selectTitularidade(state),
  documento: selectDocumento(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onChangeBanco: setBancoId,
  onChangeAgencia: setAgencia,
  onChangeConta: setConta,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormContaBancaria);
