import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { queries } from '../../../../utils/breakpoints';
import { AZUL_300, AZUL_400, BRANCO } from '../../../../utils/color';

export const StyledFormCadastroSacado = styled(Grid)`
  .titulo {
    font-weight: 700;
    font-size: 1vw;
  }

  .form {
    padding: 1vw 3.6vw 2.5vw;
  }

  .botao-submit {
    text-transform: uppercase;
    font-weight: 700;
  }

  .MuiFormControl-root {
    margin-top: 0.7em;
    width: 100%;
  }

  .MuiInputBase-root {
    font-size: 1vw;
    margin-top: 1.2em;
    padding-left: 5px;
  }

  .MuiInputLabel-root {
    font-size: 1vw;
  }

  .MuiInputLabel-shrink {
    font-weight: normal;
    font-size: 0.9vw;
  }

  .MuiFormControlLabel-label {
    font-size: 1.2vw;
  }

  .multa-container {
    display: flex;
    align-items: center;
  }

  .dica-container {
    margin-left: -10px;

    svg {
      color: ${AZUL_300};
      font-size: 1.2vw;
      cursor: pointer;
    }
  }

  .MuiAutocomplete-root .MuiFormControl-root {
    margin-top: 0;
  }

  @media ${queries.mobileDown} {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    background-color: ${BRANCO};
    flex-direction: column;

    .titulo {
      font-size: 4.2vw;
      margin-bottom: 2vw;
      text-align: center;
    }

    .MuiInputBase-root {
      font-size: 3.5vw;
      margin-top: 1.2em;
    }

    .MuiInputLabel-root {
      font-size: 3.5vw;
    }

    .MuiInputLabel-shrink {
      font-size: 3.9vw;
    }

    .MuiFormControl-root {
      margin-top: 1.2em;
    }

    .MuiFormControlLabel-label {
      font-size: 3.5vw;
    }
    .MuiFormControlLabel-root {
      margin-top: 1vw;
    }

    .botao-submit {
      width: 80%;
    }

    .MuiButton-label {
      font-size: 3vw;
    }

    .header {
      color: ${AZUL_400};
      text-decoration: underline;
      font-size: 4.8vw;
      display: flex;
      align-items: center;
      gap: 24vw;
      margin-bottom: 7vw;
      margin-top: 2vw;

      svg {
        font-size: 7vw;
      }
    }

    .multa-container {
      margin-bottom: 20vw;
    }

    .dica-container svg {
      font-size: 3.3vw;
    }
  }

  @media ${queries.desktopUp} {
    .titulo {
      font-size: 0.9rem;
    }

    .form {
      padding: 1rem 3rem 2rem;
    }

    .MuiInputBase-root,
    .MuiInputLabel-root {
      font-size: 0.8rem;
    }

    .MuiFormControl-root {
      margin-top: 1.2em;
    }

    .MuiInputLabel-shrink {
      font-size: 0.8rem;
    }

    .MuiInputLabel-root {
      font-size: inherit;
    }

    .MuiFormControlLabel-label {
      font-size: 1rem;
    }

    .dica-container svg {
      font-size: 14px;
    }
  }
`;
