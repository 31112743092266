import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Alert from '@material-ui/lab/Alert';
import SolicitarBoletoRegularizacao from './SolicitarBoletoRegularizacao';
import Notification from './Notification';
import { dispensarNotificacaoDeSucesso } from '../store/conta/actions';
import { AlertTitle } from '@material-ui/lab';
import { Link } from '@material-ui/core';

const StyledAlert = styled.div`
  padding-top: 1rem;
  padding-bottom: 0;

  .alert {
    border: 1px solid #ff9800;
    font-size: 16px
  }

  .alert-title {
    margin-bottom: 0;
    font-size: 18px;
  }

  .alert-link {
    font-size: 16px;
    color: #663c00;
    font-family: inherit;
    font-weight: bold;
    vertical-align: inherit;
  }

  @media (max-width: 990px) {
    padding: 1rem;

    .alert-title {
      font-size: 16px;
    }

    .alert, .alert-link {
      font-size: 14px
    }
  }
`;

export const AlertaPendencias = ({
  avisoPendencias,
  enviadoComSucesso,
  onSuccessMessageClose
}) => {
   const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const onSuccess = () => {
    setOpenModal(false);
  };

  if (!avisoPendencias) {
    return null
  };

  return (
    <>
      <Link onClick={handleOpenModal}>
        <StyledAlert>
          <Alert severity='warning' className='alert'>
            <AlertTitle className='alert-title'>Atenção</AlertTitle>
            Existem pendências na sua conta — {''}
            <Link
              component="button"
              className='alert-link'
              onClick={handleOpenModal}
            >
            clique aqui
            </Link>
          </Alert>

          <Notification
            severity='success'
            message='Solicitação enviada com sucesso'
            open={enviadoComSucesso}
            onClose={onSuccessMessageClose}
          />
        </StyledAlert>
      </Link>

      <SolicitarBoletoRegularizacao
        onClick={handleOpenModal}
        openModal={openModal}
        onSuccess={onSuccess}
      />
    </>
  );
};

AlertaPendencias.propTypes = {
  avisoPendencias: PropTypes.bool.isRequired,
  enviadoComSucesso: PropTypes.bool.isRequired,
  onSuccessMessageClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  avisoPendencias: state.minhaConta.avisoPendencias,
  enviadoComSucesso: state.conta.enviadoComSucesso,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onSuccessMessageClose: dispensarNotificacaoDeSucesso,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlertaPendencias);
