import React from 'react';
import styled from 'styled-components';
import {
  AZUL_300,
  CINZA_400,
  CINZA_600,
  PRETO,
  VERMELHO,
} from '../../../utils/color';
import { Grid, Hidden } from '@material-ui/core';

const StyledDepoimento = styled.div`
  margin: 0 2vw;
  height: 100%;
  width: 28vw;
`;

const Depoimento = styled.div`
  aspect-ratio: 1.9;
  border: 1px solid ${CINZA_400};
  border-radius: 4vw;
  padding: 2.5vw;

  p {
    margin: 0;
    color: ${VERMELHO};
    font-size: 1.3vw;
    font-weight: 500;
    font-style: italic;
    line-height: 1.1em;
    text-align: center;
  }

  @media (max-width: 599px) {
    aspect-ratio: auto;
    border: none;
    padding: 7vw 0 17vw 0;

    p {
      color: ${PRETO};
      text-align: left;
      font-size: 3.8vw;
      font-weight: normal;
      font-style: normal;
    }
  }
`;

const Identificador = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  top: -20%;
  width: fit-content;
  margin: auto;
  padding: 1em;
  background-color: #fff;

  .container-nome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 1rem;
    color: ${CINZA_600};
    font-size: 1.2vw;
    font-weight: bold;
  }

  @media (max-width: 599px) {
    justify-content: flex-start;
    top: 0;
    width: 100%;
    padding: 0;

    .container-nome {
      padding-top: 2rem;
      color: ${VERMELHO};
      font-size: 5vw;
      line-height: normal;

      .empresa {
        font-size: 0.8em;
      }
    }
  }
`;

const Avatar = styled.div`
  height: calc(4.5vw + 5px);
  min-width: calc(4.5vw + 5px);
  border-radius: 50%;
  background-color: ${AZUL_300};
  display: flex;
  justify-content: center;

  img {
    height: 4.5vw;
    width: 4.5vw;
    border-radius: 50%;
    padding-top: 1px;
  }

  @media (max-width: 599px) {
    background-color: ${VERMELHO};
    height: calc(28vw + 8px);
    min-width: calc(28vw + 8px);

    img {
      height: 28vw;
      width: 28vw;
    }
  }
`;

const ItemDepoimento = ({ item, activeStep }) => {
  if (Number.isInteger(activeStep) && activeStep !== item.numero) {
    return null;
  }

  const Identificacao = () => {
    return (
      <Identificador>
        <Avatar>
          <img src={item.imagem} alt='avatar-depoimento' />
        </Avatar>
        <div className='container-nome'>
          <span>{item.nome}</span>
          <span className='empresa'>{item.empresa}</span>
        </div>
      </Identificador>
    );
  };

  return (
    <Grid item sm={4} xs={12}>
      <Hidden xsDown>
        <StyledDepoimento>
          <Depoimento>
            <p dangerouslySetInnerHTML={{ __html: item.depoimento }} />
          </Depoimento>
          <Identificacao />
        </StyledDepoimento>
      </Hidden>
      <Hidden smUp>
        <Identificacao />
        <Depoimento>
          <p dangerouslySetInnerHTML={{ __html: item.depoimento }} />
        </Depoimento>
      </Hidden>
    </Grid>
  );
};

export default ItemDepoimento;
