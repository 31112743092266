/* eslint-disable camelcase */
import {
  getBancos,
  postSolicitarTokenContaBancaria,
  postContaBancaria,
  getContasBancarias,
  deleteContaBancaria,
  postConfirmarCelular
} from './api';
import * as types from './types';
import { selectTitularidade, selectDocumento } from './selectors'

export const abrirModalAdicionar = () => ({
  type: types.ABRIR_MODAL_ADICIONAR,
});

export const fecharModalAdicionar = () => ({
  type: types.FECHAR_MODAL_ADICIONAR,
});

export const fetchBancosRequest = () => ({
  type: types.FETCH_BANCOS_REQUEST,
});

export const fetchBancosSuccess = ({ bancos }) => ({
  type: types.FETCH_BANCOS_SUCCESS,
  bancos,
});

export const fetchBancos = () => async (dispatch) => {
  dispatch(fetchBancosRequest());

  const res = await getBancos();
  const bancos = res?.data?.data;
  if (!bancos) {
    return;
  }

  dispatch(fetchBancosSuccess({ bancos }));
};

export const setBancoId = (value) => ({
  type: types.SET_BANCO_ID,
  value,
});

export const setAgencia = (value) => ({
  type: types.SET_AGENCIA,
  value,
});

export const setConta = (value) => ({
  type: types.SET_CONTA,
  value,
});

export const solicitarTokenContaBancariaRequest = () => ({
  type: types.SOLICITAR_TOKEN_CONTA_BANCARIA_REQUEST,
});

export const solicitarTokenContaBancariaSuccess = () => ({
  type: types.SOLICITAR_TOKEN_CONTA_BANCARIA_SUCCESS,
});

export const solicitarTokenContaBancariaFailure = (errors) => ({
  type: types.SOLICITAR_TOKEN_CONTA_BANCARIA_ERROR,
  errors,
});

export const solicitarTokenContaBancaria = ({ onSuccess }) => (
  async (dispatch, getState) => {
    dispatch(solicitarTokenContaBancariaRequest());

    const state = getState();
    const { bancoId, agencia, conta, tipo } = state.contaBancaria;
    const payload = {
      bancoId,
      agencia,
      conta,
      tipo,
    };

    try {
      await postSolicitarTokenContaBancaria(payload);
        dispatch(solicitarTokenContaBancariaSuccess());
        onSuccess();
      }
    catch (error) {
      const data = error?.response?.data?.errors
      dispatch(solicitarTokenContaBancariaFailure(data));
    }
  }
);

export const enviarContaBancariaRequest = () => ({
  type: types.ENVIAR_CONTA_BANCARIA_REQUEST,
});

export const enviarContaBancariaSuccess = (contaBancaria) => ({
  type: types.ENVIAR_CONTA_BANCARIA_SUCCESS,
  contaBancaria,
});

export const enviarContaBancariaFailure = (errors) => ({
  type: types.ENVIAR_CONTA_BANCARIA_ERROR,
  errors,
});

export const enviarContaBancaria = (token) => (
  async (dispatch, getState) => {
    dispatch(enviarContaBancariaRequest());

    const state = getState();
    const { bancoId, agencia, conta, tipo } = state.contaBancaria;
    const payload = {
      bancoId,
      agencia,
      conta,
      tipo,
      token,
    };

    try {
      const res = await postContaBancaria(payload);
      const contaBancaria = res?.data?.data
      dispatch(enviarContaBancariaSuccess(contaBancaria));
    }
    catch (error) {
      const data = { token: error?.response?.data?.message }
      dispatch(enviarContaBancariaFailure(data))
    }
  }
);

export const dispensarNotificacaoDeSucesso = () => ({
  type: types.DISPENSAR_NOTIFICACAO_DE_SUCESSO,
});

export const fetchContasRequest = () => ({
  type: types.FETCH_CONTAS_REQUEST,
});

export const fetchContasSuccess = ({ contasBancarias }) => ({
  type: types.FETCH_CONTAS_SUCCESS,
  contasBancarias,
});

export const fetchContasBancarias = () => (
  async (dispatch) => {
    dispatch(fetchContasRequest());

    const res = await getContasBancarias();
    const contasBancarias = res?.data?.data;
    if (!contasBancarias) {
      return;
    }
    dispatch(fetchContasSuccess({ contasBancarias }));
  }
);

export const deleteContaBancariaRequest = () => ({
  type: types.DELETE_CONTA_BANCARIA_REQUEST,
});

export const deleteContaBancariaSuccess = (id) => ({
  type: types.DELETE_CONTA_BANCARIA_SUCCESS,
  id,
});

export const deleteContaBancariaFailure = (errors) => ({
  type: types.DELETE_CONTA_BANCARIA_ERROR,
  errors,
});

export const removerContaBancaria = (id) => (
  (dispatch) => {
    dispatch(deleteContaBancariaRequest());

    return (
      deleteContaBancaria(id).then(
        () => {
          dispatch(deleteContaBancariaSuccess(id));
        },
        ({ data: { data } }) => {
          dispatch(deleteContaBancariaFailure({ data }));
        },
      )
    );
  }
);

export const setTipo = (value) => ({
  type: types.SET_TIPO,
  value,
});

export const confirmarCelularRequest = () => ({
  type: types.CONFIRMAR_CELULAR_REQUEST,
});

export const confirmarCelularSuccess = (celularValido) => ({
  type: types.CONFIRMAR_CELULAR_SUCCESS,
  celularValido
});

export const confirmarCelularFailure = (error) => ({
  type: types.CONFIRMAR_CELULAR_ERROR,
  error,
});

export const confirmarCelular = (celular) => (
  async (dispatch, getState) => {
    dispatch(confirmarCelularRequest());

    const state = getState();
    const { bancoId, agencia, conta, tipo } = state.contaBancaria;
    const titularidade = selectTitularidade(state)
    const cpfcnpj = selectDocumento(state)
    const payload = {
      bancoId,
      agencia,
      conta,
      tipo,
      cpfcnpj,
      titularidade
    };

    try {
      const res = await postConfirmarCelular({ celular, payload });
      const celularValido = res?.data?.success
      dispatch(confirmarCelularSuccess(celularValido));
    }
    catch (error) {
      const data = error?.response?.data?.success
      dispatch(confirmarCelularFailure(data));
    }
  }
);
