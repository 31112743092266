import { Link } from '@material-ui/core';
import React from 'react';
import Icone from '../../../Icone';

import { StyledAguardandoAssinatura } from './AguardandoAssinatura.style';

const AguardandoAssinatura = () => {
  return (
    <StyledAguardandoAssinatura>
      <div className='icone'>
        <Icone name='saque' />
      </div>
      <span className='pedido-concluido'>
        <span className='negrito'>Seu pedido foi concluído.</span> Para
        formalizar essa operação, veriﬁque seu e-mail. Após ler atentamente,{' '}
        <span className='negrito'>
          retorne a mensagem com a palavra “concordo”.
        </span>
      </span>
      <p className='obrigado negrito'>Obrigado por usar o Crédito Rotativo</p>
      <span className='contato'>
        Qualquer dúvida entre em contato pelo email{' '}
        <span className='email'>atendimento@creditorotativo.com.br</span> ou
        pelo Whatsapp (11) 933-598-394.
      </span>
      <Link href='/minhaconta/recebaantes' className='link-pagina-inicial'>
        Página inicial
      </Link>
    </StyledAguardandoAssinatura>
  );
};

export default AguardandoAssinatura;
