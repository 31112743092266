import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { RootState } from '../../../../store';
import { adicionarParcela } from '../../../../store/antecipacao/actions';
import Texto from '../../../Texto';
import Parcela from './Parcela';
import { ParcelasBoletosStyle } from './ParcelasBoletos.style';

const ParcelasBoletos = ({ adicionarParcela, listaParcelas }) => {
  const limiteAtingido = listaParcelas.length >= 10;

  return (
    <ParcelasBoletosStyle>
      <h1 className='titulo'>Adicione as datas de vencimento</h1>
      <div className='boletos-container'>
        {listaParcelas.map((parcela, index) => (
          <Parcela numeroParcela={index} parcela={parcela} key={index} />
        ))}
        <Button
          className='botao-adicionar'
          variant='outlined'
          color='primary'
          onClick={adicionarParcela}
          disabled={limiteAtingido}
          startIcon={<AddIcon />}
        >
          Adicionar Novo Boleto
        </Button>
        {limiteAtingido && (
          <Texto className='texto-limite'>Limite de parcelas atingido</Texto>
        )}
      </div>
    </ParcelasBoletosStyle>
  );
};

const mapStateToProps = (state: RootState) => ({
  listaParcelas: state.antecipacao.parcelas,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      adicionarParcela,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ParcelasBoletos);
