import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import {
  BRANCO,
  CINZA_200_20,
  CINZA_400,
  CINZA_600,
  PRETO,
} from '../../../utils/color';

export const StyledFiltroBoletos = styled.div`
  hr {
    background-color: ${PRETO};
    height: 1px;
    margin-bottom: 0.3vw;
  }

  .filtro {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${CINZA_200_20};
    padding: 0.6vw 0.7vw;
    gap: 1.5vw;
    margin-bottom: 1.6vw;

    .filtre {
      color: ${CINZA_600};
      font-size: 0.7vw;
    }

    button {
      margin: 0;
      font-size: 0.6vw;
      min-width: 4vw;
      line-height: 1.15;
    }
  }

  .chip {
    font-size: 0.65vw;
    height: 1.5vw;
    color: ${CINZA_600};

    .calendario {
      font-size: 0.8vw;
    }
  }

  .MuiChip-label {
    padding: 0.2vw 0.8vw 0;
  }

  .MuiChip-deleteIcon {
    background: radial-gradient(
      circle,
      ${CINZA_600} 0%,
      ${BRANCO} 60%,
      ${BRANCO} 100%
    );
    color: ${BRANCO};
    border-radius: 50%;
    height: 1vw;
    width: 1vw;
  }

  @media ${queries.desktopUp} {
    hr {
      margin-bottom: 4px;
    }

    .filtro {
      padding: 8px 10px;
      gap: 20px;
      margin-bottom: 20px;

      .filtre {
        font-size: 10px;
      }

      button {
        font-size: 10px;
        min-width: 52px;
      }
    }

    .chip {
      font-size: 8px;
      height: 18px;

      .calendario {
        font-size: 10px;
      }
    }

    .MuiChip-label {
      padding: 2px 10px 0;
    }

    .MuiChip-deleteIcon {
      height: 12px;
      width: 12px;
    }
  }

  @media ${queries.mobileDown} {
    hr {
      margin-bottom: 0;
      height: 2px;
    }

    .filtro {
      background-color: unset;
      padding: 3vw 0 1.3vw;
      gap: 3vw;
      margin-bottom: 0;

      .filtre {
        color: ${CINZA_600};
        flex: 1;
        font-size: 2.7vw;
      }

      button {
        font-size: 2.7vw;
        min-width: 20.4vw;
        margin-right: 1vw;
        border-color: ${CINZA_400};
        line-height: 1.75;
      }
    }

    .chip {
      font-size: 2.8vw;
      height: 6vw;

      .calendario {
        display: none;
      }
    }

    .MuiChip-label {
      padding: 0 2.3vw 0 3vw;
    }

    .MuiChip-deleteIcon {
      height: 3vw;
      width: 3vw;
      margin-right: 2vw;
    }
  }
`;
