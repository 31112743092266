import styled from 'styled-components';
import { queries } from '../../../utils/breakpoints';
import {
  AZUL_300,
  CINZA_400,
  CINZA_600,
  CINZA_700,
} from '../../../utils/color';

const StyledFooterPedido = styled.div`
  color: ${CINZA_700};
  font-size: 0.9vw;
  .linha {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.8vw;
    padding-left: 0.4vw;
    align-items: baseline;

    .valor {
      font-weight: bold;
    }

    svg {
      color: ${AZUL_300};
      font-size: 1vw;
      cursor: pointer;
      position: absolute;
      margin-top: 0.3em;
      margin-left: 0.2em;
    }
  }

  .divider-maior {
    height: 2px;
    background-color: ${CINZA_400};
  }

  .valor-container {
    font-weight: bold;
  }

  .debitos {
    color: ${CINZA_600};
    padding-bottom: 0.8vw;
    .subtitulo {
      display: inline-block;
      padding: 0.8vw;
      padding-left: 0.4vw;
      font-weight: bold;
    }
    .linha {
      padding: 0 0.4vw;
      line-height: 1.7;
    }
    .valor {
      font-weight: normal;
    }
    .valor-container .linha {
      padding-bottom: 0.4vw;
    }
  }

  @media ${queries.desktopUp} {
    font-size: 12px;

    .linha {
      padding: 10px;
      padding-left: 5px;

      svg {
        font-size: 14px;
      }
    }

    .debitos {
      padding-bottom: 10px;
      .subtitulo {
        padding: 10px;
        padding-left: 5px;
      }
      .linha {
        padding: 0 5px;
      }
      .valor-container .linha {
        padding-bottom: 5px;
      }
    }
  }

  @media ${queries.mobileDown} {
    font-size: 3.3vw;

    .linha {
      padding: 2.3vw;

      svg {
        font-size: 3.3vw;
      }
    }
    .debitos {
      padding-bottom: 2.3vw;
      .subtitulo {
        padding: 2.3vw 2.3vw 1.3vw;
      }
      .linha {
        padding: 0 2.3vw;
      }
      .valor-container .linha {
        padding-bottom: 1.7vw;
      }
    }
  }
`;

export default StyledFooterPedido;
