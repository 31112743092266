import React from 'react';
import styled from 'styled-components';
import { Container, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import RoundButton from '../../../components/RoundButton';
import ContatoInfo from './ContatoInfo';
import FormContato from './FormContato';

const StyledFaleConosco = styled(Container)`
  padding: 3rem;

  .cadastro-button-container {
    padding: 0 2rem 3rem 2rem;
    text-align: center;
  }

  @media (max-width: 960px) {
    padding: 0;
    text-align: right;

    .cadastro-button-container {
      padding: 1rem;
    }
  }
`;

const FaleConosco = ({ isSignedIn }) => {
  const CallToActionButton = () => {
    const path = isSignedIn ? '/minhaconta' : '/cadastro';
    const buttonLabel = isSignedIn ? 'Acesse sua conta' : 'Faça seu cadastro';

    return (
      <RoundButton value={buttonLabel} color='primary' href={path} />
    );
  };

  return (
    <StyledFaleConosco name='faleconosco' id='faleconosco'>
      <div className='cadastro-button-container'>
        <CallToActionButton />
      </div>

      <Grid container>
        <ContatoInfo />
        <FormContato />
      </Grid>
    </StyledFaleConosco>
  );
};

const mapStateToProps = (state) => ({
  isSignedIn: state.auth.isSignedIn,
});

export default connect(
  mapStateToProps,
)(FaleConosco);
